const getBase64FromUrl = async (
  url: string
): Promise<string | ArrayBuffer | null> => {
  const data = await fetch(url, {
    mode: `cors`,
    cache: `no-cache`,
  })
  const blob = await data.blob()
  const file = new File([blob], ``, { type: blob.type })
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export default getBase64FromUrl
