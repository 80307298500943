import { ApolloProvider } from '@apollo/client'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as React from 'react'
import Helmet from 'react-helmet'
import DraftEntriesProvider from '../../src/components/providers/DraftEntriesProvider'
import ErrorWrapper from '../../src/components/providers/ErrorWrapper'
import FormSubmitSyncProvider from '../../src/components/providers/FormSubmitSync'
import HarnessThemeProvider from '../../src/components/providers/HarnessTheme'
import LocationProvider from '../../src/components/providers/LocationProvider'
import NetworkProvider from '../../src/components/providers/Network'
import OfflineDataProvider from '../../src/components/providers/OfflineMode'
import SnackbarProvider from '../../src/components/providers/Snackbar'
import SoundProvider from '../../src/components/providers/SoundProvider'
import { ApolloAuthProvider } from '../../src/components/providers/useApolloAuth'
import client from '../../src/services/apollo/client'

import '@wordpress/block-library/build-style/common.css'
import '@wordpress/block-library/build-style/style.css'
import '@wordpress/block-library/build-style/theme.css'

import './mui-license'

const PWAPrompt = React.lazy(() => import(`react-ios-pwa-prompt`))
interface Props {
  children: React.ReactNode
}

const Wrapper: React.FC<Props> = (props: Props) => {
  const { children } = props
  const [hasRendered, setHasRendered] = React.useState(false)

  React.useEffect(() => {
    if (!hasRendered) {
      setHasRendered(true)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="color-scheme" content="only light" />
      </Helmet>
      {hasRendered && (
        <React.Suspense fallback="">
          <PWAPrompt />
        </React.Suspense>
      )}
      <HarnessThemeProvider>
        <ErrorWrapper>
          <SnackbarProvider>
            <NetworkProvider>
              <ApolloProvider client={client}>
                <ApolloAuthProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <OfflineDataProvider>
                      <DraftEntriesProvider>
                        <FormSubmitSyncProvider>
                          <SoundProvider>
                            <LocationProvider>{children}</LocationProvider>
                          </SoundProvider>
                        </FormSubmitSyncProvider>
                      </DraftEntriesProvider>
                    </OfflineDataProvider>
                  </LocalizationProvider>
                </ApolloAuthProvider>
              </ApolloProvider>
            </NetworkProvider>
          </SnackbarProvider>
        </ErrorWrapper>
      </HarnessThemeProvider>
    </React.Fragment>
  )
}

export default Wrapper
