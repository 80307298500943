const getFileFromUrl = async (url: string): Promise<File | null> => {
  try {
    const data = await fetch(url, {
      mode: `cors`,
      cache: `no-cache`,
    })
    const filenameArr = url.split(`/`)
    if (!filenameArr.length) {
      return null
    }
    const filename = filenameArr[filenameArr.length - 1]
    const blob = await data.blob()
    const file = new File([blob], filename, { type: blob.type })
    return file
  } catch (error) {
    return null
  }
}

export default getFileFromUrl
