import { useSnackbar } from 'notistack'
import * as React from 'react'
import { WORKER_DEFAULT_WHERE_ARGS } from '../../components/GravityForms/Fields/searchers/WorkerSearchField'
import { OFFLINE_DATA_BATCH_SIZE } from '../../constants'
import {
  RootQueryToUserConnectionEdge,
  useAllWorkersLazyQuery,
} from '../../types/generated'
import { UseOfflineDataReturnValue } from '../../types/offlineData'
import usePrevious from '../usePrevious'

const useOfflineWorkers = (
  setOfflineData: React.AsyncDispatch<
    React.SetStateAction<RootQueryToUserConnectionEdge[]>
  >,
  excludeDatabaseIds?: number[]
): UseOfflineDataReturnValue => {
  const { enqueueSnackbar } = useSnackbar()
  const [fetching, setFetching] = React.useState(false)
  const batchSize = OFFLINE_DATA_BATCH_SIZE
  const [isLoading, setIsLoading] = React.useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const [getWorkers, { refetch, called, fetchMore, loading, data }] =
    useAllWorkersLazyQuery({
      variables: {
        first: batchSize,
        after: null,
        where: {
          ...WORKER_DEFAULT_WHERE_ARGS,
          exclude: excludeDatabaseIds,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: `network-only`,
    })

  const hasNextPage = data?.workers?.pageInfo?.hasNextPage
  const hasPreviousPage = data?.workers?.pageInfo?.hasPreviousPage

  const shouldFetchMore = (called && !loading && hasNextPage) ?? false

  if (shouldFetchMore && fetchMore) {
    fetchMore({
      variables: {
        first: batchSize,
        after: data?.workers?.pageInfo?.endCursor,
      },
    })
  }

  const doneFetching =
    ((!loading && called && data && !hasNextPage) ||
      data?.workers?.edges?.length === 0) ??
    false
  const prevDoneFetching = usePrevious(doneFetching && hasPreviousPage)

  React.useEffect(() => {
    if (!prevDoneFetching && doneFetching && fetching) {
      const edges = data?.workers?.edges ?? []
      enqueueSnackbar(`${edges?.length} Workers downloaded`)
      setTimeout(() => {
        // @ts-expect-error this is fine
        setOfflineData(edges).then(stopLoading).catch(stopLoading)
        setFetching(false)
      }, 300)
    }
    // eslint-disable-next-line
  }, [doneFetching, prevDoneFetching, enqueueSnackbar, fetching])

  return {
    getOfflineData: () => {
      setFetching(true)
      startLoading()
      !called ? getWorkers() : refetch?.()
    },
    loading: isLoading,
    done: doneFetching,
    called: called,
  }
}

export default useOfflineWorkers
