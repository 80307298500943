import { TextFieldProps } from '@mui/material'

export const DEFAULT_SITE_LANG_SLUG = `en_us`

import { DateFieldFormatEnum } from '../types/generated'

export const AUTH_KEY = `HARNESS_AUTH`
export const IS_PROD = process.env.NODE_ENV === `production`
export const IS_DEV = process.env.NODE_ENV === `development`

export const HARNESS_OFFLINE_PROJECTS = `harnessOfflineProject`
export const HARNESS_OFFLINE_TOOLBOX_TALKS = `harnessOfflineToolboxTalks`
export const HARNESS_OFFLINE_WORKERS = `harnessOfflineWorkers`
export const HARNESS_OFFLINE_DOCUMENTS = `harnessOfflineDocuments`
export const HARNESS_OFFLINE_EQUIPMENT = `harnessOfflineEquipment`
export const HARNESS_OFFLINE_LESSONS = `harnessOfflineLessons`
export const HARNESS_OFFLINE_CORRECTIVE_ACTIONS = `harnessOfflineCorrectiveActions`
export const HARNESS_LOCAL_DRAFT_ENTRIES_KEY = `harnessDraftEntries`
export const SOFT_TEXT_OPACITY = 72
export const PAGE_SIZE_PROJECT = 10
export const ENTRY_FILTERS = `ENTRY_FILTERS`
export const HARNESS_ERROR_REPORTING_KEY = `HARNESS_ERROR_REPORTING_KEY`

// setting storage keys
export const SOUND_ON = `SOUND_ON`

// setting
export const SOUND_ON_DEFAULT = true

export const OFFLINE_DATA_BATCH_SIZE = 50

export const PAGE_SIZE = 20
export const SEARCH_PAGE_SIZE = 20
export const LOADING_SIM_TIME = 1200

export const LIST_CARD_HEIGHT = 200

export const DEBOUNCE_TIMEOUT = 900

export const PAGE_SIZE_DETAIL_PAGE_CA = 5
export const ENTRY_PAGE_CA_SCROLL_TIME = 5000

export const WORKER_COMPLIANCE_BATCH_SIZE = 1000

export const MAX_ERROR_REPORTS = 12

export const AUTO_HIDE_DURATION = 10000 // 10 seconds

// MUI styling
export const DEFAULT_VARIANT: TextFieldProps['variant'] = `outlined`

export const CARD_GAP = 2

export const USERNAME_TAKEN_ERROR = `Sorry, that username already exists!`
export const EMAIL_TAKEN_ERROR = `Sorry, that email address is already used!`

// validations
export const phoneRegExp = /^(?:1-)?\(?([0-9]{3})\)?[- ]?([0-9]{3})-([0-9]{4})$/
export const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).*$/
export const usernameRegExp = /^[a-z0-9]{4,}$/

//formatting
//phone format has to be different than validation regex to get groups from match properly
export const phoneRegExpFormat = /^(\d{3})(\d{3})(\d{4})$/

// GF Number Field calculations
export const CALCULATION_REGEX = /(?:{[\w\s\d_\-&?\/\(\)]+:([\d\.]+)})/gi

//support email
export const SUPPORT_EMAIL = `support@harnessup.com`

// Google Maps Search Base URL
export const GOOGLE_MAPS_SEARCH_BASE_URL = `https://www.google.com/maps/search/?api=1&query=`

export const DATE_FORMAT_MAP = {
  [DateFieldFormatEnum[`Mdy`]]: `MM/dd/yyyy`,
  [DateFieldFormatEnum[`Dmy`]]: `dd/MM/yyyy`,
  [DateFieldFormatEnum[`DmyDash`]]: `dd-MM-yyyy`,
  [DateFieldFormatEnum[`DmyDot`]]: `dd.MM.yyyy`,
  [DateFieldFormatEnum[`YmdDash`]]: `yyyy-MM-dd`,
  [DateFieldFormatEnum[`YmdDot`]]: `yyyy.MM.dd`,
  [DateFieldFormatEnum[`YmdSlash`]]: `yyyy/MM/dd`,
}

export const MONTHS = [
  `Jan`,
  `Feb`,
  `Mar`,
  `Apr`,
  `May`,
  `Jun`,
  `Jul`,
  `Aug`,
  `Sep`,
  `Oct`,
  `Nov`,
  `Dec`,
]

export const FAB_HEIGHT = `56px`

export const WARN_RANGE = 30

export const ENABLE_OFFLINE_MODE_MSG = `Options enabled with respective offline mode collection`
