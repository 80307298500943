/**
 * Custom Post Type Singular Name
 */
export const PHOTO_SINGLE_NAME = `photo`

/**
 * Custom Post Type Plural Name
 */
export const PHOTO_PLURAL_NAME = `photos`

/**
 * Session Storage Key for filters
 */
export const PHOTO_LIST_PAGE_FILTERS = `PHOTO_LIST_PAGE_FILTERS`

/**
 * CPT Pages Url Slug
 */
export const PHOTO_SLUG = `photos`
