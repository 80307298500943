import { ApolloClient, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { authDataVar, cache } from './cache'
import fetch from 'cross-fetch'
import {
  deleteAuthData,
  setAuthData,
} from '../../components/providers/useApolloAuth'
import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { tokenExpired } from '../../utils/tokenExpired'
import { createUploadLink } from 'apollo-upload-client'
import decode from 'jwt-decode'
import { Me_FragFragmentDoc } from '../../types/generated'

export const uri = process.env.GATSBY_API_URL
const httpLink = createUploadLink({
  uri,
  fetch,
})
//
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // console.log({ graphQLErrors })
  }
  if (networkError) {
    // console.log({ networkError })
  }
})

// /**
//  * Refresh auth token if it is expired.
//  */
const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: `refreshJwtAuthToken`,
  isTokenValidOrUndefined: async () => {
    const { authToken } = authDataVar()
    return !!authToken && !tokenExpired(authToken)
  },
  fetchAccessToken: () => {
    const { refreshToken } = authDataVar()
    const query = `
      mutation refreshJwtAuthToken($input: RefreshJwtAuthTokenInput!) {
        refreshJwtAuthToken(input: $input) {
          authToken
        }
      }
    `

    return fetch(uri, {
      method: `POST`,
      mode: `cors`,
      headers: {
        Accept: `application/json`,
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        query,
        variables: {
          input: {
            jwtRefreshToken: refreshToken || ``,
          },
        },
      }),
    })
  },
  handleFetch: async (response: { authToken: string }) => {
    const { authToken } = response
    const tokenData = decode<{
      data?: {
        user?: { id?: string }
      }
    }>(authToken)
    const userId = tokenData?.data?.user?.id
    const query = `
    ${Me_FragFragmentDoc?.loc?.source?.body}

    query MeRefresh($databaseId: ID!) {
      user(id: $databaseId, idType: DATABASE_ID) {
        ...ME_FRAG
      }
    }
    `
    const res = await fetch(uri, {
      method: `POST`,
      mode: `cors`,
      headers: {
        Accept: `application/json`,
        'Content-Type': `application/json`,
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        query,
        variables: {
          databaseId: userId,
        },
      }),
    })
    const result = await res.json()
    const user = result?.data?.user
    setAuthData({ ...authDataVar(), authToken, user, userId: user?.databaseId })
  },
  handleError: () => {
    // only delete auth data if we are online
    // this prevents the auth data from being deleted when the user
    // is offline and the token refresh fails
    // this allows the service worker to handle the request for pages
    if (
      typeof window !== `undefined` &&
      window &&
      window.navigator &&
      window.navigator.onLine
    ) {
      deleteAuthData()
    }
  },
})

/**
 * Include auth token in request headers.
 */
const authLink = setContext((_, { headers }) => {
  const { authToken } = authDataVar()

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : ``,
    },
  }
})

/**
 * Update the authToken and refreshToken with the updated tokens
 * sent back in the response headers.
 */
const tokenUpdateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext()

    if (headers) {
      const authToken = headers.get(`x-jwt-auth`)
      const refreshToken = headers.get(`x-jwt-refresh`)

      if (authToken) {
        setAuthData({ ...authDataVar(), authToken })
      }

      if (refreshToken) {
        setAuthData({ ...authDataVar(), refreshToken })
      }
    }

    return response
  })
})

const client = new ApolloClient({
  link: ApolloLink.from([
    tokenRefreshLink,
    authLink,
    errorLink,
    tokenUpdateLink,
    httpLink,
  ]),
  cache,
})

export default client
