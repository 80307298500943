import { useReactiveVar } from '@apollo/client'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { CORRECTIVE_ACTION_DEFAULT_WHERE_ARGS } from '../../components/GravityForms/Fields/searchers/CorrectiveActionSearchField'
import { OFFLINE_DATA_BATCH_SIZE } from '../../constants'
import { authDataVar } from '../../services/apollo/cache'
import {
  PostsToPostsConnectionNameEnum,
  RelationEnum,
  RootQueryToCorrectiveActionConnectionEdge,
  useAllCorrectiveActionsLazyQuery,
} from '../../types/generated'
import { UseOfflineDataReturnValue } from '../../types/offlineData'
import usePrevious from '../usePrevious'

const useOfflineCorrectiveActions = (
  setOfflineData: React.AsyncDispatch<
    React.SetStateAction<RootQueryToCorrectiveActionConnectionEdge[]>
  >
): UseOfflineDataReturnValue => {
  const { enqueueSnackbar } = useSnackbar()
  const batchSize = OFFLINE_DATA_BATCH_SIZE
  const [fetching, setFetching] = React.useState(false)
  // const oneWeekAgo = addDays(-14, today)
  const [isLoading, setIsLoading] = React.useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)
  const user = useReactiveVar(authDataVar)?.user
  const userDatabaseId = user?.databaseId

  const [getCorrectiveActions, { refetch, called, fetchMore, loading, data }] =
    useAllCorrectiveActionsLazyQuery({
      variables: {
        first: batchSize,
        after: null,
        where: {
          ...CORRECTIVE_ACTION_DEFAULT_WHERE_ARGS,
          postToPostConnectionQuery: {
            relation: RelationEnum[`Or`],
            connections: user?.databaseId
              ? [
                  {
                    connection:
                      PostsToPostsConnectionNameEnum[
                        `CorrectiveActionAssignedWorkers`
                      ],
                    ids: [user?.databaseId],
                  },
                  {
                    connection:
                      PostsToPostsConnectionNameEnum[
                        `CorrectiveActionDeficiencyOwners`
                      ],
                    ids: [user?.databaseId],
                  },
                ]
              : undefined,
          },
        },
      },
      notifyOnNetworkStatusChange: true,
    })

  const shouldFetchMore =
    (called && !loading && data?.correctiveActions?.pageInfo?.hasNextPage) ??
    false

  if (shouldFetchMore && fetchMore) {
    fetchMore({
      variables: {
        first: batchSize,
        after: data?.correctiveActions?.pageInfo?.endCursor,
      },
    })
  }

  const doneFetching =
    ((!loading &&
      called &&
      data &&
      !data?.correctiveActions?.pageInfo?.hasNextPage) ||
      data?.correctiveActions?.edges?.length === 0) ??
    false
  const prevDoneFetching = usePrevious(
    doneFetching && data?.correctiveActions?.pageInfo?.hasPreviousPage
  )

  React.useEffect(() => {
    if (!prevDoneFetching && doneFetching && fetching) {
      const edges = data?.correctiveActions?.edges ?? []
      setTimeout(() => {
        enqueueSnackbar(`${edges?.length} Corrective Actions downloaded`)
        // @ts-expect-error this is fine
        setOfflineData(edges).then(stopLoading).catch(stopLoading)
        setFetching(false)
      }, 600)
    }
    // eslint-disable-next-line
  }, [prevDoneFetching, doneFetching, enqueueSnackbar, fetching])

  return {
    getOfflineData: () => {
      if (!userDatabaseId) {
        return
      }
      setFetching(true)
      startLoading()
      !called ? getCorrectiveActions() : refetch?.()
    },
    loading: isLoading,
    done: doneFetching,
    called: called,
  }
}

export default useOfflineCorrectiveActions
