import { useSnackbar } from 'notistack'
import * as React from 'react'
import { LESSON_DEFAULT_WHERE_ARGS } from '../../components/GravityForms/Fields/searchers/LessonSearchField'
import { OFFLINE_DATA_BATCH_SIZE } from '../../constants'
import {
  RootQueryToLessonConnectionEdge,
  useAllLessonsLazyQuery,
} from '../../types/generated'
import { UseOfflineDataReturnValue } from '../../types/offlineData'
import usePrevious from '../usePrevious'

const useOfflineLessons = (
  setOfflineData: React.AsyncDispatch<
    React.SetStateAction<RootQueryToLessonConnectionEdge[]>
  >
): UseOfflineDataReturnValue => {
  const { enqueueSnackbar } = useSnackbar()
  const [fetching, setFetching] = React.useState(false)
  const batchSize = OFFLINE_DATA_BATCH_SIZE
  const [isLoading, setIsLoading] = React.useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const [getEquipment, { refetch, called, fetchMore, loading, data }] =
    useAllLessonsLazyQuery({
      variables: {
        first: batchSize,
        after: null,
        where: LESSON_DEFAULT_WHERE_ARGS,
      },
      notifyOnNetworkStatusChange: true,
    })

  const shouldFetchMore =
    (called && !loading && data?.lessons?.pageInfo?.hasNextPage) ?? false

  if (shouldFetchMore && fetchMore) {
    fetchMore({
      variables: {
        first: batchSize,
        after: data?.lessons?.pageInfo?.endCursor,
      },
    })
  }

  const doneFetching =
    ((!loading && called && data && !data?.lessons?.pageInfo?.hasNextPage) ||
      data?.lessons?.edges?.length === 0) ??
    false
  const prevDoneFetching = usePrevious(
    doneFetching && data?.lessons?.pageInfo?.hasPreviousPage
  )

  React.useEffect(() => {
    if (doneFetching && !prevDoneFetching && fetching) {
      const edges = data?.lessons?.edges ?? []
      enqueueSnackbar(`${edges?.length} Lessons downloaded`)
      setTimeout(() => {
        // @ts-expect-error this is fine
        setOfflineData(edges).then(stopLoading).catch(stopLoading)
        setFetching(false)
      }, 300)
    }
    // eslint-disable-next-line
  }, [doneFetching, prevDoneFetching, enqueueSnackbar, fetching])

  return {
    getOfflineData: () => {
      setFetching(true)
      startLoading()
      !called ? getEquipment() : refetch?.()
    },
    loading: isLoading,
    done: doneFetching,
    called: called,
  }
}

export default useOfflineLessons
