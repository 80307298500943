import Box from '@mui/material/Box'
import debounce from 'awesome-debounce-promise'
import { useSnackbar } from 'notistack'
import React from 'react'
import {
  CARD_GAP,
  DEBOUNCE_TIMEOUT,
  IS_DEV,
  SEARCH_PAGE_SIZE,
} from '../../../../constants'
import useIsVisible from '../../../../hooks/forms/useIsVisible'
import {
  Maybe,
  OrderEnum,
  RootQueryToUserConnectionEdge,
  RootQueryToUserConnectionWhereArgs,
  useAllWorkersQuery,
  User,
  UserRoleEnum,
  UsersConnectionOrderbyEnum,
  UserStatusEnum,
} from '../../../../types/generated'
import {
  Wp_FormFieldTypeEnum,
  Wp_MultiSelectField,
  Wp_MultiSelectFieldChoice,
  Wp_SelectField,
  Wp_SelectFieldChoice,
} from '../../../../types/generated-gatsby'
import { CommonFieldProps } from '../../../../types/gf/fields/CommonFieldProps'
import { createFieldCssClass } from '../../../../utils/gravityForms/fields/createFieldCssClass'
import { createFieldHtmlId } from '../../../../utils/gravityForms/fields/createFieldHtmlId'
import { useFormTranlation } from '../../../providers/Form/formTranslation'
import { useNetwork } from '../../../providers/Network'
import { useOfflineData } from '../../../providers/OfflineMode'
import AutoComplete, { AutoCompleteProps } from '../Common/AutoComplete'

export type WorkerSearchFieldExtraProps = {
  roles?: UserRoleEnum[]
  maxSelections?: number
}

export interface WorkerSearchFieldProps extends CommonFieldProps {
  field: Omit<Wp_SelectField | Wp_MultiSelectField, 'id'> & {
    id: number | string
  } & WorkerSearchFieldExtraProps
  defaultValue?: SelectFormFieldValueWorker | SelectFormFieldValueWorker[]
  dense?: boolean
  noOptionText?: AutoCompleteProps['noOptionText']
}

const OFFLINE_NO_OPTION_TEXT = `Options enabled with Workers Offline Mode Collection`

export const WORKER_DEFAULT_WHERE_ARGS: RootQueryToUserConnectionWhereArgs = {
  userStatus: UserStatusEnum[`Active`],
  orderby: [
    {
      field: UsersConnectionOrderbyEnum[`Registered`],
      order: OrderEnum[`Desc`],
    },
  ],
}

export type SelectFormFieldValueWorker =
  | (Wp_SelectFieldChoice & {
      email?: Maybe<string>
      role?: Maybe<string>
      name?: Maybe<string>
    })
  | undefined

export const createWorkerSelectOption = (
  edge: Maybe<{
    node?: Maybe<
      Pick<User, 'name' | 'email' | 'databaseId' | 'roles' | 'employeeId'>
    >
  }>
): SelectFormFieldValueWorker => ({
  text: `${edge?.node?.name}${
    edge?.node?.employeeId ? ` - ${edge?.node?.employeeId}` : ``
  }`,
  value: edge?.node?.databaseId?.toString() ?? ``,
  email: edge?.node?.email,
  role: edge?.node?.roles?.edges?.[0]?.node?.name,
  name: edge?.node?.name,
})

const WorkerSearchField: React.FC<WorkerSearchFieldProps> = (props) => {
  const {
    field,
    defaultValue: defaultValueProp,
    dense = false,
    noOptionText,
    formId,
    color = `primary`,
  } = props
  const {
    id,
    type,
    label,
    description,
    roles,
    cssClass,
    isRequired,
    choices,
    allowCustomOption,
    choiceTranslations,
    maxSelections,
  } = field
  const [isLoading, setIsLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isOnline = useNetwork()

  const offlineChoices = (
    useOfflineData()?.offlineData?.Workers as RootQueryToUserConnectionEdge[]
  )?.map(createWorkerSelectOption)
  const filteredOfflineChoices = roles?.length
    ? offlineChoices?.filter((c) => {
        const role = (c?.role?.toUpperCase() ?? ``) as UserRoleEnum
        return roles?.includes(role)
      })
    : offlineChoices

  const htmlId = createFieldHtmlId(formId, id)
  const fieldName = String(id)

  const { fieldTranslation } = useFormTranlation(choiceTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description

  const defaultChoices = choices || filteredOfflineChoices || []
  const defaultValue =
    defaultValueProp ??
    (choices as Wp_MultiSelectFieldChoice[])?.filter(
      (choice) => choice?.isSelected
    ) ??
    []

  const { isVisible, isHidden } = useIsVisible(field)

  const whereArgs: RootQueryToUserConnectionWhereArgs = {
    roleIn: roles ?? [],
    ...WORKER_DEFAULT_WHERE_ARGS,
  }
  const { loading, data, refetch } = useAllWorkersQuery({
    variables: {
      first: SEARCH_PAGE_SIZE,
      where: whereArgs,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `network-only`,
    skip: !isOnline || cssClass?.includes(`readonly`),
  })

  const getAsyncOptions = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e?.target?.value

    if (isOnline) {
      try {
        const result = await refetch?.({
          first: SEARCH_PAGE_SIZE,
          where: {
            ...whereArgs,
            search: inputValue || undefined,
            orderby: undefined,
          },
        })

        if (result?.error) {
          enqueueSnackbar(`Error fetching workers from server`, {
            variant: `error`,
          })
        }
      } catch (err) {
        enqueueSnackbar(
          IS_DEV
            ? (err as Error)?.message
            : `Error fetching workers from server`,
          {
            variant: `error`,
          }
        )
      }
    }
    setIsLoading(false)
  }

  const debouncedLoadOptions = debounce(getAsyncOptions, DEBOUNCE_TIMEOUT)

  const options = (data?.workers?.edges?.map(createWorkerSelectOption) ??
    defaultChoices) as AutoCompleteProps['options']

  return isVisible ? (
    <Box
      mb={dense ? 0 : CARD_GAP}
      className={createFieldCssClass(type, isHidden, cssClass)}
    >
      <AutoComplete
        defaultValue={defaultValue as AutoCompleteProps['defaultValue']}
        fieldName={fieldName}
        htmlId={htmlId}
        label={safeLabel}
        options={options}
        cssClass={cssClass}
        description={safeDescription}
        isMultiSelect={type === Wp_FormFieldTypeEnum.Multiselect}
        isRequired={!isHidden && !!isRequired}
        color={color}
        onInputChange={(e) => {
          setIsLoading(true)
          // @ts-expect-error this is fine
          debouncedLoadOptions(e)
        }}
        loading={loading || isLoading}
        freeSolo={allowCustomOption}
        noOptionText={!isOnline ? OFFLINE_NO_OPTION_TEXT : noOptionText}
        maxSelections={maxSelections}
      />
    </Box>
  ) : null
}

export default WorkerSearchField
