// When using TypeScript 4.x and above
import type {} from '@mui/x-data-grid-pro/themeAugmentation'

import { keyframes, ThemeOptions } from '@mui/material'
import harnessColors from './harnessColors'
const textPrimary = harnessColors?.textPrimary
const textSecondary = harnessColors?.textSecondary
const textTertiary = harnessColors?.textTertiary
const headerCommon = {
  fontWeight: 600,
  color: textPrimary,
}

const errorMessageKeyFrame = keyframes`
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateX(250px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateX(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateX(68px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateX(32px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateX(8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
`

const theme: ThemeOptions = {
  typography: {
    fontFamily: `'Work Sans', 'Helvetica', 'Ariel', sans-serif`,
    fontSize: 16,
    h1: {
      ...headerCommon,
    },
    h2: {
      ...headerCommon,
    },
    h3: {
      ...headerCommon,
    },
    h4: {
      ...headerCommon,
    },
    h5: {
      ...headerCommon,
    },
    h6: {
      ...headerCommon,
    },
    subtitle1: {
      ...headerCommon,
    },
    body1: {
      fontWeight: 400,
      color: textPrimary,
      lineHeight: 1.6,
    },
    body2: {
      fontWeight: 400,
      color: textSecondary,
      lineHeight: 1.6,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: `0.95rem`,
      color: textTertiary,
      lineHeight: 1.8,
    },
  },
  palette: {
    mode: `light`,
    primary: {
      main: harnessColors.green900,
    },
    secondary: {
      main: harnessColors.orange300,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    background: {
      default: harnessColors.green50,
      paper: `#fff`,
    },
  },
  // If you change the default breakpoints's values, you need to provide them all:
  breakpoints: {
    values: {
      // defaults
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // custom breakpoints
      signatureMobile: 700,
    },
  },
  components: {
    MuiCollapse: {
      styleOverrides: {
        wrapper: {
          pointerEvents: `all`,
          [`.SnackbarItem-variantWarning`]: {
            color: `rgba(0, 0, 0, 0.87) !important`,
            // .. etc for other variants
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            animation: `${errorMessageKeyFrame} 750ms cubic-bezier(0.87, 0, 0.13, 1) 0s 1 normal both`,
          },
        },
      },
    },
  },
}

export default theme
