import format from 'date-fns/fp/format'
import { FormFieldValuesInput } from '../../types/generated'
import { GFormFieldApollo } from '../../types/gravityForms'
import dateFormatter from '../dates/dateFormater'
import { nonNullable } from '../nonNullable'
import { CheckboxValue } from '../../components/GravityForms/Fields/CheckboxField'

export const excludedFields = [
  `FollowUpField`,
  `SectionField`,
  `PageField`,
  `HtmlField`,
]

/**
 * Covert the GF Entry FormFields into what is expected for a GF form submission
 *
 * @param entryFormFields Gravity Forms FormFields from a GF Entry
 * @returns FormFieldValuesInput[]
 */
const makeFieldValuesInputFromEntryFields = (
  entryFormFields?: GFormFieldApollo[]
): FormFieldValuesInput[] => {
  return (
    entryFormFields?.reduce<FormFieldValuesInput[]>((acc, field) => {
      const fieldId = field?.id as number
      const fieldTypeName = field?.__typename ?? ``

      if (excludedFields?.includes(fieldTypeName) || !fieldId) {
        return acc
      }

      if (fieldTypeName === `AddressField` && fieldId) {
        const addressValues = field?.addressValues

        if (!addressValues || !addressValues?.street) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            addressValues,
          },
        ]
      } else if (fieldTypeName === `NameField` && fieldId) {
        const nameValues = field?.nameValues

        if (!nameValues?.first || !nameValues?.last) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            nameValues,
          },
        ]
      } else if (fieldTypeName === `CheckboxField` && fieldId) {
        const cleanCheckboxValues: CheckboxValue[] | undefined =
          field?.checkboxValues
            ?.filter((check) => check?.value)
            ?.map((check) => {
              if (!check?.inputId || !check?.value) {
                return null
              }
              return {
                inputId: check?.inputId,
                value: check?.value,
              }
            })
            ?.filter(nonNullable)
        // ?.map(R.pick([`inputId`, `value`]))
        if (!cleanCheckboxValues?.length) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            checkboxValues: cleanCheckboxValues,
          },
        ]
      } else if (fieldTypeName === `ConsentField` && fieldId) {
        const value = field?.value ? `Yes` : null
        if (!value) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            value,
          },
        ]
      } else if (fieldTypeName === `EmailField` && fieldId) {
        const value = field?.value
        if (!value) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            emailValues: {
              value,
            },
          },
        ]
      } else if (fieldTypeName === `DateField`) {
        const value = field?.value
        if (!value) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            value: dateFormatter(value, { dateFormat: `P` }) as string,
          },
        ]
      } else if (fieldTypeName === `TimeField`) {
        const dateValue =
          format(`P`, new Date()) + ` ` + field?.timeValues?.displayValue
        if (!dateValue || !field?.timeValues?.displayValue) {
          return acc
        }
        const value = dateFormatter(dateValue, {
          dateFormat: field?.timeFormat === `H24` ? `HH:mm` : `p`,
        }) as string
        return [
          ...acc,
          {
            id: fieldId,
            value,
          },
        ]
      } else if (fieldTypeName === `SelectField`) {
        const value = field?.value
        if (!value) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            value,
          },
        ]
      } else if (fieldTypeName === `MultiSelectField`) {
        const values = field?.values
        if (!values?.length) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            values,
          },
        ]
      } else if (fieldTypeName === `PhotoField`) {
        return acc
      } else if (
        fieldTypeName === `ComboSignatureField` ||
        fieldTypeName === `SignatureField`
      ) {
        return acc
      } else if (fieldTypeName === `ListField`) {
        const value = field?.listValues
          ?.map((listFieldValue) => {
            if (!listFieldValue) {
              return null
            }
            return {
              rowValues: listFieldValue?.values ?? [],
            }
          })
          .filter(nonNullable)
        if (!value?.length) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            listValues: value,
          },
        ]
      } else {
        const value = field?.value
        if (!value) {
          return acc
        }
        return [
          ...acc,
          {
            id: fieldId,
            value,
          },
        ]
      }
    }, []) ?? []
  )
}

export default makeFieldValuesInputFromEntryFields
