import decode from 'jwt-decode'

export const tokenExpired = (token?: string | null): boolean => {
  if (!token) {
    return true
  } else {
    const decoded = decode<{ exp?: number }>(token)
    if (typeof decoded === `string`) {
      return true
    }
    return (decoded?.exp ?? 0) <= Math.floor(Date.now() / 1000)
  }
}
