/**
 * Custom Post Type Singular Name
 */
export const LESSON_SINGLE_NAME = `lesson`
/**
 * Custom Post Type Plural Name
 */
export const LESSON_PLURAL_NAME = `lessons`
/**
 * Session Storage Key for filters
 */
export const LESSON_LIST_PAGE_FILTERS = `LESSON_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const LESSON_SLUG = `lessons`
