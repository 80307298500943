import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { authDataVar } from '../../services/apollo/cache'

interface Props {
  children: React.ReactNode
}

interface LocationContextInterface {
  loading: boolean
  location: {
    permissionGranted: boolean
    lat?: string
    long?: string
    error?: string
  }
}

const LocationContext = React.createContext<
  LocationContextInterface | undefined
>(undefined)

const LocationProvider: React.FC<Props> = ({ children }: Props) => {
  const user = useReactiveVar(authDataVar)?.user
  const userId = user?.id

  const [loading, setLoading] = React.useState(false)

  const [location, setLocation] = React.useState<
    LocationContextInterface['location']
  >({
    permissionGranted: false,
    lat: undefined,
    long: undefined,
    error: undefined,
  })

  const onSuccess = (position: {
    coords: { latitude: number; longitude: number }
  }) => {
    setLocation({
      permissionGranted: true,
      lat: position?.coords?.latitude?.toString(),
      long: position?.coords?.longitude?.toString(),
      error: undefined,
    })
    setLoading(false)
  }

  const onError = () => {
    setLocation({
      lat: undefined,
      long: undefined,
      permissionGranted: false,
      error: `No Location Data`,
    })
    setLoading(false)
  }

  React.useEffect(() => {
    if (`geolocation` in navigator && userId) {
      setLoading(true)
      const watchedId = navigator.geolocation.watchPosition(onSuccess, onError)

      return () => navigator.geolocation.clearWatch(watchedId)
    } else {
      setLocation((state) => ({
        ...state,
        error: `Geolocation not Supported`,
      }))
    }
  }, [userId])

  return (
    <LocationContext.Provider
      value={{
        loading,
        location,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export const useLocationContext = (): LocationContextInterface => {
  const context = React.useContext(LocationContext)
  if (context === undefined) {
    throw new Error(`useLocation must be used withing a LocationContext`)
  }

  return context
}

export default LocationProvider
