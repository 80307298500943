import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import isValid from 'date-fns/fp/isValid'
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import { DEFAULT_SITE_LANG_SLUG } from '../../constants'
import { LanguageCodeEnum, Maybe } from '../../types/generated'
import { safeDateParse } from './safeDateParse'

export const DEFAULT_DATE_FORMAT = `PPP`

interface CreateFormat1 {
  relative?: boolean
  dateFormat: string
  locale?: Locale
  date: any
}
const createFormat =
  ({ relative, dateFormat, locale, date }: CreateFormat1) =>
  (parser: any) => {
    return relative
      ? formatDistance(parser(date), new Date(), {
          locale,
        })
      : format(parser(date), dateFormat, {
          locale,
        })
  }

/**
 *
 * Will format a given date to a desired format.
 * If the value is not a valid date it is returned
 */
export default function dateFormatter(
  date?: Maybe<string | number>,
  options?: {
    dateFormat?: string
    language?: Maybe<string> | Maybe<LanguageCodeEnum>
    relative?: boolean
  }
): string | number {
  if (typeof date === `undefined` || date === null) {
    return ``
  }

  const dateFormat = options?.dateFormat || DEFAULT_DATE_FORMAT
  const language = options?.language || DEFAULT_SITE_LANG_SLUG
  const relative = options?.relative || false
  const locale =
    language === `es` ||
    language === LanguageCodeEnum[`EsMx`] ||
    language === LanguageCodeEnum[`EsMx`].toLowerCase()
      ? es
      : enUS

  const masterFormater = createFormat({
    relative,
    dateFormat,
    locale,
    date,
  })

  if (typeof date === `number` || !Number.isNaN(Number(date))) {
    return date
  } else if (typeof date === `object`) {
    return ``
  } else if (isValid(safeDateParse(date))) {
    return masterFormater(safeDateParse)
  } else {
    return date
  }
}
