import { FieldValues } from 'react-hook-form'
import { FieldError, FormFieldValuesInput, Maybe } from './generated'
import { Wp_GfForm } from './generated-gatsby'
import { FormPagePageContext } from './gravityForms'

export enum LocalDraftEntryStatusEnum {
  /**
   * The state the form will be in for most of its live.
   * Whether the user uses 'save & continues' options or they go offline a form submission will be in this state
   */
  InProgress = `IN_PROGRESS`,
  /**
   * Follow Up Form is ready for submission, other than waiting for the parent follow up to form to be submitted.
   * Follow up Forms usually need data returned from the parent submission mutation result, such as `entryId`
   */
  AwaitingFollowUpToForm = `AWAITING_FOLLOW_UP_TO_FORM`,
  /**
   * Ready to submit once network connection is re-established
   */
  AwaitingOnlineStatus = `AWAITING_ONLINE_STATUS`,
  /**
   * Set when there has been an attempt to submit but failed
   */
  ErrorOnSubmit = `ERROR_ON_SUBMIT`,
  /**
   * Draft Entry created and used for editing the entry on the server
   */
  UpdateInProgress = `UPDATE_IN_PROGRESS`,
  /**
   * When a user has failed a quiz and needs to fix their answers
   */
  QuizFailed = `QUIZ_FAILED`,
  /**
   * When a CPT hardcoded form was submitted offline
   * As CPT forms cannot be automatically submitted due to the fact they have different onSubmit signatures
   */
  ReadyForSubmission = `READY_FOR_SUBMISSION`,
}

export interface FollowUpToForm {
  /**
   * The draftId of the parent follow up to form that has triggered the follow up form.
   */
  draftId: number
  /**
   * FieldIds of those form fields which caused this follow up field / form to be triggered.
   * Normally this is one fieldId from a workplace inspection form field to trigger a correction action
   * The fieldIds are used as each input's name attribute
   * string[] because that is what is returned from useWatch
   */
  deficiencyFieldIds?: Maybe<string>[]
  /** url to redirect the user back to on the parent form after follow up form done */
  fromUrl?: Maybe<string>
  /** formId of the parent form */
  formId?: Maybe<number>
  formSlug?: Maybe<string>
  entryId?: Maybe<string>
  formTitle?: Maybe<string>
}

export interface LocalDraftEntry {
  /**
   * client side managed, auto-incrementing id for drafts
   */
  draftId: number
  /**
   * EntryId of the Follow Up To Form's successful submission returned entryId
   */
  entryId?: Maybe<number>
  form?: Maybe<Pick<Wp_GfForm, 'slug'>> & {
    entryIdFieldId?: Maybe<number>
    /**
     * Whether or not this is a CPT creation form
     */
    isCPTForm?: boolean
    /**
     * For update Local Draft entry we need the entryId to update
     */
    entryId?: Maybe<number>
    type?: FormPagePageContext['type']
    formTitle?: Wp_GfForm['title']
    formId?: Wp_GfForm['databaseId']
  }
  /**
   * The return resumeToken from `createDraftEntry`, `updateDraftEntry`, or `submitGravityFormsForm` with `saveAsDraft` set to true
   * Does not change.
   * Can be used as the `id` when fetching darft entries with `gravityFormsEntry` with `idType` set to ID
   */
  resumeToken: string
  /**
   * Status of the DraftEntry
   */
  status: LocalDraftEntryStatusEnum
  /**
   * FieldValues right off the onSubmit handler which is used by react hook form's handleSubmit function
   */
  fieldValues?: Maybe<FieldValues>
  /**
   * Field values of the form in the format expected by a submit mutation to WP GraphQL Gravity Forms.
   */
  fieldValuesInputArray?: Maybe<Maybe<FormFieldValuesInput>[]>
  /**
   * Follow up to form data. The parent form which caused the follow up form to be triggered.
   * Will be set to null when the form is not a follow up form.
   */
  followUpToForm?: Maybe<FollowUpToForm>
  lastPage?: Maybe<number>
  errors?: Maybe<
    Maybe<{
      __typename?: 'FieldError' | undefined
      id?: Maybe<string | number>
      message?: FieldError['message']
    }>[]
  >
  lastUpdated?: Maybe<Date>
}

/**
 * Represents a collection of local draft entries, where each entry is identified by a unique draftId.
 */
export type LocalDraftEntries = {
  [draftId: number]: LocalDraftEntry
}
