import { useSnackbar } from 'notistack'
import * as React from 'react'
import { PROJECT_DEFAULT_WHERE_ARGS } from '../../components/GravityForms/Fields/searchers/ProjectSearchField'
import { OFFLINE_DATA_BATCH_SIZE } from '../../constants'
import {
  RootQueryToProjectConnectionEdge,
  useAllProjectsLazyQuery,
} from '../../types/generated'
import { UseOfflineDataReturnValue } from '../../types/offlineData'
import usePrevious from '../usePrevious'

const useOfflineProjects = (
  setOfflineData: React.AsyncDispatch<
    React.SetStateAction<RootQueryToProjectConnectionEdge[]>
  >
): UseOfflineDataReturnValue => {
  const { enqueueSnackbar } = useSnackbar()
  const [fetching, setFetching] = React.useState(false)
  const batchSize = OFFLINE_DATA_BATCH_SIZE
  const [isLoading, setIsLoading] = React.useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const variables = {
    first: batchSize,
    after: null,
    where: PROJECT_DEFAULT_WHERE_ARGS,
  }

  const [getProjects, { refetch, called, fetchMore, loading, data }] =
    useAllProjectsLazyQuery({
      variables,
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: `cache-and-network`,
    })

  const shouldFetchMore =
    (called && !loading && data?.projects?.pageInfo?.hasNextPage) ?? false

  if (shouldFetchMore && fetchMore) {
    fetchMore({
      variables: {
        first: batchSize,
        after: data?.projects?.pageInfo?.endCursor,
      },
    })
  }

  const doneFetching =
    ((!loading && called && data && !data?.projects?.pageInfo?.hasNextPage) ||
      data?.projects?.edges?.length === 0) ??
    false
  const prevDoneFetching = usePrevious(
    doneFetching && data?.projects?.pageInfo?.hasPreviousPage
  )

  React.useEffect(() => {
    const edges = data?.projects?.edges ?? []
    if (!prevDoneFetching && doneFetching && edges?.length && fetching) {
      setTimeout(() => {
        enqueueSnackbar(`${edges?.length} Projects downloaded`)
        // @ts-expect-error this is fine
        setOfflineData(edges).then(stopLoading).catch(stopLoading)
        setFetching(false)
      }, 900)
    }
    // eslint-disable-next-line
  }, [prevDoneFetching, doneFetching, enqueueSnackbar, fetching])

  return {
    getOfflineData: () => {
      setFetching(true)
      startLoading()
      !called ? getProjects() : refetch?.(variables)
    },
    loading: isLoading,
    done: doneFetching,
    called: called,
  }
}

export default useOfflineProjects
