/**
 * Custom Post Type Singular Name
 */
export const INCIDENT_REPORT_SINGLE_NAME = `incident_report`

/**
 * Custom Post Type Plural Name
 */
export const INCIDENT_REPORT_PLURAL_NAME = `incident_reports`

/**
 * Session Storage Key for filters
 */
export const INCIDENT_REPORT_LIST_PAGE_FILTERS = `INCIDENT_REPORT_LIST_PAGE_FILTERS`

/**
 * Session storage key
 */
export const ADDITIONAL_INFO_PANEL_OPEN = `ADDITIONAL_INFO_PANEL_OPEN`

/**
 * Session storage key
 */
export const ROOT_CAUSE_PANEL_OPEN = `ROOT_CAUSE_PANEL_OPEN`

/**
 * CPT Pages Url Slug
 */
export const INCIDENT_REPORT_SLUG = `incident-reports`

/**
 * Additional Info form slug
 */
export const ADDITIONAL_INFO_SLUG = `additional-information-entry`

/**
 * Root cause form slug
 */
export const ROOT_CAUSE_SLUG = `root-cause-entry`

/**
 * Meta Key
 */
export const INCIDENT_REPORT_DATE_OF_META_KEY = `date_of_incident`

/**
 * View Incident report reports page
 */
export const VIEW_IR_REPORTS_CAP = `view_ir_reports`

/**
 * View Incident reports company breakdown chart
 */
export const VIEW_IR_COMPANY_BREAKDOWN_CAP = `view_ir_company_breakdown_chart`

/**
 * View Incident reports division breakdown chart
 */
export const VIEW_IR_DIVISION_BREAKDOWN_CAP = `view_ir_division_breakdown_chart`
