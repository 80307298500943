import { graphql, useStaticQuery } from 'gatsby'
import { AllLanguagesQuery } from '../../../types/generated-gatsby'

export const useAllLanguages = (): AllLanguagesQuery => {
  const data = useStaticQuery<AllLanguagesQuery>(
    graphql`
      query AllLanguages {
        wp {
          languages {
            slug
            name
            id
            locale
          }
        }
      }
    `
  )

  return data
}

export default useAllLanguages
