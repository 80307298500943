import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  Maybe,
  Wp_ConditionalLogic,
  Wp_ConditionalLogicLogicTypeEnum,
  Wp_ConditionalLogicRule,
} from '../../types/generated-gatsby'
import { GFormField } from '../../types/gravityForms'
import { SelectFormFieldValue } from '../../types/extra'
import { useReactiveVar } from '@apollo/client'
import { authDataVar } from '../../services/apollo/cache'
import any from 'lodash/fp/any'
import head from 'lodash/fp/head'
import equals from 'lodash/fp/equals'
import propEq from 'lodash/fp/propEq'
import compose from 'lodash/fp/compose'
import lt from 'lodash/fp/lt'
import propOr from 'lodash/fp/propOr'
import endsWith from 'lodash/fp/endsWith'
import gt from 'lodash/fp/gt'
import startsWith from 'lodash/fp/startsWith'
import includes from 'lodash/fp/includes'
import find from 'lodash/fp/find'

/**
 * @param watched - array of form field values.
 * @returns (rule: Wp_ConditionalLogicRule, idx: number) => boolean
 */
export const isRuleMatch =
  (watched: unknown[]) =>
  (rule: Maybe<Wp_ConditionalLogicRule>, idx: number): boolean => {
    // the watched array of values is the same length oa the rules, which is the same length and order as the fieldsIds
    // therefore using the idex of the rules array this will match a rule to a field value
    const watchedField = watched?.[idx]

    const fieldIsArray = Array.isArray(watchedField)
    // field values can be:
    // string | number | boolean | string[] | number[] | boolean[] | { value: string, text: string } | { value: string, text: string }[]
    const watchedValue =
      typeof watchedField === `object`
        ? !fieldIsArray
          ? (watchedField as SelectFormFieldValue)?.value
          : watchedField?.[0]
        : watchedField

    const maybeFirstItem = fieldIsArray ? head(watchedField) : undefined
    if (!watchedValue) {
      return false
    }

    // field will be a checkbox when the value is an array of primitives like: string, number or boolean
    const fieldIsCheckboxes =
      fieldIsArray &&
      typeof maybeFirstItem !== `object` &&
      (typeof maybeFirstItem === `string` || typeof maybeFirstItem === `number`)
    // field is a multi-select when the first item of the watchedField array is an object.
    // shape would be { text: string, value: string } or SelectFormFieldValue
    const fieldIsMultiSelect =
      fieldIsArray && typeof maybeFirstItem === `object`
    // else the field is a text, number, radio or select where there is only a value

    switch (rule?.operator) {
      case `IS`: {
        const compareFn = equals(rule?.value)
        if (fieldIsCheckboxes) {
          return watchedField?.includes(rule?.value)
        } else if (fieldIsMultiSelect) {
          return any(propEq(`value`, rule?.value), watchedField)
        } else {
          return (
            compareFn(watchedValue) ||
            (rule?.value === `` && watchedValue === undefined)
          )
        }
      }
      case `IS_NOT`: {
        const compareFn = equals(rule?.value)
        if (fieldIsCheckboxes) {
          return !watchedField?.includes(rule?.value)
        } else if (fieldIsMultiSelect) {
          return !any(propEq(`value`, rule?.value), watchedField)
        } else {
          return !(
            compareFn(watchedValue) ||
            (rule?.value === `` && watchedValue === undefined)
          )
        }
      }
      case `GREATER_THAN`: {
        const compareFn = (x?: string | number | boolean) =>
          typeof x !== undefined &&
          x !== false &&
          x !== `` &&
          lt(Number(rule?.value), Number(x))
        if (fieldIsCheckboxes) {
          return watchedField?.map(Number)?.some(compareFn)
        } else if (fieldIsMultiSelect) {
          return any(compose(compareFn, propOr(``, `value`)), watchedField)
        } else {
          return compareFn(watchedValue)
        }
      }
      case `LESS_THAN`: {
        const compareFn = (x?: string | number | boolean) =>
          typeof x !== undefined &&
          x !== false &&
          x !== `` &&
          gt(Number(rule?.value), Number(x))
        if (fieldIsCheckboxes) {
          return watchedField?.some(compareFn)
        } else if (fieldIsMultiSelect) {
          return any(compose(compareFn, propOr(``, `value`)), watchedField)
        } else {
          return compareFn(watchedValue)
        }
      }
      case `CONTAINS`: {
        const compareFn = includes(rule?.value)
        if (fieldIsCheckboxes) {
          return watchedField?.includes(rule?.value)
        } else if (fieldIsMultiSelect) {
          return any(
            compose(compareFn, propOr(``, `value`)),
            watchedField ?? []
          )
        } else {
          return compareFn(watchedValue ?? ``)
        }
      }
      case `STARTS_WITH`: {
        const compareFn = startsWith(rule?.value ?? ``)
        if (fieldIsCheckboxes) {
          return !!find(compareFn, watchedField)
        } else if (fieldIsMultiSelect) {
          return any(
            compose(compareFn, propOr(``, `value`)),
            watchedField ?? []
          )
        } else {
          return compareFn(watchedValue ?? ``)
        }
      }
      case `ENDS_WITH`: {
        const compareFn = endsWith(rule?.value ?? ``)
        if (fieldIsCheckboxes) {
          return !!find(compareFn, watchedField)
        } else if (fieldIsMultiSelect) {
          return any(
            compose(compareFn, propOr(``, `value`)),
            watchedField ?? []
          )
        } else {
          return compareFn(watchedValue ?? ``)
        }
      }
      default: {
        return false
      }
    }
  }

/**
 *
 * @param conditionalLogic - Wp_ConditionalLogic
 * @param values an array of FormValues with a length matching rules array length on the conditional logic passed in
 * @returns boolean
 */
export const shouldRenderField = (
  conditionalLogic: Wp_ConditionalLogic,
  values: unknown[]
): boolean => {
  let isVisible = true

  const actionType = conditionalLogic?.actionType
  const logicType = conditionalLogic?.logicType
  const rules = conditionalLogic?.rules

  if (
    !!rules?.length &&
    logicType === Wp_ConditionalLogicLogicTypeEnum[`Any`]
  ) {
    isVisible = rules.some(isRuleMatch(values))
  } else if (
    !!rules?.length &&
    logicType === Wp_ConditionalLogicLogicTypeEnum[`All`]
  ) {
    isVisible = rules.every(isRuleMatch(values))
  }

  // with rules and actionType of HIDE filp the result
  if (!!rules?.length && actionType === `HIDE`) {
    isVisible = !isVisible
  }
  return isVisible
}

// const fieldIds = rules?.map((rule) => rule?.fieldId) ?? []
// const watched = fieldIds.map((fieldId) => fieldValues[fieldId])

/**
 * @param rules - Wp_ConditionalLogicRule
 * @returns fieldIds - string[]
 */
export const rulesToFieldIds = (
  rules: Maybe<Wp_ConditionalLogicRule>[]
): string[] => rules?.map((rule) => rule?.fieldId?.toString() ?? ``)

interface UseIsVisibleResult {
  /**
   * If the field is rendered to the DOM
   */
  isVisible: boolean
  /**
   * If the field is visible to the user. Will be rendered to DOM based on isVisible
   */
  isHidden: boolean
  /**
   * If the field has conditional logic, this will be set to an array of fieldIds
   */
  watchedFieldIds?: string[]
}

/**
 * @param field GFormField - Form field from graphql
 * @returns UseIsVisibleResult - { isVisible: boolean, isHidden: boolean, watchedFieldIds: string[] }
 */
const useIsVisible = <
  IField extends Pick<GFormField, 'visibility' | 'conditionalLogic' | 'id'>
>(
  field?: IField
): UseIsVisibleResult => {
  const conditionalLogic = field?.conditionalLogic
  const visibility = field?.visibility
  const user = useReactiveVar(authDataVar)?.user
  const fieldName = String(field?.id)

  const { control, unregister } = useFormContext()

  // hidden is visually hidden but render to the dom and the value will get recorded in the form submission
  const isHidden =
    visibility === `HIDDEN` ||
    (visibility === `ADMINISTRATIVE` && !user?.isSuperAdmin)
  // convert conditional logic rules array into an array of fieldIds
  const watchedFieldIds = rulesToFieldIds(conditionalLogic?.rules ?? [])

  // using the fieldIds derived from conditional logic rules
  // create a watched variable, which is an array of raw FormValues
  const watched = useWatch({
    control,
    name: watchedFieldIds,
  })

  const isVisible =
    !conditionalLogic || shouldRenderField(conditionalLogic, watched)

  React.useEffect(() => {
    if (!isVisible) {
      unregister(fieldName)
    }
  }, [isVisible, fieldName, unregister])

  return { isVisible, watchedFieldIds, isHidden }
}

export default useIsVisible

// https://docs.gravityforms.com/common-field-settings/#:~:text=Hidden%3A%20this%20field%20will%20be,viewer%2C%20but%20will%20still%20exist.&text=Administrative%3A%20the%20field%20is%20not,any%20functionality%20to%20the%20form
