import { LicenseInfo } from '@mui/x-license-pro'
// import { LicenseInfo } from '@mui/x-data-grid-pro'

/**
 * Security
 *
 * The check of the license key is done without making any network requests.
 *
 * The license key is designed to be public, the only thing we ask of licensed users is to not proactively publicize
 * their license key. Exposing the license key in a JavaScript bundle is expected.
 *
 * https://mui.com/components/data-grid/getting-started/#security
 */
LicenseInfo.setLicenseKey(
  // `e94100f166a2f7904579ac00f0647bb0T1JERVI6Mzk3MzksRVhQSVJZPTE2Nzg5NzIxMjMwMDAsS0VZVkVSU0lPTj0x` original
  `b0b2a990e0a5072f62a642b8bfe43967Tz02OTc1MyxFPTE3MTk2ODA3NzQ2NjAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=` // expires Sat Jun 29 2024. For 1 dev only
)
