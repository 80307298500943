/**
 * Custom Post Type Singular Name
 */
export const PROJECT_SINGLE_NAME = `project`
/**
 * Custom Post Type Plural Name
 */
export const PROJECT_PLURAL_NAME = `projects`
/**
 * Session Storage Key for filters
 */
export const PROJECT_LIST_PAGE_FILTERS = `PROJECT_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const PROJECT_SLUG = `projects`

/**
 * Project Number Meta Key used on WP
 */
export const PROJECT_NUMBER_META_KEY = `_project_number`
