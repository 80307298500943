import snakeCase from 'lodash/snakeCase'

import { LanguageCodeEnum, Maybe } from '../types/generated'

export const languageSlugToEnum = (slug?: Maybe<string>): LanguageCodeEnum => {
  if (!slug) {
    return LanguageCodeEnum[`EnUs`]
  }
  return snakeCase(slug)?.toUpperCase() as LanguageCodeEnum
}
