import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_SITE_LANG_SLUG } from '../../../constants'
import { authDataVar } from '../../../services/apollo/cache'
import { LanguageCodeEnum, Maybe } from '../../../types/generated'
import {
  Wp_AddressTranlation,
  Wp_BasicTranslation,
  Wp_ChoiceTranslation,
  Wp_ConsentTranslation,
  Wp_FormTranslation,
  Wp_NameTranslation,
} from '../../../types/generated-gatsby'
import { LanguageDialogSelectProps } from '../../Common/Modals/LanguageDialogSelect'

export interface FormTranslationsProviderProps {
  children: React.ReactNode
  formLang?: Maybe<string>
  setFormLang?: React.Dispatch<React.SetStateAction<string>>
  translations?: Maybe<Maybe<Wp_FormTranslation>[]>
}

interface FormTranslationContextInterface {
  setFormLang?: React.Dispatch<React.SetStateAction<string>>
  formLang: string
  /**
   * Only set when the form is NOT using the default language
   */
  currentTranslation?: Maybe<Wp_FormTranslation>
  fieldTranslation?: Maybe<Wp_BasicTranslation> &
    Maybe<Wp_NameTranslation> &
    Maybe<Wp_AddressTranlation> &
    Maybe<Wp_ChoiceTranslation> &
    Maybe<Wp_ConsentTranslation>
  activeTranslationOptions: LanguageDialogSelectProps['options']
}

export const FormTranslationContext = React.createContext<
  FormTranslationContextInterface | undefined
>(undefined)

const FormTranslationProvider: React.FC<FormTranslationsProviderProps> = (
  props: FormTranslationsProviderProps
) => {
  const { translations = [], children } = props
  const user = useReactiveVar(authDataVar)?.user
  const [formLang, setFormLang] = React.useState(
    () =>
      user?.languages?.edges?.[0]?.node?.slug ??
      LanguageCodeEnum[`EnUs`]?.toLowerCase()
  )
  const { i18n } = useTranslation()

  const handleSetLanguage = (newLang: string | ((str: string) => string)) => {
    const value = typeof newLang === `string` ? newLang : newLang(formLang)
    setFormLang(value)
    i18n.changeLanguage(value)
  }

  const currentTranslation =
    formLang !== DEFAULT_SITE_LANG_SLUG
      ? translations?.find((translation) => translation?.slug === formLang)
      : null

  const activeTranslationOptions = [
    {
      name: `English`,
      slug: DEFAULT_SITE_LANG_SLUG,
    },
    ...(translations
      ?.filter((t) => t?.active)
      ?.map((t) => ({
        name: t?.name ?? ``,
        slug: t?.slug ?? ``,
      })) ?? []),
  ]

  return (
    <FormTranslationContext.Provider
      value={{
        formLang: formLang ?? DEFAULT_SITE_LANG_SLUG,
        setFormLang: handleSetLanguage,
        currentTranslation,
        activeTranslationOptions,
      }}
    >
      {children}
    </FormTranslationContext.Provider>
  )
}

export const useFormTranlation = (
  translations?: Maybe<
    Maybe<
      | Wp_BasicTranslation
      | Wp_AddressTranlation
      | Wp_NameTranslation
      | Wp_ChoiceTranslation
      | Wp_ConsentTranslation
    >[]
  >
): FormTranslationContextInterface => {
  const context = React.useContext(FormTranslationContext)
  if (context === undefined) {
    throw new Error(
      `useFormTransltion must be used withing a FormTranslationContext`
    )
  }

  if (translations?.length) {
    const fieldTranslation = translations?.find(
      (t) => t?.slug === context?.formLang
    )
    return {
      ...context,
      // @ts-expect-error this is fine, a issue with union of __typename
      fieldTranslation,
    }
  }

  return context
}

export default FormTranslationProvider
