/**
 * Custom Post Type Singular Name
 */
export const WORKER_SINGLE_NAME = `user`
/**
 * Custom Post Type Plural Name
 */
export const WORKER_PLURAL_NAME = `users`
/**
 * Session Storage Key for filters
 */
export const WORKER_LIST_PAGE_FILTERS = `WORKER_LIST_PAGE_FILTERS`

/**
 * CPT Pages Url Slug
 */
export const WORKER_SLUG = `workers`

/**
 * Vaccination Status form slug
 */
export const WORKER_VACCINE_STATUS_FORM_SLUG = `vaccination-status`

/**
 * Wp capability to delete users
 */
export const USER_DELETE_CAP = `delete_users`
/**
 * Wp capability to edit users
 */
export const USER_EDIT_CAP = `edit_users`
/**
 * Wp capability to create users
 */
export const USER_CREATE_CAP = `create_users`
/**
 * Wp capability to list users
 */
export const USER_LIST_CAP = `list_users`

/**
 * Employee Id Meta Key used on WP
 */
export const EMPLOYEE_ID_META_KEY = `_employee_id`
