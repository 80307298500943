// Projects
export const PROJECT_ID_CSS_CLASS = /field-project(\-id)?(\s|$)/
export const PROJECT_NUMBER_CSS_CLASS = `field-project-number`
export const PROJECT_TITLE_CSS_CLASS = `field-project-title`
export const PROJECT_LOCATION_CSS_CLASS = `field-project-location`
export const PROJECT_KEY_PERSONNEL = `field-key-personnel`

// Toolbox Talks
export const TOOLBOX_TALK_ID_CSS_CLASS = /field-toolbox-talk(\-id)?(\s|$)/
export const TOOLBOX_TALK_TITLE_CSS_CLASS = `field-toolbox-talk-title`
export const TOOLBOX_TALK_ENGLISH_TITLE_CSS_CLASS = `field-toolbox-talk-english-title`
export const TOOLBOX_TALK_CATEGORIES_CSS_CLASS = `field-toolbox-talk-categories`

// Equipment
export const EQUIPMENT_ID_CSS_CLASS = /field-equipment(\-id)?(\s|$)/
export const EQUIPMENT_TITLE_CSS_CLASS = `field-equipment-title`

// Document
export const DOCUMENT_ID_CSS_CLASS = /field-document(\-id)?(\s|$)/
export const DOCUMENT_TITLE_CSS_CLASS = `field-document-title`

// Certification
export const CERTIFICATION_ID_CSS_CLASS = /field-certification(\-id)?(\s|$)/
export const CERTIFICATION_TITLE_CSS_CLASS = `field-certification-title`

// Corrective Action
export const CORRECTIVE_ACTION_ID_CSS_CLASS =
  /field-corrective-action(\-id)?(\s|$)/
export const CORRECTIVE_ACTION_TITLE_CSS_CLASS = `field-corrective-action-title`

// workers
export const WORKER_ID_CSS_CLASS = /field-(worker|user)(\-id)?(\s|$)/
export const WORKER_NAME_CSS_CLASS = /field-(worker|user)-name(\s|$)/
export const WORKER_EMAIL_CSS_CLASS = /field-(worker|user)-email(\s|$)/
export const WORKER_COMPANY_CSS_CLASS =
  /field-(worker|user)-compan(y|ies)(\s|$)/
export const WORKER_DIVISION_CSS_CLASS = /field-(worker|user)-divisions?(\s|$)/

export const WORKER_ID_CSS_CLASS_2 = /field-(worker|user)(\-id)?-2(\s|$)/
export const WORKER_NAME_CSS_CLASS_2 = /field-(worker|user)-name-2(\s|$)/
export const WORKER_EMAIL_CSS_CLASS_2 = /field-(worker|user)-email-2(\s|$)/
export const WORKER_COMPANY_CSS_CLASS_2 =
  /field-(worker|user)-compan(y|ies)-2(\s|$)/
export const WORKER_DIVISION_CSS_CLASS_2 =
  /field-(worker|user)-divisions?-2(\s|$)/

export const COMPANY_CSS_CLASS = /field-compan(y|ies)?(\s|$)/
export const DIVISION_CSS_CLASS = /field-division(s)?(\s|$)/
