import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import muiTheme from '../../theme/mui'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Theme as MuiTheme,
} from '@mui/material/styles'
import harnessColors from '../../theme/harnessColors'

interface ThemeProviderProps {
  children?: React.ReactNode
}

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const theme = responsiveFontSizes(createTheme(muiTheme))
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.up(`lg`)]: {
    fontSize: `5.25rem`,
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.up(`lg`)]: {
    fontSize: `3.375rem`,
  },
}

export const createHarnessTheme = (theme: MuiTheme): MuiTheme => {
  return {
    ...theme,
    // sizes: {
    //   contentMaxWidth: `60rem`,
    //   contentWidth: `92%`,
    //   borderRadius: `4px`,
    //   breakTablet: `768px`,
    //   breakMobile: `414px`,
    //   boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)`,
    // },
    harnessColors,
  }
}

const harnessTheme = createHarnessTheme(theme)
const HarnessThemeContext = React.createContext(harnessTheme)

const HarnessThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <HarnessThemeContext.Provider value={harnessTheme}>
      <ThemeProvider theme={harnessTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </HarnessThemeContext.Provider>
  )
}

// Need to use useHarnessTheme instead of useTheme from MUI whenever we need access to the extra harnessColors.
// otherwise just use useTheme from MUI
export const useHarnessTheme = (): MuiTheme =>
  React.useContext(HarnessThemeContext)

export default HarnessThemeProvider
