import { Maybe } from '../../types/generated'

export const getBasedOnFieldName = (
  cssClass?: Maybe<string>
): Maybe<string> => {
  return cssClass?.includes(`based-on`)
    ? cssClass
        ?.split(` `)
        ?.find((cls) => cls?.includes(`based-on`))
        ?.split(`-`)?.[2] ?? null
    : null
}
