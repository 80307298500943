import { FieldValues } from 'react-hook-form'
import { isRuleMatch } from '../../hooks/forms/useIsVisible'
import { Maybe } from '../../types/generated'
import {
  Wp_ConditionalLogicLogicTypeEnum,
  Wp_FormConfirmation,
} from '../../types/generated-gatsby'

type MakeConfirmationMessageReturn = {
  message?: Maybe<string>
  actionUrl?: Maybe<string>
}

const makeConfirmationMessage = (
  confirmations?: Maybe<Wp_FormConfirmation>[],
  fieldValues?: Maybe<FieldValues>
): MakeConfirmationMessageReturn => {
  if (!confirmations?.length) {
    return { message: null, actionUrl: null }
  }

  const resultConfirmation =
    confirmations?.find((confirmation) => {
      const logicType = confirmation?.conditionalLogic?.logicType
      const rules = confirmation?.conditionalLogic?.rules ?? []

      const fieldIds = rules?.map((rule) => rule?.fieldId) ?? []
      const watched = fieldIds.map((fieldId) => fieldValues?.[fieldId ?? ``])

      if (logicType === Wp_ConditionalLogicLogicTypeEnum[`Any`]) {
        return rules?.some(isRuleMatch(watched))
      } else if (logicType === Wp_ConditionalLogicLogicTypeEnum[`All`]) {
        return rules?.every(isRuleMatch(watched))
      }
    }) ?? confirmations?.find((confirmation) => !confirmation?.isDefault)

  return {
    message: resultConfirmation?.message,
    actionUrl: resultConfirmation?.url,
  }
}

export default makeConfirmationMessage
