import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import Sync from '@mui/icons-material/Sync'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Zoom, { ZoomProps } from '@mui/material/Zoom'
import { navigate } from 'gatsby'
import * as React from 'react'
import { SOFT_TEXT_OPACITY } from '../../../constants'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`

const SyncIcon = styled(Sync)`
  animation: ${rotate} 2s linear infinite;
  fill: ${(props) => props.theme.palette.secondary.main};
  font-size: ${(props) => props.theme.typography.h3.fontSize};
`

const SoftTypography = styled(Typography)<{ opacity?: number }>`
  ${({ opacity = SOFT_TEXT_OPACITY }) => `
    opacity: ${opacity}%;
    color: inherit;
  `}
`

const ICON_SELECTOR = {
  loading: <CloudDownloadIcon color="secondary" />,
  completed: <CheckCircleOutlineIcon color="secondary" />,
  pending: <HourglassEmptyIcon color="secondary" />,
}

export interface LoadingDialogProps {
  open: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * Image Component, usually Gatsby Image
   * Used instead of the `logo` prop
   */
  logoComponent?: React.ComponentType
  /**
   * Can be used inplace of the logoLink prop component
   */
  logo?: {
    src: string
    alt: string
  }
  /**
   * Opacity of the white text on screen.
   *
   * @default 72
   */
  softTextOpacity?: number
  /**
   * Text which appears under the Sync Icon, above the list of things being downloaded / loaded.
   */
  mainText?: string
  loadingList?: Array<{
    id: string
    text: string
    state: keyof typeof ICON_SELECTOR
  }>
}

const Transition = React.forwardRef(function Transition(
  props: { children?: React.ReactElement } & ZoomProps,
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />
})

const maintanenceMode = Number(process.env.GATSBY_MAINTANENCE_MODE) === 1

const LoadingDialog: React.FC<LoadingDialogProps> = (props) => {
  const {
    open,
    setOpen,
    logoComponent: LogoComponent,
    logo,
    softTextOpacity = 72,
    mainText = `Downloading the following collections for offline use.`,
    loadingList,
  } = props

  React.useEffect(() => {
    if (window) {
      if (maintanenceMode && location?.pathname !== `/`) {
        navigate(`/`)
      }
    }
  }, [])

  const handleClose = React.useCallback(() => {
    setOpen?.(false)
  }, [setOpen])

  return (
    <Dialog
      fullScreen
      open={open || maintanenceMode}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box width="100%" height="100%" bgcolor="primary.main" display="flex">
        <Container maxWidth="sm">
          <Box
            mx="auto"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={4}
          >
            <Box mx="auto" width="max-content" id="loading-dialog-image">
              {LogoComponent ? (
                <LogoComponent />
              ) : (
                logo?.src && (
                  <img height="100%" src={logo?.src} alt={logo?.alt} />
                )
              )}
            </Box>
            <Box
              id="loading-dialog-text"
              mt={2}
              color="common.white"
              display="grid"
              gap="8px"
              justifyItems="center"
              alignItems="center"
            >
              {!maintanenceMode && <SyncIcon />}
              <SoftTypography
                variant="subtitle1"
                opacity={softTextOpacity}
                color="inherit"
                align="center"
              >
                {maintanenceMode
                  ? `In mainanence mode as we are currently working to bring you the latest features!`
                  : mainText}
              </SoftTypography>
            </Box>
            {loadingList?.length && !maintanenceMode && (
              <Box
                id="loading-dialog-list"
                color="common.white"
                mt={2}
                alignItems="center"
                px={2}
              >
                {loadingList?.map((load) => (
                  <ListItem key={load?.id}>
                    <ListItemText disableTypography>
                      <SoftTypography opacity={softTextOpacity} color="inherit">
                        {load?.text}...
                      </SoftTypography>
                    </ListItemText>
                    <ListItemIcon>{ICON_SELECTOR[load.state]}</ListItemIcon>
                  </ListItem>
                ))}
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Dialog>
  )
}

export default LoadingDialog
