import * as React from 'react'
import localforage from 'localforage'
import { useDeepCompareEffect } from 'use-deep-compare'

const useLocalForage = <StoredValueType>(
  key: string,
  initialValue: StoredValueType
): [
  storedValue: StoredValueType,
  setValue: React.AsyncDispatch<React.SetStateAction<StoredValueType>>,
  remove: () => Promise<void>,
  error: Error | null,
  getValue: () => Promise<StoredValueType | null>
] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<StoredValueType>(
    () => initialValue
  )
  const [error, setError] = React.useState<Error>()

  useDeepCompareEffect(() => {
    ;(async function () {
      try {
        const value = (await localforage.getItem(key)) as StoredValueType
        setStoredValue(value ?? initialValue)
      } catch (err) {
        setError(err as Error)
        return initialValue
      }
    })()
  }, [initialValue, key])

  const setValue = async (
    value: StoredValueType | ((val: StoredValueType) => StoredValueType)
  ) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save to local storage
      await localforage.setItem(key, valueToStore)
      // Save state
      setStoredValue(valueToStore)
    } catch (err) {
      setError(err as Error)
    }
  }

  const remove = async () => {
    try {
      await localforage.removeItem(key)
      setStoredValue(initialValue)
    } catch (err) {
      setError(err as Error)
    }
  }

  const getValue: () => Promise<StoredValueType | null> = async () => {
    return localforage.getItem(key)
  }

  return [storedValue, setValue, remove, error ?? null, getValue]
}

export default useLocalForage
