import Box from '@mui/material/Box'
import { Link } from 'gatsby-material-ui-components'
import { GraphQLError } from 'graphql'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import * as React from 'react'
import { DraftEntriesContextType } from '../../components/providers/DraftEntriesProvider'
import { CloseSnack } from '../../components/providers/Snackbar'
import { AUTO_HIDE_DURATION } from '../../constants'
import { LocalDraftEntry, LocalDraftEntryStatusEnum } from '../../types/drafts'
import {
  FieldError,
  GfSubmitFormMutationFn,
  GfSubmittedEntry,
  GfUpdateEntryMutationFn,
  Maybe,
} from '../../types/generated'
import { Wp_FormConfirmation } from '../../types/generated-gatsby'
import { FormPagePageContext } from '../../types/gravityForms'
import makeConfirmationMessage from './makeConfirmationMessage'
import { isEmptyValue } from '../misc'

export interface FormSubmitHanlderArgs {
  updatedDraftEntry?: Maybe<LocalDraftEntry>
  pageNumber?: Maybe<number>
  updateDraftEntry: DraftEntriesContextType['updateDraftEntry']
  getFollowUpFormDraftEntries?: DraftEntriesContextType['getFollowUpFormDraftEntries']
  removeDraftEntries: DraftEntriesContextType['removeDraftEntries']
  submitForm: GfSubmitFormMutationFn
  updateFormEntry?: GfUpdateEntryMutationFn
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
  closeSnackbar: (key?: SnackbarKey | undefined) => void
  isOnline?: Maybe<boolean>
  type?: FormPagePageContext['type']
  /**
   * Only needed for update form entry
   */
  entryIdToUpdate?: Maybe<number>
  /**
   * Used for the toast message
   */
  formTitle?: string
  confirmations?: Maybe<Wp_FormConfirmation>[]
}

/**
 * Will Submit a parent form.
 * Check for follow up forms and submit those.
 * And if not online then will save the parent draft as waiting for network submission.
 */
const formSubmitHanlder = async ({
  submitForm,
  updatedDraftEntry,
  enqueueSnackbar,
  closeSnackbar,
  isOnline,
  getFollowUpFormDraftEntries,
  updateDraftEntry,
  removeDraftEntries,
  updateFormEntry,
  type,
  entryIdToUpdate,
  formTitle,
  confirmations,
}: FormSubmitHanlderArgs): Promise<{
  entryId?: Maybe<number>
  gfServerErrors?: Maybe<
    Maybe<
      {
        __typename?: 'FieldError' | undefined
      } & Pick<FieldError, 'id' | 'message'>
    >[]
  >
  graphqlErrors?: readonly GraphQLError[]
}> => {
  // start of function
  let entryId: Maybe<number> | undefined = null
  let gfServerErrors:
    | Maybe<
        Maybe<
          {
            __typename?: 'FieldError' | undefined
          } & Pick<FieldError, 'id' | 'message'>
        >[]
      >
    | undefined
  let graphqlErrors: readonly GraphQLError[] | undefined

  const draftId = updatedDraftEntry?.draftId
  const formId = updatedDraftEntry?.form?.formId
  const pageNumber = updatedDraftEntry?.lastPage
  const formSlug = updatedDraftEntry?.form?.slug
  const isCPTForm = updatedDraftEntry?.form?.isCPTForm
  const draftPath = `/${isCPTForm ? `` : `forms/`}${formSlug}/drafts`
  const safeFormTitle = formTitle ?? updatedDraftEntry?.form?.formTitle

  if (isOnline && draftId && formId) {
    const { message, actionUrl } = makeConfirmationMessage(
      confirmations,
      updatedDraftEntry?.fieldValues
    )

    // deal with new / quiz or update submission
    if (type === `new` || type === `quiz`) {
      // sim Error Report
      // throw new Error(`Test error message`)

      // on the last page of a form need to then submit the draftEntry to a real entry result
      const submitResult = await submitForm({
        variables: {
          input: {
            id: formId?.toString(),
            fieldValues: updatedDraftEntry?.fieldValuesInputArray ?? [],
            sourcePage: pageNumber,
          },
        },
      })
      entryId = (submitResult?.data?.submitGfForm?.entry as GfSubmittedEntry)
        ?.databaseId
      graphqlErrors = submitResult?.errors
      gfServerErrors = submitResult?.data?.submitGfForm?.errors
    } else if (type === `update` && entryIdToUpdate && updateFormEntry) {
      // throw new Error(`Test error message`)
      // on the last page of a form need to then submit the draftEntry to a real entry result
      const result = await updateFormEntry({
        variables: {
          input: {
            id: entryIdToUpdate?.toString(),
            fieldValues: updatedDraftEntry?.fieldValuesInputArray,
          },
        },
      })

      entryId = result?.data?.updateGfEntry?.entry?.databaseId
      graphqlErrors = result?.errors
      gfServerErrors = result?.data?.updateGfEntry?.errors
    } // END deal with new / quiz or update submission of parent form

    /* Deal with parent form submission results: either return the errors or report to user success and delete draft */
    if (graphqlErrors || gfServerErrors) {
      // check if at least one field is filled out
      if (
        gfServerErrors?.every((e) =>
          e?.message?.includes(`At least one field must be filled out`)
        )
      ) {
        return { gfServerErrors, entryId: null }
      }

      // update parent form draft entry with errors and status
      await updateDraftEntry({
        draftId,
        status: LocalDraftEntryStatusEnum[`ErrorOnSubmit`],
        errors: gfServerErrors ?? [
          { id: null, message: graphqlErrors?.[0]?.message },
        ],
      })
      // missing required fields
      if (
        gfServerErrors?.filter((e) => e?.message?.includes(`required`))
          ?.length ??
        0 > 0
      ) {
        return { gfServerErrors, graphqlErrors, entryId }
      }

      enqueueSnackbar(`${safeFormTitle ?? `Form`} submission failed!`, {
        variant: `error`,
        autoHideDuration: AUTO_HIDE_DURATION * 1.25,
      })
      enqueueSnackbar(`View draft entry?`, {
        autoHideDuration: AUTO_HIDE_DURATION * 1.25,
        action: (key) => (
          <Box mr={1} ml={-1}>
            <Link
              to={draftPath}
              color="secondary"
              underline="always"
              onClick={() => closeSnackbar(key)}
            >
              View?
            </Link>
            <CloseSnack
              closeSnackbar={() => {
                closeSnackbar(key)
              }}
            />
          </Box>
        ),
      })

      // return early
      return {
        entryId: null,
        gfServerErrors,
        graphqlErrors,
      }
    } else {
      // tell user things were a success
      // with no errors, decide if we need to show a GF confirmation toast message.
      // Will only show if setup on the backend with conditional logic
      enqueueSnackbar(message || `${safeFormTitle ?? `Form`} submitted!`, {
        variant: `success`,
        action: actionUrl
          ? () => (
              <Box>
                <Link to={actionUrl} color="secondary">
                  {actionUrl}
                </Link>
              </Box>
            )
          : undefined,
      })
      // remove parent form draft entry
      enqueueSnackbar(`Removing draft entry`)
      await removeDraftEntries([draftId])
    } // END Deal with parent form submission results: either return the errors or report to user success and delete draft

    /* Check for completed follow up forms */
    const followUpFormDraftEntries = getFollowUpFormDraftEntries?.(draftId)
    if (
      followUpFormDraftEntries && // has follow up form drafts to submit
      !isEmptyValue(followUpFormDraftEntries) && // and it is not empty
      entryId // and the parent form submitted successfully
    ) {
      // submit forms
      const promises = Object.values(followUpFormDraftEntries).map((value) => {
        // sim error for follow up form
        // return new Promise((_, reject) => reject({ draftId: value?.draftId }))
        return submitForm({
          variables: {
            input: {
              // @ts-expect-error this is fine
              id: value?.form?.formId?.toString(),
              fieldValues: [
                ...(value?.fieldValuesInputArray ?? []),
                {
                  id: value?.form?.entryIdFieldId as number,
                  value: entryId?.toString(),
                },
              ],
            },
          },
        })
          .then(() => ({
            draftId: value?.draftId,
          }))
          .catch((error) => ({ draftId: value?.draftId, error }))
      })

      const results = await Promise.allSettled(promises)
      const draftIdsToRemove: number[] = [draftId]
      const followUpDraftIdsToSave: number[] = []

      for await (const result of results) {
        if (result?.status === `fulfilled`) {
          // When follow up form submits, add the draftId to the list to be removed
          draftIdsToRemove.push(result?.value?.draftId)
        } else if (result?.status === `rejected`) {
          // When it fails to submit, update draft entry status to error
          followUpDraftIdsToSave.push(result?.reason?.draftId)

          // add parent entryId to child draft's fieldValues to signal a successfully submitted parent form
          const childDraft = followUpFormDraftEntries?.[result?.reason?.draftId]
          const oldFieldValues = childDraft?.fieldValues

          await updateDraftEntry({
            draftId: result?.reason?.draftId,
            status: LocalDraftEntryStatusEnum[`ErrorOnSubmit`],
            errors: result?.reason?.error ?? [],
            fieldValues: {
              ...oldFieldValues,
              ...(childDraft?.form?.entryIdFieldId
                ? {
                    [childDraft.form.entryIdFieldId]: entryId?.toString(),
                  }
                : {}),
            },
            // @ts-expect-error this is fine
            followUpToForm: {
              ...childDraft?.followUpToForm,
              entryId: entryId?.toString(),
            },
          })
        }
      }

      if (!!followUpDraftIdsToSave?.length) {
        const childDraftIdsString = followUpDraftIdsToSave
          .map((childId) => `#${childId}`)
          .join(`, `)
        enqueueSnackbar(
          `Errors submitting follow up forms: ${childDraftIdsString}`,
          {
            variant: `error`,
            autoHideDuration: AUTO_HIDE_DURATION * 1.25,
          }
        )
        enqueueSnackbar(`View failed drafts`, {
          autoHideDuration: AUTO_HIDE_DURATION * 1.25,
          action: (key) => (
            <Box mr={1} ml={-1}>
              <Link
                to={draftPath}
                color="secondary"
                underline="always"
                onClick={() => closeSnackbar(key)}
              >
                View?
              </Link>
              <CloseSnack
                closeSnackbar={() => {
                  closeSnackbar(key)
                }}
              />
            </Box>
          ),
        })
      } else {
        enqueueSnackbar(`All follow up forms submitted.`, {
          variant: `success`,
        })
      }
      if (draftIdsToRemove?.length > 1) {
        enqueueSnackbar(`Removing successful follow up drafts`)
        await removeDraftEntries(draftIdsToRemove)
      }
    } // END Check for completed follow up forms
  } else if (!isOnline && draftId) {
    enqueueSnackbar(`${safeFormTitle ?? `Form`} entry saved to device storage.`)
    await updateDraftEntry({
      draftId,
      status: LocalDraftEntryStatusEnum[`AwaitingOnlineStatus`],
      fieldValues: updatedDraftEntry?.fieldValues ?? {},
      fieldValuesInputArray: updatedDraftEntry?.fieldValuesInputArray,
    })
  } else {
    enqueueSnackbar(`Missing draftId`, { variant: `error` })
  }

  return {
    entryId,
    gfServerErrors,
    graphqlErrors,
  }
}

export default formSubmitHanlder
