import { AUTH_KEY } from '../../constants'
import { LoginPayload, Maybe } from '../../types/generated'

export const getSavedAuthData = ():
  | (Pick<LoginPayload, 'refreshToken' | 'user'> & {
      userId?: Maybe<string | number>
    })
  | null => {
  if (typeof window !== `undefined`) {
    const data = localStorage?.getItem(AUTH_KEY) ?? ``
    if (data) {
      return JSON.parse(data)
    }
  }
  return null
}

// this function can be used to control which data on the User object is saved to localstorage.
// ie. the capabilities should be limited to only those needed to access views and actions in the application
export const setSavedAuthData = (
  authData: Pick<LoginPayload, 'refreshToken' | 'user'> & {
    userId?: number | string
  }
): void => localStorage?.setItem(AUTH_KEY, JSON.stringify(authData))

export const deleteSavedAuthData = (): void => {
  localStorage?.removeItem(AUTH_KEY)
}
