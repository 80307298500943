import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import useIsVisible from '../../../hooks/forms/useIsVisible'
import {
  Wp_AddressField,
  Wp_AddressFieldCountryEnum,
  Wp_AddressFieldTypeEnum,
  Wp_FormFieldTypeEnum,
  Wp_SelectFieldChoice,
  Wp_TextField,
} from '../../../types/generated-gatsby'
import { CommonFieldProps } from '../../../types/gf/fields/CommonFieldProps'
import { createFieldCssClass } from '../../../utils/gravityForms/fields/createFieldCssClass'
import { createFieldHtmlId } from '../../../utils/gravityForms/fields/createFieldHtmlId'
import { useFormTranlation } from '../../providers/Form/formTranslation'
import TextField from './TextField'

import AutoComplete from './Common/AutoComplete'
import { getCountryLabelFromCode } from '../../../utils/gravityForms/fields/getCountryLabelFromCode'

export interface AddressFieldProps extends CommonFieldProps {
  field: Omit<Wp_AddressField, 'id'> & { id: number | string }
  textFieldProps?: TextFieldProps
}

const DEFAULT_VARIANT = `outlined`

export type AddressFieldFieldNames = {
  addressFieldName: string
  address2FieldName: string
  cityFieldName: string
  stateFieldName: string
  zipFieldName: string
  countryFieldName: string
}

export const createAddressFieldFieldNames = (
  fieldName: string
): AddressFieldFieldNames => {
  const addressFieldName = `${fieldName}_address`
  const address2FieldName = `${fieldName}_address2`
  const cityFieldName = `${fieldName}_city`
  const stateFieldName = `${fieldName}_state`
  const zipFieldName = `${fieldName}_zip`
  const countryFieldName = `${fieldName}_country`

  return {
    addressFieldName,
    address2FieldName,
    cityFieldName,
    stateFieldName,
    zipFieldName,
    countryFieldName,
  }
}

export type Wp_AddressCountryFieldChoice = Omit<
  Wp_SelectFieldChoice,
  'value' | '__typename'
> & { value: Wp_AddressFieldCountryEnum }

/**
 * @returns [options: Wp_AddressCountryFieldChoice[], countryToChoiceMap: { [x: string]: Wp_AddressCountryFieldChoice }]
 */
export const createCountrySelectOptions = (): [
  options: Wp_AddressCountryFieldChoice[],
  countryToChoiceMap: { [x: string]: Wp_AddressCountryFieldChoice }
] => {
  const countryCodes = Object.values(Wp_AddressFieldCountryEnum)

  /**
   * The Select / AutoComplete field options
   */
  const options = countryCodes
    ?.reduce<Wp_AddressCountryFieldChoice[]>((acc, code) => {
      const text = getCountryLabelFromCode(code)
      if (!text) return acc
      return [
        ...acc,
        {
          isSelected: false,
          text,
          value: code,
        },
      ]
    }, [])
    ?.sort((a, b) => {
      // move United States and Canada to first positions
      if (
        a?.value === Wp_AddressFieldCountryEnum.Ca ||
        a?.value === Wp_AddressFieldCountryEnum.Us
      ) {
        return -1
      }
      if (
        b?.value === Wp_AddressFieldCountryEnum.Ca ||
        b?.value === Wp_AddressFieldCountryEnum.Us
      ) {
        return 1
      }
      if (!a?.text || !b?.text) {
        return 0
      }
      if (a?.text > b?.text) {
        return 1
      } else if (a?.text < b?.text) {
        return -1
      } else {
        return 0
      }
    })

  /**
   * a map from country text to the AddressCountryFieldEnum value
   */
  const countryToChoiceMap = options?.reduce<{
    [x: string]: Wp_AddressCountryFieldChoice
  }>((acc, o) => {
    if (!o?.text || !o.value) return acc
    return {
      ...acc,
      [o.text]: o,
    }
  }, {})

  return [options, countryToChoiceMap]
}

const AddressField: React.FC<AddressFieldProps> = (props) => {
  const { field, formId, hideLabel = false, color = `primary` } = props
  const {
    id,
    type,
    label,
    description,
    cssClass,
    inputs,
    isRequired,
    addressTranslations,
    addressType,
    // hasAutocomplete
    defaultCountry,
    defaultProvince,
    defaultState,
  } = field
  const { fieldTranslation } = useFormTranlation(addressTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description
  const isDisabled = cssClass?.includes(`readonly`)

  const htmlId = createFieldHtmlId(formId, id)
  const fieldName = String(id)
  const { isVisible, isHidden } =
    useIsVisible<AddressFieldProps['field']>(field)

  const [address, address2, city, state, zip, country] = inputs ?? []

  const isInternational = addressType === Wp_AddressFieldTypeEnum.International
  const isUS = addressType === Wp_AddressFieldTypeEnum.Us
  const isCDN = addressType === Wp_AddressFieldTypeEnum.Canada

  const {
    addressFieldName,
    address2FieldName,
    cityFieldName,
    stateFieldName,
    zipFieldName,
    countryFieldName,
  } = createAddressFieldFieldNames(fieldName)

  const { unregister, setValue } = useFormContext()

  React.useEffect(() => {
    if (!isVisible) {
      unregister(addressFieldName)
      unregister(address2FieldName)
      unregister(cityFieldName)
      unregister(stateFieldName)
      unregister(zipFieldName)
      unregister(countryFieldName)
    }
  }, [
    isVisible,
    unregister,
    address2FieldName,
    addressFieldName,
    cityFieldName,
    stateFieldName,
    zipFieldName,
    countryFieldName,
  ])

  const addresssLabel =
    fieldTranslation?.address ||
    address?.customLabel ||
    address?.label ||
    `Address`
  const addresss2Label =
    fieldTranslation?.address2 ||
    address2?.customLabel ||
    address2?.label ||
    `Address Line 2`
  const cityLabel =
    fieldTranslation?.city || city?.customLabel || city?.label || `City`
  const stateLabel =
    fieldTranslation?.state ||
    state?.customLabel ||
    state?.label ||
    (isUS ? `State` : isCDN ? `Province` : `State / Province`)
  const countryLabel =
    fieldTranslation?.country ||
    country?.customLabel ||
    country?.label ||
    `Country`
  const zipLabel =
    fieldTranslation?.zip ||
    zip?.customLabel ||
    zip?.label ||
    (isUS ? `Zip` : isCDN ? `Postal Code` : `Zip / Postal Code`)

  const [countryOptions, countryToChoiceMap] = createCountrySelectOptions()

  const defaultCountryChoice =
    (addressType === Wp_AddressFieldTypeEnum.International &&
      !!country?.defaultValue &&
      countryToChoiceMap[country?.defaultValue]) ||
    (!!defaultCountry && {
      text: getCountryLabelFromCode(defaultCountry),
      value: defaultCountry,
    }) ||
    undefined

  const defaultStateFinal =
    state?.defaultValue ||
    (addressType === Wp_AddressFieldTypeEnum.Canada && defaultProvince) ||
    (addressType === Wp_AddressFieldTypeEnum.Us && defaultState) ||
    undefined

  return isVisible ? (
    <Box mb={3} className={createFieldCssClass(type, isHidden, cssClass)}>
      <FormControl
        id={htmlId}
        fullWidth
        component="fieldset"
        required={!isHidden && !!isRequired}
      >
        <Box mb={3}>
          {!hideLabel && (
            <Typography variant="subtitle1">{safeLabel}</Typography>
          )}
        </Box>
        <Grid container spacing={3}>
          {address && !address?.isHidden && (
            <Grid item xs={12}>
              <TextField
                formId={formId}
                field={
                  {
                    // the TextField component works with both string and number
                    id: addressFieldName as unknown as number,
                    type: Wp_FormFieldTypeEnum.Text,
                    label: addresssLabel,
                    isRequired: !isHidden && !!isRequired,
                    defaultValue: address?.defaultValue,
                    placeholder: address?.placeholder ?? ``,
                    autocompleteAttribute:
                      address?.autocompleteAttribute || `street-address`,
                    hasAutocomplete: true,
                    cssClass,
                  } as Wp_TextField
                }
                color={color}
              />
            </Grid>
          )}
          {address2 && !address2?.isHidden && (
            <Grid item xs={12}>
              <TextField
                formId={formId}
                field={
                  {
                    // the TextField component works with both string and number
                    id: address2FieldName as unknown as number,
                    type: Wp_FormFieldTypeEnum.Text,
                    label: addresss2Label,
                    isRequired: !isHidden && !!isRequired,
                    defaultValue: address2?.defaultValue,
                    placeholder: address2?.placeholder ?? ``,
                    autocompleteAttribute:
                      address2.autocompleteAttribute || `address-line2`,
                    hasAutocomplete: true,
                    cssClass,
                  } as Wp_TextField
                }
                color={color}
              />
            </Grid>
          )}
          {city && !city?.isHidden && (
            <Grid item xs={12} sm={6}>
              <TextField
                formId={formId}
                field={
                  {
                    // the TextField component works with both string and number
                    id: cityFieldName as unknown as number,
                    type: Wp_FormFieldTypeEnum.Text,
                    label: cityLabel,
                    isRequired: !isHidden && !!isRequired,
                    defaultValue: city?.defaultValue,
                    placeholder: city?.placeholder ?? ``,
                    autocompleteAttribute:
                      city?.autocompleteAttribute || `address-level2`,
                    cssClass,
                  } as Wp_TextField
                }
                color={color}
              />
            </Grid>
          )}
          {state && !state?.isHidden && (
            <Grid item xs={12} sm={6}>
              <TextField
                formId={formId}
                field={
                  {
                    // the TextField component works with both string and number
                    id: stateFieldName as unknown as number,
                    type: Wp_FormFieldTypeEnum.Text,
                    label: stateLabel,
                    isRequired: !isHidden && !!isRequired,
                    defaultValue: defaultStateFinal,
                    placeholder: state?.placeholder ?? ``,
                    autocompleteAttribute:
                      state?.autocompleteAttribute || `address-level1`,
                    hasAutocomplete: true,
                    cssClass,
                  } as Wp_TextField
                }
                color={color}
              />
            </Grid>
          )}
          {zip && !zip?.isHidden && (
            <Grid item xs={12} sm={6}>
              <TextField
                formId={formId}
                field={
                  {
                    // the TextField component works with both string and number
                    id: zipFieldName as unknown as number,
                    type: Wp_FormFieldTypeEnum.Text,
                    label: zipLabel,
                    isRequired: !isHidden && !!isRequired,
                    defaultValue: zip?.defaultValue,
                    placeholder: zip?.placeholder ?? ``,
                    autocompleteAttribute:
                      zip?.autocompleteAttribute || `postal-code`,
                    hasAutocomplete: true,
                    cssClass,
                  } as Wp_TextField
                }
                color={color}
              />
            </Grid>
          )}
          {country && !country.isHidden && isInternational && (
            <Grid item xs={12} sm={6}>
              <AutoComplete
                label={countryLabel}
                hasAutocomplete
                autocompleteAttribute={
                  country.autocompleteAttribute || `country`
                }
                defaultValue={defaultCountryChoice}
                fieldName={countryFieldName}
                htmlId={`${htmlId}_country`}
                isMultiSelect={false}
                isRequired={!!isRequired}
                isDisabled={
                  addressType !== Wp_AddressFieldTypeEnum.International ||
                  isDisabled
                }
                options={countryOptions}
                onInputChange={(e, value) => {
                  const countryChoice = countryToChoiceMap[value]
                  setValue(countryFieldName, countryChoice)
                }}
                placeholder={country?.placeholder ?? undefined}
              />
            </Grid>
          )}
        </Grid>
        {safeDescription && <FormHelperText>{safeDescription}</FormHelperText>}
      </FormControl>
    </Box>
  ) : null
}

export default AddressField
