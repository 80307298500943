import { InMemoryCache, makeVar } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { LoginPayload, Maybe } from '../../types/generated'
import { getSavedAuthData } from './auth'
// import { getSavedAuthData } from "./auth"

// const isBrowser = typeof window !== `undefined`
// const savedAuthData = isBrowser ? getSavedAuthData() : null

export type AuthDataVar = LoginPayload & { userId?: Maybe<string | number> }

export const authDataVar = makeVar<AuthDataVar>({
  authToken: null,
  refreshToken: getSavedAuthData()?.refreshToken ?? null,
  user: null,
  userId: null,
  // user: getSavedAuthData()?.user ?? null,
  // userId: getSavedAuthData()?.userId ?? null,
})

export const cache = new InMemoryCache({
  typePolicies: {
    ComplianceDetails: {
      fields: {
        userDetails: relayStylePagination(),
      },
    },
    GfSubmittedEntry: {
      keyFields: [`databaseId`],
    },
    User: {
      fields: {
        correctiveActionAssignedWorkersConnection: relayStylePagination(),
        correctiveActionDeficiencyOwnersConnection: relayStylePagination(),
        projectKeyPersonnelConnection: relayStylePagination(),
      },
    },
    CorrectiveAction: {
      fields: {
        photos: {
          merge(existing, incoming) {
            return incoming ?? []
          },
        },
      },
    },
    Project: {
      fields: {
        projectCorrectiveActionConnection: relayStylePagination(),
        documentToProjectConnection: relayStylePagination(),
      },
    },
    ToolboxTalk: {
      keyFields: [`databaseId`],
    },
    Document: {
      keyFields: [`databaseId`],
    },
    Certification: {
      keyFields: [`databaseId`],
    },
    Equipment: {
      keyFields: [`databaseId`],
    },
    AddressField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `addressValues`],
    },
    CheckboxField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `checkboxValues`],
    },
    DateField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    EmailField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    FollowUpField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `followUpForm`],
    },
    HtmlField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `content`],
    },
    MultiSelectField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `values`],
    },
    NameField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `nameValues`],
    },
    NumberField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    PageField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `pageNumber`],
    },
    PhoneField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    PhotoField: {
      keyFields: false,
      // keyFields: [`id`, `formId`, `photoValues`],
    },
    RadioField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    SectionField: {
      keyFields: false,
      // keyFields: [`id`, `label`, `formId`],
    },
    SelectField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    SignatureField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    TextareaField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    TextAreaField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    TextField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    TimeField: {
      keyFields: false,
      // keyFields: [`id`, `timeValues`, `formId`],
    },
    WebsiteField: {
      keyFields: false,
      // keyFields: [`id`, `value`, `formId`],
    },
    Query: {
      fields: {
        authData: {
          read() {
            return authDataVar()
          },
        },
        certifications: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        certification: {
          keyArgs: [`id`],
          read(existing, { toReference, args }) {
            const refItem = toReference({
              __typename: `Certification`,
              databaseId: Number(args?.id),
            })
            return existing ?? refItem
          },
        },
        correctiveActions: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        allEquipment: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        equipment: {
          keyArgs: [`id`],
          read(existing, { toReference, args }) {
            const refItem = toReference({
              __typename: `Equipment`,
              databaseId: Number(args?.id),
            })
            return existing ?? refItem
          },
        },
        lessons: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        photos: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        projects: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        toolboxTalks: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        toolboxTalk: {
          keyArgs: [`id`],
          read(existing, { toReference, args }) {
            const refItem = toReference({
              __typename: `ToolboxTalk`,
              databaseId: Number(args?.id),
            })
            return existing ?? refItem
          },
        },
        documents: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        document: {
          keyArgs: [`id`],
          read(existing, { toReference, args }) {
            const refItem = toReference({
              __typename: `Document`,
              databaseId: Number(args?.id),
            })
            return existing ?? refItem
          },
        },
        users: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        gfSubmittedEntries: {
          ...relayStylePagination(),
          keyArgs: [`where`],
        },
        gfEntry: {
          keyArgs: [`id`],
        },
        correctiveAction: {
          keyArgs: [`id`],
        },
      },
    },
  },
})
