import { useReactiveVar } from '@apollo/client'
import Box from '@mui/material/Box'
import { TextFieldProps } from '@mui/material/TextField'
import debounce from 'awesome-debounce-promise'
import { useSnackbar } from 'notistack'
import React from 'react'
import { DEBOUNCE_TIMEOUT, SEARCH_PAGE_SIZE } from '../../../../constants'
import useIsVisible from '../../../../hooks/forms/useIsVisible'
import { authDataVar } from '../../../../services/apollo/cache'
import {
  CorrectiveActionStatusEnum,
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
  PostsToPostsConnectionNameEnum,
  RelationEnum,
  RootQueryToCorrectiveActionConnectionEdge,
  RootQueryToCorrectiveActionConnectionWhereArgs,
  RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryField,
  RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryOperator,
  TaxonomyEnum,
  useAllCorrectiveActionsQuery,
} from '../../../../types/generated'
import {
  Wp_FormFieldTypeEnum,
  Wp_MultiSelectField,
  Wp_MultiSelectFieldChoice,
  Wp_SelectField,
} from '../../../../types/generated-gatsby'
import { CommonFieldProps } from '../../../../types/gf/fields/CommonFieldProps'
import { SelectOptions } from '../../../../types/gravityForms'
import { useFormTranlation } from '../../../providers/Form/formTranslation'
import { useNetwork } from '../../../providers/Network'
import { useOfflineData } from '../../../providers/OfflineMode'
import AutoComplete, { AutoCompleteProps } from '../Common/AutoComplete'

export interface CorrectiveActionSearchFieldProps extends CommonFieldProps {
  field: Omit<Wp_SelectField | Wp_MultiSelectField, 'id'> & {
    id: number | string
  }
  textFieldProps?: TextFieldProps
  defaultValue?: AutoCompleteProps['defaultValue']
  isMultiSelect?: AutoCompleteProps['isMultiSelect']
  noOptionText?: AutoCompleteProps['noOptionText']
  isDisabled?: boolean
}

const OFFLINE_NO_OPTION_TEXT = `Options enabled with Corrective Action Offline Mode Collection`

const createCorrectiveActionSelectOption = (
  edge: RootQueryToCorrectiveActionConnectionEdge
) => {
  return {
    text: `${edge?.node?.title}${
      edge?.node?.correctiveActionStatuses?.edges?.[0]?.node?.name
        ? ` - ${edge?.node?.correctiveActionStatuses?.edges?.[0]?.node?.name}`
        : ``
    }${
      edge?.node?.projectCorrectiveActionConnection?.edges?.[0]?.node?.number
        ? ` - ${edge?.node?.projectCorrectiveActionConnection?.edges?.[0]?.node?.number}`
        : ``
    }`,
    value: edge?.node?.databaseId?.toString(),
  }
}

export const CORRECTIVE_ACTION_DEFAULT_WHERE_ARGS: RootQueryToCorrectiveActionConnectionWhereArgs =
  {
    orderby: [
      {
        field: PostObjectsConnectionOrderbyEnum[`Modified`],
        order: OrderEnum[`Desc`],
      },
    ],
    taxQuery: {
      relation: RelationEnum[`And`],
      taxArray: [
        {
          operator:
            RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryOperator[
              `In`
            ],
          taxonomy: TaxonomyEnum[`Correctiveactionstatus`],
          field:
            RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryField[`Slug`],
          terms: [
            CorrectiveActionStatusEnum[`Assigned`],
            CorrectiveActionStatusEnum[`Open`],
          ],
        },
      ],
    },
  }

const CorrectiveActionSearchField: React.FC<
  CorrectiveActionSearchFieldProps
> = (props) => {
  const {
    field,
    textFieldProps,
    isMultiSelect,
    defaultValue: defaultValueProp,
    noOptionText,
    isDisabled = false,
    formId,
  } = props
  const {
    id,
    type,
    label,
    description,
    cssClass,
    isRequired,
    choices,
    allowCustomOption,
    choiceTranslations,
  } = field
  const [isLoading, setIsLoading] = React.useState(false)
  const htmlId = `field_${formId}_${id}`
  const { enqueueSnackbar } = useSnackbar()
  const isOnline = useNetwork()
  const { fieldTranslation } = useFormTranlation(choiceTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description

  const defaultChoices =
    (
      useOfflineData()?.offlineData
        ?.CorrectiveActions as RootQueryToCorrectiveActionConnectionEdge[]
    )?.map(createCorrectiveActionSelectOption) ?? choices

  const defaultValue =
    defaultValueProp ??
    (choices as Wp_MultiSelectFieldChoice[])?.filter(
      (choice) => choice?.isSelected
    ) ??
    []
  const { isVisible, isHidden } = useIsVisible(field)
  const user = useReactiveVar(authDataVar)?.user

  const { loading, data, refetch } = useAllCorrectiveActionsQuery({
    variables: {
      first: SEARCH_PAGE_SIZE,
      where: {
        ...CORRECTIVE_ACTION_DEFAULT_WHERE_ARGS,
        postToPostConnectionQuery: {
          relation: RelationEnum[`Or`],
          connections: user?.databaseId
            ? [
                {
                  connection:
                    PostsToPostsConnectionNameEnum[
                      `CorrectiveActionAssignedWorkers`
                    ],
                  ids: [user?.databaseId],
                },
                {
                  connection:
                    PostsToPostsConnectionNameEnum[
                      `CorrectiveActionDeficiencyOwners`
                    ],
                  ids: [user?.databaseId],
                },
              ]
            : undefined,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !isOnline || !user?.databaseId || isDisabled,
    fetchPolicy: `network-only`,
  })

  const fieldName = String(id)

  const getAsyncOptions = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e?.target?.value

    if (isOnline) {
      try {
        const result = await refetch?.({
          first: SEARCH_PAGE_SIZE,
          where: {
            taxQuery: undefined,
            orderby: undefined,
            search: inputValue || undefined,
          },
        })

        if (result?.error) {
          enqueueSnackbar(`Error fetching corrective actions from server`, {
            variant: `warning`,
          })
        }
      } catch (err) {
        enqueueSnackbar(`Error fetching corrective actions from server`, {
          variant: `warning`,
        })
      }
    }
    setIsLoading(false)
  }

  const debouncedLoadOptions = debounce(getAsyncOptions, DEBOUNCE_TIMEOUT)

  const options: SelectOptions =
    // @ts-expect-error this is fine
    data?.correctiveActions?.edges?.map(createCorrectiveActionSelectOption) ??
    defaultChoices

  return isVisible ? (
    <Box
      mb={3}
      className={`gfield gfield-${type} ${
        isHidden && `hidden`
      } ${cssClass}`.trim()}
    >
      <AutoComplete
        defaultValue={defaultValue}
        fieldName={fieldName}
        htmlId={htmlId}
        label={safeLabel}
        options={options}
        cssClass={cssClass}
        description={safeDescription}
        isMultiSelect={
          isMultiSelect || type === Wp_FormFieldTypeEnum.Multiselect
        }
        isRequired={!isHidden && !!isRequired}
        isDisabled={isDisabled}
        textFieldProps={textFieldProps}
        onInputChange={(e) => {
          setIsLoading(true)
          // @ts-expect-error this is fine
          debouncedLoadOptions(e)
        }}
        loading={loading || isLoading}
        freeSolo={allowCustomOption}
        noOptionText={!isOnline ? OFFLINE_NO_OPTION_TEXT : noOptionText}
      />
    </Box>
  ) : null
}

export default CorrectiveActionSearchField
