import * as React from 'react'
import { FieldValues } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { HARNESS_LOCAL_DRAFT_ENTRIES_KEY } from '../../constants'
import useLocalForage from '../../hooks/useLocalForage'
import {
  LocalDraftEntries,
  LocalDraftEntry,
  LocalDraftEntryStatusEnum,
} from '../../types/drafts'
import { Maybe } from '../../types/generated'
import { GFormFieldApollo } from '../../types/gravityForms'
import makeFieldValuesInputFromEntryFields from '../../utils/gravityForms/makeFieldValueInputsFromEntryFields'
import makeFieldValues from '../../utils/gravityForms/makeFieldValues'
import uniqBy from 'lodash/uniqBy'
import omit from 'lodash/omit'
import filter from 'lodash/filter'
import last from 'lodash/last'

interface UpdateDraftEntryArgs {
  draftId: number
  fieldValues?: FieldValues
  fieldValuesInputArray?: LocalDraftEntry['fieldValuesInputArray']
  status?: LocalDraftEntryStatusEnum
  lastPage?: number
  errors?: LocalDraftEntry['errors']
  followUpToForm?: LocalDraftEntry['followUpToForm']
}

interface CreateUpdateEntryArgs {
  formFields?: GFormFieldApollo[]
  fieldValues?: FieldValues
  form: LocalDraftEntry['form']
}

export type DraftEntriesContextType = {
  localDraftEntries: LocalDraftEntries
  setLocalDraftEntries: React.AsyncDispatch<
    React.SetStateAction<LocalDraftEntries>
  >
  getDraftEntryById: (draftId?: Maybe<number>) => Maybe<LocalDraftEntry>
  getDraftEntryByIdAsync: (
    draftId?: Maybe<number>
  ) => Promise<Maybe<LocalDraftEntry>>
  updateDraftEntry: (
    args: UpdateDraftEntryArgs
  ) => Promise<Maybe<LocalDraftEntry>>
  getFollowUpFormDraftEntries: (draftId: number) => LocalDraftEntries
  removeDraftEntries: (draftIds: number[]) => Promise<void>
  createUpdateEntry: (args: CreateUpdateEntryArgs) => Promise<LocalDraftEntry>
}

export const DraftEntriesContext =
  // @ts-expect-error this is fine
  React.createContext<DraftEntriesContextType>(undefined)

interface DraftProviderProps {
  children?: React.ReactNode
}

const DraftEntriesProvider: React.FC<DraftProviderProps> = ({ children }) => {
  const [localDraftEntries, setLocalDraftEntries, _, __, getSavedDrafts] =
    useLocalForage<LocalDraftEntries>(HARNESS_LOCAL_DRAFT_ENTRIES_KEY, {})

  const getDraftEntryById = (
    draftId?: Maybe<number>
  ): Maybe<LocalDraftEntry> => {
    if (!draftId) return null
    return localDraftEntries?.[draftId] ?? null
  }

  const getDraftEntryByIdAsync = async (
    draftId?: Maybe<number>
  ): Promise<Maybe<LocalDraftEntry>> => {
    if (!draftId) return null
    const draftEntries = await getSavedDrafts()
    return draftEntries?.[draftId] ?? null
  }

  const updateDraftEntry: DraftEntriesContextType['updateDraftEntry'] = async (
    args
  ) => {
    const {
      draftId,
      fieldValues,
      fieldValuesInputArray,
      status,
      lastPage,
      errors,
      followUpToForm,
    } = args

    const draftEntry = await getDraftEntryByIdAsync(draftId)

    if (!draftEntry) {
      return null
    } else {
      const updatedFieldValues = {
        ...(draftEntry.fieldValues ?? {}),
        ...(fieldValues ?? {}),
      }

      const updatedFieldValuesInputArray = uniqBy(
        [
          ...(fieldValuesInputArray ?? []), // uniqBy takes the first value so make sure the new values are first.
          ...(draftEntry?.fieldValuesInputArray ?? []),
        ],
        `id`
      )

      const updatedDraftEntry: LocalDraftEntry = {
        ...draftEntry,
        fieldValues: updatedFieldValues,
        fieldValuesInputArray: updatedFieldValuesInputArray,
        status: status ?? draftEntry?.status,
        lastPage: lastPage ?? draftEntry?.lastPage ?? null,
        errors: errors ?? draftEntry?.errors,
        // @ts-expect-error this is fine
        followUpToForm: { ...draftEntry?.followUpToForm, ...followUpToForm },
        lastUpdated: new Date(),
      }

      const savedDrafts = await getSavedDrafts()
      const updatedDraftEntries = {
        ...savedDrafts,
        [draftEntry?.draftId]: updatedDraftEntry,
      }

      await setLocalDraftEntries(updatedDraftEntries)
      return updatedDraftEntry
    }
  }

  const getFollowUpFormDraftEntries = (draftId: number) => {
    return filter(localDraftEntries, (draft) => {
      const statusMatch =
        draft?.status === LocalDraftEntryStatusEnum[`AwaitingFollowUpToForm`] ||
        draft?.status === LocalDraftEntryStatusEnum[`ErrorOnSubmit`]
      const draftIdMatch = draft?.followUpToForm?.draftId === draftId
      return statusMatch && draftIdMatch
    })
  }

  const removeDraftEntries = async (draftIds: number[]) => {
    // const old = await getSavedDrafts()
    await setLocalDraftEntries((old) => {
      if (!old) return {}
      return omit(
        old,
        draftIds?.map((e) => e?.toString()) ?? []
      ) as LocalDraftEntries
    })
  }

  const createUpdateEntry = async (args: CreateUpdateEntryArgs) => {
    const { formFields, form, fieldValues: fieldValuesArg } = args
    const savedDrafts = (await getSavedDrafts()) ?? localDraftEntries

    const lastDraftId = parseInt(last(Object.keys(savedDrafts)) ?? `0`)
    const nextDraftId = lastDraftId + 1
    const fieldValues =
      fieldValuesArg || (await makeFieldValues(formFields ?? []))
    const fieldValuesInputArray =
      makeFieldValuesInputFromEntryFields(formFields)

    const localDraftEntry: LocalDraftEntry = {
      draftId: nextDraftId,
      followUpToForm: null, // will not be able to edit follow up forms as they turn into CPTs
      fieldValuesInputArray,
      fieldValues,
      form,
      resumeToken: uuid(),
      status: LocalDraftEntryStatusEnum[`UpdateInProgress`],
    }
    await setLocalDraftEntries((state) => ({
      ...state,
      [nextDraftId]: localDraftEntry,
    }))

    return localDraftEntry
  }

  return (
    <DraftEntriesContext.Provider
      value={{
        localDraftEntries,
        setLocalDraftEntries,
        getDraftEntryById,
        getDraftEntryByIdAsync,
        updateDraftEntry,
        getFollowUpFormDraftEntries,
        removeDraftEntries,
        createUpdateEntry,
        // setFieldValues,
      }}
    >
      {children}
    </DraftEntriesContext.Provider>
  )
}

export const useDraftEntries = (): DraftEntriesContextType =>
  React.useContext(DraftEntriesContext)

export default DraftEntriesProvider
