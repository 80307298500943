import isValid from 'date-fns/fp/isValid'
import parse from 'date-fns/fp/parse'
import parseISO from 'date-fns/fp/parseISO'
import { Maybe } from '../../types/generated'

export const DEFAULT_DATE_FORMAT = `PPP`
const mdyParser = parse(new Date(), `MM/dd/yyyy`)
const dmyParser = parse(new Date(), `dd/MM/yyyy`)
const ymdParser = parse(new Date(), `yyyy/MM/dd`)

export const safeDateParse = (
  date?: Maybe<string | number | Date>
): Maybe<Date | number | string> => {
  if (!date) {
    return null
  }

  const isValidJSConversion = isValid(new Date(date))

  if (typeof date === `number`) {
    return date
  } else if (typeof date === `object`) {
    if (date instanceof Date) {
      return date
    }
    return ``
  } else if (isValid(parseISO(date))) {
    return parseISO(date)
  } else if (isValidJSConversion && !isNaN(Number(date))) {
    // Number.isNaN checks for NaN where isNaN checks if the value can be converted to a number
    return new Date(date)
  } else if (isValid(ymdParser(date))) {
    return ymdParser(date)
  } else if (isValid(mdyParser(date))) {
    return mdyParser(date)
  } else if (isValid(dmyParser(date))) {
    return dmyParser(date)
  } else {
    return date
  }
}
