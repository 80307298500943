import Box from '@mui/material/Box'
import { TextFieldProps } from '@mui/material/TextField'
import debounce from 'awesome-debounce-promise'
import { useSnackbar } from 'notistack'
import React from 'react'
import { DEBOUNCE_TIMEOUT, SEARCH_PAGE_SIZE } from '../../../../constants'
import useIsVisible from '../../../../hooks/forms/useIsVisible'
import {
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
  RootQueryToLessonConnectionEdge,
  RootQueryToLessonConnectionWhereArgs,
  useAllLessonsQuery,
} from '../../../../types/generated'
import {
  Wp_FormFieldTypeEnum,
  Wp_MultiSelectField,
  Wp_MultiSelectFieldChoice,
  Wp_SelectField,
} from '../../../../types/generated-gatsby'
import { CommonFieldProps } from '../../../../types/gf/fields/CommonFieldProps'
import { SelectOptions } from '../../../../types/gravityForms'
import { useFormTranlation } from '../../../providers/Form/formTranslation'
import { useNetwork } from '../../../providers/Network'
import { useOfflineData } from '../../../providers/OfflineMode'
import AutoComplete, { AutoCompleteProps } from '../Common/AutoComplete'

export interface LessonSearchFieldProps extends CommonFieldProps {
  field: Omit<Wp_SelectField | Wp_MultiSelectField, 'id'> & {
    id: number | string
  }
  textFieldProps?: TextFieldProps
  defaultValue?: AutoCompleteProps['defaultValue']
  isMultiSelect?: AutoCompleteProps['isMultiSelect']
  noOptionText?: AutoCompleteProps['noOptionText']
  isDisabled?: boolean
}

const OFFLINE_NO_OPTION_TEXT = `Options enabled with Lessons Offline Mode Collection`

const createLessonSelectOption = (edge: RootQueryToLessonConnectionEdge) => ({
  text: `${edge?.node?.title}`,
  value: edge?.node?.databaseId?.toString(),
})

export const LESSON_DEFAULT_WHERE_ARGS: RootQueryToLessonConnectionWhereArgs = {
  orderby: [
    {
      field: PostObjectsConnectionOrderbyEnum[`Title`],
      order: OrderEnum[`Desc`],
    },
  ],
}

const LessonSearchField: React.FC<LessonSearchFieldProps> = (props) => {
  const {
    field,
    textFieldProps,
    isMultiSelect,
    defaultValue: defaultValueProp,
    noOptionText,
    isDisabled = false,
    formId,
  } = props
  const {
    id,
    type,
    label,
    description,
    cssClass,
    isRequired,
    choices,
    allowCustomOption,
    choiceTranslations,
  } = field
  const [isLoading, setIsLoading] = React.useState(false)
  const htmlId = `field_${formId}_${id}`
  const { enqueueSnackbar } = useSnackbar()
  const { fieldTranslation } = useFormTranlation(choiceTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description

  const defaultChoices =
    (
      useOfflineData()?.offlineData
        ?.Lessons as RootQueryToLessonConnectionEdge[]
    )?.map(createLessonSelectOption) ?? choices

  const isOnline = useNetwork()
  const defaultValue =
    defaultValueProp ??
    (choices as Wp_MultiSelectFieldChoice[])?.filter(
      (choice) => choice?.isSelected
    )
  const { isVisible, isHidden } = useIsVisible(field)

  const { loading, data, refetch } = useAllLessonsQuery({
    variables: {
      first: SEARCH_PAGE_SIZE,
      where: LESSON_DEFAULT_WHERE_ARGS,
    },
    notifyOnNetworkStatusChange: true,
    skip: !isOnline || isDisabled,
    fetchPolicy: `network-only`,
  })

  const fieldName = String(id)

  const getAsyncOptions = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e?.target?.value

    if (isOnline) {
      try {
        const result = await refetch?.({
          first: SEARCH_PAGE_SIZE,
          where: {
            ...LESSON_DEFAULT_WHERE_ARGS,
            orderby: undefined,
            search: inputValue || undefined,
          },
        })

        if (result?.error) {
          enqueueSnackbar(`Error fetching Lessons from server`, {
            variant: `warning`,
          })
        }
      } catch (err) {
        enqueueSnackbar(`Error fetching Lessons from server`, {
          variant: `warning`,
        })
      }
    }
    setIsLoading(false)
  }

  const debouncedLoadOptions = debounce(getAsyncOptions, DEBOUNCE_TIMEOUT)

  const options: SelectOptions =
    // @ts-expect-error this is fine
    data?.lessons?.edges?.map(createLessonSelectOption) ?? defaultChoices

  return isVisible ? (
    <Box
      mb={3}
      className={`gfield gfield-${type} ${
        isHidden && `hidden`
      } ${cssClass}`.trim()}
    >
      <AutoComplete
        defaultValue={defaultValue}
        fieldName={fieldName}
        htmlId={htmlId}
        label={safeLabel}
        options={options}
        cssClass={cssClass}
        description={safeDescription}
        isMultiSelect={
          isMultiSelect || type === Wp_FormFieldTypeEnum.Multiselect
        }
        isRequired={!isHidden && !!isRequired}
        textFieldProps={textFieldProps}
        isDisabled={isDisabled}
        onInputChange={(e) => {
          setIsLoading(true)
          // @ts-expect-error this is fine
          debouncedLoadOptions(e)
        }}
        loading={loading || isLoading}
        freeSolo={allowCustomOption}
        noOptionText={!isOnline ? OFFLINE_NO_OPTION_TEXT : noOptionText}
      />
    </Box>
  ) : null
}

export default LessonSearchField
