/**
 * Custom Post Type Singular Name
 */
export const CERTIFICATION_SINGLE_NAME = `certification`
/**
 * Custom Post Type Plural Name
 */
export const CERTIFICATION_PLURAL_NAME = `certifications`
/**
 * Session Storage Key for filters
 */
export const CERTIFICATION_LIST_PAGE_FILTERS = `CERTIFICATION_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const CERTIFICATION_SLUG = `certifications`
/**
 * Certification Share Url Slug
 */
export const CERTIFICATION_SHARE_SLUG = `certification-share`

/**
 * Meta Key
 */
export const CERTIFICATION_EXPIRATION_DATE_KEY = `_expiration_date`
export const CERTIFICATION_COMPLETED_DATE_KEY = `_completed_date`

export const CERTIFICATION_EXPIRATION_WARNING_SLUG = `certifications/expiration-warnings`
