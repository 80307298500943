import { useReactiveVar } from '@apollo/client'
import Box from '@mui/material/Box'
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import * as React from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import { CARD_GAP, usernameRegExp } from '../../../constants'
import useIsVisible from '../../../hooks/forms/useIsVisible'
import {
  Maybe,
  Wp_EmailField,
  Wp_FormFieldTypeEnum,
  Wp_TextAreaField,
  Wp_TextField,
  Wp_UsernameField,
  Wp_WebsiteField,
} from '../../../types/generated-gatsby'
import { authDataVar } from '../../../services/apollo/cache'
import { useFormTranlation } from '../../providers/Form/formTranslation'
import { useTranslation } from 'react-i18next'
import { CommonFieldProps } from '../../../types/gf/fields/CommonFieldProps'
import { createFieldCssClass } from '../../../utils/gravityForms/fields/createFieldCssClass'
import { createFieldHtmlId } from '../../../utils/gravityForms/fields/createFieldHtmlId'

export interface TextFieldProps extends CommonFieldProps {
  field: Omit<
    | Wp_TextField
    | Wp_EmailField
    | Wp_UsernameField
    | Wp_TextAreaField
    | Wp_WebsiteField,
    'id'
  > & { id: string | number }
  // field: Omit<Wp_TextField, 'id'> &
  //   Omit<Wp_EmailField, 'id'> & { id: number | string }
  textFieldProps?: MuiTextFieldProps
}

const getHtmlInputType = (type?: Maybe<Wp_FormFieldTypeEnum>) => {
  switch (type) {
    case Wp_FormFieldTypeEnum.Textarea:
    case Wp_FormFieldTypeEnum.Text:
      return `text`
    case Wp_FormFieldTypeEnum.Email:
      return `email`
    case Wp_FormFieldTypeEnum.Website:
      return `url`
    default:
      return `text`
  }
}

const DEFAULT_VARIANT = `outlined`
const DEFAULT_COLOR = `primary`
const DEFAULT_VALUE = ``

const TextField: React.FC<TextFieldProps> = (props) => {
  const { field, textFieldProps, formId, color = DEFAULT_COLOR } = props
  const {
    id,
    type,
    label,
    description,
    cssClass,
    isRequired,
    placeholder,
    basicTranslations,
  } = field

  const defaultValue = (field as Wp_TextField | Wp_TextAreaField)?.defaultValue
  const maxLength = (field as Wp_TextField)?.maxLength
  const autocompleteAttribute = (field as Wp_TextField)?.autocompleteAttribute
  const hasAutocomplete = (field as Wp_TextField)?.hasAutocomplete ?? false

  const { t } = useTranslation()
  const htmlId = createFieldHtmlId(formId, id)
  const { fieldTranslation } = useFormTranlation(basicTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description

  const user = useReactiveVar(authDataVar)?.user

  const safeDefaultValue =
    defaultValue == `{user:user_email}`
      ? user?.email
      : defaultValue === `{user:display_name}`
      ? user?.name
      : !!defaultValue
      ? defaultValue
      : DEFAULT_VALUE

  const fieldName = String(id)
  const { isVisible, isHidden } = useIsVisible(field)

  const isLocationField = field?.cssClass?.includes(`field-location`)

  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const error = errors?.[fieldName] as FieldError
  const hasError = !!error
  const helperText = hasError ? errors?.[fieldName]?.message : safeDescription

  return isVisible ? (
    <Box
      mb={CARD_GAP}
      className={createFieldCssClass(
        type,
        isHidden || isLocationField,
        cssClass
      )}
    >
      <Controller
        control={control}
        name={fieldName}
        rules={{
          required: {
            value: !isHidden && !!isRequired,
            message: `${safeLabel} ${t(`is required`)}.`,
          },
          maxLength: maxLength
            ? {
                value: maxLength,
                message: field?.errorMessage ?? `Max length has been exceeded`,
              }
            : undefined,
          pattern:
            type === Wp_FormFieldTypeEnum.Username
              ? {
                  value: usernameRegExp,
                  message: `Username can only contain lowercase letter and numbers`,
                }
              : undefined,
        }}
        defaultValue={safeDefaultValue}
        render={({ field: { ref, ...rest } }) => (
          <MuiTextField
            type={getHtmlInputType(type)}
            label={safeLabel}
            id={htmlId}
            variant={DEFAULT_VARIANT}
            fullWidth
            autoComplete={
              hasAutocomplete ? autocompleteAttribute ?? `off` : `off`
            }
            color={color}
            disabled={cssClass?.includes(`readonly`)}
            error={hasError}
            helperText={helperText as string}
            required={!isHidden && !!isRequired}
            placeholder={
              placeholder ||
              (type === Wp_FormFieldTypeEnum.Website ? `http://` : ``)
            }
            {...textFieldProps}
            {...rest}
            onBlur={() => clearErrors(fieldName)}
          />
        )}
      />
    </Box>
  ) : null
}

export default TextField
