/**
 * Custom Post Type Singular Name
 */
export const CORRECTIVE_ACTION_SINGLE_NAME = `corrective_action`
/**
 * Custom Post Type Plural Name
 */
export const CORRECTIVE_ACTION_PLURAL_NAME = `corrective_actions`
/**
 * Session Storage Key for filters
 */
export const CORRECTIVE_ACTION_LIST_PAGE_FILTERS = `CORRECTIVE_ACTION_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const CORRECTIVE_ACTION_SLUG = `corrective-actions`

/**
 * Meta field key
 */
export const CORRECTIVE_ACTION_DUE_DATE_META_KEY = `_due_date`
export const CORRECTIVE_ACTION_COMPLETED_DATE_META_KEY = `_date_completed`
