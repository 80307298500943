// Form IDs
export const WORKER_FORM_ID = 99
export const CREATE_WORKER_FORM_ID = 9994
export const UPDATE_WORKER_FORM_ID = 9993

export const UPDATE_CORRECTIVE_ACTION_FORM_ID = 999

export const CREATE_DOCUMENT_FORM_ID = 9998
export const UPDATE_DOCUMENT_FORM_ID = 9997

export const CREATE_CERTIFICATION_FORM_ID = 9996
export const UPDATE_CERTIFICATION_FORM_ID = 9995

export const CREATE_CERT_EXPIRATION_WARNING_FORM_ID = 9989
export const UPDATE_CERT_EXPIRATION_WARNING_FORM_ID = 9988

export const CREATE_PROJECT_FORM_ID = 9992
export const UPDATE_PROJECT_FORM_ID = 9991

export const SDS_REQUEST_FORM_ID = 9990

export const TOOLBOX_TALK_FORM_ID = 232323
export const UPDATE_TOOLBOX_TALK_FORM_ID = 242424
export const TOOLBOX_TALK_SET_TRANSLATION_FORM_ID = 242423

export const CREATE_EQUIPMENT_FORM_ID = 252525
export const UPDATE_EQUIPMENT_FORM_ID = 262626

export const CREATE_IR_FORM_ID = 8888
export const UPDATE_IR_FORM_ID = 8889
export const IR_ADDITIONAL_INFORMATION_FORM_ID = 8887
export const IR_ROOT_CAUSE_FORM_ID = 8886

// CPT Filter Pages
export const IR_FILTER_FORM_ID = 200001
export const PROJECT_FILTER_FORM_ID = 200002
