import React from 'react'
import Wrapper from './Wrapper'
import 'resize-observer-polyfill'
import { GatsbyBrowser } from 'gatsby'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <Wrapper>{element}</Wrapper>
}
