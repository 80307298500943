/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

// @ts-nocheck

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: any; output: any }
  GatsbyImageData: { input: any; output: any }
  JSON: { input: any; output: any }
}

export type AvifOptions = {
  lossless?: InputMaybe<Scalars['Boolean']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  speed?: InputMaybe<Scalars['Int']['input']>
}

export type BlurredOptions = {
  /** Force the output format for the low-res preview. Default is to use the same format as the input. You should rarely need to change this */
  toFormat?: InputMaybe<ImageFormat>
  /** Width of the generated low-res preview. Default is 20px */
  width?: InputMaybe<Scalars['Int']['input']>
}

export type BooleanQueryOperatorInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  ne?: InputMaybe<Scalars['Boolean']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
}

export type DateQueryOperatorInput = {
  eq?: InputMaybe<Scalars['Date']['input']>
  gt?: InputMaybe<Scalars['Date']['input']>
  gte?: InputMaybe<Scalars['Date']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
  lt?: InputMaybe<Scalars['Date']['input']>
  lte?: InputMaybe<Scalars['Date']['input']>
  ne?: InputMaybe<Scalars['Date']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
}

export type Directory = Node & {
  __typename?: 'Directory'
  absolutePath: Scalars['String']['output']
  accessTime: Scalars['Date']['output']
  atime: Scalars['Date']['output']
  atimeMs: Scalars['Float']['output']
  base: Scalars['String']['output']
  birthTime: Scalars['Date']['output']
  /** @deprecated Use `birthTime` instead */
  birthtime?: Maybe<Scalars['Date']['output']>
  /** @deprecated Use `birthTime` instead */
  birthtimeMs?: Maybe<Scalars['Float']['output']>
  changeTime: Scalars['Date']['output']
  children: Array<Node>
  ctime: Scalars['Date']['output']
  ctimeMs: Scalars['Float']['output']
  dev: Scalars['Int']['output']
  dir: Scalars['String']['output']
  ext: Scalars['String']['output']
  extension: Scalars['String']['output']
  gid: Scalars['Int']['output']
  id: Scalars['ID']['output']
  ino: Scalars['Float']['output']
  internal: Internal
  mode: Scalars['Int']['output']
  modifiedTime: Scalars['Date']['output']
  mtime: Scalars['Date']['output']
  mtimeMs: Scalars['Float']['output']
  name: Scalars['String']['output']
  nlink: Scalars['Int']['output']
  parent?: Maybe<Node>
  prettySize: Scalars['String']['output']
  rdev: Scalars['Int']['output']
  relativeDirectory: Scalars['String']['output']
  relativePath: Scalars['String']['output']
  root: Scalars['String']['output']
  size: Scalars['Int']['output']
  sourceInstanceName: Scalars['String']['output']
  uid: Scalars['Int']['output']
}

export type DirectoryAccessTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryAtimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryBirthTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryChangeTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryCtimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryModifiedTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryMtimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type DirectoryConnection = {
  __typename?: 'DirectoryConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<DirectoryEdge>
  group: Array<DirectoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Directory>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type DirectoryConnectionDistinctArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryConnectionGroupArgs = {
  field: DirectoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type DirectoryConnectionMaxArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryConnectionMinArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryConnectionSumArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryEdge = {
  __typename?: 'DirectoryEdge'
  next?: Maybe<Directory>
  node: Directory
  previous?: Maybe<Directory>
}

export type DirectoryFieldSelector = {
  absolutePath?: InputMaybe<FieldSelectorEnum>
  accessTime?: InputMaybe<FieldSelectorEnum>
  atime?: InputMaybe<FieldSelectorEnum>
  atimeMs?: InputMaybe<FieldSelectorEnum>
  base?: InputMaybe<FieldSelectorEnum>
  birthTime?: InputMaybe<FieldSelectorEnum>
  birthtime?: InputMaybe<FieldSelectorEnum>
  birthtimeMs?: InputMaybe<FieldSelectorEnum>
  changeTime?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  ctime?: InputMaybe<FieldSelectorEnum>
  ctimeMs?: InputMaybe<FieldSelectorEnum>
  dev?: InputMaybe<FieldSelectorEnum>
  dir?: InputMaybe<FieldSelectorEnum>
  ext?: InputMaybe<FieldSelectorEnum>
  extension?: InputMaybe<FieldSelectorEnum>
  gid?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  ino?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  mode?: InputMaybe<FieldSelectorEnum>
  modifiedTime?: InputMaybe<FieldSelectorEnum>
  mtime?: InputMaybe<FieldSelectorEnum>
  mtimeMs?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nlink?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  prettySize?: InputMaybe<FieldSelectorEnum>
  rdev?: InputMaybe<FieldSelectorEnum>
  relativeDirectory?: InputMaybe<FieldSelectorEnum>
  relativePath?: InputMaybe<FieldSelectorEnum>
  root?: InputMaybe<FieldSelectorEnum>
  size?: InputMaybe<FieldSelectorEnum>
  sourceInstanceName?: InputMaybe<FieldSelectorEnum>
  uid?: InputMaybe<FieldSelectorEnum>
}

export type DirectoryFilterInput = {
  absolutePath?: InputMaybe<StringQueryOperatorInput>
  accessTime?: InputMaybe<DateQueryOperatorInput>
  atime?: InputMaybe<DateQueryOperatorInput>
  atimeMs?: InputMaybe<FloatQueryOperatorInput>
  base?: InputMaybe<StringQueryOperatorInput>
  birthTime?: InputMaybe<DateQueryOperatorInput>
  birthtime?: InputMaybe<DateQueryOperatorInput>
  birthtimeMs?: InputMaybe<FloatQueryOperatorInput>
  changeTime?: InputMaybe<DateQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  ctime?: InputMaybe<DateQueryOperatorInput>
  ctimeMs?: InputMaybe<FloatQueryOperatorInput>
  dev?: InputMaybe<IntQueryOperatorInput>
  dir?: InputMaybe<StringQueryOperatorInput>
  ext?: InputMaybe<StringQueryOperatorInput>
  extension?: InputMaybe<StringQueryOperatorInput>
  gid?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  ino?: InputMaybe<FloatQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  mode?: InputMaybe<IntQueryOperatorInput>
  modifiedTime?: InputMaybe<DateQueryOperatorInput>
  mtime?: InputMaybe<DateQueryOperatorInput>
  mtimeMs?: InputMaybe<FloatQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nlink?: InputMaybe<IntQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  prettySize?: InputMaybe<StringQueryOperatorInput>
  rdev?: InputMaybe<IntQueryOperatorInput>
  relativeDirectory?: InputMaybe<StringQueryOperatorInput>
  relativePath?: InputMaybe<StringQueryOperatorInput>
  root?: InputMaybe<StringQueryOperatorInput>
  size?: InputMaybe<IntQueryOperatorInput>
  sourceInstanceName?: InputMaybe<StringQueryOperatorInput>
  uid?: InputMaybe<IntQueryOperatorInput>
}

export type DirectoryGroupConnection = {
  __typename?: 'DirectoryGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<DirectoryEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<DirectoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Directory>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type DirectoryGroupConnectionDistinctArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryGroupConnectionGroupArgs = {
  field: DirectoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type DirectoryGroupConnectionMaxArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryGroupConnectionMinArgs = {
  field: DirectoryFieldSelector
}

export type DirectoryGroupConnectionSumArgs = {
  field: DirectoryFieldSelector
}

export type DirectorySortInput = {
  absolutePath?: InputMaybe<SortOrderEnum>
  accessTime?: InputMaybe<SortOrderEnum>
  atime?: InputMaybe<SortOrderEnum>
  atimeMs?: InputMaybe<SortOrderEnum>
  base?: InputMaybe<SortOrderEnum>
  birthTime?: InputMaybe<SortOrderEnum>
  birthtime?: InputMaybe<SortOrderEnum>
  birthtimeMs?: InputMaybe<SortOrderEnum>
  changeTime?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  ctime?: InputMaybe<SortOrderEnum>
  ctimeMs?: InputMaybe<SortOrderEnum>
  dev?: InputMaybe<SortOrderEnum>
  dir?: InputMaybe<SortOrderEnum>
  ext?: InputMaybe<SortOrderEnum>
  extension?: InputMaybe<SortOrderEnum>
  gid?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  ino?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  mode?: InputMaybe<SortOrderEnum>
  modifiedTime?: InputMaybe<SortOrderEnum>
  mtime?: InputMaybe<SortOrderEnum>
  mtimeMs?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nlink?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  prettySize?: InputMaybe<SortOrderEnum>
  rdev?: InputMaybe<SortOrderEnum>
  relativeDirectory?: InputMaybe<SortOrderEnum>
  relativePath?: InputMaybe<SortOrderEnum>
  root?: InputMaybe<SortOrderEnum>
  size?: InputMaybe<SortOrderEnum>
  sourceInstanceName?: InputMaybe<SortOrderEnum>
  uid?: InputMaybe<SortOrderEnum>
}

export type DuotoneGradient = {
  highlight: Scalars['String']['input']
  opacity?: InputMaybe<Scalars['Int']['input']>
  shadow: Scalars['String']['input']
}

export enum FieldSelectorEnum {
  Select = 'SELECT',
}

export type File = Node & {
  __typename?: 'File'
  absolutePath: Scalars['String']['output']
  accessTime: Scalars['Date']['output']
  atime: Scalars['Date']['output']
  atimeMs: Scalars['Float']['output']
  base: Scalars['String']['output']
  birthTime: Scalars['Date']['output']
  /** @deprecated Use `birthTime` instead */
  birthtime?: Maybe<Scalars['Date']['output']>
  /** @deprecated Use `birthTime` instead */
  birthtimeMs?: Maybe<Scalars['Float']['output']>
  blksize?: Maybe<Scalars['Int']['output']>
  blocks?: Maybe<Scalars['Int']['output']>
  changeTime: Scalars['Date']['output']
  /** Returns the first child node of type ImageSharp or null if there are no children of given type on this node */
  childImageSharp?: Maybe<ImageSharp>
  children: Array<Node>
  /** Returns all children nodes filtered by type ImageSharp */
  childrenImageSharp?: Maybe<Array<Maybe<ImageSharp>>>
  ctime: Scalars['Date']['output']
  ctimeMs: Scalars['Float']['output']
  dev: Scalars['Int']['output']
  dir: Scalars['String']['output']
  ext: Scalars['String']['output']
  extension: Scalars['String']['output']
  gid: Scalars['Int']['output']
  id: Scalars['ID']['output']
  ino: Scalars['Float']['output']
  internal: Internal
  mode: Scalars['Int']['output']
  modifiedTime: Scalars['Date']['output']
  mtime: Scalars['Date']['output']
  mtimeMs: Scalars['Float']['output']
  name: Scalars['String']['output']
  nlink: Scalars['Int']['output']
  parent?: Maybe<Node>
  prettySize: Scalars['String']['output']
  /** Copy file to static directory and return public url to it */
  publicURL?: Maybe<Scalars['String']['output']>
  rdev: Scalars['Int']['output']
  relativeDirectory: Scalars['String']['output']
  relativePath: Scalars['String']['output']
  root: Scalars['String']['output']
  size: Scalars['Int']['output']
  sourceInstanceName: Scalars['String']['output']
  uid: Scalars['Int']['output']
}

export type FileAccessTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileAtimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileBirthTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileChangeTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileCtimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileModifiedTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileMtimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type FileConnection = {
  __typename?: 'FileConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<FileEdge>
  group: Array<FileGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<File>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type FileConnectionDistinctArgs = {
  field: FileFieldSelector
}

export type FileConnectionGroupArgs = {
  field: FileFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type FileConnectionMaxArgs = {
  field: FileFieldSelector
}

export type FileConnectionMinArgs = {
  field: FileFieldSelector
}

export type FileConnectionSumArgs = {
  field: FileFieldSelector
}

export type FileEdge = {
  __typename?: 'FileEdge'
  next?: Maybe<File>
  node: File
  previous?: Maybe<File>
}

export type FileFieldSelector = {
  absolutePath?: InputMaybe<FieldSelectorEnum>
  accessTime?: InputMaybe<FieldSelectorEnum>
  atime?: InputMaybe<FieldSelectorEnum>
  atimeMs?: InputMaybe<FieldSelectorEnum>
  base?: InputMaybe<FieldSelectorEnum>
  birthTime?: InputMaybe<FieldSelectorEnum>
  birthtime?: InputMaybe<FieldSelectorEnum>
  birthtimeMs?: InputMaybe<FieldSelectorEnum>
  blksize?: InputMaybe<FieldSelectorEnum>
  blocks?: InputMaybe<FieldSelectorEnum>
  changeTime?: InputMaybe<FieldSelectorEnum>
  childImageSharp?: InputMaybe<ImageSharpFieldSelector>
  children?: InputMaybe<NodeFieldSelector>
  childrenImageSharp?: InputMaybe<ImageSharpFieldSelector>
  ctime?: InputMaybe<FieldSelectorEnum>
  ctimeMs?: InputMaybe<FieldSelectorEnum>
  dev?: InputMaybe<FieldSelectorEnum>
  dir?: InputMaybe<FieldSelectorEnum>
  ext?: InputMaybe<FieldSelectorEnum>
  extension?: InputMaybe<FieldSelectorEnum>
  gid?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  ino?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  mode?: InputMaybe<FieldSelectorEnum>
  modifiedTime?: InputMaybe<FieldSelectorEnum>
  mtime?: InputMaybe<FieldSelectorEnum>
  mtimeMs?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nlink?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  prettySize?: InputMaybe<FieldSelectorEnum>
  publicURL?: InputMaybe<FieldSelectorEnum>
  rdev?: InputMaybe<FieldSelectorEnum>
  relativeDirectory?: InputMaybe<FieldSelectorEnum>
  relativePath?: InputMaybe<FieldSelectorEnum>
  root?: InputMaybe<FieldSelectorEnum>
  size?: InputMaybe<FieldSelectorEnum>
  sourceInstanceName?: InputMaybe<FieldSelectorEnum>
  uid?: InputMaybe<FieldSelectorEnum>
}

export type FileFilterInput = {
  absolutePath?: InputMaybe<StringQueryOperatorInput>
  accessTime?: InputMaybe<DateQueryOperatorInput>
  atime?: InputMaybe<DateQueryOperatorInput>
  atimeMs?: InputMaybe<FloatQueryOperatorInput>
  base?: InputMaybe<StringQueryOperatorInput>
  birthTime?: InputMaybe<DateQueryOperatorInput>
  birthtime?: InputMaybe<DateQueryOperatorInput>
  birthtimeMs?: InputMaybe<FloatQueryOperatorInput>
  blksize?: InputMaybe<IntQueryOperatorInput>
  blocks?: InputMaybe<IntQueryOperatorInput>
  changeTime?: InputMaybe<DateQueryOperatorInput>
  childImageSharp?: InputMaybe<ImageSharpFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  childrenImageSharp?: InputMaybe<ImageSharpFilterListInput>
  ctime?: InputMaybe<DateQueryOperatorInput>
  ctimeMs?: InputMaybe<FloatQueryOperatorInput>
  dev?: InputMaybe<IntQueryOperatorInput>
  dir?: InputMaybe<StringQueryOperatorInput>
  ext?: InputMaybe<StringQueryOperatorInput>
  extension?: InputMaybe<StringQueryOperatorInput>
  gid?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  ino?: InputMaybe<FloatQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  mode?: InputMaybe<IntQueryOperatorInput>
  modifiedTime?: InputMaybe<DateQueryOperatorInput>
  mtime?: InputMaybe<DateQueryOperatorInput>
  mtimeMs?: InputMaybe<FloatQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nlink?: InputMaybe<IntQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  prettySize?: InputMaybe<StringQueryOperatorInput>
  publicURL?: InputMaybe<StringQueryOperatorInput>
  rdev?: InputMaybe<IntQueryOperatorInput>
  relativeDirectory?: InputMaybe<StringQueryOperatorInput>
  relativePath?: InputMaybe<StringQueryOperatorInput>
  root?: InputMaybe<StringQueryOperatorInput>
  size?: InputMaybe<IntQueryOperatorInput>
  sourceInstanceName?: InputMaybe<StringQueryOperatorInput>
  uid?: InputMaybe<IntQueryOperatorInput>
}

export type FileGroupConnection = {
  __typename?: 'FileGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<FileEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<FileGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<File>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type FileGroupConnectionDistinctArgs = {
  field: FileFieldSelector
}

export type FileGroupConnectionGroupArgs = {
  field: FileFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type FileGroupConnectionMaxArgs = {
  field: FileFieldSelector
}

export type FileGroupConnectionMinArgs = {
  field: FileFieldSelector
}

export type FileGroupConnectionSumArgs = {
  field: FileFieldSelector
}

export type FileSortInput = {
  absolutePath?: InputMaybe<SortOrderEnum>
  accessTime?: InputMaybe<SortOrderEnum>
  atime?: InputMaybe<SortOrderEnum>
  atimeMs?: InputMaybe<SortOrderEnum>
  base?: InputMaybe<SortOrderEnum>
  birthTime?: InputMaybe<SortOrderEnum>
  birthtime?: InputMaybe<SortOrderEnum>
  birthtimeMs?: InputMaybe<SortOrderEnum>
  blksize?: InputMaybe<SortOrderEnum>
  blocks?: InputMaybe<SortOrderEnum>
  changeTime?: InputMaybe<SortOrderEnum>
  childImageSharp?: InputMaybe<ImageSharpSortInput>
  children?: InputMaybe<NodeSortInput>
  childrenImageSharp?: InputMaybe<ImageSharpSortInput>
  ctime?: InputMaybe<SortOrderEnum>
  ctimeMs?: InputMaybe<SortOrderEnum>
  dev?: InputMaybe<SortOrderEnum>
  dir?: InputMaybe<SortOrderEnum>
  ext?: InputMaybe<SortOrderEnum>
  extension?: InputMaybe<SortOrderEnum>
  gid?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  ino?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  mode?: InputMaybe<SortOrderEnum>
  modifiedTime?: InputMaybe<SortOrderEnum>
  mtime?: InputMaybe<SortOrderEnum>
  mtimeMs?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nlink?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  prettySize?: InputMaybe<SortOrderEnum>
  publicURL?: InputMaybe<SortOrderEnum>
  rdev?: InputMaybe<SortOrderEnum>
  relativeDirectory?: InputMaybe<SortOrderEnum>
  relativePath?: InputMaybe<SortOrderEnum>
  root?: InputMaybe<SortOrderEnum>
  size?: InputMaybe<SortOrderEnum>
  sourceInstanceName?: InputMaybe<SortOrderEnum>
  uid?: InputMaybe<SortOrderEnum>
}

export type FloatQueryOperatorInput = {
  eq?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  ne?: InputMaybe<Scalars['Float']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export type GatsbyImageDataQueryOperatorInput = {
  eq?: InputMaybe<Scalars['GatsbyImageData']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['GatsbyImageData']['input']>>>
  ne?: InputMaybe<Scalars['GatsbyImageData']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['GatsbyImageData']['input']>>>
}

export enum GatsbyImageFormat {
  Auto = 'AUTO',
  Avif = 'AVIF',
  Jpg = 'JPG',
  NoChange = 'NO_CHANGE',
  Png = 'PNG',
  Webp = 'WEBP',
}

export enum GatsbyImageLayout {
  Constrained = 'CONSTRAINED',
  Fixed = 'FIXED',
  FullWidth = 'FULL_WIDTH',
}

export enum GatsbyImagePlaceholder {
  Blurred = 'BLURRED',
  DominantColor = 'DOMINANT_COLOR',
  None = 'NONE',
  TracedSvg = 'TRACED_SVG',
}

export type IdQueryOperatorInput = {
  eq?: InputMaybe<Scalars['ID']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  ne?: InputMaybe<Scalars['ID']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export enum ImageCropFocus {
  Attention = 'ATTENTION',
  Center = 'CENTER',
  East = 'EAST',
  Entropy = 'ENTROPY',
  North = 'NORTH',
  Northeast = 'NORTHEAST',
  Northwest = 'NORTHWEST',
  South = 'SOUTH',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST',
  West = 'WEST',
}

export enum ImageFit {
  Contain = 'CONTAIN',
  Cover = 'COVER',
  Fill = 'FILL',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE',
}

export enum ImageFormat {
  Auto = 'AUTO',
  Avif = 'AVIF',
  Jpg = 'JPG',
  NoChange = 'NO_CHANGE',
  Png = 'PNG',
  Webp = 'WEBP',
}

export enum ImageLayout {
  Constrained = 'CONSTRAINED',
  Fixed = 'FIXED',
  FullWidth = 'FULL_WIDTH',
}

export enum ImagePlaceholder {
  Blurred = 'BLURRED',
  DominantColor = 'DOMINANT_COLOR',
  None = 'NONE',
  TracedSvg = 'TRACED_SVG',
}

export type ImageSharp = Node & {
  __typename?: 'ImageSharp'
  children: Array<Node>
  fixed?: Maybe<ImageSharpFixed>
  fluid?: Maybe<ImageSharpFluid>
  gatsbyImageData: Scalars['GatsbyImageData']['output']
  id: Scalars['ID']['output']
  internal: Internal
  original?: Maybe<ImageSharpOriginal>
  parent?: Maybe<Node>
  resize?: Maybe<ImageSharpResize>
}

export type ImageSharpFixedArgs = {
  background?: InputMaybe<Scalars['String']['input']>
  base64Width?: InputMaybe<Scalars['Int']['input']>
  cropFocus?: InputMaybe<ImageCropFocus>
  duotone?: InputMaybe<DuotoneGradient>
  fit?: InputMaybe<ImageFit>
  grayscale?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  jpegProgressive?: InputMaybe<Scalars['Boolean']['input']>
  jpegQuality?: InputMaybe<Scalars['Int']['input']>
  pngCompressionSpeed?: InputMaybe<Scalars['Int']['input']>
  pngQuality?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  rotate?: InputMaybe<Scalars['Int']['input']>
  toFormat?: InputMaybe<ImageFormat>
  toFormatBase64?: InputMaybe<ImageFormat>
  traceSVG?: InputMaybe<Potrace>
  trim?: InputMaybe<Scalars['Float']['input']>
  webpQuality?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSharpFluidArgs = {
  background?: InputMaybe<Scalars['String']['input']>
  base64Width?: InputMaybe<Scalars['Int']['input']>
  cropFocus?: InputMaybe<ImageCropFocus>
  duotone?: InputMaybe<DuotoneGradient>
  fit?: InputMaybe<ImageFit>
  grayscale?: InputMaybe<Scalars['Boolean']['input']>
  jpegProgressive?: InputMaybe<Scalars['Boolean']['input']>
  jpegQuality?: InputMaybe<Scalars['Int']['input']>
  maxHeight?: InputMaybe<Scalars['Int']['input']>
  maxWidth?: InputMaybe<Scalars['Int']['input']>
  pngCompressionSpeed?: InputMaybe<Scalars['Int']['input']>
  pngQuality?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  rotate?: InputMaybe<Scalars['Int']['input']>
  sizes?: InputMaybe<Scalars['String']['input']>
  srcSetBreakpoints?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  toFormat?: InputMaybe<ImageFormat>
  toFormatBase64?: InputMaybe<ImageFormat>
  traceSVG?: InputMaybe<Potrace>
  trim?: InputMaybe<Scalars['Float']['input']>
  webpQuality?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSharpGatsbyImageDataArgs = {
  aspectRatio?: InputMaybe<Scalars['Float']['input']>
  avifOptions?: InputMaybe<AvifOptions>
  backgroundColor?: InputMaybe<Scalars['String']['input']>
  blurredOptions?: InputMaybe<BlurredOptions>
  breakpoints?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  formats?: InputMaybe<Array<InputMaybe<ImageFormat>>>
  height?: InputMaybe<Scalars['Int']['input']>
  jpgOptions?: InputMaybe<JpgOptions>
  layout?: InputMaybe<ImageLayout>
  outputPixelDensities?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >
  placeholder?: InputMaybe<ImagePlaceholder>
  pngOptions?: InputMaybe<PngOptions>
  quality?: InputMaybe<Scalars['Int']['input']>
  sizes?: InputMaybe<Scalars['String']['input']>
  tracedSVGOptions?: InputMaybe<Potrace>
  transformOptions?: InputMaybe<TransformOptions>
  webpOptions?: InputMaybe<WebPOptions>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSharpResizeArgs = {
  background?: InputMaybe<Scalars['String']['input']>
  base64?: InputMaybe<Scalars['Boolean']['input']>
  cropFocus?: InputMaybe<ImageCropFocus>
  duotone?: InputMaybe<DuotoneGradient>
  fit?: InputMaybe<ImageFit>
  grayscale?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  jpegProgressive?: InputMaybe<Scalars['Boolean']['input']>
  jpegQuality?: InputMaybe<Scalars['Int']['input']>
  pngCompressionLevel?: InputMaybe<Scalars['Int']['input']>
  pngCompressionSpeed?: InputMaybe<Scalars['Int']['input']>
  pngQuality?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  rotate?: InputMaybe<Scalars['Int']['input']>
  toFormat?: InputMaybe<ImageFormat>
  traceSVG?: InputMaybe<Potrace>
  trim?: InputMaybe<Scalars['Float']['input']>
  webpQuality?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSharpConnection = {
  __typename?: 'ImageSharpConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<ImageSharpEdge>
  group: Array<ImageSharpGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<ImageSharp>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type ImageSharpConnectionDistinctArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpConnectionGroupArgs = {
  field: ImageSharpFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSharpConnectionMaxArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpConnectionMinArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpConnectionSumArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpEdge = {
  __typename?: 'ImageSharpEdge'
  next?: Maybe<ImageSharp>
  node: ImageSharp
  previous?: Maybe<ImageSharp>
}

export type ImageSharpFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  fixed?: InputMaybe<ImageSharpFixedFieldSelector>
  fluid?: InputMaybe<ImageSharpFluidFieldSelector>
  gatsbyImageData?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  original?: InputMaybe<ImageSharpOriginalFieldSelector>
  parent?: InputMaybe<NodeFieldSelector>
  resize?: InputMaybe<ImageSharpResizeFieldSelector>
}

export type ImageSharpFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  fixed?: InputMaybe<ImageSharpFixedFilterInput>
  fluid?: InputMaybe<ImageSharpFluidFilterInput>
  gatsbyImageData?: InputMaybe<GatsbyImageDataQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  original?: InputMaybe<ImageSharpOriginalFilterInput>
  parent?: InputMaybe<NodeFilterInput>
  resize?: InputMaybe<ImageSharpResizeFilterInput>
}

export type ImageSharpFilterListInput = {
  elemMatch?: InputMaybe<ImageSharpFilterInput>
}

export type ImageSharpFixed = {
  __typename?: 'ImageSharpFixed'
  aspectRatio?: Maybe<Scalars['Float']['output']>
  base64?: Maybe<Scalars['String']['output']>
  height: Scalars['Float']['output']
  originalName?: Maybe<Scalars['String']['output']>
  src: Scalars['String']['output']
  srcSet: Scalars['String']['output']
  srcSetWebp?: Maybe<Scalars['String']['output']>
  srcWebp?: Maybe<Scalars['String']['output']>
  tracedSVG?: Maybe<Scalars['String']['output']>
  width: Scalars['Float']['output']
}

export type ImageSharpFixedFieldSelector = {
  aspectRatio?: InputMaybe<FieldSelectorEnum>
  base64?: InputMaybe<FieldSelectorEnum>
  height?: InputMaybe<FieldSelectorEnum>
  originalName?: InputMaybe<FieldSelectorEnum>
  src?: InputMaybe<FieldSelectorEnum>
  srcSet?: InputMaybe<FieldSelectorEnum>
  srcSetWebp?: InputMaybe<FieldSelectorEnum>
  srcWebp?: InputMaybe<FieldSelectorEnum>
  tracedSVG?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type ImageSharpFixedFilterInput = {
  aspectRatio?: InputMaybe<FloatQueryOperatorInput>
  base64?: InputMaybe<StringQueryOperatorInput>
  height?: InputMaybe<FloatQueryOperatorInput>
  originalName?: InputMaybe<StringQueryOperatorInput>
  src?: InputMaybe<StringQueryOperatorInput>
  srcSet?: InputMaybe<StringQueryOperatorInput>
  srcSetWebp?: InputMaybe<StringQueryOperatorInput>
  srcWebp?: InputMaybe<StringQueryOperatorInput>
  tracedSVG?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<FloatQueryOperatorInput>
}

export type ImageSharpFixedSortInput = {
  aspectRatio?: InputMaybe<SortOrderEnum>
  base64?: InputMaybe<SortOrderEnum>
  height?: InputMaybe<SortOrderEnum>
  originalName?: InputMaybe<SortOrderEnum>
  src?: InputMaybe<SortOrderEnum>
  srcSet?: InputMaybe<SortOrderEnum>
  srcSetWebp?: InputMaybe<SortOrderEnum>
  srcWebp?: InputMaybe<SortOrderEnum>
  tracedSVG?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

export type ImageSharpFluid = {
  __typename?: 'ImageSharpFluid'
  aspectRatio: Scalars['Float']['output']
  base64?: Maybe<Scalars['String']['output']>
  originalImg?: Maybe<Scalars['String']['output']>
  originalName?: Maybe<Scalars['String']['output']>
  presentationHeight: Scalars['Int']['output']
  presentationWidth: Scalars['Int']['output']
  sizes: Scalars['String']['output']
  src: Scalars['String']['output']
  srcSet: Scalars['String']['output']
  srcSetWebp?: Maybe<Scalars['String']['output']>
  srcWebp?: Maybe<Scalars['String']['output']>
  tracedSVG?: Maybe<Scalars['String']['output']>
}

export type ImageSharpFluidFieldSelector = {
  aspectRatio?: InputMaybe<FieldSelectorEnum>
  base64?: InputMaybe<FieldSelectorEnum>
  originalImg?: InputMaybe<FieldSelectorEnum>
  originalName?: InputMaybe<FieldSelectorEnum>
  presentationHeight?: InputMaybe<FieldSelectorEnum>
  presentationWidth?: InputMaybe<FieldSelectorEnum>
  sizes?: InputMaybe<FieldSelectorEnum>
  src?: InputMaybe<FieldSelectorEnum>
  srcSet?: InputMaybe<FieldSelectorEnum>
  srcSetWebp?: InputMaybe<FieldSelectorEnum>
  srcWebp?: InputMaybe<FieldSelectorEnum>
  tracedSVG?: InputMaybe<FieldSelectorEnum>
}

export type ImageSharpFluidFilterInput = {
  aspectRatio?: InputMaybe<FloatQueryOperatorInput>
  base64?: InputMaybe<StringQueryOperatorInput>
  originalImg?: InputMaybe<StringQueryOperatorInput>
  originalName?: InputMaybe<StringQueryOperatorInput>
  presentationHeight?: InputMaybe<IntQueryOperatorInput>
  presentationWidth?: InputMaybe<IntQueryOperatorInput>
  sizes?: InputMaybe<StringQueryOperatorInput>
  src?: InputMaybe<StringQueryOperatorInput>
  srcSet?: InputMaybe<StringQueryOperatorInput>
  srcSetWebp?: InputMaybe<StringQueryOperatorInput>
  srcWebp?: InputMaybe<StringQueryOperatorInput>
  tracedSVG?: InputMaybe<StringQueryOperatorInput>
}

export type ImageSharpFluidSortInput = {
  aspectRatio?: InputMaybe<SortOrderEnum>
  base64?: InputMaybe<SortOrderEnum>
  originalImg?: InputMaybe<SortOrderEnum>
  originalName?: InputMaybe<SortOrderEnum>
  presentationHeight?: InputMaybe<SortOrderEnum>
  presentationWidth?: InputMaybe<SortOrderEnum>
  sizes?: InputMaybe<SortOrderEnum>
  src?: InputMaybe<SortOrderEnum>
  srcSet?: InputMaybe<SortOrderEnum>
  srcSetWebp?: InputMaybe<SortOrderEnum>
  srcWebp?: InputMaybe<SortOrderEnum>
  tracedSVG?: InputMaybe<SortOrderEnum>
}

export type ImageSharpGroupConnection = {
  __typename?: 'ImageSharpGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<ImageSharpEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<ImageSharpGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<ImageSharp>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type ImageSharpGroupConnectionDistinctArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpGroupConnectionGroupArgs = {
  field: ImageSharpFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSharpGroupConnectionMaxArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpGroupConnectionMinArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpGroupConnectionSumArgs = {
  field: ImageSharpFieldSelector
}

export type ImageSharpOriginal = {
  __typename?: 'ImageSharpOriginal'
  height?: Maybe<Scalars['Float']['output']>
  src?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

export type ImageSharpOriginalFieldSelector = {
  height?: InputMaybe<FieldSelectorEnum>
  src?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type ImageSharpOriginalFilterInput = {
  height?: InputMaybe<FloatQueryOperatorInput>
  src?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<FloatQueryOperatorInput>
}

export type ImageSharpOriginalSortInput = {
  height?: InputMaybe<SortOrderEnum>
  src?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

export type ImageSharpResize = {
  __typename?: 'ImageSharpResize'
  aspectRatio?: Maybe<Scalars['Float']['output']>
  height?: Maybe<Scalars['Int']['output']>
  originalName?: Maybe<Scalars['String']['output']>
  src?: Maybe<Scalars['String']['output']>
  tracedSVG?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

export type ImageSharpResizeFieldSelector = {
  aspectRatio?: InputMaybe<FieldSelectorEnum>
  height?: InputMaybe<FieldSelectorEnum>
  originalName?: InputMaybe<FieldSelectorEnum>
  src?: InputMaybe<FieldSelectorEnum>
  tracedSVG?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type ImageSharpResizeFilterInput = {
  aspectRatio?: InputMaybe<FloatQueryOperatorInput>
  height?: InputMaybe<IntQueryOperatorInput>
  originalName?: InputMaybe<StringQueryOperatorInput>
  src?: InputMaybe<StringQueryOperatorInput>
  tracedSVG?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<IntQueryOperatorInput>
}

export type ImageSharpResizeSortInput = {
  aspectRatio?: InputMaybe<SortOrderEnum>
  height?: InputMaybe<SortOrderEnum>
  originalName?: InputMaybe<SortOrderEnum>
  src?: InputMaybe<SortOrderEnum>
  tracedSVG?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

export type ImageSharpSortInput = {
  children?: InputMaybe<NodeSortInput>
  fixed?: InputMaybe<ImageSharpFixedSortInput>
  fluid?: InputMaybe<ImageSharpFluidSortInput>
  gatsbyImageData?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  original?: InputMaybe<ImageSharpOriginalSortInput>
  parent?: InputMaybe<NodeSortInput>
  resize?: InputMaybe<ImageSharpResizeSortInput>
}

export type IntQueryOperatorInput = {
  eq?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  ne?: InputMaybe<Scalars['Int']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type Internal = {
  __typename?: 'Internal'
  content?: Maybe<Scalars['String']['output']>
  contentDigest: Scalars['String']['output']
  contentFilePath?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  fieldOwners?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  ignoreType?: Maybe<Scalars['Boolean']['output']>
  mediaType?: Maybe<Scalars['String']['output']>
  owner: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type InternalFieldSelector = {
  content?: InputMaybe<FieldSelectorEnum>
  contentDigest?: InputMaybe<FieldSelectorEnum>
  contentFilePath?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  fieldOwners?: InputMaybe<FieldSelectorEnum>
  ignoreType?: InputMaybe<FieldSelectorEnum>
  mediaType?: InputMaybe<FieldSelectorEnum>
  owner?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
}

export type InternalFilterInput = {
  content?: InputMaybe<StringQueryOperatorInput>
  contentDigest?: InputMaybe<StringQueryOperatorInput>
  contentFilePath?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  fieldOwners?: InputMaybe<StringQueryOperatorInput>
  ignoreType?: InputMaybe<BooleanQueryOperatorInput>
  mediaType?: InputMaybe<StringQueryOperatorInput>
  owner?: InputMaybe<StringQueryOperatorInput>
  type?: InputMaybe<StringQueryOperatorInput>
}

export type InternalSortInput = {
  content?: InputMaybe<SortOrderEnum>
  contentDigest?: InputMaybe<SortOrderEnum>
  contentFilePath?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  fieldOwners?: InputMaybe<SortOrderEnum>
  ignoreType?: InputMaybe<SortOrderEnum>
  mediaType?: InputMaybe<SortOrderEnum>
  owner?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
}

export type JpgOptions = {
  progressive?: InputMaybe<Scalars['Boolean']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
}

export type JsonQueryOperatorInput = {
  eq?: InputMaybe<Scalars['JSON']['input']>
  glob?: InputMaybe<Scalars['JSON']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  ne?: InputMaybe<Scalars['JSON']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  regex?: InputMaybe<Scalars['JSON']['input']>
}

/** Node Interface */
export type Node = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  parent?: Maybe<Node>
}

export type NodeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  parent?: InputMaybe<NodeFieldSelector>
}

export type NodeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type NodeFilterListInput = {
  elemMatch?: InputMaybe<NodeFilterInput>
}

export type NodeSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  parent?: InputMaybe<NodeSortInput>
}

export type PngOptions = {
  compressionSpeed?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  currentPage: Scalars['Int']['output']
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  itemCount: Scalars['Int']['output']
  pageCount: Scalars['Int']['output']
  perPage?: Maybe<Scalars['Int']['output']>
  totalCount: Scalars['Int']['output']
}

export type Potrace = {
  alphaMax?: InputMaybe<Scalars['Float']['input']>
  background?: InputMaybe<Scalars['String']['input']>
  blackOnWhite?: InputMaybe<Scalars['Boolean']['input']>
  color?: InputMaybe<Scalars['String']['input']>
  optCurve?: InputMaybe<Scalars['Boolean']['input']>
  optTolerance?: InputMaybe<Scalars['Float']['input']>
  threshold?: InputMaybe<Scalars['Int']['input']>
  turdSize?: InputMaybe<Scalars['Float']['input']>
  turnPolicy?: InputMaybe<PotraceTurnPolicy>
}

export enum PotraceTurnPolicy {
  TurnpolicyBlack = 'TURNPOLICY_BLACK',
  TurnpolicyLeft = 'TURNPOLICY_LEFT',
  TurnpolicyMajority = 'TURNPOLICY_MAJORITY',
  TurnpolicyMinority = 'TURNPOLICY_MINORITY',
  TurnpolicyRight = 'TURNPOLICY_RIGHT',
  TurnpolicyWhite = 'TURNPOLICY_WHITE',
}

export type Query = {
  __typename?: 'Query'
  allDirectory: DirectoryConnection
  allFile: FileConnection
  allImageSharp: ImageSharpConnection
  allSite: SiteConnection
  allSiteBuildMetadata: SiteBuildMetadataConnection
  allSiteFunction: SiteFunctionConnection
  allSitePage: SitePageConnection
  allSitePlugin: SitePluginConnection
  allWp: Wp_Connection
  allWpCategory: Wp_CategoryConnection
  allWpComment: Wp_CommentConnection
  allWpCommentAuthor: Wp_CommentAuthorConnection
  allWpCompany: Wp_CompanyConnection
  allWpContentNode: Wp_ContentNodeConnection
  allWpContentType: Wp_ContentTypeConnection
  allWpCorrectiveActionStatus: Wp_CorrectiveActionStatusConnection
  allWpDeficiencyType: Wp_DeficiencyTypeConnection
  allWpDivision: Wp_DivisionConnection
  allWpDocument: Wp_DocumentConnection
  allWpDocumentTag: Wp_DocumentTagConnection
  allWpEquipmentStatus: Wp_EquipmentStatusConnection
  allWpEquipmentTag: Wp_EquipmentTagConnection
  allWpFieldId: Wp_FieldIdConnection
  allWpFormId: Wp_FormIdConnection
  allWpGfForm: Wp_GfFormConnection
  allWpIncidentReportClassification: Wp_IncidentReportClassificationConnection
  allWpIncidentReportStatus: Wp_IncidentReportStatusConnection
  allWpIncidentType: Wp_IncidentTypeConnection
  allWpInjuryArea: Wp_InjuryAreaConnection
  allWpInspectionInterval: Wp_InspectionIntervalConnection
  allWpLesson: Wp_LessonConnection
  allWpLessonCategory: Wp_LessonCategoryConnection
  allWpLessonDifficulty: Wp_LessonDifficultyConnection
  allWpManufacturer: Wp_ManufacturerConnection
  allWpMediaItem: Wp_MediaItemConnection
  allWpMenu: Wp_MenuConnection
  allWpMenuItem: Wp_MenuItemConnection
  allWpNodeWithAuthor: Wp_NodeWithAuthorConnection
  allWpNodeWithComments: Wp_NodeWithCommentsConnection
  allWpNodeWithContentEditor: Wp_NodeWithContentEditorConnection
  allWpNodeWithExcerpt: Wp_NodeWithExcerptConnection
  allWpNodeWithFeaturedImage: Wp_NodeWithFeaturedImageConnection
  allWpNodeWithPageAttributes: Wp_NodeWithPageAttributesConnection
  allWpNodeWithRevisions: Wp_NodeWithRevisionsConnection
  allWpNodeWithTemplate: Wp_NodeWithTemplateConnection
  allWpNodeWithTitle: Wp_NodeWithTitleConnection
  allWpNodeWithTrackbacks: Wp_NodeWithTrackbacksConnection
  allWpPostFormat: Wp_PostFormatConnection
  allWpProjectStatus: Wp_ProjectStatusConnection
  allWpProjectType: Wp_ProjectTypeConnection
  allWpProvider: Wp_ProviderConnection
  allWpRootCause: Wp_RootCauseConnection
  allWpSeverity: Wp_SeverityConnection
  allWpTag: Wp_TagConnection
  allWpTaxonomy: Wp_TaxonomyConnection
  allWpTermNode: Wp_TermNodeConnection
  allWpToolboxTalkCategory: Wp_ToolboxTalkCategoryConnection
  allWpTrainingType: Wp_TrainingTypeConnection
  allWpUniformResourceIdentifiable: Wp_UniformResourceIdentifiableConnection
  allWpUser: Wp_UserConnection
  allWpUserCompany: Wp_UserCompanyConnection
  allWpUserDivision: Wp_UserDivisionConnection
  allWpUserLanguage: Wp_UserLanguageConnection
  allWpUserRole: Wp_UserRoleConnection
  allWpUserStatus: Wp_UserStatusConnection
  allWpUserVaccineStatus: Wp_UserVaccineStatusConnection
  directory?: Maybe<Directory>
  file?: Maybe<File>
  imageSharp?: Maybe<ImageSharp>
  site?: Maybe<Site>
  siteBuildMetadata?: Maybe<SiteBuildMetadata>
  siteFunction?: Maybe<SiteFunction>
  sitePage?: Maybe<SitePage>
  sitePlugin?: Maybe<SitePlugin>
  wp?: Maybe<Wp_>
  wpCategory?: Maybe<Wp_Category>
  wpComment?: Maybe<Wp_Comment>
  wpCommentAuthor?: Maybe<Wp_CommentAuthor>
  wpCompany?: Maybe<Wp_Company>
  wpContentNode?: Maybe<Wp_ContentNode>
  wpContentType?: Maybe<Wp_ContentType>
  wpCorrectiveActionStatus?: Maybe<Wp_CorrectiveActionStatus>
  wpDeficiencyType?: Maybe<Wp_DeficiencyType>
  wpDivision?: Maybe<Wp_Division>
  wpDocument?: Maybe<Wp_Document>
  wpDocumentTag?: Maybe<Wp_DocumentTag>
  wpEquipmentStatus?: Maybe<Wp_EquipmentStatus>
  wpEquipmentTag?: Maybe<Wp_EquipmentTag>
  wpFieldId?: Maybe<Wp_FieldId>
  wpFormId?: Maybe<Wp_FormId>
  wpGfForm?: Maybe<Wp_GfForm>
  wpIncidentReportClassification?: Maybe<Wp_IncidentReportClassification>
  wpIncidentReportStatus?: Maybe<Wp_IncidentReportStatus>
  wpIncidentType?: Maybe<Wp_IncidentType>
  wpInjuryArea?: Maybe<Wp_InjuryArea>
  wpInspectionInterval?: Maybe<Wp_InspectionInterval>
  wpLesson?: Maybe<Wp_Lesson>
  wpLessonCategory?: Maybe<Wp_LessonCategory>
  wpLessonDifficulty?: Maybe<Wp_LessonDifficulty>
  wpManufacturer?: Maybe<Wp_Manufacturer>
  wpMediaItem?: Maybe<Wp_MediaItem>
  wpMenu?: Maybe<Wp_Menu>
  wpMenuItem?: Maybe<Wp_MenuItem>
  wpNodeWithAuthor?: Maybe<Wp_NodeWithAuthor>
  wpNodeWithComments?: Maybe<Wp_NodeWithComments>
  wpNodeWithContentEditor?: Maybe<Wp_NodeWithContentEditor>
  wpNodeWithExcerpt?: Maybe<Wp_NodeWithExcerpt>
  wpNodeWithFeaturedImage?: Maybe<Wp_NodeWithFeaturedImage>
  wpNodeWithPageAttributes?: Maybe<Wp_NodeWithPageAttributes>
  wpNodeWithRevisions?: Maybe<Wp_NodeWithRevisions>
  wpNodeWithTemplate?: Maybe<Wp_NodeWithTemplate>
  wpNodeWithTitle?: Maybe<Wp_NodeWithTitle>
  wpNodeWithTrackbacks?: Maybe<Wp_NodeWithTrackbacks>
  wpPostFormat?: Maybe<Wp_PostFormat>
  wpProjectStatus?: Maybe<Wp_ProjectStatus>
  wpProjectType?: Maybe<Wp_ProjectType>
  wpProvider?: Maybe<Wp_Provider>
  wpRootCause?: Maybe<Wp_RootCause>
  wpSeverity?: Maybe<Wp_Severity>
  wpTag?: Maybe<Wp_Tag>
  wpTaxonomy?: Maybe<Wp_Taxonomy>
  wpTermNode?: Maybe<Wp_TermNode>
  wpToolboxTalkCategory?: Maybe<Wp_ToolboxTalkCategory>
  wpTrainingType?: Maybe<Wp_TrainingType>
  wpUniformResourceIdentifiable?: Maybe<Wp_UniformResourceIdentifiable>
  wpUser?: Maybe<Wp_User>
  wpUserCompany?: Maybe<Wp_UserCompany>
  wpUserDivision?: Maybe<Wp_UserDivision>
  wpUserLanguage?: Maybe<Wp_UserLanguage>
  wpUserRole?: Maybe<Wp_UserRole>
  wpUserStatus?: Maybe<Wp_UserStatus>
  wpUserVaccineStatus?: Maybe<Wp_UserVaccineStatus>
}

export type QueryAllDirectoryArgs = {
  filter?: InputMaybe<DirectoryFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<DirectorySortInput>>>
}

export type QueryAllFileArgs = {
  filter?: InputMaybe<FileFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<FileSortInput>>>
}

export type QueryAllImageSharpArgs = {
  filter?: InputMaybe<ImageSharpFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<ImageSharpSortInput>>>
}

export type QueryAllSiteArgs = {
  filter?: InputMaybe<SiteFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<SiteSortInput>>>
}

export type QueryAllSiteBuildMetadataArgs = {
  filter?: InputMaybe<SiteBuildMetadataFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<SiteBuildMetadataSortInput>>>
}

export type QueryAllSiteFunctionArgs = {
  filter?: InputMaybe<SiteFunctionFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<SiteFunctionSortInput>>>
}

export type QueryAllSitePageArgs = {
  filter?: InputMaybe<SitePageFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<SitePageSortInput>>>
}

export type QueryAllSitePluginArgs = {
  filter?: InputMaybe<SitePluginFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<SitePluginSortInput>>>
}

export type QueryAllWpArgs = {
  filter?: InputMaybe<Wp_FilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_SortInput>>>
}

export type QueryAllWpCategoryArgs = {
  filter?: InputMaybe<Wp_CategoryFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_CategorySortInput>>>
}

export type QueryAllWpCommentArgs = {
  filter?: InputMaybe<Wp_CommentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_CommentSortInput>>>
}

export type QueryAllWpCommentAuthorArgs = {
  filter?: InputMaybe<Wp_CommentAuthorFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_CommentAuthorSortInput>>>
}

export type QueryAllWpCompanyArgs = {
  filter?: InputMaybe<Wp_CompanyFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_CompanySortInput>>>
}

export type QueryAllWpContentNodeArgs = {
  filter?: InputMaybe<Wp_ContentNodeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ContentNodeSortInput>>>
}

export type QueryAllWpContentTypeArgs = {
  filter?: InputMaybe<Wp_ContentTypeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ContentTypeSortInput>>>
}

export type QueryAllWpCorrectiveActionStatusArgs = {
  filter?: InputMaybe<Wp_CorrectiveActionStatusFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_CorrectiveActionStatusSortInput>>>
}

export type QueryAllWpDeficiencyTypeArgs = {
  filter?: InputMaybe<Wp_DeficiencyTypeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_DeficiencyTypeSortInput>>>
}

export type QueryAllWpDivisionArgs = {
  filter?: InputMaybe<Wp_DivisionFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_DivisionSortInput>>>
}

export type QueryAllWpDocumentArgs = {
  filter?: InputMaybe<Wp_DocumentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_DocumentSortInput>>>
}

export type QueryAllWpDocumentTagArgs = {
  filter?: InputMaybe<Wp_DocumentTagFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_DocumentTagSortInput>>>
}

export type QueryAllWpEquipmentStatusArgs = {
  filter?: InputMaybe<Wp_EquipmentStatusFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_EquipmentStatusSortInput>>>
}

export type QueryAllWpEquipmentTagArgs = {
  filter?: InputMaybe<Wp_EquipmentTagFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_EquipmentTagSortInput>>>
}

export type QueryAllWpFieldIdArgs = {
  filter?: InputMaybe<Wp_FieldIdFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_FieldIdSortInput>>>
}

export type QueryAllWpFormIdArgs = {
  filter?: InputMaybe<Wp_FormIdFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_FormIdSortInput>>>
}

export type QueryAllWpGfFormArgs = {
  filter?: InputMaybe<Wp_GfFormFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_GfFormSortInput>>>
}

export type QueryAllWpIncidentReportClassificationArgs = {
  filter?: InputMaybe<Wp_IncidentReportClassificationFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_IncidentReportClassificationSortInput>>>
}

export type QueryAllWpIncidentReportStatusArgs = {
  filter?: InputMaybe<Wp_IncidentReportStatusFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_IncidentReportStatusSortInput>>>
}

export type QueryAllWpIncidentTypeArgs = {
  filter?: InputMaybe<Wp_IncidentTypeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_IncidentTypeSortInput>>>
}

export type QueryAllWpInjuryAreaArgs = {
  filter?: InputMaybe<Wp_InjuryAreaFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_InjuryAreaSortInput>>>
}

export type QueryAllWpInspectionIntervalArgs = {
  filter?: InputMaybe<Wp_InspectionIntervalFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_InspectionIntervalSortInput>>>
}

export type QueryAllWpLessonArgs = {
  filter?: InputMaybe<Wp_LessonFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_LessonSortInput>>>
}

export type QueryAllWpLessonCategoryArgs = {
  filter?: InputMaybe<Wp_LessonCategoryFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_LessonCategorySortInput>>>
}

export type QueryAllWpLessonDifficultyArgs = {
  filter?: InputMaybe<Wp_LessonDifficultyFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_LessonDifficultySortInput>>>
}

export type QueryAllWpManufacturerArgs = {
  filter?: InputMaybe<Wp_ManufacturerFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ManufacturerSortInput>>>
}

export type QueryAllWpMediaItemArgs = {
  filter?: InputMaybe<Wp_MediaItemFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_MediaItemSortInput>>>
}

export type QueryAllWpMenuArgs = {
  filter?: InputMaybe<Wp_MenuFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_MenuSortInput>>>
}

export type QueryAllWpMenuItemArgs = {
  filter?: InputMaybe<Wp_MenuItemFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_MenuItemSortInput>>>
}

export type QueryAllWpNodeWithAuthorArgs = {
  filter?: InputMaybe<Wp_NodeWithAuthorFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithAuthorSortInput>>>
}

export type QueryAllWpNodeWithCommentsArgs = {
  filter?: InputMaybe<Wp_NodeWithCommentsFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithCommentsSortInput>>>
}

export type QueryAllWpNodeWithContentEditorArgs = {
  filter?: InputMaybe<Wp_NodeWithContentEditorFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithContentEditorSortInput>>>
}

export type QueryAllWpNodeWithExcerptArgs = {
  filter?: InputMaybe<Wp_NodeWithExcerptFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithExcerptSortInput>>>
}

export type QueryAllWpNodeWithFeaturedImageArgs = {
  filter?: InputMaybe<Wp_NodeWithFeaturedImageFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithFeaturedImageSortInput>>>
}

export type QueryAllWpNodeWithPageAttributesArgs = {
  filter?: InputMaybe<Wp_NodeWithPageAttributesFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithPageAttributesSortInput>>>
}

export type QueryAllWpNodeWithRevisionsArgs = {
  filter?: InputMaybe<Wp_NodeWithRevisionsFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithRevisionsSortInput>>>
}

export type QueryAllWpNodeWithTemplateArgs = {
  filter?: InputMaybe<Wp_NodeWithTemplateFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithTemplateSortInput>>>
}

export type QueryAllWpNodeWithTitleArgs = {
  filter?: InputMaybe<Wp_NodeWithTitleFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithTitleSortInput>>>
}

export type QueryAllWpNodeWithTrackbacksArgs = {
  filter?: InputMaybe<Wp_NodeWithTrackbacksFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_NodeWithTrackbacksSortInput>>>
}

export type QueryAllWpPostFormatArgs = {
  filter?: InputMaybe<Wp_PostFormatFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_PostFormatSortInput>>>
}

export type QueryAllWpProjectStatusArgs = {
  filter?: InputMaybe<Wp_ProjectStatusFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ProjectStatusSortInput>>>
}

export type QueryAllWpProjectTypeArgs = {
  filter?: InputMaybe<Wp_ProjectTypeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ProjectTypeSortInput>>>
}

export type QueryAllWpProviderArgs = {
  filter?: InputMaybe<Wp_ProviderFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ProviderSortInput>>>
}

export type QueryAllWpRootCauseArgs = {
  filter?: InputMaybe<Wp_RootCauseFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_RootCauseSortInput>>>
}

export type QueryAllWpSeverityArgs = {
  filter?: InputMaybe<Wp_SeverityFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_SeveritySortInput>>>
}

export type QueryAllWpTagArgs = {
  filter?: InputMaybe<Wp_TagFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_TagSortInput>>>
}

export type QueryAllWpTaxonomyArgs = {
  filter?: InputMaybe<Wp_TaxonomyFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_TaxonomySortInput>>>
}

export type QueryAllWpTermNodeArgs = {
  filter?: InputMaybe<Wp_TermNodeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_TermNodeSortInput>>>
}

export type QueryAllWpToolboxTalkCategoryArgs = {
  filter?: InputMaybe<Wp_ToolboxTalkCategoryFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_ToolboxTalkCategorySortInput>>>
}

export type QueryAllWpTrainingTypeArgs = {
  filter?: InputMaybe<Wp_TrainingTypeFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_TrainingTypeSortInput>>>
}

export type QueryAllWpUniformResourceIdentifiableArgs = {
  filter?: InputMaybe<Wp_UniformResourceIdentifiableFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UniformResourceIdentifiableSortInput>>>
}

export type QueryAllWpUserArgs = {
  filter?: InputMaybe<Wp_UserFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserSortInput>>>
}

export type QueryAllWpUserCompanyArgs = {
  filter?: InputMaybe<Wp_UserCompanyFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserCompanySortInput>>>
}

export type QueryAllWpUserDivisionArgs = {
  filter?: InputMaybe<Wp_UserDivisionFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserDivisionSortInput>>>
}

export type QueryAllWpUserLanguageArgs = {
  filter?: InputMaybe<Wp_UserLanguageFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserLanguageSortInput>>>
}

export type QueryAllWpUserRoleArgs = {
  filter?: InputMaybe<Wp_UserRoleFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserRoleSortInput>>>
}

export type QueryAllWpUserStatusArgs = {
  filter?: InputMaybe<Wp_UserStatusFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserStatusSortInput>>>
}

export type QueryAllWpUserVaccineStatusArgs = {
  filter?: InputMaybe<Wp_UserVaccineStatusFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<Wp_UserVaccineStatusSortInput>>>
}

export type QueryDirectoryArgs = {
  absolutePath?: InputMaybe<StringQueryOperatorInput>
  accessTime?: InputMaybe<DateQueryOperatorInput>
  atime?: InputMaybe<DateQueryOperatorInput>
  atimeMs?: InputMaybe<FloatQueryOperatorInput>
  base?: InputMaybe<StringQueryOperatorInput>
  birthTime?: InputMaybe<DateQueryOperatorInput>
  birthtime?: InputMaybe<DateQueryOperatorInput>
  birthtimeMs?: InputMaybe<FloatQueryOperatorInput>
  changeTime?: InputMaybe<DateQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  ctime?: InputMaybe<DateQueryOperatorInput>
  ctimeMs?: InputMaybe<FloatQueryOperatorInput>
  dev?: InputMaybe<IntQueryOperatorInput>
  dir?: InputMaybe<StringQueryOperatorInput>
  ext?: InputMaybe<StringQueryOperatorInput>
  extension?: InputMaybe<StringQueryOperatorInput>
  gid?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  ino?: InputMaybe<FloatQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  mode?: InputMaybe<IntQueryOperatorInput>
  modifiedTime?: InputMaybe<DateQueryOperatorInput>
  mtime?: InputMaybe<DateQueryOperatorInput>
  mtimeMs?: InputMaybe<FloatQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nlink?: InputMaybe<IntQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  prettySize?: InputMaybe<StringQueryOperatorInput>
  rdev?: InputMaybe<IntQueryOperatorInput>
  relativeDirectory?: InputMaybe<StringQueryOperatorInput>
  relativePath?: InputMaybe<StringQueryOperatorInput>
  root?: InputMaybe<StringQueryOperatorInput>
  size?: InputMaybe<IntQueryOperatorInput>
  sourceInstanceName?: InputMaybe<StringQueryOperatorInput>
  uid?: InputMaybe<IntQueryOperatorInput>
}

export type QueryFileArgs = {
  absolutePath?: InputMaybe<StringQueryOperatorInput>
  accessTime?: InputMaybe<DateQueryOperatorInput>
  atime?: InputMaybe<DateQueryOperatorInput>
  atimeMs?: InputMaybe<FloatQueryOperatorInput>
  base?: InputMaybe<StringQueryOperatorInput>
  birthTime?: InputMaybe<DateQueryOperatorInput>
  birthtime?: InputMaybe<DateQueryOperatorInput>
  birthtimeMs?: InputMaybe<FloatQueryOperatorInput>
  blksize?: InputMaybe<IntQueryOperatorInput>
  blocks?: InputMaybe<IntQueryOperatorInput>
  changeTime?: InputMaybe<DateQueryOperatorInput>
  childImageSharp?: InputMaybe<ImageSharpFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  childrenImageSharp?: InputMaybe<ImageSharpFilterListInput>
  ctime?: InputMaybe<DateQueryOperatorInput>
  ctimeMs?: InputMaybe<FloatQueryOperatorInput>
  dev?: InputMaybe<IntQueryOperatorInput>
  dir?: InputMaybe<StringQueryOperatorInput>
  ext?: InputMaybe<StringQueryOperatorInput>
  extension?: InputMaybe<StringQueryOperatorInput>
  gid?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  ino?: InputMaybe<FloatQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  mode?: InputMaybe<IntQueryOperatorInput>
  modifiedTime?: InputMaybe<DateQueryOperatorInput>
  mtime?: InputMaybe<DateQueryOperatorInput>
  mtimeMs?: InputMaybe<FloatQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nlink?: InputMaybe<IntQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  prettySize?: InputMaybe<StringQueryOperatorInput>
  publicURL?: InputMaybe<StringQueryOperatorInput>
  rdev?: InputMaybe<IntQueryOperatorInput>
  relativeDirectory?: InputMaybe<StringQueryOperatorInput>
  relativePath?: InputMaybe<StringQueryOperatorInput>
  root?: InputMaybe<StringQueryOperatorInput>
  size?: InputMaybe<IntQueryOperatorInput>
  sourceInstanceName?: InputMaybe<StringQueryOperatorInput>
  uid?: InputMaybe<IntQueryOperatorInput>
}

export type QueryImageSharpArgs = {
  children?: InputMaybe<NodeFilterListInput>
  fixed?: InputMaybe<ImageSharpFixedFilterInput>
  fluid?: InputMaybe<ImageSharpFluidFilterInput>
  gatsbyImageData?: InputMaybe<GatsbyImageDataQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  original?: InputMaybe<ImageSharpOriginalFilterInput>
  parent?: InputMaybe<NodeFilterInput>
  resize?: InputMaybe<ImageSharpResizeFilterInput>
}

export type QuerySiteArgs = {
  buildTime?: InputMaybe<DateQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  flags?: InputMaybe<SiteFlagsFilterInput>
  graphqlTypegen?: InputMaybe<BooleanQueryOperatorInput>
  host?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  jsxRuntime?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pathPrefix?: InputMaybe<StringQueryOperatorInput>
  polyfill?: InputMaybe<BooleanQueryOperatorInput>
  port?: InputMaybe<IntQueryOperatorInput>
  siteMetadata?: InputMaybe<SiteSiteMetadataFilterInput>
  trailingSlash?: InputMaybe<StringQueryOperatorInput>
}

export type QuerySiteBuildMetadataArgs = {
  buildTime?: InputMaybe<DateQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QuerySiteFunctionArgs = {
  absoluteCompiledFilePath?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  functionRoute?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  matchPath?: InputMaybe<StringQueryOperatorInput>
  originalAbsoluteFilePath?: InputMaybe<StringQueryOperatorInput>
  originalRelativeFilePath?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pluginName?: InputMaybe<StringQueryOperatorInput>
  relativeCompiledFilePath?: InputMaybe<StringQueryOperatorInput>
}

export type QuerySitePageArgs = {
  children?: InputMaybe<NodeFilterListInput>
  component?: InputMaybe<StringQueryOperatorInput>
  componentChunkName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  internalComponentName?: InputMaybe<StringQueryOperatorInput>
  matchPath?: InputMaybe<StringQueryOperatorInput>
  pageContext?: InputMaybe<JsonQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  path?: InputMaybe<StringQueryOperatorInput>
  pluginCreator?: InputMaybe<SitePluginFilterInput>
}

export type QuerySitePluginArgs = {
  browserAPIs?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeAPIs?: InputMaybe<StringQueryOperatorInput>
  packageJson?: InputMaybe<JsonQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pluginFilepath?: InputMaybe<StringQueryOperatorInput>
  pluginOptions?: InputMaybe<JsonQueryOperatorInput>
  resolve?: InputMaybe<StringQueryOperatorInput>
  ssrAPIs?: InputMaybe<StringQueryOperatorInput>
  version?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpArgs = {
  allSettings?: InputMaybe<Wp_SettingsFilterInput>
  certificationExpiryWarnings?: InputMaybe<Wp_CertificationExpiryWarningRuleFilterListInput>
  children?: InputMaybe<NodeFilterListInput>
  defaultLanguage?: InputMaybe<Wp_LanguageFilterInput>
  discussionSettings?: InputMaybe<Wp_DiscussionSettingsFilterInput>
  generalSettings?: InputMaybe<Wp_GeneralSettingsFilterInput>
  gfSettings?: InputMaybe<Wp_GfSettingsFilterInput>
  harnessSettings?: InputMaybe<Wp_HarnessSettingsFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  incidentReportData?: InputMaybe<Wp_IncidentReportDataFilterInput>
  integrationConfig?: InputMaybe<Wp_IntegrationConfigFilterInput>
  internal?: InputMaybe<InternalFilterInput>
  languages?: InputMaybe<Wp_LanguageFilterListInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  readingSettings?: InputMaybe<Wp_ReadingSettingsFilterInput>
  schemaMd5?: InputMaybe<StringQueryOperatorInput>
  viewer?: InputMaybe<Wp_UserFilterInput>
  wpGatsby?: InputMaybe<Wp_WpGatsbyFilterInput>
  writingSettings?: InputMaybe<Wp_WritingSettingsFilterInput>
}

export type QueryWpCategoryArgs = {
  ancestors?: InputMaybe<Wp_CategoryToAncestorsCategoryConnectionFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_CategoryToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_CategoryToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_CategoryFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  wpChildren?: InputMaybe<Wp_CategoryToCategoryConnectionFilterInput>
  wpParent?: InputMaybe<Wp_CategoryToParentCategoryConnectionEdgeFilterInput>
}

export type QueryWpCommentArgs = {
  agent?: InputMaybe<StringQueryOperatorInput>
  approved?: InputMaybe<BooleanQueryOperatorInput>
  author?: InputMaybe<Wp_CommentToCommenterConnectionEdgeFilterInput>
  authorIp?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  commentedOn?: InputMaybe<Wp_CommentToContentNodeConnectionEdgeFilterInput>
  content?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  karma?: InputMaybe<IntQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  replies?: InputMaybe<Wp_CommentToCommentConnectionFilterInput>
  type?: InputMaybe<StringQueryOperatorInput>
  wpParent?: InputMaybe<Wp_CommentToParentCommentConnectionEdgeFilterInput>
}

export type QueryWpCommentAuthorArgs = {
  avatar?: InputMaybe<Wp_AvatarFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  email?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpCompanyArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_CompanyToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  documents?: InputMaybe<Wp_CompanyToDocumentConnectionFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_CompanyToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_CompanyToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpContentNodeArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpContentTypeArgs = {
  archivePath?: InputMaybe<StringQueryOperatorInput>
  canExport?: InputMaybe<BooleanQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  connectedTaxonomies?: InputMaybe<Wp_ContentTypeToTaxonomyConnectionFilterInput>
  contentNodes?: InputMaybe<Wp_ContentTypeToContentNodeConnectionFilterInput>
  deleteWithUser?: InputMaybe<BooleanQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  excludeFromSearch?: InputMaybe<BooleanQueryOperatorInput>
  graphqlPluralName?: InputMaybe<StringQueryOperatorInput>
  graphqlSingleName?: InputMaybe<StringQueryOperatorInput>
  hasArchive?: InputMaybe<BooleanQueryOperatorInput>
  hierarchical?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isFrontPage?: InputMaybe<BooleanQueryOperatorInput>
  isPostsPage?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  label?: InputMaybe<StringQueryOperatorInput>
  labels?: InputMaybe<Wp_PostTypeLabelDetailsFilterInput>
  menuIcon?: InputMaybe<StringQueryOperatorInput>
  menuPosition?: InputMaybe<IntQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  public?: InputMaybe<BooleanQueryOperatorInput>
  publiclyQueryable?: InputMaybe<BooleanQueryOperatorInput>
  restBase?: InputMaybe<StringQueryOperatorInput>
  restControllerClass?: InputMaybe<StringQueryOperatorInput>
  showInAdminBar?: InputMaybe<BooleanQueryOperatorInput>
  showInGraphql?: InputMaybe<BooleanQueryOperatorInput>
  showInMenu?: InputMaybe<BooleanQueryOperatorInput>
  showInNavMenus?: InputMaybe<BooleanQueryOperatorInput>
  showInRest?: InputMaybe<BooleanQueryOperatorInput>
  showUi?: InputMaybe<BooleanQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpCorrectiveActionStatusArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_CorrectiveActionStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpDeficiencyTypeArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_DeficiencyTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_DeficiencyTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpDivisionArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_DivisionToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  documents?: InputMaybe<Wp_DivisionToDocumentConnectionFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_DivisionToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_DivisionToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpDocumentArgs = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  companies?: InputMaybe<Wp_DocumentToCompanyConnectionFilterInput>
  content?: InputMaybe<StringQueryOperatorInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  divisions?: InputMaybe<Wp_DocumentToDivisionConnectionFilterInput>
  documentTags?: InputMaybe<Wp_DocumentToDocumentTagConnectionFilterInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput>
  featuredImageDatabaseId?: InputMaybe<IntQueryOperatorInput>
  featuredImageId?: InputMaybe<IdQueryOperatorInput>
  fileAttachments?: InputMaybe<Wp_DocumentToMediaItemConnectionFilterInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_DocumentToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  terms?: InputMaybe<Wp_DocumentToTermNodeConnectionFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpDocumentTagArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_DocumentTagToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  documents?: InputMaybe<Wp_DocumentTagToDocumentConnectionFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_DocumentTagToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpEquipmentStatusArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_EquipmentStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_EquipmentStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpEquipmentTagArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_EquipmentTagToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_EquipmentTagToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpFieldIdArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_FieldIdToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_FieldIdToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpFormIdArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_FormIdToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_FormIdToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpGfFormArgs = {
  cardDisplaySettings?: InputMaybe<Wp_CardDisplaySettingsFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  confirmations?: InputMaybe<Wp_FormConfirmationFilterListInput>
  cssClass?: InputMaybe<StringQueryOperatorInput>
  customRequiredIndicator?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  dateCreated?: InputMaybe<StringQueryOperatorInput>
  dateCreatedGmt?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  descriptionPlacement?: InputMaybe<Wp_FormDescriptionPlacementEnumQueryOperatorInput>
  disableEditing?: InputMaybe<BooleanQueryOperatorInput>
  entries?: InputMaybe<Wp_GfFormToGfEntryConnectionFilterInput>
  entryLimits?: InputMaybe<Wp_FormEntryLimitsFilterInput>
  firstPageCssClass?: InputMaybe<StringQueryOperatorInput>
  formFields?: InputMaybe<Wp_GfFormToFormFieldConnectionFilterInput>
  group?: InputMaybe<StringQueryOperatorInput>
  hasConditionalLogicAnimation?: InputMaybe<BooleanQueryOperatorInput>
  hasHoneypot?: InputMaybe<BooleanQueryOperatorInput>
  hasValidationSummary?: InputMaybe<BooleanQueryOperatorInput>
  hideInNav?: InputMaybe<BooleanQueryOperatorInput>
  hideJumpToSection?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isActive?: InputMaybe<BooleanQueryOperatorInput>
  isCAForm?: InputMaybe<BooleanQueryOperatorInput>
  isPrimary?: InputMaybe<BooleanQueryOperatorInput>
  isQuizForm?: InputMaybe<BooleanQueryOperatorInput>
  isTrash?: InputMaybe<BooleanQueryOperatorInput>
  labelPlacement?: InputMaybe<Wp_FormLabelPlacementEnumQueryOperatorInput>
  login?: InputMaybe<Wp_FormLoginFilterInput>
  markupVersion?: InputMaybe<IntQueryOperatorInput>
  nextFieldId?: InputMaybe<IntQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  notifications?: InputMaybe<Wp_FormNotificationFilterListInput>
  pagination?: InputMaybe<Wp_FormPaginationFilterInput>
  parent?: InputMaybe<NodeFilterInput>
  personalData?: InputMaybe<Wp_FormPersonalDataFilterInput>
  postCreation?: InputMaybe<Wp_FormPostCreationFilterInput>
  postTypeToCreate?: InputMaybe<StringQueryOperatorInput>
  quiz?: InputMaybe<Wp_FormQuizFilterInput>
  reloadOnSubmit?: InputMaybe<BooleanQueryOperatorInput>
  requiredIndicator?: InputMaybe<Wp_FormFieldRequiredIndicatorEnumQueryOperatorInput>
  restrictEntriesToUserCreated?: InputMaybe<StringQueryOperatorInput>
  restrictFormSubmissionByRole?: InputMaybe<StringQueryOperatorInput>
  saveAndContinue?: InputMaybe<Wp_FormSaveAndContinueFilterInput>
  scheduling?: InputMaybe<Wp_FormScheduleFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  subLabelPlacement?: InputMaybe<Wp_FormSubLabelPlacementEnumQueryOperatorInput>
  submissionRules?: InputMaybe<Wp_SubmissionRuleFilterListInput>
  submitButton?: InputMaybe<Wp_FormSubmitButtonFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  translations?: InputMaybe<Wp_FormTranslationFilterListInput>
  version?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpIncidentReportClassificationArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_IncidentReportClassificationToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_IncidentReportClassificationToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpIncidentReportStatusArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_IncidentReportStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_IncidentReportStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpIncidentTypeArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_IncidentTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_IncidentTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpInjuryAreaArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_InjuryAreaToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_InjuryAreaToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpInspectionIntervalArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_InspectionIntervalToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_InspectionIntervalToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpLessonArgs = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  companies?: InputMaybe<Wp_LessonToCompanyConnectionFilterInput>
  content?: InputMaybe<StringQueryOperatorInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  divisions?: InputMaybe<Wp_LessonToDivisionConnectionFilterInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  excerpt?: InputMaybe<StringQueryOperatorInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput>
  featuredImageDatabaseId?: InputMaybe<IntQueryOperatorInput>
  featuredImageId?: InputMaybe<IdQueryOperatorInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  lessonCategories?: InputMaybe<Wp_LessonToLessonCategoryConnectionFilterInput>
  lessonDetails?: InputMaybe<Wp_Lesson_LessondetailsFilterInput>
  lessonDifficulties?: InputMaybe<Wp_LessonToLessonDifficultyConnectionFilterInput>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_LessonToDocumentConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  providers?: InputMaybe<Wp_LessonToProviderConnectionFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  terms?: InputMaybe<Wp_LessonToTermNodeConnectionFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  trainingTypes?: InputMaybe<Wp_LessonToTrainingTypeConnectionFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpLessonCategoryArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_LessonCategoryToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_LessonCategoryToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_LessonCategoryToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpLessonDifficultyArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_LessonDifficultyToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_LessonDifficultyToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_LessonDifficultyToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpManufacturerArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ManufacturerToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ManufacturerToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpMediaItemArgs = {
  altText?: InputMaybe<StringQueryOperatorInput>
  ancestors?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionFilterInput>
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  caption?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  commentCount?: InputMaybe<IntQueryOperatorInput>
  commentStatus?: InputMaybe<StringQueryOperatorInput>
  comments?: InputMaybe<Wp_MediaItemToCommentConnectionFilterInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  fileSize?: InputMaybe<IntQueryOperatorInput>
  filename?: InputMaybe<StringQueryOperatorInput>
  filesize?: InputMaybe<IntQueryOperatorInput>
  gatsbyImage?: InputMaybe<GatsbyImageDataQueryOperatorInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  height?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  localFile?: InputMaybe<FileFilterInput>
  mediaDetails?: InputMaybe<Wp_MediaDetailsFilterInput>
  mediaItemUrl?: InputMaybe<StringQueryOperatorInput>
  mediaType?: InputMaybe<StringQueryOperatorInput>
  mimeType?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  publicUrl?: InputMaybe<StringQueryOperatorInput>
  resize?: InputMaybe<RemoteFileResizeFilterInput>
  sizes?: InputMaybe<StringQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  sourceUrl?: InputMaybe<StringQueryOperatorInput>
  srcSet?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  translations?: InputMaybe<Wp_MediaItemFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<IntQueryOperatorInput>
  wpChildren?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeChildrenConnectionFilterInput>
  wpParent?: InputMaybe<Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeFilterInput>
}

export type QueryWpMenuArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  locations?: InputMaybe<Wp_MenuLocationEnumQueryOperatorInput>
  menuItems?: InputMaybe<Wp_MenuToMenuItemConnectionFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpMenuItemArgs = {
  childItems?: InputMaybe<Wp_MenuItemToMenuItemConnectionFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  connectedNode?: InputMaybe<Wp_MenuItemToMenuItemLinkableConnectionEdgeFilterInput>
  cssClasses?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  label?: InputMaybe<StringQueryOperatorInput>
  linkRelationship?: InputMaybe<StringQueryOperatorInput>
  locations?: InputMaybe<Wp_MenuLocationEnumQueryOperatorInput>
  menu?: InputMaybe<Wp_MenuItemToMenuConnectionEdgeFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  order?: InputMaybe<IntQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  path?: InputMaybe<StringQueryOperatorInput>
  target?: InputMaybe<StringQueryOperatorInput>
  title?: InputMaybe<StringQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpNodeWithAuthorArgs = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithCommentsArgs = {
  children?: InputMaybe<NodeFilterListInput>
  commentCount?: InputMaybe<IntQueryOperatorInput>
  commentStatus?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithContentEditorArgs = {
  children?: InputMaybe<NodeFilterListInput>
  content?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithExcerptArgs = {
  children?: InputMaybe<NodeFilterListInput>
  excerpt?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithFeaturedImageArgs = {
  children?: InputMaybe<NodeFilterListInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput>
  featuredImageDatabaseId?: InputMaybe<IntQueryOperatorInput>
  featuredImageId?: InputMaybe<IdQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithPageAttributesArgs = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  menuOrder?: InputMaybe<IntQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithRevisionsArgs = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isRevision?: InputMaybe<BooleanQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpNodeWithTemplateArgs = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
}

export type QueryWpNodeWithTitleArgs = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpNodeWithTrackbacksArgs = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pingStatus?: InputMaybe<StringQueryOperatorInput>
  pinged?: InputMaybe<StringQueryOperatorInput>
  toPing?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpPostFormatArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_PostFormatToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_PostFormatToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpProjectStatusArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ProjectStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ProjectStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpProjectTypeArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ProjectTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ProjectTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpProviderArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ProviderToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_ProviderToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ProviderToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpRootCauseArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_RootCauseToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_RootCauseToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpSeverityArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_SeverityToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_SeverityToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpTagArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_TagToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_TagToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_TagFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpTaxonomyArgs = {
  archivePath?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  connectedContentTypes?: InputMaybe<Wp_TaxonomyToContentTypeConnectionFilterInput>
  description?: InputMaybe<StringQueryOperatorInput>
  graphqlPluralName?: InputMaybe<StringQueryOperatorInput>
  graphqlSingleName?: InputMaybe<StringQueryOperatorInput>
  hierarchical?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  label?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  public?: InputMaybe<BooleanQueryOperatorInput>
  restBase?: InputMaybe<StringQueryOperatorInput>
  restControllerClass?: InputMaybe<StringQueryOperatorInput>
  showCloud?: InputMaybe<BooleanQueryOperatorInput>
  showInAdminColumn?: InputMaybe<BooleanQueryOperatorInput>
  showInGraphql?: InputMaybe<BooleanQueryOperatorInput>
  showInMenu?: InputMaybe<BooleanQueryOperatorInput>
  showInNavMenus?: InputMaybe<BooleanQueryOperatorInput>
  showInQuickEdit?: InputMaybe<BooleanQueryOperatorInput>
  showInRest?: InputMaybe<BooleanQueryOperatorInput>
  showUi?: InputMaybe<BooleanQueryOperatorInput>
}

export type QueryWpTermNodeArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpToolboxTalkCategoryArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ToolboxTalkCategoryToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_ToolboxTalkCategoryFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpTrainingTypeArgs = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_TrainingTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_TrainingTypeToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_TrainingTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpUniformResourceIdentifiableArgs = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpUserArgs = {
  avatar?: InputMaybe<Wp_AvatarFilterInput>
  capKey?: InputMaybe<StringQueryOperatorInput>
  capabilities?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  comments?: InputMaybe<Wp_UserToCommentConnectionFilterInput>
  companies?: InputMaybe<Wp_UserToUserCompanyConnectionFilterInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  divisions?: InputMaybe<Wp_UserToUserDivisionConnectionFilterInput>
  documents?: InputMaybe<Wp_UserToDocumentConnectionFilterInput>
  email?: InputMaybe<StringQueryOperatorInput>
  emergencyContactInfo?: InputMaybe<Wp_User_EmergencycontactinfoFilterInput>
  employeeId?: InputMaybe<StringQueryOperatorInput>
  extraCapabilities?: InputMaybe<StringQueryOperatorInput>
  firstName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  integrationSource?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isSuperAdmin?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  languages?: InputMaybe<Wp_UserToUserLanguageConnectionFilterInput>
  lastName?: InputMaybe<StringQueryOperatorInput>
  lessons?: InputMaybe<Wp_UserToLessonConnectionFilterInput>
  locale?: InputMaybe<StringQueryOperatorInput>
  magicBellHmacEmail?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nicename?: InputMaybe<StringQueryOperatorInput>
  nickname?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  offlineMode?: InputMaybe<BooleanQueryOperatorInput>
  offlineModeData?: InputMaybe<Wp_OfflineModeDataCollectionNameEnumQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  phoneNumber?: InputMaybe<StringQueryOperatorInput>
  registeredDate?: InputMaybe<StringQueryOperatorInput>
  roles?: InputMaybe<Wp_UserToUserRoleConnectionFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<Wp_UserToUserStatusConnectionFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
  usageCounts?: InputMaybe<Wp_User_UsagecountsFilterInput>
  username?: InputMaybe<StringQueryOperatorInput>
  vaccineStatus?: InputMaybe<Wp_UserToUserVaccineStatusConnectionEdgeFilterInput>
}

export type QueryWpUserCompanyArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserCompanyToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpUserDivisionArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserDivisionToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpUserLanguageArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserLanguageToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpUserRoleArgs = {
  capabilities?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  displayName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type QueryWpUserStatusArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type QueryWpUserVaccineStatusArgs = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserVaccineStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

/** Remote Interface */
export type RemoteFile = {
  filename: Scalars['String']['output']
  filesize?: Maybe<Scalars['Int']['output']>
  /** Data used in the <GatsbyImage /> component. See https://gatsby.dev/img for more info. */
  gatsbyImage?: Maybe<Scalars['GatsbyImageData']['output']>
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  mimeType: Scalars['String']['output']
  publicUrl: Scalars['String']['output']
  resize?: Maybe<RemoteFileResize>
  width?: Maybe<Scalars['Int']['output']>
}

/** Remote Interface */
export type RemoteFileGatsbyImageArgs = {
  aspectRatio?: InputMaybe<Scalars['Float']['input']>
  backgroundColor?: InputMaybe<Scalars['String']['input']>
  breakpoints?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  cropFocus?: InputMaybe<Array<InputMaybe<RemoteFileCropFocus>>>
  fit?: InputMaybe<RemoteFileFit>
  formats?: InputMaybe<Array<RemoteFileFormat>>
  height?: InputMaybe<Scalars['Int']['input']>
  layout?: InputMaybe<RemoteFileLayout>
  outputPixelDensities?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >
  placeholder?: InputMaybe<RemoteFilePlaceholder>
  quality?: InputMaybe<Scalars['Int']['input']>
  sizes?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** Remote Interface */
export type RemoteFileResizeArgs = {
  aspectRatio?: InputMaybe<Scalars['Float']['input']>
  cropFocus?: InputMaybe<Array<InputMaybe<RemoteFileCropFocus>>>
  fit?: InputMaybe<RemoteFileFit>
  format?: InputMaybe<RemoteFileFormat>
  height?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export enum RemoteFileCropFocus {
  Bottom = 'BOTTOM',
  Center = 'CENTER',
  Edges = 'EDGES',
  Entropy = 'ENTROPY',
  Faces = 'FACES',
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP',
}

export enum RemoteFileFit {
  Contain = 'CONTAIN',
  Cover = 'COVER',
  Fill = 'FILL',
  Outside = 'OUTSIDE',
}

export enum RemoteFileFormat {
  Auto = 'AUTO',
  Avif = 'AVIF',
  Jpg = 'JPG',
  Png = 'PNG',
  Webp = 'WEBP',
}

export enum RemoteFileLayout {
  Constrained = 'CONSTRAINED',
  Fixed = 'FIXED',
  FullWidth = 'FULL_WIDTH',
}

export enum RemoteFilePlaceholder {
  Blurred = 'BLURRED',
  DominantColor = 'DOMINANT_COLOR',
  None = 'NONE',
  TracedSvg = 'TRACED_SVG',
}

export type RemoteFileResize = {
  __typename?: 'RemoteFileResize'
  height?: Maybe<Scalars['Int']['output']>
  src?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

export type RemoteFileResizeFieldSelector = {
  height?: InputMaybe<FieldSelectorEnum>
  src?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type RemoteFileResizeFilterInput = {
  height?: InputMaybe<IntQueryOperatorInput>
  src?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<IntQueryOperatorInput>
}

export type RemoteFileResizeSortInput = {
  height?: InputMaybe<SortOrderEnum>
  src?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

export type Site = Node & {
  __typename?: 'Site'
  buildTime?: Maybe<Scalars['Date']['output']>
  children: Array<Node>
  flags?: Maybe<SiteFlags>
  graphqlTypegen?: Maybe<Scalars['Boolean']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  jsxRuntime?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  pathPrefix?: Maybe<Scalars['String']['output']>
  polyfill?: Maybe<Scalars['Boolean']['output']>
  port?: Maybe<Scalars['Int']['output']>
  siteMetadata?: Maybe<SiteSiteMetadata>
  trailingSlash?: Maybe<Scalars['String']['output']>
}

export type SiteBuildTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type SiteBuildMetadata = Node & {
  __typename?: 'SiteBuildMetadata'
  buildTime?: Maybe<Scalars['Date']['output']>
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  parent?: Maybe<Node>
}

export type SiteBuildMetadataBuildTimeArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type SiteBuildMetadataConnection = {
  __typename?: 'SiteBuildMetadataConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SiteBuildMetadataEdge>
  group: Array<SiteBuildMetadataGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SiteBuildMetadata>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SiteBuildMetadataConnectionDistinctArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataConnectionGroupArgs = {
  field: SiteBuildMetadataFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SiteBuildMetadataConnectionMaxArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataConnectionMinArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataConnectionSumArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataEdge = {
  __typename?: 'SiteBuildMetadataEdge'
  next?: Maybe<SiteBuildMetadata>
  node: SiteBuildMetadata
  previous?: Maybe<SiteBuildMetadata>
}

export type SiteBuildMetadataFieldSelector = {
  buildTime?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  parent?: InputMaybe<NodeFieldSelector>
}

export type SiteBuildMetadataFilterInput = {
  buildTime?: InputMaybe<DateQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type SiteBuildMetadataGroupConnection = {
  __typename?: 'SiteBuildMetadataGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SiteBuildMetadataEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<SiteBuildMetadataGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SiteBuildMetadata>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SiteBuildMetadataGroupConnectionDistinctArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataGroupConnectionGroupArgs = {
  field: SiteBuildMetadataFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SiteBuildMetadataGroupConnectionMaxArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataGroupConnectionMinArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataGroupConnectionSumArgs = {
  field: SiteBuildMetadataFieldSelector
}

export type SiteBuildMetadataSortInput = {
  buildTime?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  parent?: InputMaybe<NodeSortInput>
}

export type SiteConnection = {
  __typename?: 'SiteConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SiteEdge>
  group: Array<SiteGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Site>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SiteConnectionDistinctArgs = {
  field: SiteFieldSelector
}

export type SiteConnectionGroupArgs = {
  field: SiteFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SiteConnectionMaxArgs = {
  field: SiteFieldSelector
}

export type SiteConnectionMinArgs = {
  field: SiteFieldSelector
}

export type SiteConnectionSumArgs = {
  field: SiteFieldSelector
}

export type SiteEdge = {
  __typename?: 'SiteEdge'
  next?: Maybe<Site>
  node: Site
  previous?: Maybe<Site>
}

export type SiteFieldSelector = {
  buildTime?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  flags?: InputMaybe<SiteFlagsFieldSelector>
  graphqlTypegen?: InputMaybe<FieldSelectorEnum>
  host?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  jsxRuntime?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  pathPrefix?: InputMaybe<FieldSelectorEnum>
  polyfill?: InputMaybe<FieldSelectorEnum>
  port?: InputMaybe<FieldSelectorEnum>
  siteMetadata?: InputMaybe<SiteSiteMetadataFieldSelector>
  trailingSlash?: InputMaybe<FieldSelectorEnum>
}

export type SiteFilterInput = {
  buildTime?: InputMaybe<DateQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  flags?: InputMaybe<SiteFlagsFilterInput>
  graphqlTypegen?: InputMaybe<BooleanQueryOperatorInput>
  host?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  jsxRuntime?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pathPrefix?: InputMaybe<StringQueryOperatorInput>
  polyfill?: InputMaybe<BooleanQueryOperatorInput>
  port?: InputMaybe<IntQueryOperatorInput>
  siteMetadata?: InputMaybe<SiteSiteMetadataFilterInput>
  trailingSlash?: InputMaybe<StringQueryOperatorInput>
}

export type SiteFlags = {
  __typename?: 'SiteFlags'
  DEV_SSR?: Maybe<Scalars['Boolean']['output']>
}

export type SiteFlagsFieldSelector = {
  DEV_SSR?: InputMaybe<FieldSelectorEnum>
}

export type SiteFlagsFilterInput = {
  DEV_SSR?: InputMaybe<BooleanQueryOperatorInput>
}

export type SiteFlagsSortInput = {
  DEV_SSR?: InputMaybe<SortOrderEnum>
}

export type SiteFunction = Node & {
  __typename?: 'SiteFunction'
  absoluteCompiledFilePath: Scalars['String']['output']
  children: Array<Node>
  functionRoute: Scalars['String']['output']
  id: Scalars['ID']['output']
  internal: Internal
  matchPath?: Maybe<Scalars['String']['output']>
  originalAbsoluteFilePath: Scalars['String']['output']
  originalRelativeFilePath: Scalars['String']['output']
  parent?: Maybe<Node>
  pluginName: Scalars['String']['output']
  relativeCompiledFilePath: Scalars['String']['output']
}

export type SiteFunctionConnection = {
  __typename?: 'SiteFunctionConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SiteFunctionEdge>
  group: Array<SiteFunctionGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SiteFunction>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SiteFunctionConnectionDistinctArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionConnectionGroupArgs = {
  field: SiteFunctionFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SiteFunctionConnectionMaxArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionConnectionMinArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionConnectionSumArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionEdge = {
  __typename?: 'SiteFunctionEdge'
  next?: Maybe<SiteFunction>
  node: SiteFunction
  previous?: Maybe<SiteFunction>
}

export type SiteFunctionFieldSelector = {
  absoluteCompiledFilePath?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  functionRoute?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  matchPath?: InputMaybe<FieldSelectorEnum>
  originalAbsoluteFilePath?: InputMaybe<FieldSelectorEnum>
  originalRelativeFilePath?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  pluginName?: InputMaybe<FieldSelectorEnum>
  relativeCompiledFilePath?: InputMaybe<FieldSelectorEnum>
}

export type SiteFunctionFilterInput = {
  absoluteCompiledFilePath?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  functionRoute?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  matchPath?: InputMaybe<StringQueryOperatorInput>
  originalAbsoluteFilePath?: InputMaybe<StringQueryOperatorInput>
  originalRelativeFilePath?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pluginName?: InputMaybe<StringQueryOperatorInput>
  relativeCompiledFilePath?: InputMaybe<StringQueryOperatorInput>
}

export type SiteFunctionGroupConnection = {
  __typename?: 'SiteFunctionGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SiteFunctionEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<SiteFunctionGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SiteFunction>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SiteFunctionGroupConnectionDistinctArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionGroupConnectionGroupArgs = {
  field: SiteFunctionFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SiteFunctionGroupConnectionMaxArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionGroupConnectionMinArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionGroupConnectionSumArgs = {
  field: SiteFunctionFieldSelector
}

export type SiteFunctionSortInput = {
  absoluteCompiledFilePath?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  functionRoute?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  matchPath?: InputMaybe<SortOrderEnum>
  originalAbsoluteFilePath?: InputMaybe<SortOrderEnum>
  originalRelativeFilePath?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  pluginName?: InputMaybe<SortOrderEnum>
  relativeCompiledFilePath?: InputMaybe<SortOrderEnum>
}

export type SiteGroupConnection = {
  __typename?: 'SiteGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SiteEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<SiteGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Site>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SiteGroupConnectionDistinctArgs = {
  field: SiteFieldSelector
}

export type SiteGroupConnectionGroupArgs = {
  field: SiteFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SiteGroupConnectionMaxArgs = {
  field: SiteFieldSelector
}

export type SiteGroupConnectionMinArgs = {
  field: SiteFieldSelector
}

export type SiteGroupConnectionSumArgs = {
  field: SiteFieldSelector
}

export type SitePage = Node & {
  __typename?: 'SitePage'
  children: Array<Node>
  component: Scalars['String']['output']
  componentChunkName: Scalars['String']['output']
  id: Scalars['ID']['output']
  internal: Internal
  internalComponentName: Scalars['String']['output']
  matchPath?: Maybe<Scalars['String']['output']>
  pageContext?: Maybe<Scalars['JSON']['output']>
  parent?: Maybe<Node>
  path: Scalars['String']['output']
  pluginCreator?: Maybe<SitePlugin>
}

export type SitePageConnection = {
  __typename?: 'SitePageConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SitePageEdge>
  group: Array<SitePageGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SitePage>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SitePageConnectionDistinctArgs = {
  field: SitePageFieldSelector
}

export type SitePageConnectionGroupArgs = {
  field: SitePageFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SitePageConnectionMaxArgs = {
  field: SitePageFieldSelector
}

export type SitePageConnectionMinArgs = {
  field: SitePageFieldSelector
}

export type SitePageConnectionSumArgs = {
  field: SitePageFieldSelector
}

export type SitePageEdge = {
  __typename?: 'SitePageEdge'
  next?: Maybe<SitePage>
  node: SitePage
  previous?: Maybe<SitePage>
}

export type SitePageFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  component?: InputMaybe<FieldSelectorEnum>
  componentChunkName?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  internalComponentName?: InputMaybe<FieldSelectorEnum>
  matchPath?: InputMaybe<FieldSelectorEnum>
  pageContext?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  path?: InputMaybe<FieldSelectorEnum>
  pluginCreator?: InputMaybe<SitePluginFieldSelector>
}

export type SitePageFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  component?: InputMaybe<StringQueryOperatorInput>
  componentChunkName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  internalComponentName?: InputMaybe<StringQueryOperatorInput>
  matchPath?: InputMaybe<StringQueryOperatorInput>
  pageContext?: InputMaybe<JsonQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  path?: InputMaybe<StringQueryOperatorInput>
  pluginCreator?: InputMaybe<SitePluginFilterInput>
}

export type SitePageGroupConnection = {
  __typename?: 'SitePageGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SitePageEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<SitePageGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SitePage>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SitePageGroupConnectionDistinctArgs = {
  field: SitePageFieldSelector
}

export type SitePageGroupConnectionGroupArgs = {
  field: SitePageFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SitePageGroupConnectionMaxArgs = {
  field: SitePageFieldSelector
}

export type SitePageGroupConnectionMinArgs = {
  field: SitePageFieldSelector
}

export type SitePageGroupConnectionSumArgs = {
  field: SitePageFieldSelector
}

export type SitePageSortInput = {
  children?: InputMaybe<NodeSortInput>
  component?: InputMaybe<SortOrderEnum>
  componentChunkName?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  internalComponentName?: InputMaybe<SortOrderEnum>
  matchPath?: InputMaybe<SortOrderEnum>
  pageContext?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  path?: InputMaybe<SortOrderEnum>
  pluginCreator?: InputMaybe<SitePluginSortInput>
}

export type SitePlugin = Node & {
  __typename?: 'SitePlugin'
  browserAPIs?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  name?: Maybe<Scalars['String']['output']>
  nodeAPIs?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  packageJson?: Maybe<Scalars['JSON']['output']>
  parent?: Maybe<Node>
  pluginFilepath?: Maybe<Scalars['String']['output']>
  pluginOptions?: Maybe<Scalars['JSON']['output']>
  resolve?: Maybe<Scalars['String']['output']>
  ssrAPIs?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  version?: Maybe<Scalars['String']['output']>
}

export type SitePluginConnection = {
  __typename?: 'SitePluginConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SitePluginEdge>
  group: Array<SitePluginGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SitePlugin>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SitePluginConnectionDistinctArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginConnectionGroupArgs = {
  field: SitePluginFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SitePluginConnectionMaxArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginConnectionMinArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginConnectionSumArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginEdge = {
  __typename?: 'SitePluginEdge'
  next?: Maybe<SitePlugin>
  node: SitePlugin
  previous?: Maybe<SitePlugin>
}

export type SitePluginFieldSelector = {
  browserAPIs?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  name?: InputMaybe<FieldSelectorEnum>
  nodeAPIs?: InputMaybe<FieldSelectorEnum>
  packageJson?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  pluginFilepath?: InputMaybe<FieldSelectorEnum>
  pluginOptions?: InputMaybe<FieldSelectorEnum>
  resolve?: InputMaybe<FieldSelectorEnum>
  ssrAPIs?: InputMaybe<FieldSelectorEnum>
  version?: InputMaybe<FieldSelectorEnum>
}

export type SitePluginFilterInput = {
  browserAPIs?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeAPIs?: InputMaybe<StringQueryOperatorInput>
  packageJson?: InputMaybe<JsonQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pluginFilepath?: InputMaybe<StringQueryOperatorInput>
  pluginOptions?: InputMaybe<JsonQueryOperatorInput>
  resolve?: InputMaybe<StringQueryOperatorInput>
  ssrAPIs?: InputMaybe<StringQueryOperatorInput>
  version?: InputMaybe<StringQueryOperatorInput>
}

export type SitePluginGroupConnection = {
  __typename?: 'SitePluginGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<SitePluginEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<SitePluginGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<SitePlugin>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type SitePluginGroupConnectionDistinctArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginGroupConnectionGroupArgs = {
  field: SitePluginFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SitePluginGroupConnectionMaxArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginGroupConnectionMinArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginGroupConnectionSumArgs = {
  field: SitePluginFieldSelector
}

export type SitePluginSortInput = {
  browserAPIs?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  name?: InputMaybe<SortOrderEnum>
  nodeAPIs?: InputMaybe<SortOrderEnum>
  packageJson?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  pluginFilepath?: InputMaybe<SortOrderEnum>
  pluginOptions?: InputMaybe<SortOrderEnum>
  resolve?: InputMaybe<SortOrderEnum>
  ssrAPIs?: InputMaybe<SortOrderEnum>
  version?: InputMaybe<SortOrderEnum>
}

export type SiteSiteMetadata = {
  __typename?: 'SiteSiteMetadata'
  apiUrl?: Maybe<Scalars['String']['output']>
  author?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  siteUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  version?: Maybe<Scalars['String']['output']>
}

export type SiteSiteMetadataFieldSelector = {
  apiUrl?: InputMaybe<FieldSelectorEnum>
  author?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  siteUrl?: InputMaybe<FieldSelectorEnum>
  title?: InputMaybe<FieldSelectorEnum>
  version?: InputMaybe<FieldSelectorEnum>
}

export type SiteSiteMetadataFilterInput = {
  apiUrl?: InputMaybe<StringQueryOperatorInput>
  author?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  siteUrl?: InputMaybe<StringQueryOperatorInput>
  title?: InputMaybe<StringQueryOperatorInput>
  version?: InputMaybe<StringQueryOperatorInput>
}

export type SiteSiteMetadataSortInput = {
  apiUrl?: InputMaybe<SortOrderEnum>
  author?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  siteUrl?: InputMaybe<SortOrderEnum>
  title?: InputMaybe<SortOrderEnum>
  version?: InputMaybe<SortOrderEnum>
}

export type SiteSortInput = {
  buildTime?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  flags?: InputMaybe<SiteFlagsSortInput>
  graphqlTypegen?: InputMaybe<SortOrderEnum>
  host?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  jsxRuntime?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  pathPrefix?: InputMaybe<SortOrderEnum>
  polyfill?: InputMaybe<SortOrderEnum>
  port?: InputMaybe<SortOrderEnum>
  siteMetadata?: InputMaybe<SiteSiteMetadataSortInput>
  trailingSlash?: InputMaybe<SortOrderEnum>
}

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StringQueryOperatorInput = {
  eq?: InputMaybe<Scalars['String']['input']>
  glob?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  ne?: InputMaybe<Scalars['String']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  regex?: InputMaybe<Scalars['String']['input']>
}

export type TransformOptions = {
  cropFocus?: InputMaybe<ImageCropFocus>
  duotone?: InputMaybe<DuotoneGradient>
  fit?: InputMaybe<ImageFit>
  grayscale?: InputMaybe<Scalars['Boolean']['input']>
  rotate?: InputMaybe<Scalars['Int']['input']>
  trim?: InputMaybe<Scalars['Float']['input']>
}

export type WebPOptions = {
  quality?: InputMaybe<Scalars['Int']['input']>
}

/** Non-node WPGraphQL root fields. */
export type Wp_ = Node & {
  __typename?: 'Wp_'
  /** Entry point to get all settings for the site */
  allSettings?: Maybe<Wp_Settings>
  /** The configured warnings for certificate expirations. */
  certificationExpiryWarnings?: Maybe<
    Array<Maybe<Wp_CertificationExpiryWarningRule>>
  >
  children: Array<Node>
  /** Get language list */
  defaultLanguage?: Maybe<Wp_Language>
  /** Fields of the &#039;DiscussionSettings&#039; settings group */
  discussionSettings?: Maybe<Wp_DiscussionSettings>
  /** Fields of the &#039;GeneralSettings&#039; settings group */
  generalSettings?: Maybe<Wp_GeneralSettings>
  /** Gravity Forms settings. */
  gfSettings?: Maybe<Wp_GfSettings>
  /** Harness client-specific settings */
  harnessSettings?: Maybe<Wp_HarnessSettings>
  id: Scalars['ID']['output']
  /** Incident report data used to complete the OSHA 300A form. */
  incidentReportData?: Maybe<Wp_IncidentReportData>
  /** The integration configuration */
  integrationConfig?: Maybe<Wp_IntegrationConfig>
  internal: Internal
  /** List available languages */
  languages?: Maybe<Array<Maybe<Wp_Language>>>
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** Fields of the &#039;ReadingSettings&#039; settings group */
  readingSettings?: Maybe<Wp_ReadingSettings>
  /** Returns an MD5 hash of the schema, useful in determining if the schema has changed. */
  schemaMd5?: Maybe<Scalars['String']['output']>
  /** Returns the current user */
  viewer?: Maybe<Wp_User>
  /** Information needed by gatsby-source-wordpress. */
  wpGatsby?: Maybe<Wp_WpGatsby>
  /** Fields of the &#039;WritingSettings&#039; settings group */
  writingSettings?: Maybe<Wp_WritingSettings>
}

export type Wp_AcfFieldGroup = {
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
}

/** The active integration */
export type Wp_ActiveIntegration = {
  __typename?: 'Wp_ActiveIntegration'
  /** The WordPress (i.e. destination) data type. */
  destinationType?: Maybe<Wp_IntegrationWpDataTypeEnum>
  /** The source data type */
  sourceType?: Maybe<Wp_IntegrationSourceDataTypeEnum>
  /** The fields sourced from the Integration. */
  sourcedFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type Wp_ActiveIntegrationFieldSelector = {
  destinationType?: InputMaybe<FieldSelectorEnum>
  sourceType?: InputMaybe<FieldSelectorEnum>
  sourcedFields?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ActiveIntegrationFilterInput = {
  destinationType?: InputMaybe<Wp_IntegrationWpDataTypeEnumQueryOperatorInput>
  sourceType?: InputMaybe<Wp_IntegrationSourceDataTypeEnumQueryOperatorInput>
  sourcedFields?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ActiveIntegrationFilterListInput = {
  elemMatch?: InputMaybe<Wp_ActiveIntegrationFilterInput>
}

export type Wp_ActiveIntegrationSortInput = {
  destinationType?: InputMaybe<SortOrderEnum>
  sourceType?: InputMaybe<SortOrderEnum>
  sourcedFields?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_AddressField = Wp_FormField & {
  __typename?: 'Wp_AddressField'
  /** Address Field Specific Translations */
  addressTranslations?: Maybe<Array<Maybe<Wp_AddressTranlation>>>
  /** Determines the type of address to be displayed. */
  addressType?: Maybe<Wp_AddressFieldTypeEnum>
  /** Address field value. */
  addressValues?: Maybe<Wp_AddressFieldValue>
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** The field id of the field being used as the copy source. */
  copyValuesOptionFieldId?: Maybe<Scalars['Int']['output']>
  /** The label that appears next to the copy values option when the form is displayed. The default value is \“Same as previous\”. */
  copyValuesOptionLabel?: Maybe<Scalars['String']['output']>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the country that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;INTERATIONAL&quot;. */
  defaultCountry?: Maybe<Wp_AddressFieldCountryEnum>
  /** Contains the province that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;CANADA&quot;. */
  defaultProvince?: Maybe<Scalars['String']['output']>
  /** Contains the state that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;US&quot;. */
  defaultState?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the address field. */
  inputs?: Maybe<Array<Maybe<Wp_AddressInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Indicates whether the copy values option can be used. This option allows users to skip filling out the field and use the same values as another. For example, if the mailing and billing address are the same. */
  shouldCopyValuesOption?: Maybe<Scalars['Boolean']['output']>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Countries supported by Gravity Forms Address Field. */
export enum Wp_AddressFieldCountryEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

/** Determines the type of address to be displayed. */
export enum Wp_AddressFieldTypeEnum {
  Canada = 'CANADA',
  International = 'INTERNATIONAL',
  Us = 'US',
}

/** The individual properties for each element of the address value field. */
export type Wp_AddressFieldValue = {
  __typename?: 'Wp_AddressFieldValue'
  /** Address city. */
  city?: Maybe<Scalars['String']['output']>
  /** Address country. */
  country?: Maybe<Wp_AddressFieldCountryEnum>
  /** Address line two. */
  lineTwo?: Maybe<Scalars['String']['output']>
  /** Address state/region/province name. */
  state?: Maybe<Scalars['String']['output']>
  /** Street address. */
  street?: Maybe<Scalars['String']['output']>
  /** Address zip code. */
  zip?: Maybe<Scalars['String']['output']>
}

/** Address input values. */
export type Wp_AddressInputProperty = {
  __typename?: 'Wp_AddressInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']['output']>
  /** Key used to identify this input. */
  key?: Maybe<Scalars['String']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms translation settings */
export type Wp_AddressTranlation = {
  __typename?: 'Wp_AddressTranlation'
  /** Address label translation */
  address?: Maybe<Scalars['String']['output']>
  /** Address2 label translation */
  address2?: Maybe<Scalars['String']['output']>
  /** City label translation */
  city?: Maybe<Scalars['String']['output']>
  /** Country label translation */
  country?: Maybe<Scalars['String']['output']>
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
  /** State label translation */
  state?: Maybe<Scalars['String']['output']>
  /** Zip label translation */
  zip?: Maybe<Scalars['String']['output']>
}

/** The AM or PM cycle in a 12-hour clock. */
export enum Wp_AmPmEnum {
  Am = 'AM',
  Pm = 'PM',
}

export type Wp_AmPmEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_AmPmEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_AmPmEnum>>>
  ne?: InputMaybe<Wp_AmPmEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_AmPmEnum>>>
}

/** Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from. */
export type Wp_Avatar = {
  __typename?: 'Wp_Avatar'
  /** URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo). */
  default?: Maybe<Scalars['String']['output']>
  /** HTML attributes to insert in the IMG element. Is not sanitized. */
  extraAttr?: Maybe<Scalars['String']['output']>
  /** Whether to always show the default image, never the Gravatar. */
  forceDefault?: Maybe<Scalars['Boolean']['output']>
  /** Whether the avatar was successfully found. */
  foundAvatar?: Maybe<Scalars['Boolean']['output']>
  /** Height of the avatar image. */
  height?: Maybe<Scalars['Int']['output']>
  /** What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order. */
  rating?: Maybe<Scalars['String']['output']>
  /** Type of url scheme to use. Typically HTTP vs. HTTPS. */
  scheme?: Maybe<Scalars['String']['output']>
  /** The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image. */
  size?: Maybe<Scalars['Int']['output']>
  /** URL for the gravatar image source. */
  url?: Maybe<Scalars['String']['output']>
  /** Width of the avatar image. */
  width?: Maybe<Scalars['Int']['output']>
}

export type Wp_AvatarFieldSelector = {
  default?: InputMaybe<FieldSelectorEnum>
  extraAttr?: InputMaybe<FieldSelectorEnum>
  forceDefault?: InputMaybe<FieldSelectorEnum>
  foundAvatar?: InputMaybe<FieldSelectorEnum>
  height?: InputMaybe<FieldSelectorEnum>
  rating?: InputMaybe<FieldSelectorEnum>
  scheme?: InputMaybe<FieldSelectorEnum>
  size?: InputMaybe<FieldSelectorEnum>
  url?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type Wp_AvatarFilterInput = {
  default?: InputMaybe<StringQueryOperatorInput>
  extraAttr?: InputMaybe<StringQueryOperatorInput>
  forceDefault?: InputMaybe<BooleanQueryOperatorInput>
  foundAvatar?: InputMaybe<BooleanQueryOperatorInput>
  height?: InputMaybe<IntQueryOperatorInput>
  rating?: InputMaybe<StringQueryOperatorInput>
  scheme?: InputMaybe<StringQueryOperatorInput>
  size?: InputMaybe<IntQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_AvatarSortInput = {
  default?: InputMaybe<SortOrderEnum>
  extraAttr?: InputMaybe<SortOrderEnum>
  forceDefault?: InputMaybe<SortOrderEnum>
  foundAvatar?: InputMaybe<SortOrderEnum>
  height?: InputMaybe<SortOrderEnum>
  rating?: InputMaybe<SortOrderEnum>
  scheme?: InputMaybe<SortOrderEnum>
  size?: InputMaybe<SortOrderEnum>
  url?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

/** Gravity Forms translation settings */
export type Wp_BasicTranslation = {
  __typename?: 'Wp_BasicTranslation'
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
}

export type Wp_BlockAttributesObject = {
  __typename?: 'Wp_BlockAttributesObject'
  foobar?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type Wp_CaptchaField = Wp_FormField & {
  __typename?: 'Wp_CaptchaField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress. */
  captchaBadgePosition?: Maybe<Wp_CaptchaFieldBadgePositionEnum>
  /** The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress. */
  captchaLanguage?: Maybe<Scalars['String']['output']>
  /** Determines the theme to be used for the reCAPTCHA field. Only applicable to the recaptcha captcha type. */
  captchaTheme?: Maybe<Wp_CaptchaFieldThemeEnum>
  /** Determines the type of CAPTCHA field to be used. */
  captchaType?: Maybe<Wp_CaptchaFieldTypeEnum>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Determines the image’s background color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaBackgroundColor?: Maybe<Scalars['String']['output']>
  /** Determines the image’s font color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaFontColor?: Maybe<Scalars['String']['output']>
  /** Determines the CAPTCHA image size. Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaSize?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The position to place the (invisible) reCaptcha badge. */
export enum Wp_CaptchaFieldBadgePositionEnum {
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  Inline = 'INLINE',
}

/** The theme to be used for the reCAPTCHA field. */
export enum Wp_CaptchaFieldThemeEnum {
  Dark = 'DARK',
  Light = 'LIGHT',
}

/** Type of CAPTCHA field to be used. */
export enum Wp_CaptchaFieldTypeEnum {
  Math = 'MATH',
  Recaptcha = 'RECAPTCHA',
  Simple = 'SIMPLE',
}

/** Gravity Forms card display settings */
export type Wp_CardDisplaySettings = {
  __typename?: 'Wp_CardDisplaySettings'
  /** Field ID to use for the first detail. */
  detail1FieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for the second detail. */
  detail2FieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for the third detail. */
  detail3FieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for the heading. */
  headingFieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for subheading. */
  subheadingFieldId?: Maybe<Scalars['String']['output']>
}

export type Wp_CardDisplaySettingsFieldSelector = {
  detail1FieldId?: InputMaybe<FieldSelectorEnum>
  detail2FieldId?: InputMaybe<FieldSelectorEnum>
  detail3FieldId?: InputMaybe<FieldSelectorEnum>
  headingFieldId?: InputMaybe<FieldSelectorEnum>
  subheadingFieldId?: InputMaybe<FieldSelectorEnum>
}

export type Wp_CardDisplaySettingsFilterInput = {
  detail1FieldId?: InputMaybe<StringQueryOperatorInput>
  detail2FieldId?: InputMaybe<StringQueryOperatorInput>
  detail3FieldId?: InputMaybe<StringQueryOperatorInput>
  headingFieldId?: InputMaybe<StringQueryOperatorInput>
  subheadingFieldId?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_CardDisplaySettingsSortInput = {
  detail1FieldId?: InputMaybe<SortOrderEnum>
  detail2FieldId?: InputMaybe<SortOrderEnum>
  detail3FieldId?: InputMaybe<SortOrderEnum>
  headingFieldId?: InputMaybe<SortOrderEnum>
  subheadingFieldId?: InputMaybe<SortOrderEnum>
}

/** Card Display Detail Label Translations */
export type Wp_CardDisplayTranslation = {
  __typename?: 'Wp_CardDisplayTranslation'
  /** Detail 1 Translated Label */
  detail1?: Maybe<Scalars['String']['output']>
  /** Detail 2 Translated Label */
  detail2?: Maybe<Scalars['String']['output']>
  /** Detail 3 Translated Label */
  detail3?: Maybe<Scalars['String']['output']>
}

export type Wp_CardDisplayTranslationFieldSelector = {
  detail1?: InputMaybe<FieldSelectorEnum>
  detail2?: InputMaybe<FieldSelectorEnum>
  detail3?: InputMaybe<FieldSelectorEnum>
}

export type Wp_CardDisplayTranslationFilterInput = {
  detail1?: InputMaybe<StringQueryOperatorInput>
  detail2?: InputMaybe<StringQueryOperatorInput>
  detail3?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_CardDisplayTranslationSortInput = {
  detail1?: InputMaybe<SortOrderEnum>
  detail2?: InputMaybe<SortOrderEnum>
  detail3?: InputMaybe<SortOrderEnum>
}

/** The category type */
export type Wp_Category = Node &
  Wp_DatabaseIdentifier &
  Wp_HierarchicalTermNode &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Category'
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<Wp_CategoryToAncestorsCategoryConnection>
    children: Array<Node>
    /** Connection between the Category type and the ContentNode type */
    contentNodes?: Maybe<Wp_CategoryToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** List available translations for this post */
    language?: Maybe<Wp_Language>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Category type and the Taxonomy type */
    taxonomy?: Maybe<Wp_CategoryToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Wp_Category>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /** Connection between the category type and its children categories. */
    wpChildren?: Maybe<Wp_CategoryToCategoryConnection>
    /** Connection between the category type and its parent category. */
    wpParent?: Maybe<Wp_CategoryToParentCategoryConnectionEdge>
  }

export type Wp_CategoryConnection = {
  __typename?: 'Wp_CategoryConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CategoryEdge>
  group: Array<Wp_CategoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Category>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CategoryConnectionDistinctArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryConnectionGroupArgs = {
  field: Wp_CategoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CategoryConnectionMaxArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryConnectionMinArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryConnectionSumArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryEdge = {
  __typename?: 'Wp_CategoryEdge'
  next?: Maybe<Wp_Category>
  node: Wp_Category
  previous?: Maybe<Wp_Category>
}

export type Wp_CategoryFieldSelector = {
  ancestors?: InputMaybe<Wp_CategoryToAncestorsCategoryConnectionFieldSelector>
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_CategoryToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  language?: InputMaybe<Wp_LanguageFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  parentDatabaseId?: InputMaybe<FieldSelectorEnum>
  parentId?: InputMaybe<FieldSelectorEnum>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_CategoryToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  translations?: InputMaybe<Wp_CategoryFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
  wpChildren?: InputMaybe<Wp_CategoryToCategoryConnectionFieldSelector>
  wpParent?: InputMaybe<Wp_CategoryToParentCategoryConnectionEdgeFieldSelector>
}

export type Wp_CategoryFilterInput = {
  ancestors?: InputMaybe<Wp_CategoryToAncestorsCategoryConnectionFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_CategoryToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_CategoryToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_CategoryFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  wpChildren?: InputMaybe<Wp_CategoryToCategoryConnectionFilterInput>
  wpParent?: InputMaybe<Wp_CategoryToParentCategoryConnectionEdgeFilterInput>
}

export type Wp_CategoryFilterListInput = {
  elemMatch?: InputMaybe<Wp_CategoryFilterInput>
}

export type Wp_CategoryGroupConnection = {
  __typename?: 'Wp_CategoryGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CategoryEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_CategoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Category>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CategoryGroupConnectionDistinctArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryGroupConnectionGroupArgs = {
  field: Wp_CategoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CategoryGroupConnectionMaxArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryGroupConnectionMinArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategoryGroupConnectionSumArgs = {
  field: Wp_CategoryFieldSelector
}

export type Wp_CategorySortInput = {
  ancestors?: InputMaybe<Wp_CategoryToAncestorsCategoryConnectionSortInput>
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_CategoryToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  language?: InputMaybe<Wp_LanguageSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  parentDatabaseId?: InputMaybe<SortOrderEnum>
  parentId?: InputMaybe<SortOrderEnum>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_CategoryToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  translations?: InputMaybe<Wp_CategorySortInput>
  uri?: InputMaybe<SortOrderEnum>
  wpChildren?: InputMaybe<Wp_CategoryToCategoryConnectionSortInput>
  wpParent?: InputMaybe<Wp_CategoryToParentCategoryConnectionEdgeSortInput>
}

/** Connection between the Category type and the category type */
export type Wp_CategoryToAncestorsCategoryConnection = {
  __typename?: 'Wp_CategoryToAncestorsCategoryConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Category>>>
}

export type Wp_CategoryToAncestorsCategoryConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CategoryFieldSelector>
}

export type Wp_CategoryToAncestorsCategoryConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CategoryFilterListInput>
}

export type Wp_CategoryToAncestorsCategoryConnectionSortInput = {
  nodes?: InputMaybe<Wp_CategorySortInput>
}

/** Connection between the Category type and the category type */
export type Wp_CategoryToCategoryConnection = {
  __typename?: 'Wp_CategoryToCategoryConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Category>>>
}

export type Wp_CategoryToCategoryConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CategoryFieldSelector>
}

export type Wp_CategoryToCategoryConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CategoryFilterListInput>
}

export type Wp_CategoryToCategoryConnectionSortInput = {
  nodes?: InputMaybe<Wp_CategorySortInput>
}

/** Connection between the Category type and the ContentNode type */
export type Wp_CategoryToContentNodeConnection = {
  __typename?: 'Wp_CategoryToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_CategoryToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_CategoryToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_CategoryToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Category type and the category type */
export type Wp_CategoryToParentCategoryConnectionEdge = {
  __typename?: 'Wp_CategoryToParentCategoryConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Category>
}

export type Wp_CategoryToParentCategoryConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_CategoryFieldSelector>
}

export type Wp_CategoryToParentCategoryConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_CategoryFilterInput>
}

export type Wp_CategoryToParentCategoryConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_CategorySortInput>
}

/** Connection between the Category type and the Taxonomy type */
export type Wp_CategoryToTaxonomyConnectionEdge = {
  __typename?: 'Wp_CategoryToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_CategoryToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_CategoryToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_CategoryToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The configured warnings for certificate expirations. */
export type Wp_CertificationExpiryWarningRule = {
  __typename?: 'Wp_CertificationExpiryWarningRule'
  /** The number of days before a certificate expires to send the alert. */
  alertWindow?: Maybe<Scalars['Int']['output']>
  /** Connection between the CertificationExpiryWarningRule type and the Company type */
  companies?: Maybe<Wp_CertificationExpiryWarningRuleToCompanyConnection>
  /** The unique identifier of the rule. */
  databaseId?: Maybe<Scalars['Int']['output']>
  /** The date the rule was created. */
  dateCreated?: Maybe<Scalars['String']['output']>
  /** The date the rule was last modified. */
  dateModified?: Maybe<Scalars['String']['output']>
  /** The external contacts to send the alert to. */
  externalContacts?: Maybe<Array<Maybe<Wp_ExternalContact>>>
  /** Connection between the CertificationExpiryWarningRule type and the User type */
  internalContacts?: Maybe<Wp_CertificationExpiryWarningRuleToUserConnection>
  /** Connection between the CertificationExpiryWarningRule type and the User type */
  lastEditedBy?: Maybe<Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdge>
}

export type Wp_CertificationExpiryWarningRuleFieldSelector = {
  alertWindow?: InputMaybe<FieldSelectorEnum>
  companies?: InputMaybe<Wp_CertificationExpiryWarningRuleToCompanyConnectionFieldSelector>
  databaseId?: InputMaybe<FieldSelectorEnum>
  dateCreated?: InputMaybe<FieldSelectorEnum>
  dateModified?: InputMaybe<FieldSelectorEnum>
  externalContacts?: InputMaybe<Wp_ExternalContactFieldSelector>
  internalContacts?: InputMaybe<Wp_CertificationExpiryWarningRuleToUserConnectionFieldSelector>
  lastEditedBy?: InputMaybe<Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdgeFieldSelector>
}

export type Wp_CertificationExpiryWarningRuleFilterInput = {
  alertWindow?: InputMaybe<IntQueryOperatorInput>
  companies?: InputMaybe<Wp_CertificationExpiryWarningRuleToCompanyConnectionFilterInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  dateCreated?: InputMaybe<StringQueryOperatorInput>
  dateModified?: InputMaybe<StringQueryOperatorInput>
  externalContacts?: InputMaybe<Wp_ExternalContactFilterListInput>
  internalContacts?: InputMaybe<Wp_CertificationExpiryWarningRuleToUserConnectionFilterInput>
  lastEditedBy?: InputMaybe<Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdgeFilterInput>
}

export type Wp_CertificationExpiryWarningRuleFilterListInput = {
  elemMatch?: InputMaybe<Wp_CertificationExpiryWarningRuleFilterInput>
}

export type Wp_CertificationExpiryWarningRuleSortInput = {
  alertWindow?: InputMaybe<SortOrderEnum>
  companies?: InputMaybe<Wp_CertificationExpiryWarningRuleToCompanyConnectionSortInput>
  databaseId?: InputMaybe<SortOrderEnum>
  dateCreated?: InputMaybe<SortOrderEnum>
  dateModified?: InputMaybe<SortOrderEnum>
  externalContacts?: InputMaybe<Wp_ExternalContactSortInput>
  internalContacts?: InputMaybe<Wp_CertificationExpiryWarningRuleToUserConnectionSortInput>
  lastEditedBy?: InputMaybe<Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdgeSortInput>
}

/** Connection between the CertificationExpiryWarningRule type and the Company type */
export type Wp_CertificationExpiryWarningRuleToCompanyConnection = {
  __typename?: 'Wp_CertificationExpiryWarningRuleToCompanyConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Company>>>
}

export type Wp_CertificationExpiryWarningRuleToCompanyConnectionFieldSelector =
  {
    nodes?: InputMaybe<Wp_CompanyFieldSelector>
  }

export type Wp_CertificationExpiryWarningRuleToCompanyConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CompanyFilterListInput>
}

export type Wp_CertificationExpiryWarningRuleToCompanyConnectionSortInput = {
  nodes?: InputMaybe<Wp_CompanySortInput>
}

/** Connection between the CertificationExpiryWarningRule type and the User type */
export type Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdge = {
  __typename?: 'Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_User>
}

export type Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdgeFieldSelector =
  {
    node?: InputMaybe<Wp_UserFieldSelector>
  }

export type Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdgeFilterInput =
  {
    node?: InputMaybe<Wp_UserFilterInput>
  }

export type Wp_CertificationExpiryWarningRuleToLastEditedByConnectionEdgeSortInput =
  {
    node?: InputMaybe<Wp_UserSortInput>
  }

/** Connection between the CertificationExpiryWarningRule type and the User type */
export type Wp_CertificationExpiryWarningRuleToUserConnection = {
  __typename?: 'Wp_CertificationExpiryWarningRuleToUserConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_User>>>
}

export type Wp_CertificationExpiryWarningRuleToUserConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_UserFieldSelector>
}

export type Wp_CertificationExpiryWarningRuleToUserConnectionFilterInput = {
  nodes?: InputMaybe<Wp_UserFilterListInput>
}

export type Wp_CertificationExpiryWarningRuleToUserConnectionSortInput = {
  nodes?: InputMaybe<Wp_UserSortInput>
}

/** A Gravity Forms   field. */
export type Wp_CheckboxField = Wp_FormField & {
  __typename?: 'Wp_CheckboxField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Checkbox field value. */
  checkboxValues?: Maybe<Array<Maybe<Wp_CheckboxFieldValue>>>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the checkbox field. */
  choices?: Maybe<Array<Maybe<Wp_CheckboxFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the checkbox field. */
  inputs?: Maybe<Array<Maybe<Wp_CheckboxInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Checkbox choice values. */
export type Wp_CheckboxFieldChoice = {
  __typename?: 'Wp_CheckboxFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** The individual properties for each element of the Checkbox value field. */
export type Wp_CheckboxFieldValue = {
  __typename?: 'Wp_CheckboxFieldValue'
  /** Input ID. */
  inputId?: Maybe<Scalars['Float']['output']>
  /** Input text. */
  text?: Maybe<Scalars['String']['output']>
  /** Input value. */
  value?: Maybe<Scalars['String']['output']>
}

/** Checkbox input values. */
export type Wp_CheckboxInputProperty = {
  __typename?: 'Wp_CheckboxInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms translation settings */
export type Wp_ChoiceTranslation = {
  __typename?: 'Wp_ChoiceTranslation'
  /** Text Translation of the Choice */
  choices?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
}

export type Wp_ChoiceTranslationFieldSelector = {
  choices?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  label?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  slug?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ChoiceTranslationFilterInput = {
  choices?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  label?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ChoiceTranslationFilterListInput = {
  elemMatch?: InputMaybe<Wp_ChoiceTranslationFilterInput>
}

export type Wp_ChoiceTranslationSortInput = {
  choices?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  label?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  slug?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_ComboSignatureField = Wp_FormField & {
  __typename?: 'Wp_ComboSignatureField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Color to be used for the background of the signature area. Can be any valid CSS color value. */
  backgroundColor?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Color to be used for the border around the signature area. Can be any valid CSS color value. */
  borderColor?: Maybe<Scalars['String']['output']>
  /** Border style to be used around the signature area. */
  borderStyle?: Maybe<Wp_SignatureFieldBorderStyleEnum>
  /** Width of the border around the signature area. */
  borderWidth?: Maybe<Wp_SignatureFieldBorderWidthEnum>
  /** Width of the signature field in pixels. */
  boxWidth?: Maybe<Scalars['Int']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the combo_signature field. */
  choices?: Maybe<Array<Maybe<Wp_ComboSignatureFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The maximum number of signatures required by the field. */
  maxSignatures?: Maybe<Scalars['Int']['output']>
  /** The minimum number of signatures required by the field. */
  minSignatures?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Color of the pen to be used for the signature. Can be any valid CSS color value. */
  penColor?: Maybe<Scalars['String']['output']>
  /** Size of the pen cursor. */
  penSize?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Combo signature field values. */
  signatureValues?: Maybe<Array<Maybe<Wp_ComboSignatureFieldValue>>>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Combo_signature choice values. */
export type Wp_ComboSignatureFieldChoice = {
  __typename?: 'Wp_ComboSignatureFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Value for a single input within a ComboSignature field. */
export type Wp_ComboSignatureFieldValue = {
  __typename?: 'Wp_ComboSignatureFieldValue'
  /** The url to the signature file. */
  signature?: Maybe<Scalars['String']['output']>
  /** The user */
  user?: Maybe<Wp_User>
  /** The User ID. */
  userId?: Maybe<Scalars['Int']['output']>
}

/** A Comment object */
export type Wp_Comment = Node &
  Wp_DatabaseIdentifier &
  Wp_Node & {
    __typename?: 'Wp_Comment'
    /** User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL. */
    agent?: Maybe<Scalars['String']['output']>
    /** The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL. */
    approved?: Maybe<Scalars['Boolean']['output']>
    /** The author of the comment */
    author?: Maybe<Wp_CommentToCommenterConnectionEdge>
    /** IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL. */
    authorIp?: Maybe<Scalars['String']['output']>
    children: Array<Node>
    /** Connection between the Comment type and the ContentNode type */
    commentedOn?: Maybe<Wp_CommentToContentNodeConnectionEdge>
    /** Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL. */
    content?: Maybe<Scalars['String']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL. */
    date?: Maybe<Scalars['Date']['output']>
    /** Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL. */
    dateGmt?: Maybe<Scalars['Date']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL. */
    karma?: Maybe<Scalars['Int']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** The database id of the parent comment node or null if it is the root comment */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent comment node. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Comment type and the Comment type */
    replies?: Maybe<Wp_CommentToCommentConnection>
    /** Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL. */
    type?: Maybe<Scalars['String']['output']>
    /** Connection between the Comment type and the Comment type */
    wpParent?: Maybe<Wp_CommentToParentCommentConnectionEdge>
  }

/** A Comment object */
export type Wp_CommentDateArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** A Comment object */
export type Wp_CommentDateGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** A Comment Author object */
export type Wp_CommentAuthor = Node &
  Wp_Commenter &
  Wp_Node & {
    __typename?: 'Wp_CommentAuthor'
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Wp_Avatar>
    children: Array<Node>
    /** Identifies the primary key from the database. */
    databaseId: Scalars['Int']['output']
    /** The email for the comment author */
    email?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** The name for the comment author. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** The url the comment author. */
    url?: Maybe<Scalars['String']['output']>
  }

export type Wp_CommentAuthorConnection = {
  __typename?: 'Wp_CommentAuthorConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CommentAuthorEdge>
  group: Array<Wp_CommentAuthorGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_CommentAuthor>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CommentAuthorConnectionDistinctArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorConnectionGroupArgs = {
  field: Wp_CommentAuthorFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CommentAuthorConnectionMaxArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorConnectionMinArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorConnectionSumArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorEdge = {
  __typename?: 'Wp_CommentAuthorEdge'
  next?: Maybe<Wp_CommentAuthor>
  node: Wp_CommentAuthor
  previous?: Maybe<Wp_CommentAuthor>
}

export type Wp_CommentAuthorFieldSelector = {
  avatar?: InputMaybe<Wp_AvatarFieldSelector>
  children?: InputMaybe<NodeFieldSelector>
  databaseId?: InputMaybe<FieldSelectorEnum>
  email?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  url?: InputMaybe<FieldSelectorEnum>
}

export type Wp_CommentAuthorFilterInput = {
  avatar?: InputMaybe<Wp_AvatarFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  email?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_CommentAuthorGroupConnection = {
  __typename?: 'Wp_CommentAuthorGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CommentAuthorEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_CommentAuthorGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_CommentAuthor>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CommentAuthorGroupConnectionDistinctArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorGroupConnectionGroupArgs = {
  field: Wp_CommentAuthorFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CommentAuthorGroupConnectionMaxArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorGroupConnectionMinArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorGroupConnectionSumArgs = {
  field: Wp_CommentAuthorFieldSelector
}

export type Wp_CommentAuthorSortInput = {
  avatar?: InputMaybe<Wp_AvatarSortInput>
  children?: InputMaybe<NodeSortInput>
  databaseId?: InputMaybe<SortOrderEnum>
  email?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  url?: InputMaybe<SortOrderEnum>
}

export type Wp_CommentConnection = {
  __typename?: 'Wp_CommentConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CommentEdge>
  group: Array<Wp_CommentGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Comment>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CommentConnectionDistinctArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentConnectionGroupArgs = {
  field: Wp_CommentFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CommentConnectionMaxArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentConnectionMinArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentConnectionSumArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentEdge = {
  __typename?: 'Wp_CommentEdge'
  next?: Maybe<Wp_Comment>
  node: Wp_Comment
  previous?: Maybe<Wp_Comment>
}

export type Wp_CommentFieldSelector = {
  agent?: InputMaybe<FieldSelectorEnum>
  approved?: InputMaybe<FieldSelectorEnum>
  author?: InputMaybe<Wp_CommentToCommenterConnectionEdgeFieldSelector>
  authorIp?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  commentedOn?: InputMaybe<Wp_CommentToContentNodeConnectionEdgeFieldSelector>
  content?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  date?: InputMaybe<FieldSelectorEnum>
  dateGmt?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  karma?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  parentDatabaseId?: InputMaybe<FieldSelectorEnum>
  parentId?: InputMaybe<FieldSelectorEnum>
  replies?: InputMaybe<Wp_CommentToCommentConnectionFieldSelector>
  type?: InputMaybe<FieldSelectorEnum>
  wpParent?: InputMaybe<Wp_CommentToParentCommentConnectionEdgeFieldSelector>
}

export type Wp_CommentFilterInput = {
  agent?: InputMaybe<StringQueryOperatorInput>
  approved?: InputMaybe<BooleanQueryOperatorInput>
  author?: InputMaybe<Wp_CommentToCommenterConnectionEdgeFilterInput>
  authorIp?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  commentedOn?: InputMaybe<Wp_CommentToContentNodeConnectionEdgeFilterInput>
  content?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  karma?: InputMaybe<IntQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  replies?: InputMaybe<Wp_CommentToCommentConnectionFilterInput>
  type?: InputMaybe<StringQueryOperatorInput>
  wpParent?: InputMaybe<Wp_CommentToParentCommentConnectionEdgeFilterInput>
}

export type Wp_CommentFilterListInput = {
  elemMatch?: InputMaybe<Wp_CommentFilterInput>
}

export type Wp_CommentGroupConnection = {
  __typename?: 'Wp_CommentGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CommentEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_CommentGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Comment>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CommentGroupConnectionDistinctArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentGroupConnectionGroupArgs = {
  field: Wp_CommentFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CommentGroupConnectionMaxArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentGroupConnectionMinArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentGroupConnectionSumArgs = {
  field: Wp_CommentFieldSelector
}

export type Wp_CommentSortInput = {
  agent?: InputMaybe<SortOrderEnum>
  approved?: InputMaybe<SortOrderEnum>
  author?: InputMaybe<Wp_CommentToCommenterConnectionEdgeSortInput>
  authorIp?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  commentedOn?: InputMaybe<Wp_CommentToContentNodeConnectionEdgeSortInput>
  content?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  date?: InputMaybe<SortOrderEnum>
  dateGmt?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  karma?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  parentDatabaseId?: InputMaybe<SortOrderEnum>
  parentId?: InputMaybe<SortOrderEnum>
  replies?: InputMaybe<Wp_CommentToCommentConnectionSortInput>
  type?: InputMaybe<SortOrderEnum>
  wpParent?: InputMaybe<Wp_CommentToParentCommentConnectionEdgeSortInput>
}

/** Connection between the Comment type and the Comment type */
export type Wp_CommentToCommentConnection = {
  __typename?: 'Wp_CommentToCommentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Comment>>>
}

export type Wp_CommentToCommentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CommentFieldSelector>
}

export type Wp_CommentToCommentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CommentFilterListInput>
}

export type Wp_CommentToCommentConnectionSortInput = {
  nodes?: InputMaybe<Wp_CommentSortInput>
}

/** Connection between the Comment type and the Commenter type */
export type Wp_CommentToCommenterConnectionEdge = {
  __typename?: 'Wp_CommentToCommenterConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Commenter>
}

export type Wp_CommentToCommenterConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_CommenterFieldSelector>
}

export type Wp_CommentToCommenterConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_CommenterFilterInput>
}

export type Wp_CommentToCommenterConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_CommenterSortInput>
}

/** Connection between the Comment type and the ContentNode type */
export type Wp_CommentToContentNodeConnectionEdge = {
  __typename?: 'Wp_CommentToContentNodeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_ContentNode>
}

export type Wp_CommentToContentNodeConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_CommentToContentNodeConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_ContentNodeFilterInput>
}

export type Wp_CommentToContentNodeConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Comment type and the Comment type */
export type Wp_CommentToParentCommentConnectionEdge = {
  __typename?: 'Wp_CommentToParentCommentConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Comment>
}

export type Wp_CommentToParentCommentConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_CommentFieldSelector>
}

export type Wp_CommentToParentCommentConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_CommentFilterInput>
}

export type Wp_CommentToParentCommentConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_CommentSortInput>
}

export type Wp_Commenter = {
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Wp_Avatar>
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output']
  /** The email address of the author of a comment. */
  email?: Maybe<Scalars['String']['output']>
  /** The globally unique identifier for the comment author. */
  id: Scalars['ID']['output']
  /** The name of the author of a comment. */
  name?: Maybe<Scalars['String']['output']>
  /** The url of the author of a comment. */
  url?: Maybe<Scalars['String']['output']>
}

export type Wp_CommenterFieldSelector = {
  avatar?: InputMaybe<Wp_AvatarFieldSelector>
  databaseId?: InputMaybe<FieldSelectorEnum>
  email?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  url?: InputMaybe<FieldSelectorEnum>
}

export type Wp_CommenterFilterInput = {
  avatar?: InputMaybe<Wp_AvatarFilterInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  email?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<IdQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_CommenterSortInput = {
  avatar?: InputMaybe<Wp_AvatarSortInput>
  databaseId?: InputMaybe<SortOrderEnum>
  email?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  url?: InputMaybe<SortOrderEnum>
}

/** The Company type */
export type Wp_Company = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Company'
    children: Array<Node>
    /** Connection between the Company type and the ContentNode type */
    contentNodes?: Maybe<Wp_CompanyToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the Company type and the Document type */
    documents?: Maybe<Wp_CompanyToDocumentConnection>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the Company type and the Lesson type */
    lessons?: Maybe<Wp_CompanyToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Company type and the Taxonomy type */
    taxonomy?: Maybe<Wp_CompanyToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_CompanyConnection = {
  __typename?: 'Wp_CompanyConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CompanyEdge>
  group: Array<Wp_CompanyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Company>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CompanyConnectionDistinctArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyConnectionGroupArgs = {
  field: Wp_CompanyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CompanyConnectionMaxArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyConnectionMinArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyConnectionSumArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyEdge = {
  __typename?: 'Wp_CompanyEdge'
  next?: Maybe<Wp_Company>
  node: Wp_Company
  previous?: Maybe<Wp_Company>
}

export type Wp_CompanyFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_CompanyToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  documents?: InputMaybe<Wp_CompanyToDocumentConnectionFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_CompanyToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_CompanyToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_CompanyFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_CompanyToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  documents?: InputMaybe<Wp_CompanyToDocumentConnectionFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_CompanyToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_CompanyToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_CompanyFilterListInput = {
  elemMatch?: InputMaybe<Wp_CompanyFilterInput>
}

export type Wp_CompanyGroupConnection = {
  __typename?: 'Wp_CompanyGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CompanyEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_CompanyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Company>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CompanyGroupConnectionDistinctArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyGroupConnectionGroupArgs = {
  field: Wp_CompanyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CompanyGroupConnectionMaxArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyGroupConnectionMinArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanyGroupConnectionSumArgs = {
  field: Wp_CompanyFieldSelector
}

export type Wp_CompanySortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_CompanyToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  documents?: InputMaybe<Wp_CompanyToDocumentConnectionSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_CompanyToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_CompanyToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Company type and the ContentNode type */
export type Wp_CompanyToContentNodeConnection = {
  __typename?: 'Wp_CompanyToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_CompanyToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_CompanyToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_CompanyToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Company type and the Document type */
export type Wp_CompanyToDocumentConnection = {
  __typename?: 'Wp_CompanyToDocumentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Document>>>
}

export type Wp_CompanyToDocumentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DocumentFieldSelector>
}

export type Wp_CompanyToDocumentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DocumentFilterListInput>
}

export type Wp_CompanyToDocumentConnectionSortInput = {
  nodes?: InputMaybe<Wp_DocumentSortInput>
}

/** Connection between the Company type and the Lesson type */
export type Wp_CompanyToLessonConnection = {
  __typename?: 'Wp_CompanyToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_CompanyToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_CompanyToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_CompanyToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the Company type and the Taxonomy type */
export type Wp_CompanyToTaxonomyConnectionEdge = {
  __typename?: 'Wp_CompanyToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_CompanyToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_CompanyToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_CompanyToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** Gravity Forms conditional logic. */
export type Wp_ConditionalLogic = {
  __typename?: 'Wp_ConditionalLogic'
  /** The type of action the conditional logic will perform. */
  actionType?: Maybe<Wp_ConditionalLogicActionTypeEnum>
  /** Determines how to the rules should be evaluated. */
  logicType?: Maybe<Wp_ConditionalLogicLogicTypeEnum>
  /** Conditional logic rules. */
  rules?: Maybe<Array<Maybe<Wp_ConditionalLogicRule>>>
}

/** The type of action the conditional logic will perform. */
export enum Wp_ConditionalLogicActionTypeEnum {
  Hide = 'HIDE',
  Show = 'SHOW',
}

export type Wp_ConditionalLogicActionTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_ConditionalLogicActionTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_ConditionalLogicActionTypeEnum>>>
  ne?: InputMaybe<Wp_ConditionalLogicActionTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_ConditionalLogicActionTypeEnum>>>
}

export type Wp_ConditionalLogicFieldSelector = {
  actionType?: InputMaybe<FieldSelectorEnum>
  logicType?: InputMaybe<FieldSelectorEnum>
  rules?: InputMaybe<Wp_ConditionalLogicRuleFieldSelector>
}

export type Wp_ConditionalLogicFilterInput = {
  actionType?: InputMaybe<Wp_ConditionalLogicActionTypeEnumQueryOperatorInput>
  logicType?: InputMaybe<Wp_ConditionalLogicLogicTypeEnumQueryOperatorInput>
  rules?: InputMaybe<Wp_ConditionalLogicRuleFilterListInput>
}

/** Determines how to the rules should be evaluated. */
export enum Wp_ConditionalLogicLogicTypeEnum {
  All = 'ALL',
  Any = 'ANY',
}

export type Wp_ConditionalLogicLogicTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_ConditionalLogicLogicTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_ConditionalLogicLogicTypeEnum>>>
  ne?: InputMaybe<Wp_ConditionalLogicLogicTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_ConditionalLogicLogicTypeEnum>>>
}

/** Gravity Forms conditional logic rule. */
export type Wp_ConditionalLogicRule = {
  __typename?: 'Wp_ConditionalLogicRule'
  /** Target field Id. Field that will have it’s value compared with the value property to determine if this rule is a match. */
  fieldId?: Maybe<Scalars['Float']['output']>
  /** Operator to be used when evaluating this rule. */
  operator?: Maybe<Wp_FormRuleOperatorEnum>
  /** The value to compare with field specified by fieldId. */
  value?: Maybe<Scalars['String']['output']>
}

export type Wp_ConditionalLogicRuleFieldSelector = {
  fieldId?: InputMaybe<FieldSelectorEnum>
  operator?: InputMaybe<FieldSelectorEnum>
  value?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ConditionalLogicRuleFilterInput = {
  fieldId?: InputMaybe<FloatQueryOperatorInput>
  operator?: InputMaybe<Wp_FormRuleOperatorEnumQueryOperatorInput>
  value?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ConditionalLogicRuleFilterListInput = {
  elemMatch?: InputMaybe<Wp_ConditionalLogicRuleFilterInput>
}

export type Wp_ConditionalLogicRuleSortInput = {
  fieldId?: InputMaybe<SortOrderEnum>
  operator?: InputMaybe<SortOrderEnum>
  value?: InputMaybe<SortOrderEnum>
}

export type Wp_ConditionalLogicSortInput = {
  actionType?: InputMaybe<SortOrderEnum>
  logicType?: InputMaybe<SortOrderEnum>
  rules?: InputMaybe<Wp_ConditionalLogicRuleSortInput>
}

export type Wp_Connection = {
  __typename?: 'Wp_Connection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_Edge>
  group: Array<Wp_GroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ConnectionDistinctArgs = {
  field: Wp_FieldSelector
}

export type Wp_ConnectionGroupArgs = {
  field: Wp_FieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ConnectionMaxArgs = {
  field: Wp_FieldSelector
}

export type Wp_ConnectionMinArgs = {
  field: Wp_FieldSelector
}

export type Wp_ConnectionSumArgs = {
  field: Wp_FieldSelector
}

/** A Gravity Forms   field. */
export type Wp_ConsentField = Wp_FormField & {
  __typename?: 'Wp_ConsentField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Text of the consent checkbox. */
  checkboxLabel?: Maybe<Scalars['String']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** Consent Field Specific Translations */
  consentTranslations?: Maybe<Array<Maybe<Wp_ConsentTranslation>>>
  /** Consent field value. This is `true` when consent is given, `false` when it is not. */
  consentValue?: Maybe<Scalars['Boolean']['output']>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Gravity Forms translation settings */
export type Wp_ConsentTranslation = {
  __typename?: 'Wp_ConsentTranslation'
  /** Text Translation of the chechbox label */
  checkboxLabel?: Maybe<Scalars['String']['output']>
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
}

export type Wp_ContentNode = {
  children: Array<Node>
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<Wp_ContentNodeToContentTypeConnectionEdge>
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output']
  /** The ID of the node in the database. */
  databaseId: Scalars['Int']['output']
  /** Post publishing date. */
  date?: Maybe<Scalars['Date']['output']>
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['Date']['output']>
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']['output']>
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']['output']>
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output']
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output']
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<Wp_ContentNodeToEditLastConnectionEdge>
  /** The permalink of the post */
  link?: Maybe<Scalars['String']['output']>
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['Date']['output']>
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['Date']['output']>
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']['output']>
  /** The current status of the object */
  status?: Maybe<Scalars['String']['output']>
  /** The template assigned to a node of content */
  template?: Maybe<Wp_ContentTemplate>
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

export type Wp_ContentNodeDateArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type Wp_ContentNodeDateGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type Wp_ContentNodeModifiedArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type Wp_ContentNodeModifiedGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type Wp_ContentNodeConnection = {
  __typename?: 'Wp_ContentNodeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ContentNodeEdge>
  group: Array<Wp_ContentNodeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ContentNode>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ContentNodeConnectionDistinctArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeConnectionGroupArgs = {
  field: Wp_ContentNodeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ContentNodeConnectionMaxArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeConnectionMinArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeConnectionSumArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeEdge = {
  __typename?: 'Wp_ContentNodeEdge'
  next?: Maybe<Wp_ContentNode>
  node: Wp_ContentNode
  previous?: Maybe<Wp_ContentNode>
}

export type Wp_ContentNodeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFieldSelector>
  contentTypeName?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  date?: InputMaybe<FieldSelectorEnum>
  dateGmt?: InputMaybe<FieldSelectorEnum>
  desiredSlug?: InputMaybe<FieldSelectorEnum>
  enclosure?: InputMaybe<FieldSelectorEnum>
  guid?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  modified?: InputMaybe<FieldSelectorEnum>
  modifiedGmt?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  status?: InputMaybe<FieldSelectorEnum>
  template?: InputMaybe<Wp_ContentTemplateFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ContentNodeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ContentNodeFilterListInput = {
  elemMatch?: InputMaybe<Wp_ContentNodeFilterInput>
}

export type Wp_ContentNodeGroupConnection = {
  __typename?: 'Wp_ContentNodeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ContentNodeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ContentNodeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ContentNode>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ContentNodeGroupConnectionDistinctArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeGroupConnectionGroupArgs = {
  field: Wp_ContentNodeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ContentNodeGroupConnectionMaxArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeGroupConnectionMinArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeGroupConnectionSumArgs = {
  field: Wp_ContentNodeFieldSelector
}

export type Wp_ContentNodeSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeSortInput>
  contentTypeName?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  date?: InputMaybe<SortOrderEnum>
  dateGmt?: InputMaybe<SortOrderEnum>
  desiredSlug?: InputMaybe<SortOrderEnum>
  enclosure?: InputMaybe<SortOrderEnum>
  guid?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeSortInput>
  link?: InputMaybe<SortOrderEnum>
  modified?: InputMaybe<SortOrderEnum>
  modifiedGmt?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  status?: InputMaybe<SortOrderEnum>
  template?: InputMaybe<Wp_ContentTemplateSortInput>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the ContentNode type and the ContentType type */
export type Wp_ContentNodeToContentTypeConnectionEdge = {
  __typename?: 'Wp_ContentNodeToContentTypeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_ContentType>
}

export type Wp_ContentNodeToContentTypeConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_ContentTypeFieldSelector>
}

export type Wp_ContentNodeToContentTypeConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_ContentTypeFilterInput>
}

export type Wp_ContentNodeToContentTypeConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_ContentTypeSortInput>
}

/** Connection between the ContentNode type and the User type */
export type Wp_ContentNodeToEditLastConnectionEdge = {
  __typename?: 'Wp_ContentNodeToEditLastConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_User>
}

export type Wp_ContentNodeToEditLastConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_UserFieldSelector>
}

export type Wp_ContentNodeToEditLastConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_UserFilterInput>
}

export type Wp_ContentNodeToEditLastConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_UserSortInput>
}

/** Connection between the ContentNode type and the User type */
export type Wp_ContentNodeToEditLockConnectionEdge = {
  __typename?: 'Wp_ContentNodeToEditLockConnectionEdge'
  /** The timestamp for when the node was last edited */
  lockTimestamp?: Maybe<Scalars['String']['output']>
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_User>
}

export type Wp_ContentTemplate = {
  /** The name of the template */
  templateName?: Maybe<Scalars['String']['output']>
}

export type Wp_ContentTemplateFieldSelector = {
  templateName?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ContentTemplateFilterInput = {
  templateName?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ContentTemplateSortInput = {
  templateName?: InputMaybe<SortOrderEnum>
}

/** An Post Type object */
export type Wp_ContentType = Node &
  Wp_Node &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_ContentType'
    /** The url path of the first page of the archive page for this content type. */
    archivePath?: Maybe<Scalars['String']['output']>
    /** Whether this content type should can be exported. */
    canExport?: Maybe<Scalars['Boolean']['output']>
    children: Array<Node>
    /** Connection between the ContentType type and the Taxonomy type */
    connectedTaxonomies?: Maybe<Wp_ContentTypeToTaxonomyConnection>
    /** Connection between the ContentType type and the ContentNode type */
    contentNodes?: Maybe<Wp_ContentTypeToContentNodeConnection>
    /** Whether content of this type should be deleted when the author of it is deleted from the system. */
    deleteWithUser?: Maybe<Scalars['Boolean']['output']>
    /** Description of the content type. */
    description?: Maybe<Scalars['String']['output']>
    /** Whether to exclude nodes of this content type from front end search results. */
    excludeFromSearch?: Maybe<Scalars['Boolean']['output']>
    /** The plural name of the content type within the GraphQL Schema. */
    graphqlPluralName?: Maybe<Scalars['String']['output']>
    /** The singular name of the content type within the GraphQL Schema. */
    graphqlSingleName?: Maybe<Scalars['String']['output']>
    /** Whether this content type should have archives. Content archives are generated by type and by date. */
    hasArchive?: Maybe<Scalars['Boolean']['output']>
    /** Whether the content type is hierarchical, for example pages. */
    hierarchical?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output']
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Display name of the content type. */
    label?: Maybe<Scalars['String']['output']>
    /** Details about the content type labels. */
    labels?: Maybe<Wp_PostTypeLabelDetails>
    /** The name of the icon file to display as a menu icon. */
    menuIcon?: Maybe<Scalars['String']['output']>
    /** The position of this post type in the menu. Only applies if show_in_menu is true. */
    menuPosition?: Maybe<Scalars['Int']['output']>
    /** The internal name of the post type. This should not be used for display purposes. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention. */
    public?: Maybe<Scalars['Boolean']['output']>
    /** Whether queries can be performed on the front end for the content type as part of parse_request(). */
    publiclyQueryable?: Maybe<Scalars['Boolean']['output']>
    /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
    restBase?: Maybe<Scalars['String']['output']>
    /** The REST Controller class assigned to handling this content type. */
    restControllerClass?: Maybe<Scalars['String']['output']>
    /** Makes this content type available via the admin bar. */
    showInAdminBar?: Maybe<Scalars['Boolean']['output']>
    /** Whether to add the content type to the GraphQL Schema. */
    showInGraphql?: Maybe<Scalars['Boolean']['output']>
    /** Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that. */
    showInMenu?: Maybe<Scalars['Boolean']['output']>
    /** Makes this content type available for selection in navigation menus. */
    showInNavMenus?: Maybe<Scalars['Boolean']['output']>
    /** Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace. */
    showInRest?: Maybe<Scalars['Boolean']['output']>
    /** Whether to generate and allow a UI for managing this content type in the admin. */
    showUi?: Maybe<Scalars['Boolean']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_ContentTypeConnection = {
  __typename?: 'Wp_ContentTypeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ContentTypeEdge>
  group: Array<Wp_ContentTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ContentType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ContentTypeConnectionDistinctArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeConnectionGroupArgs = {
  field: Wp_ContentTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ContentTypeConnectionMaxArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeConnectionMinArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeConnectionSumArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeEdge = {
  __typename?: 'Wp_ContentTypeEdge'
  next?: Maybe<Wp_ContentType>
  node: Wp_ContentType
  previous?: Maybe<Wp_ContentType>
}

export type Wp_ContentTypeFieldSelector = {
  archivePath?: InputMaybe<FieldSelectorEnum>
  canExport?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  connectedTaxonomies?: InputMaybe<Wp_ContentTypeToTaxonomyConnectionFieldSelector>
  contentNodes?: InputMaybe<Wp_ContentTypeToContentNodeConnectionFieldSelector>
  deleteWithUser?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  excludeFromSearch?: InputMaybe<FieldSelectorEnum>
  graphqlPluralName?: InputMaybe<FieldSelectorEnum>
  graphqlSingleName?: InputMaybe<FieldSelectorEnum>
  hasArchive?: InputMaybe<FieldSelectorEnum>
  hierarchical?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isFrontPage?: InputMaybe<FieldSelectorEnum>
  isPostsPage?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  label?: InputMaybe<FieldSelectorEnum>
  labels?: InputMaybe<Wp_PostTypeLabelDetailsFieldSelector>
  menuIcon?: InputMaybe<FieldSelectorEnum>
  menuPosition?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  public?: InputMaybe<FieldSelectorEnum>
  publiclyQueryable?: InputMaybe<FieldSelectorEnum>
  restBase?: InputMaybe<FieldSelectorEnum>
  restControllerClass?: InputMaybe<FieldSelectorEnum>
  showInAdminBar?: InputMaybe<FieldSelectorEnum>
  showInGraphql?: InputMaybe<FieldSelectorEnum>
  showInMenu?: InputMaybe<FieldSelectorEnum>
  showInNavMenus?: InputMaybe<FieldSelectorEnum>
  showInRest?: InputMaybe<FieldSelectorEnum>
  showUi?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ContentTypeFilterInput = {
  archivePath?: InputMaybe<StringQueryOperatorInput>
  canExport?: InputMaybe<BooleanQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  connectedTaxonomies?: InputMaybe<Wp_ContentTypeToTaxonomyConnectionFilterInput>
  contentNodes?: InputMaybe<Wp_ContentTypeToContentNodeConnectionFilterInput>
  deleteWithUser?: InputMaybe<BooleanQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  excludeFromSearch?: InputMaybe<BooleanQueryOperatorInput>
  graphqlPluralName?: InputMaybe<StringQueryOperatorInput>
  graphqlSingleName?: InputMaybe<StringQueryOperatorInput>
  hasArchive?: InputMaybe<BooleanQueryOperatorInput>
  hierarchical?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isFrontPage?: InputMaybe<BooleanQueryOperatorInput>
  isPostsPage?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  label?: InputMaybe<StringQueryOperatorInput>
  labels?: InputMaybe<Wp_PostTypeLabelDetailsFilterInput>
  menuIcon?: InputMaybe<StringQueryOperatorInput>
  menuPosition?: InputMaybe<IntQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  public?: InputMaybe<BooleanQueryOperatorInput>
  publiclyQueryable?: InputMaybe<BooleanQueryOperatorInput>
  restBase?: InputMaybe<StringQueryOperatorInput>
  restControllerClass?: InputMaybe<StringQueryOperatorInput>
  showInAdminBar?: InputMaybe<BooleanQueryOperatorInput>
  showInGraphql?: InputMaybe<BooleanQueryOperatorInput>
  showInMenu?: InputMaybe<BooleanQueryOperatorInput>
  showInNavMenus?: InputMaybe<BooleanQueryOperatorInput>
  showInRest?: InputMaybe<BooleanQueryOperatorInput>
  showUi?: InputMaybe<BooleanQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ContentTypeFilterListInput = {
  elemMatch?: InputMaybe<Wp_ContentTypeFilterInput>
}

export type Wp_ContentTypeGroupConnection = {
  __typename?: 'Wp_ContentTypeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ContentTypeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ContentTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ContentType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ContentTypeGroupConnectionDistinctArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeGroupConnectionGroupArgs = {
  field: Wp_ContentTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ContentTypeGroupConnectionMaxArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeGroupConnectionMinArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeGroupConnectionSumArgs = {
  field: Wp_ContentTypeFieldSelector
}

export type Wp_ContentTypeSortInput = {
  archivePath?: InputMaybe<SortOrderEnum>
  canExport?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  connectedTaxonomies?: InputMaybe<Wp_ContentTypeToTaxonomyConnectionSortInput>
  contentNodes?: InputMaybe<Wp_ContentTypeToContentNodeConnectionSortInput>
  deleteWithUser?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  excludeFromSearch?: InputMaybe<SortOrderEnum>
  graphqlPluralName?: InputMaybe<SortOrderEnum>
  graphqlSingleName?: InputMaybe<SortOrderEnum>
  hasArchive?: InputMaybe<SortOrderEnum>
  hierarchical?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isFrontPage?: InputMaybe<SortOrderEnum>
  isPostsPage?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  label?: InputMaybe<SortOrderEnum>
  labels?: InputMaybe<Wp_PostTypeLabelDetailsSortInput>
  menuIcon?: InputMaybe<SortOrderEnum>
  menuPosition?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  public?: InputMaybe<SortOrderEnum>
  publiclyQueryable?: InputMaybe<SortOrderEnum>
  restBase?: InputMaybe<SortOrderEnum>
  restControllerClass?: InputMaybe<SortOrderEnum>
  showInAdminBar?: InputMaybe<SortOrderEnum>
  showInGraphql?: InputMaybe<SortOrderEnum>
  showInMenu?: InputMaybe<SortOrderEnum>
  showInNavMenus?: InputMaybe<SortOrderEnum>
  showInRest?: InputMaybe<SortOrderEnum>
  showUi?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the ContentType type and the ContentNode type */
export type Wp_ContentTypeToContentNodeConnection = {
  __typename?: 'Wp_ContentTypeToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_ContentTypeToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_ContentTypeToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_ContentTypeToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the ContentType type and the Taxonomy type */
export type Wp_ContentTypeToTaxonomyConnection = {
  __typename?: 'Wp_ContentTypeToTaxonomyConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Taxonomy>>>
}

export type Wp_ContentTypeToTaxonomyConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_ContentTypeToTaxonomyConnectionFilterInput = {
  nodes?: InputMaybe<Wp_TaxonomyFilterListInput>
}

export type Wp_ContentTypeToTaxonomyConnectionSortInput = {
  nodes?: InputMaybe<Wp_TaxonomySortInput>
}

/** Data to populate Corrective Actions with when this field is deficient. */
export type Wp_CorrectiveActionSettings = {
  __typename?: 'Wp_CorrectiveActionSettings'
  /** Corrective action taken to correct the deficiency */
  actionTaken?: Maybe<Scalars['String']['output']>
  /** Deficiency text */
  deficiency?: Maybe<Scalars['String']['output']>
  /** Deficiency Type taxonomy terms */
  deficiencyType?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Severity */
  severity?: Maybe<Scalars['String']['output']>
}

/** The CorrectiveActionStatus type */
export type Wp_CorrectiveActionStatus = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_CorrectiveActionStatus'
    children: Array<Node>
    /** Connection between the CorrectiveActionStatus type and the ContentNode type */
    contentNodes?: Maybe<Wp_CorrectiveActionStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the CorrectiveActionStatus type and the Taxonomy type */
    taxonomy?: Maybe<Wp_CorrectiveActionStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_CorrectiveActionStatusConnection = {
  __typename?: 'Wp_CorrectiveActionStatusConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CorrectiveActionStatusEdge>
  group: Array<Wp_CorrectiveActionStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_CorrectiveActionStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CorrectiveActionStatusConnectionDistinctArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusConnectionGroupArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CorrectiveActionStatusConnectionMaxArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusConnectionMinArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusConnectionSumArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusEdge = {
  __typename?: 'Wp_CorrectiveActionStatusEdge'
  next?: Maybe<Wp_CorrectiveActionStatus>
  node: Wp_CorrectiveActionStatus
  previous?: Maybe<Wp_CorrectiveActionStatus>
}

export type Wp_CorrectiveActionStatusFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_CorrectiveActionStatusToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_CorrectiveActionStatusFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_CorrectiveActionStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_CorrectiveActionStatusGroupConnection = {
  __typename?: 'Wp_CorrectiveActionStatusGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_CorrectiveActionStatusEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_CorrectiveActionStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_CorrectiveActionStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_CorrectiveActionStatusGroupConnectionDistinctArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusGroupConnectionGroupArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_CorrectiveActionStatusGroupConnectionMaxArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusGroupConnectionMinArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusGroupConnectionSumArgs = {
  field: Wp_CorrectiveActionStatusFieldSelector
}

export type Wp_CorrectiveActionStatusSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_CorrectiveActionStatusToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the CorrectiveActionStatus type and the ContentNode type */
export type Wp_CorrectiveActionStatusToContentNodeConnection = {
  __typename?: 'Wp_CorrectiveActionStatusToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_CorrectiveActionStatusToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_CorrectiveActionStatusToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_CorrectiveActionStatusToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the CorrectiveActionStatus type and the Taxonomy type */
export type Wp_CorrectiveActionStatusToTaxonomyConnectionEdge = {
  __typename?: 'Wp_CorrectiveActionStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_CorrectiveActionStatusToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_DatabaseIdentifier = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output']
}

/** A Gravity Forms   field. */
export type Wp_DateField = Wp_FormField & {
  __typename?: 'Wp_DateField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines how the date field displays it’s calendar icon. */
  calendarIconType?: Maybe<Wp_FormFieldCalendarIconTypeEnum>
  /** Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom. */
  calendarIconUrl?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Determines how the date is displayed. */
  dateFormat?: Maybe<Wp_DateFieldFormatEnum>
  /** The type of date field to display. */
  dateType?: Maybe<Wp_DateFieldTypeEnum>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the date field. */
  inputs?: Maybe<Array<Maybe<Wp_DateInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** How the DateField date is displayed. */
export enum Wp_DateFieldFormatEnum {
  Dmy = 'DMY',
  DmyDash = 'DMY_DASH',
  DmyDot = 'DMY_DOT',
  Mdy = 'MDY',
  YmdDash = 'YMD_DASH',
  YmdDot = 'YMD_DOT',
  YmdSlash = 'YMD_SLASH',
}

/** Type of date field to display. */
export enum Wp_DateFieldTypeEnum {
  Dropdown = 'DROPDOWN',
  Field = 'FIELD',
  Picker = 'PICKER',
}

/** Date input values. */
export type Wp_DateInputProperty = {
  __typename?: 'Wp_DateInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** The template assigned to the node */
export type Wp_DefaultTemplate = Wp_ContentTemplate & {
  __typename?: 'Wp_DefaultTemplate'
  /** The name of the template */
  templateName?: Maybe<Scalars['String']['output']>
}

/** The DeficiencyType type */
export type Wp_DeficiencyType = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_DeficiencyType'
    children: Array<Node>
    /** Connection between the DeficiencyType type and the ContentNode type */
    contentNodes?: Maybe<Wp_DeficiencyTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the DeficiencyType type and the Taxonomy type */
    taxonomy?: Maybe<Wp_DeficiencyTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_DeficiencyTypeConnection = {
  __typename?: 'Wp_DeficiencyTypeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DeficiencyTypeEdge>
  group: Array<Wp_DeficiencyTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_DeficiencyType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DeficiencyTypeConnectionDistinctArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeConnectionGroupArgs = {
  field: Wp_DeficiencyTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DeficiencyTypeConnectionMaxArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeConnectionMinArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeConnectionSumArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeEdge = {
  __typename?: 'Wp_DeficiencyTypeEdge'
  next?: Maybe<Wp_DeficiencyType>
  node: Wp_DeficiencyType
  previous?: Maybe<Wp_DeficiencyType>
}

export type Wp_DeficiencyTypeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_DeficiencyTypeToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_DeficiencyTypeToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_DeficiencyTypeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_DeficiencyTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_DeficiencyTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_DeficiencyTypeGroupConnection = {
  __typename?: 'Wp_DeficiencyTypeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DeficiencyTypeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_DeficiencyTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_DeficiencyType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DeficiencyTypeGroupConnectionDistinctArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeGroupConnectionGroupArgs = {
  field: Wp_DeficiencyTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DeficiencyTypeGroupConnectionMaxArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeGroupConnectionMinArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeGroupConnectionSumArgs = {
  field: Wp_DeficiencyTypeFieldSelector
}

export type Wp_DeficiencyTypeSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_DeficiencyTypeToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_DeficiencyTypeToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the DeficiencyType type and the ContentNode type */
export type Wp_DeficiencyTypeToContentNodeConnection = {
  __typename?: 'Wp_DeficiencyTypeToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_DeficiencyTypeToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_DeficiencyTypeToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_DeficiencyTypeToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the DeficiencyType type and the Taxonomy type */
export type Wp_DeficiencyTypeToTaxonomyConnectionEdge = {
  __typename?: 'Wp_DeficiencyTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_DeficiencyTypeToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_DeficiencyTypeToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_DeficiencyTypeToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The discussion setting type */
export type Wp_DiscussionSettings = {
  __typename?: 'Wp_DiscussionSettings'
  /** Allow people to submit comments on new posts. */
  defaultCommentStatus?: Maybe<Scalars['String']['output']>
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  defaultPingStatus?: Maybe<Scalars['String']['output']>
}

export type Wp_DiscussionSettingsFieldSelector = {
  defaultCommentStatus?: InputMaybe<FieldSelectorEnum>
  defaultPingStatus?: InputMaybe<FieldSelectorEnum>
}

export type Wp_DiscussionSettingsFilterInput = {
  defaultCommentStatus?: InputMaybe<StringQueryOperatorInput>
  defaultPingStatus?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_DiscussionSettingsSortInput = {
  defaultCommentStatus?: InputMaybe<SortOrderEnum>
  defaultPingStatus?: InputMaybe<SortOrderEnum>
}

/** The Division type */
export type Wp_Division = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Division'
    children: Array<Node>
    /** Connection between the Division type and the ContentNode type */
    contentNodes?: Maybe<Wp_DivisionToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the Division type and the Document type */
    documents?: Maybe<Wp_DivisionToDocumentConnection>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the Division type and the Lesson type */
    lessons?: Maybe<Wp_DivisionToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Division type and the Taxonomy type */
    taxonomy?: Maybe<Wp_DivisionToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_DivisionConnection = {
  __typename?: 'Wp_DivisionConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DivisionEdge>
  group: Array<Wp_DivisionGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Division>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DivisionConnectionDistinctArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionConnectionGroupArgs = {
  field: Wp_DivisionFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DivisionConnectionMaxArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionConnectionMinArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionConnectionSumArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionEdge = {
  __typename?: 'Wp_DivisionEdge'
  next?: Maybe<Wp_Division>
  node: Wp_Division
  previous?: Maybe<Wp_Division>
}

export type Wp_DivisionFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_DivisionToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  documents?: InputMaybe<Wp_DivisionToDocumentConnectionFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_DivisionToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_DivisionToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_DivisionFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_DivisionToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  documents?: InputMaybe<Wp_DivisionToDocumentConnectionFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_DivisionToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_DivisionToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_DivisionFilterListInput = {
  elemMatch?: InputMaybe<Wp_DivisionFilterInput>
}

export type Wp_DivisionGroupConnection = {
  __typename?: 'Wp_DivisionGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DivisionEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_DivisionGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Division>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DivisionGroupConnectionDistinctArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionGroupConnectionGroupArgs = {
  field: Wp_DivisionFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DivisionGroupConnectionMaxArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionGroupConnectionMinArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionGroupConnectionSumArgs = {
  field: Wp_DivisionFieldSelector
}

export type Wp_DivisionSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_DivisionToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  documents?: InputMaybe<Wp_DivisionToDocumentConnectionSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_DivisionToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_DivisionToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Division type and the ContentNode type */
export type Wp_DivisionToContentNodeConnection = {
  __typename?: 'Wp_DivisionToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_DivisionToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_DivisionToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_DivisionToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Division type and the Document type */
export type Wp_DivisionToDocumentConnection = {
  __typename?: 'Wp_DivisionToDocumentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Document>>>
}

export type Wp_DivisionToDocumentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DocumentFieldSelector>
}

export type Wp_DivisionToDocumentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DocumentFilterListInput>
}

export type Wp_DivisionToDocumentConnectionSortInput = {
  nodes?: InputMaybe<Wp_DocumentSortInput>
}

/** Connection between the Division type and the Lesson type */
export type Wp_DivisionToLessonConnection = {
  __typename?: 'Wp_DivisionToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_DivisionToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_DivisionToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_DivisionToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the Division type and the Taxonomy type */
export type Wp_DivisionToTaxonomyConnectionEdge = {
  __typename?: 'Wp_DivisionToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_DivisionToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_DivisionToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_DivisionToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The Document type */
export type Wp_Document = Node &
  Wp_ContentNode &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_NodeWithAuthor &
  Wp_NodeWithContentEditor &
  Wp_NodeWithFeaturedImage &
  Wp_NodeWithTemplate &
  Wp_NodeWithTitle &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Document'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<Wp_NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    children: Array<Node>
    /** Connection between the Document type and the Company type */
    companies?: Maybe<Wp_DocumentToCompanyConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<Wp_ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['Date']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['Date']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the Document type and the Division type */
    divisions?: Maybe<Wp_DocumentToDivisionConnection>
    /** Connection between the Document type and the DocumentTag type */
    documentTags?: Maybe<Wp_DocumentToDocumentTagConnection>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Document type and the MediaItem type */
    fileAttachments?: Maybe<Wp_DocumentToMediaItemConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<Wp_ContentNodeToEditLastConnectionEdge>
    /** Connection between the Document type and the Lesson type */
    lessonDocumentConnectionConnection?: Maybe<Wp_DocumentToLessonConnection>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['Date']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['Date']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<Wp_ContentTemplate>
    /** Connection between the Document type and the TermNode type */
    terms?: Maybe<Wp_DocumentToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Document type */
export type Wp_DocumentDateArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The Document type */
export type Wp_DocumentDateGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The Document type */
export type Wp_DocumentModifiedArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The Document type */
export type Wp_DocumentModifiedGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type Wp_DocumentConnection = {
  __typename?: 'Wp_DocumentConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DocumentEdge>
  group: Array<Wp_DocumentGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Document>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DocumentConnectionDistinctArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentConnectionGroupArgs = {
  field: Wp_DocumentFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DocumentConnectionMaxArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentConnectionMinArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentConnectionSumArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentEdge = {
  __typename?: 'Wp_DocumentEdge'
  next?: Maybe<Wp_Document>
  node: Wp_Document
  previous?: Maybe<Wp_Document>
}

export type Wp_DocumentFieldSelector = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFieldSelector>
  authorDatabaseId?: InputMaybe<FieldSelectorEnum>
  authorId?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  companies?: InputMaybe<Wp_DocumentToCompanyConnectionFieldSelector>
  content?: InputMaybe<FieldSelectorEnum>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFieldSelector>
  contentTypeName?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  date?: InputMaybe<FieldSelectorEnum>
  dateGmt?: InputMaybe<FieldSelectorEnum>
  desiredSlug?: InputMaybe<FieldSelectorEnum>
  divisions?: InputMaybe<Wp_DocumentToDivisionConnectionFieldSelector>
  documentTags?: InputMaybe<Wp_DocumentToDocumentTagConnectionFieldSelector>
  enclosure?: InputMaybe<FieldSelectorEnum>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFieldSelector>
  featuredImageDatabaseId?: InputMaybe<FieldSelectorEnum>
  featuredImageId?: InputMaybe<FieldSelectorEnum>
  fileAttachments?: InputMaybe<Wp_DocumentToMediaItemConnectionFieldSelector>
  guid?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFieldSelector>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_DocumentToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  modified?: InputMaybe<FieldSelectorEnum>
  modifiedGmt?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  status?: InputMaybe<FieldSelectorEnum>
  template?: InputMaybe<Wp_ContentTemplateFieldSelector>
  terms?: InputMaybe<Wp_DocumentToTermNodeConnectionFieldSelector>
  title?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_DocumentFilterInput = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  companies?: InputMaybe<Wp_DocumentToCompanyConnectionFilterInput>
  content?: InputMaybe<StringQueryOperatorInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  divisions?: InputMaybe<Wp_DocumentToDivisionConnectionFilterInput>
  documentTags?: InputMaybe<Wp_DocumentToDocumentTagConnectionFilterInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput>
  featuredImageDatabaseId?: InputMaybe<IntQueryOperatorInput>
  featuredImageId?: InputMaybe<IdQueryOperatorInput>
  fileAttachments?: InputMaybe<Wp_DocumentToMediaItemConnectionFilterInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_DocumentToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  terms?: InputMaybe<Wp_DocumentToTermNodeConnectionFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_DocumentFilterListInput = {
  elemMatch?: InputMaybe<Wp_DocumentFilterInput>
}

export type Wp_DocumentGroupConnection = {
  __typename?: 'Wp_DocumentGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DocumentEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_DocumentGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Document>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DocumentGroupConnectionDistinctArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentGroupConnectionGroupArgs = {
  field: Wp_DocumentFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DocumentGroupConnectionMaxArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentGroupConnectionMinArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentGroupConnectionSumArgs = {
  field: Wp_DocumentFieldSelector
}

export type Wp_DocumentSortInput = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeSortInput>
  authorDatabaseId?: InputMaybe<SortOrderEnum>
  authorId?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  companies?: InputMaybe<Wp_DocumentToCompanyConnectionSortInput>
  content?: InputMaybe<SortOrderEnum>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeSortInput>
  contentTypeName?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  date?: InputMaybe<SortOrderEnum>
  dateGmt?: InputMaybe<SortOrderEnum>
  desiredSlug?: InputMaybe<SortOrderEnum>
  divisions?: InputMaybe<Wp_DocumentToDivisionConnectionSortInput>
  documentTags?: InputMaybe<Wp_DocumentToDocumentTagConnectionSortInput>
  enclosure?: InputMaybe<SortOrderEnum>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeSortInput>
  featuredImageDatabaseId?: InputMaybe<SortOrderEnum>
  featuredImageId?: InputMaybe<SortOrderEnum>
  fileAttachments?: InputMaybe<Wp_DocumentToMediaItemConnectionSortInput>
  guid?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeSortInput>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_DocumentToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  modified?: InputMaybe<SortOrderEnum>
  modifiedGmt?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  status?: InputMaybe<SortOrderEnum>
  template?: InputMaybe<Wp_ContentTemplateSortInput>
  terms?: InputMaybe<Wp_DocumentToTermNodeConnectionSortInput>
  title?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** The DocumentTag type */
export type Wp_DocumentTag = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_DocumentTag'
    children: Array<Node>
    /** Connection between the DocumentTag type and the ContentNode type */
    contentNodes?: Maybe<Wp_DocumentTagToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the DocumentTag type and the Document type */
    documents?: Maybe<Wp_DocumentTagToDocumentConnection>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the DocumentTag type and the Taxonomy type */
    taxonomy?: Maybe<Wp_DocumentTagToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_DocumentTagConnection = {
  __typename?: 'Wp_DocumentTagConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DocumentTagEdge>
  group: Array<Wp_DocumentTagGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_DocumentTag>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DocumentTagConnectionDistinctArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagConnectionGroupArgs = {
  field: Wp_DocumentTagFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DocumentTagConnectionMaxArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagConnectionMinArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagConnectionSumArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagEdge = {
  __typename?: 'Wp_DocumentTagEdge'
  next?: Maybe<Wp_DocumentTag>
  node: Wp_DocumentTag
  previous?: Maybe<Wp_DocumentTag>
}

export type Wp_DocumentTagFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_DocumentTagToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  documents?: InputMaybe<Wp_DocumentTagToDocumentConnectionFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_DocumentTagToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_DocumentTagFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_DocumentTagToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  documents?: InputMaybe<Wp_DocumentTagToDocumentConnectionFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_DocumentTagToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_DocumentTagFilterListInput = {
  elemMatch?: InputMaybe<Wp_DocumentTagFilterInput>
}

export type Wp_DocumentTagGroupConnection = {
  __typename?: 'Wp_DocumentTagGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_DocumentTagEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_DocumentTagGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_DocumentTag>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_DocumentTagGroupConnectionDistinctArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagGroupConnectionGroupArgs = {
  field: Wp_DocumentTagFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_DocumentTagGroupConnectionMaxArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagGroupConnectionMinArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagGroupConnectionSumArgs = {
  field: Wp_DocumentTagFieldSelector
}

export type Wp_DocumentTagSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_DocumentTagToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  documents?: InputMaybe<Wp_DocumentTagToDocumentConnectionSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_DocumentTagToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the DocumentTag type and the ContentNode type */
export type Wp_DocumentTagToContentNodeConnection = {
  __typename?: 'Wp_DocumentTagToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_DocumentTagToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_DocumentTagToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_DocumentTagToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the DocumentTag type and the Document type */
export type Wp_DocumentTagToDocumentConnection = {
  __typename?: 'Wp_DocumentTagToDocumentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Document>>>
}

export type Wp_DocumentTagToDocumentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DocumentFieldSelector>
}

export type Wp_DocumentTagToDocumentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DocumentFilterListInput>
}

export type Wp_DocumentTagToDocumentConnectionSortInput = {
  nodes?: InputMaybe<Wp_DocumentSortInput>
}

/** Connection between the DocumentTag type and the Taxonomy type */
export type Wp_DocumentTagToTaxonomyConnectionEdge = {
  __typename?: 'Wp_DocumentTagToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_DocumentTagToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_DocumentTagToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_DocumentTagToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** Connection between the Document type and the Company type */
export type Wp_DocumentToCompanyConnection = {
  __typename?: 'Wp_DocumentToCompanyConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Company>>>
}

export type Wp_DocumentToCompanyConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CompanyFieldSelector>
}

export type Wp_DocumentToCompanyConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CompanyFilterListInput>
}

export type Wp_DocumentToCompanyConnectionSortInput = {
  nodes?: InputMaybe<Wp_CompanySortInput>
}

/** Connection between the Document type and the Division type */
export type Wp_DocumentToDivisionConnection = {
  __typename?: 'Wp_DocumentToDivisionConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Division>>>
}

export type Wp_DocumentToDivisionConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DivisionFieldSelector>
}

export type Wp_DocumentToDivisionConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DivisionFilterListInput>
}

export type Wp_DocumentToDivisionConnectionSortInput = {
  nodes?: InputMaybe<Wp_DivisionSortInput>
}

/** Connection between the Document type and the DocumentTag type */
export type Wp_DocumentToDocumentTagConnection = {
  __typename?: 'Wp_DocumentToDocumentTagConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_DocumentTag>>>
}

export type Wp_DocumentToDocumentTagConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DocumentTagFieldSelector>
}

export type Wp_DocumentToDocumentTagConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DocumentTagFilterListInput>
}

export type Wp_DocumentToDocumentTagConnectionSortInput = {
  nodes?: InputMaybe<Wp_DocumentTagSortInput>
}

/** Connection between the Document type and the Lesson type */
export type Wp_DocumentToLessonConnection = {
  __typename?: 'Wp_DocumentToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_DocumentToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_DocumentToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_DocumentToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the Document type and the MediaItem type */
export type Wp_DocumentToMediaItemConnection = {
  __typename?: 'Wp_DocumentToMediaItemConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_MediaItem>>>
}

export type Wp_DocumentToMediaItemConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_MediaItemFieldSelector>
}

export type Wp_DocumentToMediaItemConnectionFilterInput = {
  nodes?: InputMaybe<Wp_MediaItemFilterListInput>
}

export type Wp_DocumentToMediaItemConnectionSortInput = {
  nodes?: InputMaybe<Wp_MediaItemSortInput>
}

/** Connection between the Document type and the TermNode type */
export type Wp_DocumentToTermNodeConnection = {
  __typename?: 'Wp_DocumentToTermNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_TermNode>>>
}

export type Wp_DocumentToTermNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_TermNodeFieldSelector>
}

export type Wp_DocumentToTermNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_TermNodeFilterListInput>
}

export type Wp_DocumentToTermNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_TermNodeSortInput>
}

export type Wp_Edge = {
  __typename?: 'Wp_Edge'
  next?: Maybe<Wp_>
  node: Wp_
  previous?: Maybe<Wp_>
}

/** A Gravity Forms   field. */
export type Wp_EmailField = Wp_FormField & {
  __typename?: 'Wp_EmailField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Determines whether the Confirm Email field is active. */
  hasEmailConfirmation?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the email field. */
  inputs?: Maybe<Array<Maybe<Wp_EmailInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Email input values. */
export type Wp_EmailInputProperty = {
  __typename?: 'Wp_EmailInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** The EquipmentStatus type */
export type Wp_EquipmentStatus = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_EquipmentStatus'
    children: Array<Node>
    /** Connection between the EquipmentStatus type and the ContentNode type */
    contentNodes?: Maybe<Wp_EquipmentStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the EquipmentStatus type and the Taxonomy type */
    taxonomy?: Maybe<Wp_EquipmentStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_EquipmentStatusConnection = {
  __typename?: 'Wp_EquipmentStatusConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_EquipmentStatusEdge>
  group: Array<Wp_EquipmentStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_EquipmentStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_EquipmentStatusConnectionDistinctArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusConnectionGroupArgs = {
  field: Wp_EquipmentStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_EquipmentStatusConnectionMaxArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusConnectionMinArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusConnectionSumArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusEdge = {
  __typename?: 'Wp_EquipmentStatusEdge'
  next?: Maybe<Wp_EquipmentStatus>
  node: Wp_EquipmentStatus
  previous?: Maybe<Wp_EquipmentStatus>
}

export type Wp_EquipmentStatusFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_EquipmentStatusToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_EquipmentStatusToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_EquipmentStatusFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_EquipmentStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_EquipmentStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_EquipmentStatusGroupConnection = {
  __typename?: 'Wp_EquipmentStatusGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_EquipmentStatusEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_EquipmentStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_EquipmentStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_EquipmentStatusGroupConnectionDistinctArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusGroupConnectionGroupArgs = {
  field: Wp_EquipmentStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_EquipmentStatusGroupConnectionMaxArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusGroupConnectionMinArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusGroupConnectionSumArgs = {
  field: Wp_EquipmentStatusFieldSelector
}

export type Wp_EquipmentStatusSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_EquipmentStatusToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_EquipmentStatusToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the EquipmentStatus type and the ContentNode type */
export type Wp_EquipmentStatusToContentNodeConnection = {
  __typename?: 'Wp_EquipmentStatusToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_EquipmentStatusToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_EquipmentStatusToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_EquipmentStatusToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the EquipmentStatus type and the Taxonomy type */
export type Wp_EquipmentStatusToTaxonomyConnectionEdge = {
  __typename?: 'Wp_EquipmentStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_EquipmentStatusToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_EquipmentStatusToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_EquipmentStatusToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The EquipmentTag type */
export type Wp_EquipmentTag = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_EquipmentTag'
    children: Array<Node>
    /** Connection between the EquipmentTag type and the ContentNode type */
    contentNodes?: Maybe<Wp_EquipmentTagToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the EquipmentTag type and the Taxonomy type */
    taxonomy?: Maybe<Wp_EquipmentTagToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_EquipmentTagConnection = {
  __typename?: 'Wp_EquipmentTagConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_EquipmentTagEdge>
  group: Array<Wp_EquipmentTagGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_EquipmentTag>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_EquipmentTagConnectionDistinctArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagConnectionGroupArgs = {
  field: Wp_EquipmentTagFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_EquipmentTagConnectionMaxArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagConnectionMinArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagConnectionSumArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagEdge = {
  __typename?: 'Wp_EquipmentTagEdge'
  next?: Maybe<Wp_EquipmentTag>
  node: Wp_EquipmentTag
  previous?: Maybe<Wp_EquipmentTag>
}

export type Wp_EquipmentTagFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_EquipmentTagToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_EquipmentTagToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_EquipmentTagFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_EquipmentTagToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_EquipmentTagToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_EquipmentTagGroupConnection = {
  __typename?: 'Wp_EquipmentTagGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_EquipmentTagEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_EquipmentTagGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_EquipmentTag>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_EquipmentTagGroupConnectionDistinctArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagGroupConnectionGroupArgs = {
  field: Wp_EquipmentTagFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_EquipmentTagGroupConnectionMaxArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagGroupConnectionMinArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagGroupConnectionSumArgs = {
  field: Wp_EquipmentTagFieldSelector
}

export type Wp_EquipmentTagSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_EquipmentTagToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_EquipmentTagToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the EquipmentTag type and the ContentNode type */
export type Wp_EquipmentTagToContentNodeConnection = {
  __typename?: 'Wp_EquipmentTagToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_EquipmentTagToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_EquipmentTagToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_EquipmentTagToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the EquipmentTag type and the Taxonomy type */
export type Wp_EquipmentTagToTaxonomyConnectionEdge = {
  __typename?: 'Wp_EquipmentTagToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_EquipmentTagToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_EquipmentTagToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_EquipmentTagToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** External contact data */
export type Wp_ExternalContact = {
  __typename?: 'Wp_ExternalContact'
  /** The contact email */
  email?: Maybe<Scalars['String']['output']>
  /** The contact name */
  name?: Maybe<Scalars['String']['output']>
}

export type Wp_ExternalContactFieldSelector = {
  email?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ExternalContactFilterInput = {
  email?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ExternalContactFilterListInput = {
  elemMatch?: InputMaybe<Wp_ExternalContactFilterInput>
}

export type Wp_ExternalContactSortInput = {
  email?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
}

/** The FieldId type */
export type Wp_FieldId = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_FieldId'
    children: Array<Node>
    /** Connection between the FieldId type and the ContentNode type */
    contentNodes?: Maybe<Wp_FieldIdToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the FieldId type and the Taxonomy type */
    taxonomy?: Maybe<Wp_FieldIdToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_FieldIdConnection = {
  __typename?: 'Wp_FieldIdConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_FieldIdEdge>
  group: Array<Wp_FieldIdGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_FieldId>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_FieldIdConnectionDistinctArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdConnectionGroupArgs = {
  field: Wp_FieldIdFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_FieldIdConnectionMaxArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdConnectionMinArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdConnectionSumArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdEdge = {
  __typename?: 'Wp_FieldIdEdge'
  next?: Maybe<Wp_FieldId>
  node: Wp_FieldId
  previous?: Maybe<Wp_FieldId>
}

export type Wp_FieldIdFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_FieldIdToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_FieldIdToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FieldIdFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_FieldIdToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_FieldIdToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FieldIdGroupConnection = {
  __typename?: 'Wp_FieldIdGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_FieldIdEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_FieldIdGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_FieldId>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_FieldIdGroupConnectionDistinctArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdGroupConnectionGroupArgs = {
  field: Wp_FieldIdFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_FieldIdGroupConnectionMaxArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdGroupConnectionMinArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdGroupConnectionSumArgs = {
  field: Wp_FieldIdFieldSelector
}

export type Wp_FieldIdSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_FieldIdToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_FieldIdToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the FieldId type and the ContentNode type */
export type Wp_FieldIdToContentNodeConnection = {
  __typename?: 'Wp_FieldIdToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_FieldIdToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_FieldIdToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_FieldIdToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the FieldId type and the Taxonomy type */
export type Wp_FieldIdToTaxonomyConnectionEdge = {
  __typename?: 'Wp_FieldIdToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_FieldIdToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_FieldIdToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_FieldIdToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_FieldSelector = {
  allSettings?: InputMaybe<Wp_SettingsFieldSelector>
  certificationExpiryWarnings?: InputMaybe<Wp_CertificationExpiryWarningRuleFieldSelector>
  children?: InputMaybe<NodeFieldSelector>
  defaultLanguage?: InputMaybe<Wp_LanguageFieldSelector>
  discussionSettings?: InputMaybe<Wp_DiscussionSettingsFieldSelector>
  generalSettings?: InputMaybe<Wp_GeneralSettingsFieldSelector>
  gfSettings?: InputMaybe<Wp_GfSettingsFieldSelector>
  harnessSettings?: InputMaybe<Wp_HarnessSettingsFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  incidentReportData?: InputMaybe<Wp_IncidentReportDataFieldSelector>
  integrationConfig?: InputMaybe<Wp_IntegrationConfigFieldSelector>
  internal?: InputMaybe<InternalFieldSelector>
  languages?: InputMaybe<Wp_LanguageFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  readingSettings?: InputMaybe<Wp_ReadingSettingsFieldSelector>
  schemaMd5?: InputMaybe<FieldSelectorEnum>
  viewer?: InputMaybe<Wp_UserFieldSelector>
  wpGatsby?: InputMaybe<Wp_WpGatsbyFieldSelector>
  writingSettings?: InputMaybe<Wp_WritingSettingsFieldSelector>
}

/** A Gravity Forms   field. */
export type Wp_FileUploadField = Wp_FormField & {
  __typename?: 'Wp_FileUploadField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Indicates whether multiple files may be uploaded. */
  canAcceptMultipleFiles?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** File upload value */
  fileUploadValues?: Maybe<Array<Maybe<Wp_FileUploadFieldValue>>>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The maximum size (in MB) an uploaded file may be . */
  maxFileSize?: Maybe<Scalars['Int']['output']>
  /** When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded. */
  maxFiles?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The individual file properties from an uploaded file. */
export type Wp_FileUploadFieldValue = {
  __typename?: 'Wp_FileUploadFieldValue'
  /** The path to the parent directory of the file. */
  basePath?: Maybe<Scalars['String']['output']>
  /** The base url to the parent directory of the file. */
  baseUrl?: Maybe<Scalars['String']['output']>
  /** The filename. */
  filename?: Maybe<Scalars['String']['output']>
  /** The url to the file. */
  url?: Maybe<Scalars['String']['output']>
}

export type Wp_FilterInput = {
  allSettings?: InputMaybe<Wp_SettingsFilterInput>
  certificationExpiryWarnings?: InputMaybe<Wp_CertificationExpiryWarningRuleFilterListInput>
  children?: InputMaybe<NodeFilterListInput>
  defaultLanguage?: InputMaybe<Wp_LanguageFilterInput>
  discussionSettings?: InputMaybe<Wp_DiscussionSettingsFilterInput>
  generalSettings?: InputMaybe<Wp_GeneralSettingsFilterInput>
  gfSettings?: InputMaybe<Wp_GfSettingsFilterInput>
  harnessSettings?: InputMaybe<Wp_HarnessSettingsFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  incidentReportData?: InputMaybe<Wp_IncidentReportDataFilterInput>
  integrationConfig?: InputMaybe<Wp_IntegrationConfigFilterInput>
  internal?: InputMaybe<InternalFilterInput>
  languages?: InputMaybe<Wp_LanguageFilterListInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  readingSettings?: InputMaybe<Wp_ReadingSettingsFilterInput>
  schemaMd5?: InputMaybe<StringQueryOperatorInput>
  viewer?: InputMaybe<Wp_UserFilterInput>
  wpGatsby?: InputMaybe<Wp_WpGatsbyFilterInput>
  writingSettings?: InputMaybe<Wp_WritingSettingsFilterInput>
}

/** A Gravity Forms   field. */
export type Wp_FollowUpField = Wp_FormField & {
  __typename?: 'Wp_FollowUpField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** Content of an HTML block field to be displayed on the form. */
  content?: Maybe<Scalars['String']['output']>
  /** Data to populate Corrective Actions with when this field is deficient */
  correctiveActionSettings?: Maybe<Array<Maybe<Wp_CorrectiveActionSettings>>>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Form to be used for follow-up. */
  followUpForm?: Maybe<Wp_GfForm>
  /** ID of the form to be used for follow-up. */
  followUpFormId?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the default margins are turned on to align the HTML content with other fields. */
  hasMargins?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Gravity Forms button. */
export type Wp_FormButton = {
  __typename?: 'Wp_FormButton'
  /** Controls when the form button should be visible based on values selected on the form. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']['output']>
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<Wp_FormButtonTypeEnum>
}

/** Type of button to be displayed. Default is TEXT. */
export enum Wp_FormButtonTypeEnum {
  Image = 'IMAGE',
  Text = 'TEXT',
}

export type Wp_FormButtonTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormButtonTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormButtonTypeEnum>>>
  ne?: InputMaybe<Wp_FormButtonTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormButtonTypeEnum>>>
}

/** Properties for all the email notifications which exist for a form. */
export type Wp_FormConfirmation = {
  __typename?: 'Wp_FormConfirmation'
  /** Controls which form confirmation message should be displayed. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** ID. */
  id?: Maybe<Scalars['String']['output']>
  /** Whether the confirmation is active or inactive. The default confirmation is always active. */
  isActive?: Maybe<Scalars['Boolean']['output']>
  /** Whether the confirmation message should be formatted so that paragraphs are automatically added for new lines. */
  isAutoformatted?: Maybe<Scalars['Boolean']['output']>
  /** Whether this is the default confirmation. */
  isDefault?: Maybe<Scalars['Boolean']['output']>
  /** Contains the confirmation message that will be displayed. Only applicable when type is set to &quot;MESSAGE&quot;. */
  message?: Maybe<Scalars['String']['output']>
  /** The confirmation name. */
  name?: Maybe<Scalars['String']['output']>
  /** Contains the Id of the WordPress page that the browser will be redirected to. Only applicable when type is set to `PAGE`. */
  pageId?: Maybe<Scalars['Int']['output']>
  /** Contains the query string to be appended to the redirection url. Only applicable when type is set to `REDIRECT`. */
  queryString?: Maybe<Scalars['String']['output']>
  /** Determines the type of confirmation to be used. */
  type?: Maybe<Wp_FormConfirmationTypeEnum>
  /** Contains the URL that the browser will be redirected to. Only applicable when type is set to `REDIRECT`. */
  url?: Maybe<Scalars['String']['output']>
}

export type Wp_FormConfirmationFieldSelector = {
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  isActive?: InputMaybe<FieldSelectorEnum>
  isAutoformatted?: InputMaybe<FieldSelectorEnum>
  isDefault?: InputMaybe<FieldSelectorEnum>
  message?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  pageId?: InputMaybe<FieldSelectorEnum>
  queryString?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
  url?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormConfirmationFilterInput = {
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFilterInput>
  id?: InputMaybe<StringQueryOperatorInput>
  isActive?: InputMaybe<BooleanQueryOperatorInput>
  isAutoformatted?: InputMaybe<BooleanQueryOperatorInput>
  isDefault?: InputMaybe<BooleanQueryOperatorInput>
  message?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  pageId?: InputMaybe<IntQueryOperatorInput>
  queryString?: InputMaybe<StringQueryOperatorInput>
  type?: InputMaybe<Wp_FormConfirmationTypeEnumQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormConfirmationFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormConfirmationFilterInput>
}

export type Wp_FormConfirmationSortInput = {
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicSortInput>
  id?: InputMaybe<SortOrderEnum>
  isActive?: InputMaybe<SortOrderEnum>
  isAutoformatted?: InputMaybe<SortOrderEnum>
  isDefault?: InputMaybe<SortOrderEnum>
  message?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  pageId?: InputMaybe<SortOrderEnum>
  queryString?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
  url?: InputMaybe<SortOrderEnum>
}

/** Type of form confirmation to be used. */
export enum Wp_FormConfirmationTypeEnum {
  Message = 'MESSAGE',
  Page = 'PAGE',
  Redirect = 'REDIRECT',
}

export type Wp_FormConfirmationTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormConfirmationTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormConfirmationTypeEnum>>>
  ne?: InputMaybe<Wp_FormConfirmationTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormConfirmationTypeEnum>>>
}

/** The policies governing which entry data to include when erasing and exporting personal data. */
export type Wp_FormDataPolicies = {
  __typename?: 'Wp_FormDataPolicies'
  /** Whether entry data from this form is included when erasing and exporting personal data. */
  canExportAndErase?: Maybe<Scalars['Boolean']['output']>
  /** The individual entry data exporting and erasing policies. */
  entryData?: Maybe<Array<Maybe<Wp_FormEntryDataPolicy>>>
  /** The database ID of the Gravity Forms field used to identify the user. */
  identificationFieldDatabaseId?: Maybe<Scalars['Int']['output']>
}

export type Wp_FormDataPoliciesFieldSelector = {
  canExportAndErase?: InputMaybe<FieldSelectorEnum>
  entryData?: InputMaybe<Wp_FormEntryDataPolicyFieldSelector>
  identificationFieldDatabaseId?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormDataPoliciesFilterInput = {
  canExportAndErase?: InputMaybe<BooleanQueryOperatorInput>
  entryData?: InputMaybe<Wp_FormEntryDataPolicyFilterListInput>
  identificationFieldDatabaseId?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_FormDataPoliciesSortInput = {
  canExportAndErase?: InputMaybe<SortOrderEnum>
  entryData?: InputMaybe<Wp_FormEntryDataPolicySortInput>
  identificationFieldDatabaseId?: InputMaybe<SortOrderEnum>
}

/** Determines where the field description is displayed relative to the field. */
export enum Wp_FormDescriptionPlacementEnum {
  Above = 'ABOVE',
  Below = 'BELOW',
}

export type Wp_FormDescriptionPlacementEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormDescriptionPlacementEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormDescriptionPlacementEnum>>>
  ne?: InputMaybe<Wp_FormDescriptionPlacementEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormDescriptionPlacementEnum>>>
}

/** The individual entry data exporting and erasing policies. */
export type Wp_FormEntryDataPolicy = {
  __typename?: 'Wp_FormEntryDataPolicy'
  /** The array key for the Gravity Forms Entry. */
  key?: Maybe<Scalars['String']['output']>
  /** Whether this field should be included when erasing personal data. */
  shouldErase?: Maybe<Scalars['Boolean']['output']>
  /** Whether this field should be included when exporting personal data. */
  shouldExport?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_FormEntryDataPolicyFieldSelector = {
  key?: InputMaybe<FieldSelectorEnum>
  shouldErase?: InputMaybe<FieldSelectorEnum>
  shouldExport?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormEntryDataPolicyFilterInput = {
  key?: InputMaybe<StringQueryOperatorInput>
  shouldErase?: InputMaybe<BooleanQueryOperatorInput>
  shouldExport?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_FormEntryDataPolicyFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormEntryDataPolicyFilterInput>
}

export type Wp_FormEntryDataPolicySortInput = {
  key?: InputMaybe<SortOrderEnum>
  shouldErase?: InputMaybe<SortOrderEnum>
  shouldExport?: InputMaybe<SortOrderEnum>
}

/** Gravity Forms form entry limititation details. */
export type Wp_FormEntryLimits = {
  __typename?: 'Wp_FormEntryLimits'
  /** Whether the form has a limit on the number of submissions. */
  hasLimit?: Maybe<Scalars['Boolean']['output']>
  /** Message that will be displayed when the maximum number of submissions have been reached. */
  limitReachedMessage?: Maybe<Scalars['String']['output']>
  /** The time period during which submissions are allowed. */
  limitationPeriod?: Maybe<Wp_FormLimitEntriesPeriodEnum>
  /** The number of submissions allowed. */
  maxEntries?: Maybe<Scalars['Int']['output']>
}

export type Wp_FormEntryLimitsFieldSelector = {
  hasLimit?: InputMaybe<FieldSelectorEnum>
  limitReachedMessage?: InputMaybe<FieldSelectorEnum>
  limitationPeriod?: InputMaybe<FieldSelectorEnum>
  maxEntries?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormEntryLimitsFilterInput = {
  hasLimit?: InputMaybe<BooleanQueryOperatorInput>
  limitReachedMessage?: InputMaybe<StringQueryOperatorInput>
  limitationPeriod?: InputMaybe<Wp_FormLimitEntriesPeriodEnumQueryOperatorInput>
  maxEntries?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_FormEntryLimitsSortInput = {
  hasLimit?: InputMaybe<SortOrderEnum>
  limitReachedMessage?: InputMaybe<SortOrderEnum>
  limitationPeriod?: InputMaybe<SortOrderEnum>
  maxEntries?: InputMaybe<SortOrderEnum>
}

export type Wp_FormField = {
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** How the date field displays its calendar icon. */
export enum Wp_FormFieldCalendarIconTypeEnum {
  Calendar = 'CALENDAR',
  Custom = 'CUSTOM',
  None = 'NONE',
}

/** The form field-specifc policies for exporting and erasing personal data. */
export type Wp_FormFieldDataPolicy = {
  __typename?: 'Wp_FormFieldDataPolicy'
  /** Whether this field is used to identify the user&#039;s personal data. */
  isIdentificationField?: Maybe<Scalars['Boolean']['output']>
  /** Whether this field should be included when erasing personal data. */
  shouldErase?: Maybe<Scalars['Boolean']['output']>
  /** Whether this field should be included when exporting personal data. */
  shouldExport?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_FormFieldDataPolicyFieldSelector = {
  isIdentificationField?: InputMaybe<FieldSelectorEnum>
  shouldErase?: InputMaybe<FieldSelectorEnum>
  shouldExport?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormFieldDataPolicyFilterInput = {
  isIdentificationField?: InputMaybe<BooleanQueryOperatorInput>
  shouldErase?: InputMaybe<BooleanQueryOperatorInput>
  shouldExport?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_FormFieldDataPolicySortInput = {
  isIdentificationField?: InputMaybe<SortOrderEnum>
  shouldErase?: InputMaybe<SortOrderEnum>
  shouldExport?: InputMaybe<SortOrderEnum>
}

/** Determines where the field description is displayed relative to the field. */
export enum Wp_FormFieldDescriptionPlacementEnum {
  Above = 'ABOVE',
  Below = 'BELOW',
  Inherit = 'INHERIT',
}

export type Wp_FormFieldDescriptionPlacementEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormFieldDescriptionPlacementEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormFieldDescriptionPlacementEnum>>>
  ne?: InputMaybe<Wp_FormFieldDescriptionPlacementEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormFieldDescriptionPlacementEnum>>>
}

export type Wp_FormFieldFieldSelector = {
  displayOnly?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  inputType?: InputMaybe<FieldSelectorEnum>
  layoutGridColumnSpan?: InputMaybe<FieldSelectorEnum>
  layoutSpacerGridColumnSpan?: InputMaybe<FieldSelectorEnum>
  pageNumber?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
  visibility?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormFieldFilterInput = {
  displayOnly?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<IntQueryOperatorInput>
  inputType?: InputMaybe<Wp_FormFieldTypeEnumQueryOperatorInput>
  layoutGridColumnSpan?: InputMaybe<IntQueryOperatorInput>
  layoutSpacerGridColumnSpan?: InputMaybe<IntQueryOperatorInput>
  pageNumber?: InputMaybe<IntQueryOperatorInput>
  type?: InputMaybe<Wp_FormFieldTypeEnumQueryOperatorInput>
  visibility?: InputMaybe<Wp_FormFieldVisibilityEnumQueryOperatorInput>
}

export type Wp_FormFieldFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormFieldFilterInput>
}

/** The field label position. Empty when using the form defaults or a value of "hidden_label". */
export enum Wp_FormFieldLabelPlacementEnum {
  Hidden = 'HIDDEN',
  Inherit = 'INHERIT',
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP',
}

export type Wp_FormFieldLabelPlacementEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormFieldLabelPlacementEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormFieldLabelPlacementEnum>>>
  ne?: InputMaybe<Wp_FormFieldLabelPlacementEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormFieldLabelPlacementEnum>>>
}

/** Type of indicator to use when field is required. */
export enum Wp_FormFieldRequiredIndicatorEnum {
  Asterisk = 'ASTERISK',
  Custom = 'CUSTOM',
  Text = 'TEXT',
}

export type Wp_FormFieldRequiredIndicatorEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormFieldRequiredIndicatorEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormFieldRequiredIndicatorEnum>>>
  ne?: InputMaybe<Wp_FormFieldRequiredIndicatorEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormFieldRequiredIndicatorEnum>>>
}

/** The size of the field when displayed on the page. */
export enum Wp_FormFieldSizeEnum {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}

export type Wp_FormFieldSortInput = {
  displayOnly?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  inputType?: InputMaybe<SortOrderEnum>
  layoutGridColumnSpan?: InputMaybe<SortOrderEnum>
  layoutSpacerGridColumnSpan?: InputMaybe<SortOrderEnum>
  pageNumber?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
  visibility?: InputMaybe<SortOrderEnum>
}

/** Determines how sub-labels are aligned. */
export enum Wp_FormFieldSubLabelPlacementEnum {
  Above = 'ABOVE',
  Below = 'BELOW',
  Inherit = 'INHERIT',
}

/** Gravity Forms Field Type. */
export enum Wp_FormFieldTypeEnum {
  Address = 'ADDRESS',
  Calculation = 'CALCULATION',
  Captcha = 'CAPTCHA',
  Checkbox = 'CHECKBOX',
  ComboSignature = 'COMBO_SIGNATURE',
  Consent = 'CONSENT',
  Date = 'DATE',
  Email = 'EMAIL',
  Fileupload = 'FILEUPLOAD',
  Followup = 'FOLLOWUP',
  Hidden = 'HIDDEN',
  Hiddenproduct = 'HIDDENPRODUCT',
  Html = 'HTML',
  List = 'LIST',
  Multiselect = 'MULTISELECT',
  Name = 'NAME',
  Number = 'NUMBER',
  Page = 'PAGE',
  Password = 'PASSWORD',
  Phone = 'PHONE',
  Photo = 'PHOTO',
  PostCategory = 'POST_CATEGORY',
  PostContent = 'POST_CONTENT',
  PostCustomField = 'POST_CUSTOM_FIELD',
  PostExcerpt = 'POST_EXCERPT',
  PostImage = 'POST_IMAGE',
  PostTags = 'POST_TAGS',
  PostTitle = 'POST_TITLE',
  Quiz = 'QUIZ',
  Radio = 'RADIO',
  RememberMe = 'REMEMBER_ME',
  Section = 'SECTION',
  Select = 'SELECT',
  Signature = 'SIGNATURE',
  Singleproduct = 'SINGLEPRODUCT',
  Singleshipping = 'SINGLESHIPPING',
  Submit = 'SUBMIT',
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  Time = 'TIME',
  Username = 'USERNAME',
  Website = 'WEBSITE',
}

export type Wp_FormFieldTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormFieldTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormFieldTypeEnum>>>
  ne?: InputMaybe<Wp_FormFieldTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormFieldTypeEnum>>>
}

/** Field visibility. */
export enum Wp_FormFieldVisibilityEnum {
  Administrative = 'ADMINISTRATIVE',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export type Wp_FormFieldVisibilityEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormFieldVisibilityEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormFieldVisibilityEnum>>>
  ne?: InputMaybe<Wp_FormFieldVisibilityEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormFieldVisibilityEnum>>>
}

/** Form Group fields */
export type Wp_FormGroup = {
  __typename?: 'Wp_FormGroup'
  /** Form Group Header */
  header?: Maybe<Scalars['String']['output']>
  /** Header ordering */
  order?: Maybe<Scalars['Int']['output']>
  translations?: Maybe<Array<Maybe<Wp_FormGroupTranslation>>>
}

export type Wp_FormGroupFieldSelector = {
  header?: InputMaybe<FieldSelectorEnum>
  order?: InputMaybe<FieldSelectorEnum>
  translations?: InputMaybe<Wp_FormGroupTranslationFieldSelector>
}

export type Wp_FormGroupFilterInput = {
  header?: InputMaybe<StringQueryOperatorInput>
  order?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_FormGroupTranslationFilterListInput>
}

export type Wp_FormGroupFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormGroupFilterInput>
}

export type Wp_FormGroupSortInput = {
  header?: InputMaybe<SortOrderEnum>
  order?: InputMaybe<SortOrderEnum>
  translations?: InputMaybe<Wp_FormGroupTranslationSortInput>
}

/** Form Group Translation fields */
export type Wp_FormGroupTranslation = {
  __typename?: 'Wp_FormGroupTranslation'
  code?: Maybe<Wp_LanguageCodeEnum>
  header?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
}

export type Wp_FormGroupTranslationFieldSelector = {
  code?: InputMaybe<FieldSelectorEnum>
  header?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  slug?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormGroupTranslationFilterInput = {
  code?: InputMaybe<Wp_LanguageCodeEnumQueryOperatorInput>
  header?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<IdQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormGroupTranslationFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormGroupTranslationFilterInput>
}

export type Wp_FormGroupTranslationSortInput = {
  code?: InputMaybe<SortOrderEnum>
  header?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  slug?: InputMaybe<SortOrderEnum>
}

/** The FormId type */
export type Wp_FormId = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_FormId'
    children: Array<Node>
    /** Connection between the FormId type and the ContentNode type */
    contentNodes?: Maybe<Wp_FormIdToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the FormId type and the Taxonomy type */
    taxonomy?: Maybe<Wp_FormIdToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_FormIdConnection = {
  __typename?: 'Wp_FormIdConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_FormIdEdge>
  group: Array<Wp_FormIdGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_FormId>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_FormIdConnectionDistinctArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdConnectionGroupArgs = {
  field: Wp_FormIdFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_FormIdConnectionMaxArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdConnectionMinArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdConnectionSumArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdEdge = {
  __typename?: 'Wp_FormIdEdge'
  next?: Maybe<Wp_FormId>
  node: Wp_FormId
  previous?: Maybe<Wp_FormId>
}

export type Wp_FormIdFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_FormIdToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_FormIdToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormIdFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_FormIdToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_FormIdToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormIdGroupConnection = {
  __typename?: 'Wp_FormIdGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_FormIdEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_FormIdGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_FormId>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_FormIdGroupConnectionDistinctArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdGroupConnectionGroupArgs = {
  field: Wp_FormIdFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_FormIdGroupConnectionMaxArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdGroupConnectionMinArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdGroupConnectionSumArgs = {
  field: Wp_FormIdFieldSelector
}

export type Wp_FormIdSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_FormIdToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_FormIdToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the FormId type and the ContentNode type */
export type Wp_FormIdToContentNodeConnection = {
  __typename?: 'Wp_FormIdToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_FormIdToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_FormIdToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_FormIdToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the FormId type and the Taxonomy type */
export type Wp_FormIdToTaxonomyConnectionEdge = {
  __typename?: 'Wp_FormIdToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_FormIdToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_FormIdToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_FormIdToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** Determines where the field labels should be placed in relation to the field. */
export enum Wp_FormLabelPlacementEnum {
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP',
}

export type Wp_FormLabelPlacementEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormLabelPlacementEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormLabelPlacementEnum>>>
  ne?: InputMaybe<Wp_FormLabelPlacementEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormLabelPlacementEnum>>>
}

/** Gravity Forms button. */
export type Wp_FormLastPageButton = {
  __typename?: 'Wp_FormLastPageButton'
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']['output']>
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<Wp_FormButtonTypeEnum>
}

export type Wp_FormLastPageButtonFieldSelector = {
  imageUrl?: InputMaybe<FieldSelectorEnum>
  text?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormLastPageButtonFilterInput = {
  imageUrl?: InputMaybe<StringQueryOperatorInput>
  text?: InputMaybe<StringQueryOperatorInput>
  type?: InputMaybe<Wp_FormButtonTypeEnumQueryOperatorInput>
}

export type Wp_FormLastPageButtonSortInput = {
  imageUrl?: InputMaybe<SortOrderEnum>
  text?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
}

/** When limitEntries is set to 1, this property specifies the time period during which submissions are allowed. */
export enum Wp_FormLimitEntriesPeriodEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type Wp_FormLimitEntriesPeriodEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormLimitEntriesPeriodEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormLimitEntriesPeriodEnum>>>
  ne?: InputMaybe<Wp_FormLimitEntriesPeriodEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormLimitEntriesPeriodEnum>>>
}

/** Gravity Forms form login requirements data. */
export type Wp_FormLogin = {
  __typename?: 'Wp_FormLogin'
  /** Whether the form is configured to be displayed only to logged in users. */
  isLoginRequired?: Maybe<Scalars['Boolean']['output']>
  /** When `isLoginRequired` is set to true, this controls the message displayed when non-logged in user tries to access the form. */
  loginRequiredMessage?: Maybe<Scalars['String']['output']>
}

export type Wp_FormLoginFieldSelector = {
  isLoginRequired?: InputMaybe<FieldSelectorEnum>
  loginRequiredMessage?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormLoginFilterInput = {
  isLoginRequired?: InputMaybe<BooleanQueryOperatorInput>
  loginRequiredMessage?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormLoginSortInput = {
  isLoginRequired?: InputMaybe<SortOrderEnum>
  loginRequiredMessage?: InputMaybe<SortOrderEnum>
}

/** Properties for all the email notifications which exist for a form. */
export type Wp_FormNotification = {
  __typename?: 'Wp_FormNotification'
  /** The email or merge tags to be used as the email bcc address. */
  bcc?: Maybe<Scalars['String']['output']>
  /** An associative array containing the conditional logic rules. See the Conditional Logic Object for more details. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** The notification event. Default is form_submission. */
  event?: Maybe<Scalars['String']['output']>
  /** The email or merge tag to be used as the email from address. */
  from?: Maybe<Scalars['String']['output']>
  /** The text or merge tag to be used as the email from name. */
  fromName?: Maybe<Scalars['String']['output']>
  /** The notification ID. A 13 character unique ID. */
  id?: Maybe<Scalars['String']['output']>
  /** Is the notification active or inactive. The default is true (active). */
  isActive?: Maybe<Scalars['Boolean']['output']>
  /** Whether the email message should be formatted so that paragraphs are automatically added for new lines. */
  isAutoformatted?: Maybe<Scalars['Boolean']['output']>
  /** The email body/content. Merge tags supported. */
  message?: Maybe<Scalars['String']['output']>
  /** The notification name. */
  name?: Maybe<Scalars['String']['output']>
  /** The email or merge tags to be used as the email reply to address. */
  replyTo?: Maybe<Scalars['String']['output']>
  /** Routing rules. */
  routing?: Maybe<Array<Maybe<Wp_FormNotificationRouting>>>
  /** The name of the service to be used when sending this notification. Default is wordpress. */
  service?: Maybe<Scalars['String']['output']>
  /** Determines if files uploaded on the form should be included when the notification is sent. */
  shouldSendAttachments?: Maybe<Scalars['Boolean']['output']>
  /** The email subject line. Merge tags supported. */
  subject?: Maybe<Scalars['String']['output']>
  /** The ID of an email field, an email address or merge tag to be used as the email to address. */
  to?: Maybe<Scalars['String']['output']>
  /** Identifies what to use for the notification &quot;to&quot;. */
  toType?: Maybe<Wp_FormNotificationToTypeEnum>
}

export type Wp_FormNotificationFieldSelector = {
  bcc?: InputMaybe<FieldSelectorEnum>
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFieldSelector>
  event?: InputMaybe<FieldSelectorEnum>
  from?: InputMaybe<FieldSelectorEnum>
  fromName?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  isActive?: InputMaybe<FieldSelectorEnum>
  isAutoformatted?: InputMaybe<FieldSelectorEnum>
  message?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  replyTo?: InputMaybe<FieldSelectorEnum>
  routing?: InputMaybe<Wp_FormNotificationRoutingFieldSelector>
  service?: InputMaybe<FieldSelectorEnum>
  shouldSendAttachments?: InputMaybe<FieldSelectorEnum>
  subject?: InputMaybe<FieldSelectorEnum>
  to?: InputMaybe<FieldSelectorEnum>
  toType?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormNotificationFilterInput = {
  bcc?: InputMaybe<StringQueryOperatorInput>
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFilterInput>
  event?: InputMaybe<StringQueryOperatorInput>
  from?: InputMaybe<StringQueryOperatorInput>
  fromName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  isActive?: InputMaybe<BooleanQueryOperatorInput>
  isAutoformatted?: InputMaybe<BooleanQueryOperatorInput>
  message?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  replyTo?: InputMaybe<StringQueryOperatorInput>
  routing?: InputMaybe<Wp_FormNotificationRoutingFilterListInput>
  service?: InputMaybe<StringQueryOperatorInput>
  shouldSendAttachments?: InputMaybe<BooleanQueryOperatorInput>
  subject?: InputMaybe<StringQueryOperatorInput>
  to?: InputMaybe<StringQueryOperatorInput>
  toType?: InputMaybe<Wp_FormNotificationToTypeEnumQueryOperatorInput>
}

export type Wp_FormNotificationFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormNotificationFilterInput>
}

/** Properties for all the email notifications which exist for a form. */
export type Wp_FormNotificationRouting = {
  __typename?: 'Wp_FormNotificationRouting'
  /** The email or merge tag to be used as the email To address if this rule is a match. */
  email?: Maybe<Scalars['String']['output']>
  /** Target field ID. The field that will have it’s value compared with the value property to determine if this rule is a match. */
  fieldId?: Maybe<Scalars['Int']['output']>
  /** Operator to be used when evaluating this rule. */
  operator?: Maybe<Wp_FormRuleOperatorEnum>
  /** The value to compare with the field specified by fieldId. */
  value?: Maybe<Scalars['String']['output']>
}

export type Wp_FormNotificationRoutingFieldSelector = {
  email?: InputMaybe<FieldSelectorEnum>
  fieldId?: InputMaybe<FieldSelectorEnum>
  operator?: InputMaybe<FieldSelectorEnum>
  value?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormNotificationRoutingFilterInput = {
  email?: InputMaybe<StringQueryOperatorInput>
  fieldId?: InputMaybe<IntQueryOperatorInput>
  operator?: InputMaybe<Wp_FormRuleOperatorEnumQueryOperatorInput>
  value?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormNotificationRoutingFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormNotificationRoutingFilterInput>
}

export type Wp_FormNotificationRoutingSortInput = {
  email?: InputMaybe<SortOrderEnum>
  fieldId?: InputMaybe<SortOrderEnum>
  operator?: InputMaybe<SortOrderEnum>
  value?: InputMaybe<SortOrderEnum>
}

export type Wp_FormNotificationSortInput = {
  bcc?: InputMaybe<SortOrderEnum>
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicSortInput>
  event?: InputMaybe<SortOrderEnum>
  from?: InputMaybe<SortOrderEnum>
  fromName?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  isActive?: InputMaybe<SortOrderEnum>
  isAutoformatted?: InputMaybe<SortOrderEnum>
  message?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  replyTo?: InputMaybe<SortOrderEnum>
  routing?: InputMaybe<Wp_FormNotificationRoutingSortInput>
  service?: InputMaybe<SortOrderEnum>
  shouldSendAttachments?: InputMaybe<SortOrderEnum>
  subject?: InputMaybe<SortOrderEnum>
  to?: InputMaybe<SortOrderEnum>
  toType?: InputMaybe<SortOrderEnum>
}

/** What to use for the notification "to". */
export enum Wp_FormNotificationToTypeEnum {
  Email = 'EMAIL',
  Field = 'FIELD',
  Hidden = 'HIDDEN',
  Routing = 'ROUTING',
}

export type Wp_FormNotificationToTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormNotificationToTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormNotificationToTypeEnum>>>
  ne?: InputMaybe<Wp_FormNotificationToTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormNotificationToTypeEnum>>>
}

/** Style of progress bar. */
export enum Wp_FormPageProgressStyleEnum {
  Blue = 'BLUE',
  Custom = 'CUSTOM',
  Green = 'GREEN',
  Grey = 'GREY',
  Orange = 'ORANGE',
  Red = 'RED',
}

export type Wp_FormPageProgressStyleEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormPageProgressStyleEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormPageProgressStyleEnum>>>
  ne?: InputMaybe<Wp_FormPageProgressStyleEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormPageProgressStyleEnum>>>
}

/** Type of page progress indicator to be displayed. */
export enum Wp_FormPageProgressTypeEnum {
  None = 'NONE',
  Percentage = 'PERCENTAGE',
  Steps = 'STEPS',
}

export type Wp_FormPageProgressTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormPageProgressTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormPageProgressTypeEnum>>>
  ne?: InputMaybe<Wp_FormPageProgressTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormPageProgressTypeEnum>>>
}

/** Gravity Forms form pagination data. */
export type Wp_FormPagination = {
  __typename?: 'Wp_FormPagination'
  /** Progress bar background color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;. */
  backgroundColor?: Maybe<Scalars['String']['output']>
  /** Progress bar text color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;. */
  color?: Maybe<Scalars['String']['output']>
  /** Whether the confirmation bar should be displayed with the confirmation text. */
  hasProgressbarOnConfirmation?: Maybe<Scalars['Boolean']['output']>
  /** Last page button data. */
  lastPageButton?: Maybe<Wp_FormLastPageButton>
  /** Names of the form&#039;s pages. */
  pageNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The confirmation text to display once the end of the progress bar has been reached. Only applies when `hasProgressbarOnConfirmation` is set to true. */
  progressbarCompletionText?: Maybe<Scalars['String']['output']>
  /** Style of progress bar. */
  style?: Maybe<Wp_FormPageProgressStyleEnum>
  /** Type of progress indicator. */
  type?: Maybe<Wp_FormPageProgressTypeEnum>
}

export type Wp_FormPaginationFieldSelector = {
  backgroundColor?: InputMaybe<FieldSelectorEnum>
  color?: InputMaybe<FieldSelectorEnum>
  hasProgressbarOnConfirmation?: InputMaybe<FieldSelectorEnum>
  lastPageButton?: InputMaybe<Wp_FormLastPageButtonFieldSelector>
  pageNames?: InputMaybe<FieldSelectorEnum>
  progressbarCompletionText?: InputMaybe<FieldSelectorEnum>
  style?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormPaginationFilterInput = {
  backgroundColor?: InputMaybe<StringQueryOperatorInput>
  color?: InputMaybe<StringQueryOperatorInput>
  hasProgressbarOnConfirmation?: InputMaybe<BooleanQueryOperatorInput>
  lastPageButton?: InputMaybe<Wp_FormLastPageButtonFilterInput>
  pageNames?: InputMaybe<StringQueryOperatorInput>
  progressbarCompletionText?: InputMaybe<StringQueryOperatorInput>
  style?: InputMaybe<Wp_FormPageProgressStyleEnumQueryOperatorInput>
  type?: InputMaybe<Wp_FormPageProgressTypeEnumQueryOperatorInput>
}

export type Wp_FormPaginationSortInput = {
  backgroundColor?: InputMaybe<SortOrderEnum>
  color?: InputMaybe<SortOrderEnum>
  hasProgressbarOnConfirmation?: InputMaybe<SortOrderEnum>
  lastPageButton?: InputMaybe<Wp_FormLastPageButtonSortInput>
  pageNames?: InputMaybe<SortOrderEnum>
  progressbarCompletionText?: InputMaybe<SortOrderEnum>
  style?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
}

/** Gravity Forms form Personal Data settings. */
export type Wp_FormPersonalData = {
  __typename?: 'Wp_FormPersonalData'
  /** The policies governing which entry data to include when erasing and exporting personal data. */
  dataPolicies?: Maybe<Wp_FormDataPolicies>
  /** The number of days to retain entries. `null` if `retentionPolicy` is set to `RETAIN` entries indefinitely. */
  daysToRetain?: Maybe<Scalars['Int']['output']>
  /** The policy for retaining old entry data. */
  retentionPolicy?: Maybe<Wp_FormRetentionPolicyEnum>
  /** Whether the IP address should be saved to the form submission. */
  shouldSaveIP?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_FormPersonalDataFieldSelector = {
  dataPolicies?: InputMaybe<Wp_FormDataPoliciesFieldSelector>
  daysToRetain?: InputMaybe<FieldSelectorEnum>
  retentionPolicy?: InputMaybe<FieldSelectorEnum>
  shouldSaveIP?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormPersonalDataFilterInput = {
  dataPolicies?: InputMaybe<Wp_FormDataPoliciesFilterInput>
  daysToRetain?: InputMaybe<IntQueryOperatorInput>
  retentionPolicy?: InputMaybe<Wp_FormRetentionPolicyEnumQueryOperatorInput>
  shouldSaveIP?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_FormPersonalDataSortInput = {
  dataPolicies?: InputMaybe<Wp_FormDataPoliciesSortInput>
  daysToRetain?: InputMaybe<SortOrderEnum>
  retentionPolicy?: InputMaybe<SortOrderEnum>
  shouldSaveIP?: InputMaybe<SortOrderEnum>
}

/** Gravity Forms form entry limititation details. */
export type Wp_FormPostCreation = {
  __typename?: 'Wp_FormPostCreation'
  /** When `useCurrentUserAsAuthor` is `false`, this contains the User object for the author. */
  author?: Maybe<Wp_User>
  /** When `useCurrentUserAsAuthor` is `false`, this property contains the user database that will be used as the Post author. */
  authorDatabaseId?: Maybe<Scalars['Int']['output']>
  /** When `useCurrentUserAsAuthor` is `false`, this property contains the user ID that will be used as the Post author. */
  authorId?: Maybe<Scalars['ID']['output']>
  /** Form forms with Post fields, but without a Post Category field, this property contains the default category database ID the post will be associated with when created. */
  categoryDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Template to be used when creating the post content. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post content. Only applicable when `hasContentTemplate` is `true`. */
  contentTemplate?: Maybe<Scalars['String']['output']>
  /** Determines the format that the Post should be created with. */
  format?: Maybe<Wp_PostFormatTypeEnum>
  /** Determines if the post template functionality is enabled. When enabled, the post content will be created based on the template specified by `contentTemplate`. */
  hasContentTemplate?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the post title template functionality is enabled. When enabled, the post title will be created based on the template specified by `titleTemplate`. */
  hasTitleTemplate?: Maybe<Scalars['Boolean']['output']>
  /** For forms with Post fields, this determines if the post should be created using the current logged in user as the author. */
  shouldUseCurrentUserAsAuthor?: Maybe<Scalars['Boolean']['output']>
  /** For forms with Post fields, determines the status that the Post should be created with. */
  status?: Maybe<Scalars['String']['output']>
  /** Template to be used when creating the post title. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post title. Only applicable when `hasTitleTemplate` is `true`. */
  titleTemplate?: Maybe<Scalars['String']['output']>
}

export type Wp_FormPostCreationFieldSelector = {
  author?: InputMaybe<Wp_UserFieldSelector>
  authorDatabaseId?: InputMaybe<FieldSelectorEnum>
  authorId?: InputMaybe<FieldSelectorEnum>
  categoryDatabaseId?: InputMaybe<FieldSelectorEnum>
  contentTemplate?: InputMaybe<FieldSelectorEnum>
  format?: InputMaybe<FieldSelectorEnum>
  hasContentTemplate?: InputMaybe<FieldSelectorEnum>
  hasTitleTemplate?: InputMaybe<FieldSelectorEnum>
  shouldUseCurrentUserAsAuthor?: InputMaybe<FieldSelectorEnum>
  status?: InputMaybe<FieldSelectorEnum>
  titleTemplate?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormPostCreationFilterInput = {
  author?: InputMaybe<Wp_UserFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  categoryDatabaseId?: InputMaybe<IntQueryOperatorInput>
  contentTemplate?: InputMaybe<StringQueryOperatorInput>
  format?: InputMaybe<Wp_PostFormatTypeEnumQueryOperatorInput>
  hasContentTemplate?: InputMaybe<BooleanQueryOperatorInput>
  hasTitleTemplate?: InputMaybe<BooleanQueryOperatorInput>
  shouldUseCurrentUserAsAuthor?: InputMaybe<BooleanQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  titleTemplate?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormPostCreationSortInput = {
  author?: InputMaybe<Wp_UserSortInput>
  authorDatabaseId?: InputMaybe<SortOrderEnum>
  authorId?: InputMaybe<SortOrderEnum>
  categoryDatabaseId?: InputMaybe<SortOrderEnum>
  contentTemplate?: InputMaybe<SortOrderEnum>
  format?: InputMaybe<SortOrderEnum>
  hasContentTemplate?: InputMaybe<SortOrderEnum>
  hasTitleTemplate?: InputMaybe<SortOrderEnum>
  shouldUseCurrentUserAsAuthor?: InputMaybe<SortOrderEnum>
  status?: InputMaybe<SortOrderEnum>
  titleTemplate?: InputMaybe<SortOrderEnum>
}

/** Quiz-specific settings that will affect ALL Quiz fields in the form. */
export type Wp_FormQuiz = {
  __typename?: 'Wp_FormQuiz'
  /** The message to display if the quiz grade is below the Pass Percentage. Only used if grading is set to `PASSFAIL`. */
  failConfirmation?: Maybe<Wp_FormQuizConfirmation>
  /** The letter grades to be assigned based on the percentage score achieved. Only used if `grading` is set to `LETTER`. */
  grades?: Maybe<Array<Maybe<Wp_FormQuizGrades>>>
  /** The quiz grading type. Defaults to `NONE`. */
  gradingType?: Maybe<Wp_QuizFieldGradingTypeEnum>
  /** Display correct or incorrect indicator and explanation (if any) immediately after answer selection. This setting only applies to radio button quiz fields and it is intended for training applications and trivial quizzes. It should not be considered a secure option for critical testing requirements. */
  hasInstantFeedback?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display a confirmation message upon submission of the quiz form. Only used if `grading` is set to `LETTER`. */
  hasLetterConfirmationMessage?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display a confirmation message upon submission of the quiz form. Only used if grading is set to `PASSFAIL`. */
  hasPassFailConfirmationMessage?: Maybe<Scalars['Boolean']['output']>
  /** Randomize the order of the quiz fields on this form each time the form is loaded. */
  isShuffleFieldsEnabled?: Maybe<Scalars['Boolean']['output']>
  /** The confirmation message to display if `grading` is set to `LETTER`. */
  letterConfirmation?: Maybe<Wp_FormQuizConfirmation>
  /** The maximum score for this form. */
  maxScore?: Maybe<Scalars['Float']['output']>
  /** The message to display if the quiz grade is above or equal to the Pass Percentage. Only used if grading is set to `PASSFAIL`. */
  passConfirmation?: Maybe<Wp_FormQuizConfirmation>
  /** The percentage score the user must equal or exceed to be considered to have &#039;passed.&#039; Only used if `grading` is set to `PASSFAIL`. */
  passPercent?: Maybe<Scalars['Int']['output']>
}

/** The Quiz Confirmation message data. */
export type Wp_FormQuizConfirmation = {
  __typename?: 'Wp_FormQuizConfirmation'
  /** Whether autoformatting is enabled for the confirmation message. */
  isAutoformatted?: Maybe<Scalars['Boolean']['output']>
  /** The message to display. */
  message?: Maybe<Scalars['String']['output']>
}

export type Wp_FormQuizConfirmationFieldSelector = {
  isAutoformatted?: InputMaybe<FieldSelectorEnum>
  message?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormQuizConfirmationFilterInput = {
  isAutoformatted?: InputMaybe<BooleanQueryOperatorInput>
  message?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormQuizConfirmationSortInput = {
  isAutoformatted?: InputMaybe<SortOrderEnum>
  message?: InputMaybe<SortOrderEnum>
}

export type Wp_FormQuizFieldSelector = {
  failConfirmation?: InputMaybe<Wp_FormQuizConfirmationFieldSelector>
  grades?: InputMaybe<Wp_FormQuizGradesFieldSelector>
  gradingType?: InputMaybe<FieldSelectorEnum>
  hasInstantFeedback?: InputMaybe<FieldSelectorEnum>
  hasLetterConfirmationMessage?: InputMaybe<FieldSelectorEnum>
  hasPassFailConfirmationMessage?: InputMaybe<FieldSelectorEnum>
  isShuffleFieldsEnabled?: InputMaybe<FieldSelectorEnum>
  letterConfirmation?: InputMaybe<Wp_FormQuizConfirmationFieldSelector>
  maxScore?: InputMaybe<FieldSelectorEnum>
  passConfirmation?: InputMaybe<Wp_FormQuizConfirmationFieldSelector>
  passPercent?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormQuizFilterInput = {
  failConfirmation?: InputMaybe<Wp_FormQuizConfirmationFilterInput>
  grades?: InputMaybe<Wp_FormQuizGradesFilterListInput>
  gradingType?: InputMaybe<Wp_QuizFieldGradingTypeEnumQueryOperatorInput>
  hasInstantFeedback?: InputMaybe<BooleanQueryOperatorInput>
  hasLetterConfirmationMessage?: InputMaybe<BooleanQueryOperatorInput>
  hasPassFailConfirmationMessage?: InputMaybe<BooleanQueryOperatorInput>
  isShuffleFieldsEnabled?: InputMaybe<BooleanQueryOperatorInput>
  letterConfirmation?: InputMaybe<Wp_FormQuizConfirmationFilterInput>
  maxScore?: InputMaybe<FloatQueryOperatorInput>
  passConfirmation?: InputMaybe<Wp_FormQuizConfirmationFilterInput>
  passPercent?: InputMaybe<IntQueryOperatorInput>
}

/** The letter grades to be assigned based on the percentage score achieved. Only used if `grading` is set to `LETTER`. */
export type Wp_FormQuizGrades = {
  __typename?: 'Wp_FormQuizGrades'
  /** The grade label. */
  text?: Maybe<Scalars['String']['output']>
  /** The minimum percentage score required to achieve this grade. */
  value?: Maybe<Scalars['Int']['output']>
}

export type Wp_FormQuizGradesFieldSelector = {
  text?: InputMaybe<FieldSelectorEnum>
  value?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormQuizGradesFilterInput = {
  text?: InputMaybe<StringQueryOperatorInput>
  value?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_FormQuizGradesFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormQuizGradesFilterInput>
}

export type Wp_FormQuizGradesSortInput = {
  text?: InputMaybe<SortOrderEnum>
  value?: InputMaybe<SortOrderEnum>
}

export type Wp_FormQuizSortInput = {
  failConfirmation?: InputMaybe<Wp_FormQuizConfirmationSortInput>
  grades?: InputMaybe<Wp_FormQuizGradesSortInput>
  gradingType?: InputMaybe<SortOrderEnum>
  hasInstantFeedback?: InputMaybe<SortOrderEnum>
  hasLetterConfirmationMessage?: InputMaybe<SortOrderEnum>
  hasPassFailConfirmationMessage?: InputMaybe<SortOrderEnum>
  isShuffleFieldsEnabled?: InputMaybe<SortOrderEnum>
  letterConfirmation?: InputMaybe<Wp_FormQuizConfirmationSortInput>
  maxScore?: InputMaybe<SortOrderEnum>
  passConfirmation?: InputMaybe<Wp_FormQuizConfirmationSortInput>
  passPercent?: InputMaybe<SortOrderEnum>
}

/** The Personal Data retention policy. */
export enum Wp_FormRetentionPolicyEnum {
  Delete = 'DELETE',
  Retain = 'RETAIN',
  Trash = 'TRASH',
}

export type Wp_FormRetentionPolicyEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormRetentionPolicyEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormRetentionPolicyEnum>>>
  ne?: InputMaybe<Wp_FormRetentionPolicyEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormRetentionPolicyEnum>>>
}

/** Operator to be used when evaluating logic rules. */
export enum Wp_FormRuleOperatorEnum {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  GreaterThan = 'GREATER_THAN',
  Is = 'IS',
  IsNot = 'IS_NOT',
  LessThan = 'LESS_THAN',
  StartsWith = 'STARTS_WITH',
}

export type Wp_FormRuleOperatorEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormRuleOperatorEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormRuleOperatorEnum>>>
  ne?: InputMaybe<Wp_FormRuleOperatorEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormRuleOperatorEnum>>>
}

/** Gravity Forms form Save and Continue data. */
export type Wp_FormSaveAndContinue = {
  __typename?: 'Wp_FormSaveAndContinue'
  /** Contains the save button text. */
  buttonText?: Maybe<Scalars['String']['output']>
  /** Whether the Save And Continue feature is enabled. */
  hasSaveAndContinue?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_FormSaveAndContinueFieldSelector = {
  buttonText?: InputMaybe<FieldSelectorEnum>
  hasSaveAndContinue?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormSaveAndContinueFilterInput = {
  buttonText?: InputMaybe<StringQueryOperatorInput>
  hasSaveAndContinue?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_FormSaveAndContinueSortInput = {
  buttonText?: InputMaybe<SortOrderEnum>
  hasSaveAndContinue?: InputMaybe<SortOrderEnum>
}

/** Gravity Forms form scheduling data. */
export type Wp_FormSchedule = {
  __typename?: 'Wp_FormSchedule'
  /** Message to be displayed when form is no longer available. */
  closedMessage?: Maybe<Scalars['String']['output']>
  /** The Date/time details when the form will become inactive. */
  endDetails?: Maybe<Wp_FormScheduleDetails>
  /** Specifies if this form is scheduled to be displayed only during a certain configured date/time. */
  hasSchedule?: Maybe<Scalars['Boolean']['output']>
  /** Message to be displayed when form is not yet available. */
  pendingMessage?: Maybe<Scalars['String']['output']>
  /** The Date/time details when the form will become active/visible. */
  startDetails?: Maybe<Wp_FormScheduleDetails>
}

/** Gravity Forms form scheduling data. */
export type Wp_FormScheduleDetails = {
  __typename?: 'Wp_FormScheduleDetails'
  /** Whether the date is in the AM or PM of a 12-hour clock. */
  amPm?: Maybe<Wp_AmPmEnum>
  /** The schedule date in local time. */
  date?: Maybe<Scalars['Date']['output']>
  /** The schedule date in GMT. */
  dateGmt?: Maybe<Scalars['Date']['output']>
  /** The hour (1-12). */
  hour?: Maybe<Scalars['Int']['output']>
  /** The minute. */
  minute?: Maybe<Scalars['Int']['output']>
}

/** Gravity Forms form scheduling data. */
export type Wp_FormScheduleDetailsDateArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** Gravity Forms form scheduling data. */
export type Wp_FormScheduleDetailsDateGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

export type Wp_FormScheduleDetailsFieldSelector = {
  amPm?: InputMaybe<FieldSelectorEnum>
  date?: InputMaybe<FieldSelectorEnum>
  dateGmt?: InputMaybe<FieldSelectorEnum>
  hour?: InputMaybe<FieldSelectorEnum>
  minute?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormScheduleDetailsFilterInput = {
  amPm?: InputMaybe<Wp_AmPmEnumQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  hour?: InputMaybe<IntQueryOperatorInput>
  minute?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_FormScheduleDetailsSortInput = {
  amPm?: InputMaybe<SortOrderEnum>
  date?: InputMaybe<SortOrderEnum>
  dateGmt?: InputMaybe<SortOrderEnum>
  hour?: InputMaybe<SortOrderEnum>
  minute?: InputMaybe<SortOrderEnum>
}

export type Wp_FormScheduleFieldSelector = {
  closedMessage?: InputMaybe<FieldSelectorEnum>
  endDetails?: InputMaybe<Wp_FormScheduleDetailsFieldSelector>
  hasSchedule?: InputMaybe<FieldSelectorEnum>
  pendingMessage?: InputMaybe<FieldSelectorEnum>
  startDetails?: InputMaybe<Wp_FormScheduleDetailsFieldSelector>
}

export type Wp_FormScheduleFilterInput = {
  closedMessage?: InputMaybe<StringQueryOperatorInput>
  endDetails?: InputMaybe<Wp_FormScheduleDetailsFilterInput>
  hasSchedule?: InputMaybe<BooleanQueryOperatorInput>
  pendingMessage?: InputMaybe<StringQueryOperatorInput>
  startDetails?: InputMaybe<Wp_FormScheduleDetailsFilterInput>
}

export type Wp_FormScheduleSortInput = {
  closedMessage?: InputMaybe<SortOrderEnum>
  endDetails?: InputMaybe<Wp_FormScheduleDetailsSortInput>
  hasSchedule?: InputMaybe<SortOrderEnum>
  pendingMessage?: InputMaybe<SortOrderEnum>
  startDetails?: InputMaybe<Wp_FormScheduleDetailsSortInput>
}

/** Determines how sub-labels are aligned. */
export enum Wp_FormSubLabelPlacementEnum {
  Above = 'ABOVE',
  Below = 'BELOW',
}

export type Wp_FormSubLabelPlacementEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormSubLabelPlacementEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormSubLabelPlacementEnum>>>
  ne?: InputMaybe<Wp_FormSubLabelPlacementEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormSubLabelPlacementEnum>>>
}

/** Gravity Forms submit button. */
export type Wp_FormSubmitButton = {
  __typename?: 'Wp_FormSubmitButton'
  /** Controls when the form button should be visible based on values selected on the form. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Where the submit button should be located. */
  location?: Maybe<Wp_FormSubmitButtonLocationEnum>
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']['output']>
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<Wp_FormButtonTypeEnum>
  /** The width of the submit button element. */
  width?: Maybe<Wp_FormSubmitButtonWidthEnum>
}

export type Wp_FormSubmitButtonFieldSelector = {
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFieldSelector>
  imageUrl?: InputMaybe<FieldSelectorEnum>
  layoutGridColumnSpan?: InputMaybe<FieldSelectorEnum>
  location?: InputMaybe<FieldSelectorEnum>
  text?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormSubmitButtonFilterInput = {
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFilterInput>
  imageUrl?: InputMaybe<StringQueryOperatorInput>
  layoutGridColumnSpan?: InputMaybe<IntQueryOperatorInput>
  location?: InputMaybe<Wp_FormSubmitButtonLocationEnumQueryOperatorInput>
  text?: InputMaybe<StringQueryOperatorInput>
  type?: InputMaybe<Wp_FormButtonTypeEnumQueryOperatorInput>
  width?: InputMaybe<Wp_FormSubmitButtonWidthEnumQueryOperatorInput>
}

/** Where the submit button should be located. */
export enum Wp_FormSubmitButtonLocationEnum {
  Bottom = 'BOTTOM',
  Inline = 'INLINE',
}

export type Wp_FormSubmitButtonLocationEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormSubmitButtonLocationEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormSubmitButtonLocationEnum>>>
  ne?: InputMaybe<Wp_FormSubmitButtonLocationEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormSubmitButtonLocationEnum>>>
}

export type Wp_FormSubmitButtonSortInput = {
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicSortInput>
  imageUrl?: InputMaybe<SortOrderEnum>
  layoutGridColumnSpan?: InputMaybe<SortOrderEnum>
  location?: InputMaybe<SortOrderEnum>
  text?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

/** Submit button width. */
export enum Wp_FormSubmitButtonWidthEnum {
  Auto = 'AUTO',
  Full = 'FULL',
}

export type Wp_FormSubmitButtonWidthEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_FormSubmitButtonWidthEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_FormSubmitButtonWidthEnum>>>
  ne?: InputMaybe<Wp_FormSubmitButtonWidthEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_FormSubmitButtonWidthEnum>>>
}

/** Gravity Forms translation settings */
export type Wp_FormTranslation = {
  __typename?: 'Wp_FormTranslation'
  /** Is this an active translation to make form pages for. */
  active?: Maybe<Scalars['Boolean']['output']>
  /** Entries List page Card Display Detail Labels */
  cardDisplay?: Maybe<Wp_CardDisplayTranslation>
  /** Clear button text */
  clearButtonText?: Maybe<Scalars['String']['output']>
  /** Form Description */
  description?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Next button text */
  nextButtonText?: Maybe<Scalars['String']['output']>
  /** Previous button text */
  previousButtonText?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
  /** Submit button text */
  submitButtonText?: Maybe<Scalars['String']['output']>
  /** Form Title */
  title?: Maybe<Scalars['String']['output']>
}

export type Wp_FormTranslationFieldSelector = {
  active?: InputMaybe<FieldSelectorEnum>
  cardDisplay?: InputMaybe<Wp_CardDisplayTranslationFieldSelector>
  clearButtonText?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nextButtonText?: InputMaybe<FieldSelectorEnum>
  previousButtonText?: InputMaybe<FieldSelectorEnum>
  slug?: InputMaybe<FieldSelectorEnum>
  submitButtonText?: InputMaybe<FieldSelectorEnum>
  title?: InputMaybe<FieldSelectorEnum>
}

export type Wp_FormTranslationFilterInput = {
  active?: InputMaybe<BooleanQueryOperatorInput>
  cardDisplay?: InputMaybe<Wp_CardDisplayTranslationFilterInput>
  clearButtonText?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nextButtonText?: InputMaybe<StringQueryOperatorInput>
  previousButtonText?: InputMaybe<StringQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  submitButtonText?: InputMaybe<StringQueryOperatorInput>
  title?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_FormTranslationFilterListInput = {
  elemMatch?: InputMaybe<Wp_FormTranslationFilterInput>
}

export type Wp_FormTranslationSortInput = {
  active?: InputMaybe<SortOrderEnum>
  cardDisplay?: InputMaybe<Wp_CardDisplayTranslationSortInput>
  clearButtonText?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nextButtonText?: InputMaybe<SortOrderEnum>
  previousButtonText?: InputMaybe<SortOrderEnum>
  slug?: InputMaybe<SortOrderEnum>
  submitButtonText?: InputMaybe<SortOrderEnum>
  title?: InputMaybe<SortOrderEnum>
}

/** The general setting type */
export type Wp_GeneralSettings = {
  __typename?: 'Wp_GeneralSettings'
  /** A date format for all date strings. */
  dateFormat?: Maybe<Scalars['String']['output']>
  /** Site tagline. */
  description?: Maybe<Scalars['String']['output']>
  /** WordPress locale code. */
  language?: Maybe<Scalars['String']['output']>
  /** A day number of the week that the week should start on. */
  startOfWeek?: Maybe<Scalars['Int']['output']>
  /** A time format for all time strings. */
  timeFormat?: Maybe<Scalars['String']['output']>
  /** A city in the same timezone as you. */
  timezone?: Maybe<Scalars['String']['output']>
  /** Site title. */
  title?: Maybe<Scalars['String']['output']>
  /** Site URL. */
  url?: Maybe<Scalars['String']['output']>
}

export type Wp_GeneralSettingsFieldSelector = {
  dateFormat?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  language?: InputMaybe<FieldSelectorEnum>
  startOfWeek?: InputMaybe<FieldSelectorEnum>
  timeFormat?: InputMaybe<FieldSelectorEnum>
  timezone?: InputMaybe<FieldSelectorEnum>
  title?: InputMaybe<FieldSelectorEnum>
  url?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GeneralSettingsFilterInput = {
  dateFormat?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  language?: InputMaybe<StringQueryOperatorInput>
  startOfWeek?: InputMaybe<IntQueryOperatorInput>
  timeFormat?: InputMaybe<StringQueryOperatorInput>
  timezone?: InputMaybe<StringQueryOperatorInput>
  title?: InputMaybe<StringQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_GeneralSettingsSortInput = {
  dateFormat?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  language?: InputMaybe<SortOrderEnum>
  startOfWeek?: InputMaybe<SortOrderEnum>
  timeFormat?: InputMaybe<SortOrderEnum>
  timezone?: InputMaybe<SortOrderEnum>
  title?: InputMaybe<SortOrderEnum>
  url?: InputMaybe<SortOrderEnum>
}

/** Currencies supported by Gravity Forms. */
export enum Wp_GfCurrencyEnum {
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Ils = 'ILS',
  Jpy = 'JPY',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Php = 'PHP',
  Pln = 'PLN',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Twd = 'TWD',
  Usd = 'USD',
  Zar = 'ZAR',
}

export type Wp_GfCurrencyEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_GfCurrencyEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_GfCurrencyEnum>>>
  ne?: InputMaybe<Wp_GfCurrencyEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_GfCurrencyEnum>>>
}

/** Gravity Forms form. */
export type Wp_GfForm = Node &
  Wp_DatabaseIdentifier &
  Wp_Node & {
    __typename?: 'Wp_GfForm'
    /** Card display settings. */
    cardDisplaySettings?: Maybe<Wp_CardDisplaySettings>
    children: Array<Node>
    /** Contains the form confirmation settings such as confirmation text or redirect URL. */
    confirmations?: Maybe<Array<Maybe<Wp_FormConfirmation>>>
    /** String containing the custom CSS classes to be added to the &lt;form&gt; tag. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** The custom text to use to indicate a field is required. */
    customRequiredIndicator?: Maybe<Scalars['String']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** The date and time that the entry was created in local time. */
    dateCreated?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was created in GMT. */
    dateCreatedGmt?: Maybe<Scalars['String']['output']>
    /** Form description. */
    description?: Maybe<Scalars['String']['output']>
    /** Determines if the field description is displayed above the field input (i.e. immediately after the field label) or below the field input. */
    descriptionPlacement?: Maybe<Wp_FormDescriptionPlacementEnum>
    /** Disable entry editing for this form */
    disableEditing?: Maybe<Scalars['Boolean']['output']>
    /** Connection between the GfForm type and the GfEntry type */
    entries?: Maybe<Wp_GfFormToGfEntryConnection>
    /** The entry limit settings. */
    entryLimits?: Maybe<Wp_FormEntryLimits>
    /** CSS class for the first page. */
    firstPageCssClass?: Maybe<Scalars['String']['output']>
    /** Connection between the GfForm type and the FormField type */
    formFields?: Maybe<Wp_GfFormToFormFieldConnection>
    /** Form grouping header shown in the form drop down. */
    group?: Maybe<Scalars['String']['output']>
    /** When enabled, conditional logic hide/show operation will be performed with a jQuery slide animation. Only applicable to forms with conditional logic. */
    hasConditionalLogicAnimation?: Maybe<Scalars['Boolean']['output']>
    /** Specifies if the form has the Honeypot spam-protection feature. */
    hasHoneypot?: Maybe<Scalars['Boolean']['output']>
    /** If enabled, will show a summary that lists form validation errors at the top of the form when a user attempts a failed submission. */
    hasValidationSummary?: Maybe<Scalars['Boolean']['output']>
    /** Whether the form should be hidden in frontend navigation controls. */
    hideInNav?: Maybe<Scalars['Boolean']['output']>
    /** Whether the Jump to Section Button should be hidden on the frontend for this form. */
    hideJumpToSection?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Determines whether the form is active. */
    isActive?: Maybe<Scalars['Boolean']['output']>
    /** Is this a corrective action form */
    isCAForm?: Maybe<Scalars['Boolean']['output']>
    /** Is the Primary form to be first in dropdown selection. */
    isPrimary?: Maybe<Scalars['Boolean']['output']>
    /** Is this a quiz form */
    isQuizForm?: Maybe<Scalars['Boolean']['output']>
    /** Determines whether the form is in the trash. */
    isTrash?: Maybe<Scalars['Boolean']['output']>
    /** Determines where the field labels should be placed in relation to the field. */
    labelPlacement?: Maybe<Wp_FormLabelPlacementEnum>
    /** Login requirements data. */
    login?: Maybe<Wp_FormLogin>
    /** The Gravity Forms markup version. */
    markupVersion?: Maybe<Scalars['Int']['output']>
    /** The ID to assign to the next field that is added to the form. */
    nextFieldId?: Maybe<Scalars['Int']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    /** The properties for all the email notifications which exist for a form. */
    notifications?: Maybe<Array<Maybe<Wp_FormNotification>>>
    /** Pagination data. */
    pagination?: Maybe<Wp_FormPagination>
    parent?: Maybe<Node>
    /** Personal data settings. */
    personalData?: Maybe<Wp_FormPersonalData>
    /** Post creation data. */
    postCreation?: Maybe<Wp_FormPostCreation>
    /** The type of post to be automatically */
    postTypeToCreate?: Maybe<Scalars['String']['output']>
    /** Quiz-specific settings that will affect ALL Quiz fields in the form. Requires Gravity Forms Quiz addon. */
    quiz?: Maybe<Wp_FormQuiz>
    /** If true, form will reload upon successful submission instead of redirect to respective list page */
    reloadOnSubmit?: Maybe<Scalars['Boolean']['output']>
    /** Type of indicator to use when field is required. */
    requiredIndicator?: Maybe<Wp_FormFieldRequiredIndicatorEnum>
    /** List of the roles to restrict the form entry viewing to only those entries submitted / created by the current user */
    restrictEntriesToUserCreated?: Maybe<
      Array<Maybe<Scalars['String']['output']>>
    >
    /** List of the roles which should be restricted from form submission */
    restrictFormSubmissionByRole?: Maybe<
      Array<Maybe<Scalars['String']['output']>>
    >
    /** \&quot;Save and Continue\&quot; data. */
    saveAndContinue?: Maybe<Wp_FormSaveAndContinue>
    /** Form scheduling data. */
    scheduling?: Maybe<Wp_FormSchedule>
    /** The URL slug for the form. */
    slug?: Maybe<Scalars['String']['output']>
    /** How sub-labels are aligned. */
    subLabelPlacement?: Maybe<Wp_FormSubLabelPlacementEnum>
    /** Expose Submission Rules */
    submissionRules?: Maybe<Array<Maybe<Wp_SubmissionRule>>>
    /** Contains the form button settings such as the button text or image button source. */
    submitButton?: Maybe<Wp_FormSubmitButton>
    /** Form title. */
    title?: Maybe<Scalars['String']['output']>
    /** Form Level Translated values */
    translations?: Maybe<Array<Maybe<Wp_FormTranslation>>>
    /** The version of Gravity Forms used to create this form. */
    version?: Maybe<Scalars['String']['output']>
  }

export type Wp_GfFormConnection = {
  __typename?: 'Wp_GfFormConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_GfFormEdge>
  group: Array<Wp_GfFormGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_GfForm>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_GfFormConnectionDistinctArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormConnectionGroupArgs = {
  field: Wp_GfFormFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_GfFormConnectionMaxArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormConnectionMinArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormConnectionSumArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormEdge = {
  __typename?: 'Wp_GfFormEdge'
  next?: Maybe<Wp_GfForm>
  node: Wp_GfForm
  previous?: Maybe<Wp_GfForm>
}

export type Wp_GfFormFieldSelector = {
  cardDisplaySettings?: InputMaybe<Wp_CardDisplaySettingsFieldSelector>
  children?: InputMaybe<NodeFieldSelector>
  confirmations?: InputMaybe<Wp_FormConfirmationFieldSelector>
  cssClass?: InputMaybe<FieldSelectorEnum>
  customRequiredIndicator?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  dateCreated?: InputMaybe<FieldSelectorEnum>
  dateCreatedGmt?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  descriptionPlacement?: InputMaybe<FieldSelectorEnum>
  disableEditing?: InputMaybe<FieldSelectorEnum>
  entries?: InputMaybe<Wp_GfFormToGfEntryConnectionFieldSelector>
  entryLimits?: InputMaybe<Wp_FormEntryLimitsFieldSelector>
  firstPageCssClass?: InputMaybe<FieldSelectorEnum>
  formFields?: InputMaybe<Wp_GfFormToFormFieldConnectionFieldSelector>
  group?: InputMaybe<FieldSelectorEnum>
  hasConditionalLogicAnimation?: InputMaybe<FieldSelectorEnum>
  hasHoneypot?: InputMaybe<FieldSelectorEnum>
  hasValidationSummary?: InputMaybe<FieldSelectorEnum>
  hideInNav?: InputMaybe<FieldSelectorEnum>
  hideJumpToSection?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isActive?: InputMaybe<FieldSelectorEnum>
  isCAForm?: InputMaybe<FieldSelectorEnum>
  isPrimary?: InputMaybe<FieldSelectorEnum>
  isQuizForm?: InputMaybe<FieldSelectorEnum>
  isTrash?: InputMaybe<FieldSelectorEnum>
  labelPlacement?: InputMaybe<FieldSelectorEnum>
  login?: InputMaybe<Wp_FormLoginFieldSelector>
  markupVersion?: InputMaybe<FieldSelectorEnum>
  nextFieldId?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  notifications?: InputMaybe<Wp_FormNotificationFieldSelector>
  pagination?: InputMaybe<Wp_FormPaginationFieldSelector>
  parent?: InputMaybe<NodeFieldSelector>
  personalData?: InputMaybe<Wp_FormPersonalDataFieldSelector>
  postCreation?: InputMaybe<Wp_FormPostCreationFieldSelector>
  postTypeToCreate?: InputMaybe<FieldSelectorEnum>
  quiz?: InputMaybe<Wp_FormQuizFieldSelector>
  reloadOnSubmit?: InputMaybe<FieldSelectorEnum>
  requiredIndicator?: InputMaybe<FieldSelectorEnum>
  restrictEntriesToUserCreated?: InputMaybe<FieldSelectorEnum>
  restrictFormSubmissionByRole?: InputMaybe<FieldSelectorEnum>
  saveAndContinue?: InputMaybe<Wp_FormSaveAndContinueFieldSelector>
  scheduling?: InputMaybe<Wp_FormScheduleFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  subLabelPlacement?: InputMaybe<FieldSelectorEnum>
  submissionRules?: InputMaybe<Wp_SubmissionRuleFieldSelector>
  submitButton?: InputMaybe<Wp_FormSubmitButtonFieldSelector>
  title?: InputMaybe<FieldSelectorEnum>
  translations?: InputMaybe<Wp_FormTranslationFieldSelector>
  version?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfFormFilterInput = {
  cardDisplaySettings?: InputMaybe<Wp_CardDisplaySettingsFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  confirmations?: InputMaybe<Wp_FormConfirmationFilterListInput>
  cssClass?: InputMaybe<StringQueryOperatorInput>
  customRequiredIndicator?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  dateCreated?: InputMaybe<StringQueryOperatorInput>
  dateCreatedGmt?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  descriptionPlacement?: InputMaybe<Wp_FormDescriptionPlacementEnumQueryOperatorInput>
  disableEditing?: InputMaybe<BooleanQueryOperatorInput>
  entries?: InputMaybe<Wp_GfFormToGfEntryConnectionFilterInput>
  entryLimits?: InputMaybe<Wp_FormEntryLimitsFilterInput>
  firstPageCssClass?: InputMaybe<StringQueryOperatorInput>
  formFields?: InputMaybe<Wp_GfFormToFormFieldConnectionFilterInput>
  group?: InputMaybe<StringQueryOperatorInput>
  hasConditionalLogicAnimation?: InputMaybe<BooleanQueryOperatorInput>
  hasHoneypot?: InputMaybe<BooleanQueryOperatorInput>
  hasValidationSummary?: InputMaybe<BooleanQueryOperatorInput>
  hideInNav?: InputMaybe<BooleanQueryOperatorInput>
  hideJumpToSection?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isActive?: InputMaybe<BooleanQueryOperatorInput>
  isCAForm?: InputMaybe<BooleanQueryOperatorInput>
  isPrimary?: InputMaybe<BooleanQueryOperatorInput>
  isQuizForm?: InputMaybe<BooleanQueryOperatorInput>
  isTrash?: InputMaybe<BooleanQueryOperatorInput>
  labelPlacement?: InputMaybe<Wp_FormLabelPlacementEnumQueryOperatorInput>
  login?: InputMaybe<Wp_FormLoginFilterInput>
  markupVersion?: InputMaybe<IntQueryOperatorInput>
  nextFieldId?: InputMaybe<IntQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  notifications?: InputMaybe<Wp_FormNotificationFilterListInput>
  pagination?: InputMaybe<Wp_FormPaginationFilterInput>
  parent?: InputMaybe<NodeFilterInput>
  personalData?: InputMaybe<Wp_FormPersonalDataFilterInput>
  postCreation?: InputMaybe<Wp_FormPostCreationFilterInput>
  postTypeToCreate?: InputMaybe<StringQueryOperatorInput>
  quiz?: InputMaybe<Wp_FormQuizFilterInput>
  reloadOnSubmit?: InputMaybe<BooleanQueryOperatorInput>
  requiredIndicator?: InputMaybe<Wp_FormFieldRequiredIndicatorEnumQueryOperatorInput>
  restrictEntriesToUserCreated?: InputMaybe<StringQueryOperatorInput>
  restrictFormSubmissionByRole?: InputMaybe<StringQueryOperatorInput>
  saveAndContinue?: InputMaybe<Wp_FormSaveAndContinueFilterInput>
  scheduling?: InputMaybe<Wp_FormScheduleFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  subLabelPlacement?: InputMaybe<Wp_FormSubLabelPlacementEnumQueryOperatorInput>
  submissionRules?: InputMaybe<Wp_SubmissionRuleFilterListInput>
  submitButton?: InputMaybe<Wp_FormSubmitButtonFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  translations?: InputMaybe<Wp_FormTranslationFilterListInput>
  version?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_GfFormGroupConnection = {
  __typename?: 'Wp_GfFormGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_GfFormEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_GfFormGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_GfForm>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_GfFormGroupConnectionDistinctArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormGroupConnectionGroupArgs = {
  field: Wp_GfFormFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_GfFormGroupConnectionMaxArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormGroupConnectionMinArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormGroupConnectionSumArgs = {
  field: Wp_GfFormFieldSelector
}

export type Wp_GfFormSortInput = {
  cardDisplaySettings?: InputMaybe<Wp_CardDisplaySettingsSortInput>
  children?: InputMaybe<NodeSortInput>
  confirmations?: InputMaybe<Wp_FormConfirmationSortInput>
  cssClass?: InputMaybe<SortOrderEnum>
  customRequiredIndicator?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  dateCreated?: InputMaybe<SortOrderEnum>
  dateCreatedGmt?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  descriptionPlacement?: InputMaybe<SortOrderEnum>
  disableEditing?: InputMaybe<SortOrderEnum>
  entries?: InputMaybe<Wp_GfFormToGfEntryConnectionSortInput>
  entryLimits?: InputMaybe<Wp_FormEntryLimitsSortInput>
  firstPageCssClass?: InputMaybe<SortOrderEnum>
  formFields?: InputMaybe<Wp_GfFormToFormFieldConnectionSortInput>
  group?: InputMaybe<SortOrderEnum>
  hasConditionalLogicAnimation?: InputMaybe<SortOrderEnum>
  hasHoneypot?: InputMaybe<SortOrderEnum>
  hasValidationSummary?: InputMaybe<SortOrderEnum>
  hideInNav?: InputMaybe<SortOrderEnum>
  hideJumpToSection?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isActive?: InputMaybe<SortOrderEnum>
  isCAForm?: InputMaybe<SortOrderEnum>
  isPrimary?: InputMaybe<SortOrderEnum>
  isQuizForm?: InputMaybe<SortOrderEnum>
  isTrash?: InputMaybe<SortOrderEnum>
  labelPlacement?: InputMaybe<SortOrderEnum>
  login?: InputMaybe<Wp_FormLoginSortInput>
  markupVersion?: InputMaybe<SortOrderEnum>
  nextFieldId?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  notifications?: InputMaybe<Wp_FormNotificationSortInput>
  pagination?: InputMaybe<Wp_FormPaginationSortInput>
  parent?: InputMaybe<NodeSortInput>
  personalData?: InputMaybe<Wp_FormPersonalDataSortInput>
  postCreation?: InputMaybe<Wp_FormPostCreationSortInput>
  postTypeToCreate?: InputMaybe<SortOrderEnum>
  quiz?: InputMaybe<Wp_FormQuizSortInput>
  reloadOnSubmit?: InputMaybe<SortOrderEnum>
  requiredIndicator?: InputMaybe<SortOrderEnum>
  restrictEntriesToUserCreated?: InputMaybe<SortOrderEnum>
  restrictFormSubmissionByRole?: InputMaybe<SortOrderEnum>
  saveAndContinue?: InputMaybe<Wp_FormSaveAndContinueSortInput>
  scheduling?: InputMaybe<Wp_FormScheduleSortInput>
  slug?: InputMaybe<SortOrderEnum>
  subLabelPlacement?: InputMaybe<SortOrderEnum>
  submissionRules?: InputMaybe<Wp_SubmissionRuleSortInput>
  submitButton?: InputMaybe<Wp_FormSubmitButtonSortInput>
  title?: InputMaybe<SortOrderEnum>
  translations?: InputMaybe<Wp_FormTranslationSortInput>
  version?: InputMaybe<SortOrderEnum>
}

/** Connection between the GfForm type and the FormField type */
export type Wp_GfFormToFormFieldConnection = {
  __typename?: 'Wp_GfFormToFormFieldConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_FormField>>>
}

export type Wp_GfFormToFormFieldConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_FormFieldFieldSelector>
}

export type Wp_GfFormToFormFieldConnectionFilterInput = {
  nodes?: InputMaybe<Wp_FormFieldFilterListInput>
}

export type Wp_GfFormToFormFieldConnectionSortInput = {
  nodes?: InputMaybe<Wp_FormFieldSortInput>
}

/** Connection between the GfForm type and the GfEntry type */
export type Wp_GfFormToGfEntryConnection = {
  __typename?: 'Wp_GfFormToGfEntryConnection'
  /** The number of (filtered) entries submitted to the form. */
  count?: Maybe<Scalars['Int']['output']>
  /** The quiz results for the given form. */
  quizResults?: Maybe<Wp_GfQuizResults>
}

export type Wp_GfFormToGfEntryConnectionFieldSelector = {
  count?: InputMaybe<FieldSelectorEnum>
  quizResults?: InputMaybe<Wp_GfQuizResultsFieldSelector>
}

export type Wp_GfFormToGfEntryConnectionFilterInput = {
  count?: InputMaybe<IntQueryOperatorInput>
  quizResults?: InputMaybe<Wp_GfQuizResultsFilterInput>
}

export type Wp_GfFormToGfEntryConnectionSortInput = {
  count?: InputMaybe<SortOrderEnum>
  quizResults?: InputMaybe<Wp_GfQuizResultsSortInput>
}

/** Gravity Forms Logging Settings. */
export type Wp_GfLogger = {
  __typename?: 'Wp_GfLogger'
  /** Whether the logger is enabled. */
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  /** The name of the Gravity Forms logger. */
  name?: Maybe<Scalars['String']['output']>
}

export type Wp_GfLoggerFieldSelector = {
  isEnabled?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfLoggerFilterInput = {
  isEnabled?: InputMaybe<BooleanQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_GfLoggerFilterListInput = {
  elemMatch?: InputMaybe<Wp_GfLoggerFilterInput>
}

export type Wp_GfLoggerSortInput = {
  isEnabled?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
}

/** The quiz results for all entries. */
export type Wp_GfQuizResults = {
  __typename?: 'Wp_GfQuizResults'
  /** Average percentage score as calculated across all entries received. */
  averagePercentage?: Maybe<Scalars['Float']['output']>
  /** Average score as calculated across all entries received. */
  averageScore?: Maybe<Scalars['Float']['output']>
  /** Quantity of all the entries received for this quiz. */
  entryCount?: Maybe<Scalars['Int']['output']>
  /** A list of fields and frequency of each answer provided. */
  fieldCounts?: Maybe<Array<Maybe<Wp_GfQuizResultsFieldCount>>>
  /** If using letter grades, will show the frequency of each letter grade across all entries received. */
  gradeCounts?: Maybe<Array<Maybe<Wp_GfQuizResultsGradeCount>>>
  /** The pass-fail rate for all the entries received for this quiz. */
  passRate?: Maybe<Scalars['Float']['output']>
  /** Displays a frequency bar chart showing the spread of each quiz score. */
  scoreCounts?: Maybe<Array<Maybe<Wp_GfQuizResultsScoreCount>>>
  /** The total sum of all entry scores. Useful for calculating custom result statistics. */
  sum?: Maybe<Scalars['Float']['output']>
}

/** The response counts for individual quiz fields. */
export type Wp_GfQuizResultsChoiceCount = {
  __typename?: 'Wp_GfQuizResultsChoiceCount'
  /** The number of entries with this choice provided. */
  count?: Maybe<Scalars['Int']['output']>
  /** The choice text. */
  text?: Maybe<Scalars['String']['output']>
  /** The internal value used to represent the quiz choice. */
  value?: Maybe<Scalars['String']['output']>
}

export type Wp_GfQuizResultsChoiceCountFieldSelector = {
  count?: InputMaybe<FieldSelectorEnum>
  text?: InputMaybe<FieldSelectorEnum>
  value?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfQuizResultsChoiceCountFilterInput = {
  count?: InputMaybe<IntQueryOperatorInput>
  text?: InputMaybe<StringQueryOperatorInput>
  value?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_GfQuizResultsChoiceCountFilterListInput = {
  elemMatch?: InputMaybe<Wp_GfQuizResultsChoiceCountFilterInput>
}

export type Wp_GfQuizResultsChoiceCountSortInput = {
  count?: InputMaybe<SortOrderEnum>
  text?: InputMaybe<SortOrderEnum>
  value?: InputMaybe<SortOrderEnum>
}

/** The quiz results summary for an individual quiz field. */
export type Wp_GfQuizResultsFieldCount = {
  __typename?: 'Wp_GfQuizResultsFieldCount'
  /** A list of the individual responses and their counts. */
  choiceCounts?: Maybe<Array<Maybe<Wp_GfQuizResultsChoiceCount>>>
  /** The number of correct responses across all entries received. */
  correctCount?: Maybe<Scalars['Int']['output']>
  /** The quiz field ID. */
  fieldId?: Maybe<Scalars['Int']['output']>
  /** Connection between the GfQuizResultsFieldCount type and the QuizField type */
  formField?: Maybe<Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdge>
  /** The number of incorrect responses across all entries received. */
  incorrectCount?: Maybe<Scalars['Int']['output']>
}

export type Wp_GfQuizResultsFieldCountFieldSelector = {
  choiceCounts?: InputMaybe<Wp_GfQuizResultsChoiceCountFieldSelector>
  correctCount?: InputMaybe<FieldSelectorEnum>
  fieldId?: InputMaybe<FieldSelectorEnum>
  formField?: InputMaybe<Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdgeFieldSelector>
  incorrectCount?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfQuizResultsFieldCountFilterInput = {
  choiceCounts?: InputMaybe<Wp_GfQuizResultsChoiceCountFilterListInput>
  correctCount?: InputMaybe<IntQueryOperatorInput>
  fieldId?: InputMaybe<IntQueryOperatorInput>
  formField?: InputMaybe<Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdgeFilterInput>
  incorrectCount?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_GfQuizResultsFieldCountFilterListInput = {
  elemMatch?: InputMaybe<Wp_GfQuizResultsFieldCountFilterInput>
}

export type Wp_GfQuizResultsFieldCountSortInput = {
  choiceCounts?: InputMaybe<Wp_GfQuizResultsChoiceCountSortInput>
  correctCount?: InputMaybe<SortOrderEnum>
  fieldId?: InputMaybe<SortOrderEnum>
  formField?: InputMaybe<Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdgeSortInput>
  incorrectCount?: InputMaybe<SortOrderEnum>
}

/** Connection between the GfQuizResultsFieldCount type and the QuizField type */
export type Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdge = {
  __typename?: 'Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_QuizField>
}

export type Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_QuizFieldFieldSelector>
}

export type Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_QuizFieldFilterInput>
}

export type Wp_GfQuizResultsFieldCountToQuizFieldConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_QuizFieldSortInput>
}

export type Wp_GfQuizResultsFieldSelector = {
  averagePercentage?: InputMaybe<FieldSelectorEnum>
  averageScore?: InputMaybe<FieldSelectorEnum>
  entryCount?: InputMaybe<FieldSelectorEnum>
  fieldCounts?: InputMaybe<Wp_GfQuizResultsFieldCountFieldSelector>
  gradeCounts?: InputMaybe<Wp_GfQuizResultsGradeCountFieldSelector>
  passRate?: InputMaybe<FieldSelectorEnum>
  scoreCounts?: InputMaybe<Wp_GfQuizResultsScoreCountFieldSelector>
  sum?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfQuizResultsFilterInput = {
  averagePercentage?: InputMaybe<FloatQueryOperatorInput>
  averageScore?: InputMaybe<FloatQueryOperatorInput>
  entryCount?: InputMaybe<IntQueryOperatorInput>
  fieldCounts?: InputMaybe<Wp_GfQuizResultsFieldCountFilterListInput>
  gradeCounts?: InputMaybe<Wp_GfQuizResultsGradeCountFilterListInput>
  passRate?: InputMaybe<FloatQueryOperatorInput>
  scoreCounts?: InputMaybe<Wp_GfQuizResultsScoreCountFilterListInput>
  sum?: InputMaybe<FloatQueryOperatorInput>
}

/** A quiz Grade and its count. */
export type Wp_GfQuizResultsGradeCount = {
  __typename?: 'Wp_GfQuizResultsGradeCount'
  /** The number of entries that received this grade across all entries received. */
  count?: Maybe<Scalars['Int']['output']>
  /** The quiz grade. */
  grade?: Maybe<Scalars['String']['output']>
}

export type Wp_GfQuizResultsGradeCountFieldSelector = {
  count?: InputMaybe<FieldSelectorEnum>
  grade?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfQuizResultsGradeCountFilterInput = {
  count?: InputMaybe<IntQueryOperatorInput>
  grade?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_GfQuizResultsGradeCountFilterListInput = {
  elemMatch?: InputMaybe<Wp_GfQuizResultsGradeCountFilterInput>
}

export type Wp_GfQuizResultsGradeCountSortInput = {
  count?: InputMaybe<SortOrderEnum>
  grade?: InputMaybe<SortOrderEnum>
}

/** A quiz score and its count. */
export type Wp_GfQuizResultsScoreCount = {
  __typename?: 'Wp_GfQuizResultsScoreCount'
  /** The number of entries that received this score across all entries received. */
  count?: Maybe<Scalars['Int']['output']>
  /** The quiz score. */
  score?: Maybe<Scalars['Float']['output']>
}

export type Wp_GfQuizResultsScoreCountFieldSelector = {
  count?: InputMaybe<FieldSelectorEnum>
  score?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfQuizResultsScoreCountFilterInput = {
  count?: InputMaybe<IntQueryOperatorInput>
  score?: InputMaybe<FloatQueryOperatorInput>
}

export type Wp_GfQuizResultsScoreCountFilterListInput = {
  elemMatch?: InputMaybe<Wp_GfQuizResultsScoreCountFilterInput>
}

export type Wp_GfQuizResultsScoreCountSortInput = {
  count?: InputMaybe<SortOrderEnum>
  score?: InputMaybe<SortOrderEnum>
}

export type Wp_GfQuizResultsSortInput = {
  averagePercentage?: InputMaybe<SortOrderEnum>
  averageScore?: InputMaybe<SortOrderEnum>
  entryCount?: InputMaybe<SortOrderEnum>
  fieldCounts?: InputMaybe<Wp_GfQuizResultsFieldCountSortInput>
  gradeCounts?: InputMaybe<Wp_GfQuizResultsGradeCountSortInput>
  passRate?: InputMaybe<SortOrderEnum>
  scoreCounts?: InputMaybe<Wp_GfQuizResultsScoreCountSortInput>
  sum?: InputMaybe<SortOrderEnum>
}

/** Gravity Forms Settings. */
export type Wp_GfSettings = {
  __typename?: 'Wp_GfSettings'
  /** The default currency for your forms. Used for product, credit card, and other fields. */
  currency?: Maybe<Wp_GfCurrencyEnum>
  /** Whether Gravity Forms to download and install bug fixes and security updates automatically in the background. Requires a valid license key. */
  hasBackgroundUpdates?: Maybe<Scalars['Boolean']['output']>
  /** Whether to output Gravity Forms&#039; default CSS. */
  hasDefaultCss?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display the forms menu in the WordPress top toolbar. The forms menu will display the ten forms recently opened in the form editor. */
  hasToolbar?: Maybe<Scalars['Boolean']['output']>
  /** Whether the server-generated form markup uses HTML5. */
  isHtml5Enabled?: Maybe<Scalars['Boolean']['output']>
  /** Enable to prevent extraneous scripts and styles from being printed on a Gravity Forms admin pages, reducing conflicts with other plugins and themes. */
  isNoConflictModeEnabled?: Maybe<Scalars['Boolean']['output']>
  /** Logging settings. */
  logging?: Maybe<Wp_GfSettingsLogging>
  /** Recaptcha settings. */
  recaptcha?: Maybe<Wp_GfSettingsRecaptcha>
}

export type Wp_GfSettingsFieldSelector = {
  currency?: InputMaybe<FieldSelectorEnum>
  hasBackgroundUpdates?: InputMaybe<FieldSelectorEnum>
  hasDefaultCss?: InputMaybe<FieldSelectorEnum>
  hasToolbar?: InputMaybe<FieldSelectorEnum>
  isHtml5Enabled?: InputMaybe<FieldSelectorEnum>
  isNoConflictModeEnabled?: InputMaybe<FieldSelectorEnum>
  logging?: InputMaybe<Wp_GfSettingsLoggingFieldSelector>
  recaptcha?: InputMaybe<Wp_GfSettingsRecaptchaFieldSelector>
}

export type Wp_GfSettingsFilterInput = {
  currency?: InputMaybe<Wp_GfCurrencyEnumQueryOperatorInput>
  hasBackgroundUpdates?: InputMaybe<BooleanQueryOperatorInput>
  hasDefaultCss?: InputMaybe<BooleanQueryOperatorInput>
  hasToolbar?: InputMaybe<BooleanQueryOperatorInput>
  isHtml5Enabled?: InputMaybe<BooleanQueryOperatorInput>
  isNoConflictModeEnabled?: InputMaybe<BooleanQueryOperatorInput>
  logging?: InputMaybe<Wp_GfSettingsLoggingFilterInput>
  recaptcha?: InputMaybe<Wp_GfSettingsRecaptchaFilterInput>
}

/** Gravity Forms Logging Settings. */
export type Wp_GfSettingsLogging = {
  __typename?: 'Wp_GfSettingsLogging'
  /** Whether Gravity Forms internal logging is enabled. Logging allows you to easily debug the inner workings of Gravity Forms to solve any possible issues. */
  isLoggingEnabled?: Maybe<Scalars['Boolean']['output']>
  /** A list of registered Gravity Forms loggers and their configurations. */
  loggers?: Maybe<Array<Maybe<Wp_GfLogger>>>
}

export type Wp_GfSettingsLoggingFieldSelector = {
  isLoggingEnabled?: InputMaybe<FieldSelectorEnum>
  loggers?: InputMaybe<Wp_GfLoggerFieldSelector>
}

export type Wp_GfSettingsLoggingFilterInput = {
  isLoggingEnabled?: InputMaybe<BooleanQueryOperatorInput>
  loggers?: InputMaybe<Wp_GfLoggerFilterListInput>
}

export type Wp_GfSettingsLoggingSortInput = {
  isLoggingEnabled?: InputMaybe<SortOrderEnum>
  loggers?: InputMaybe<Wp_GfLoggerSortInput>
}

/** Gravity Forms reCAPTCHA Settings. */
export type Wp_GfSettingsRecaptcha = {
  __typename?: 'Wp_GfSettingsRecaptcha'
  /** The public reCAPTCHA site key. */
  publicKey?: Maybe<Scalars['String']['output']>
  /** The type of of reCAPTCHA v2 to be used */
  type?: Maybe<Wp_RecaptchaTypeEnum>
}

export type Wp_GfSettingsRecaptchaFieldSelector = {
  publicKey?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
}

export type Wp_GfSettingsRecaptchaFilterInput = {
  publicKey?: InputMaybe<StringQueryOperatorInput>
  type?: InputMaybe<Wp_RecaptchaTypeEnumQueryOperatorInput>
}

export type Wp_GfSettingsRecaptchaSortInput = {
  publicKey?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
}

export type Wp_GfSettingsSortInput = {
  currency?: InputMaybe<SortOrderEnum>
  hasBackgroundUpdates?: InputMaybe<SortOrderEnum>
  hasDefaultCss?: InputMaybe<SortOrderEnum>
  hasToolbar?: InputMaybe<SortOrderEnum>
  isHtml5Enabled?: InputMaybe<SortOrderEnum>
  isNoConflictModeEnabled?: InputMaybe<SortOrderEnum>
  logging?: InputMaybe<Wp_GfSettingsLoggingSortInput>
  recaptcha?: InputMaybe<Wp_GfSettingsRecaptchaSortInput>
}

export type Wp_GroupConnection = {
  __typename?: 'Wp_GroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_Edge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_GroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_GroupConnectionDistinctArgs = {
  field: Wp_FieldSelector
}

export type Wp_GroupConnectionGroupArgs = {
  field: Wp_FieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_GroupConnectionMaxArgs = {
  field: Wp_FieldSelector
}

export type Wp_GroupConnectionMinArgs = {
  field: Wp_FieldSelector
}

export type Wp_GroupConnectionSumArgs = {
  field: Wp_FieldSelector
}

/** Gravity Forms card display settings */
export type Wp_HarnessSettings = {
  __typename?: 'Wp_HarnessSettings'
  /** Connection between the HarnessSettings type and the MediaItem type */
  clientLogo?: Maybe<Wp_HarnessSettingsToMediaItemConnectionEdge>
  /** Boolean to require employee Id on worker forms and allow worker upload */
  employeeIdRequired?: Maybe<Scalars['Boolean']['output']>
  /** Boolean to ETS vax stuff on frontend */
  etsSupport?: Maybe<Scalars['Boolean']['output']>
  /** Form Groups in the correct order */
  formGroups?: Maybe<Array<Maybe<Wp_FormGroup>>>
  /** Hide Companies entirely */
  hideCompany?: Maybe<Scalars['Boolean']['output']>
  /** Hide Division entirely */
  hideDivision?: Maybe<Scalars['Boolean']['output']>
  /** List of modules of the application to hide on the frontend */
  moduleHideList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Boolean to require project number on project forms and allow project upload */
  projectNumberRequired?: Maybe<Scalars['Boolean']['output']>
  /** List of SDS Manufacturers to exclude from the SDS search options. */
  sdsManufacturerHideList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Whether to show U.S. States or Canadian Provinces in non-Gravity Forms forms on the frontend */
  stateType?: Maybe<Scalars['String']['output']>
}

export type Wp_HarnessSettingsFieldSelector = {
  clientLogo?: InputMaybe<Wp_HarnessSettingsToMediaItemConnectionEdgeFieldSelector>
  employeeIdRequired?: InputMaybe<FieldSelectorEnum>
  etsSupport?: InputMaybe<FieldSelectorEnum>
  formGroups?: InputMaybe<Wp_FormGroupFieldSelector>
  hideCompany?: InputMaybe<FieldSelectorEnum>
  hideDivision?: InputMaybe<FieldSelectorEnum>
  moduleHideList?: InputMaybe<FieldSelectorEnum>
  projectNumberRequired?: InputMaybe<FieldSelectorEnum>
  sdsManufacturerHideList?: InputMaybe<FieldSelectorEnum>
  stateType?: InputMaybe<FieldSelectorEnum>
}

export type Wp_HarnessSettingsFilterInput = {
  clientLogo?: InputMaybe<Wp_HarnessSettingsToMediaItemConnectionEdgeFilterInput>
  employeeIdRequired?: InputMaybe<BooleanQueryOperatorInput>
  etsSupport?: InputMaybe<BooleanQueryOperatorInput>
  formGroups?: InputMaybe<Wp_FormGroupFilterListInput>
  hideCompany?: InputMaybe<BooleanQueryOperatorInput>
  hideDivision?: InputMaybe<BooleanQueryOperatorInput>
  moduleHideList?: InputMaybe<StringQueryOperatorInput>
  projectNumberRequired?: InputMaybe<BooleanQueryOperatorInput>
  sdsManufacturerHideList?: InputMaybe<StringQueryOperatorInput>
  stateType?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_HarnessSettingsSortInput = {
  clientLogo?: InputMaybe<Wp_HarnessSettingsToMediaItemConnectionEdgeSortInput>
  employeeIdRequired?: InputMaybe<SortOrderEnum>
  etsSupport?: InputMaybe<SortOrderEnum>
  formGroups?: InputMaybe<Wp_FormGroupSortInput>
  hideCompany?: InputMaybe<SortOrderEnum>
  hideDivision?: InputMaybe<SortOrderEnum>
  moduleHideList?: InputMaybe<SortOrderEnum>
  projectNumberRequired?: InputMaybe<SortOrderEnum>
  sdsManufacturerHideList?: InputMaybe<SortOrderEnum>
  stateType?: InputMaybe<SortOrderEnum>
}

/** Connection between the HarnessSettings type and the MediaItem type */
export type Wp_HarnessSettingsToMediaItemConnectionEdge = {
  __typename?: 'Wp_HarnessSettingsToMediaItemConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_MediaItem>
}

export type Wp_HarnessSettingsToMediaItemConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_MediaItemFieldSelector>
}

export type Wp_HarnessSettingsToMediaItemConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_MediaItemFilterInput>
}

export type Wp_HarnessSettingsToMediaItemConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_MediaItemSortInput>
}

/** A Gravity Forms   field. */
export type Wp_HiddenField = Wp_FormField & {
  __typename?: 'Wp_HiddenField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

export type Wp_HierarchicalContentNode = {
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<Wp_HierarchicalContentNodeToContentNodeAncestorsConnection>
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  wpChildren?: Maybe<Wp_HierarchicalContentNodeToContentNodeChildrenConnection>
  /** The parent of the node. The parent object can be of various types */
  wpParent?: Maybe<Wp_HierarchicalContentNodeToParentContentNodeConnectionEdge>
}

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type Wp_HierarchicalContentNodeToContentNodeAncestorsConnection = {
  __typename?: 'Wp_HierarchicalContentNodeToContentNodeAncestorsConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionFieldSelector =
  {
    nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
  }

export type Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionFilterInput =
  {
    nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
  }

export type Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionSortInput =
  {
    nodes?: InputMaybe<Wp_ContentNodeSortInput>
  }

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type Wp_HierarchicalContentNodeToContentNodeChildrenConnection = {
  __typename?: 'Wp_HierarchicalContentNodeToContentNodeChildrenConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_HierarchicalContentNodeToContentNodeChildrenConnectionFieldSelector =
  {
    nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
  }

export type Wp_HierarchicalContentNodeToContentNodeChildrenConnectionFilterInput =
  {
    nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
  }

export type Wp_HierarchicalContentNodeToContentNodeChildrenConnectionSortInput =
  {
    nodes?: InputMaybe<Wp_ContentNodeSortInput>
  }

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type Wp_HierarchicalContentNodeToParentContentNodeConnectionEdge = {
  __typename?: 'Wp_HierarchicalContentNodeToParentContentNodeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_ContentNode>
}

export type Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeFieldSelector =
  {
    node?: InputMaybe<Wp_ContentNodeFieldSelector>
  }

export type Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeFilterInput =
  {
    node?: InputMaybe<Wp_ContentNodeFilterInput>
  }

export type Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeSortInput =
  {
    node?: InputMaybe<Wp_ContentNodeSortInput>
  }

export type Wp_HierarchicalTermNode = {
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>
}

/** A Gravity Forms   field. */
export type Wp_HtmlField = Wp_FormField & {
  __typename?: 'Wp_HtmlField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** Content of an HTML block field to be displayed on the form. */
  content?: Maybe<Scalars['String']['output']>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the default margins are turned on to align the HTML content with other fields. */
  hasMargins?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The individual properties for each element of the PostImage value field. */
export type Wp_ImageFieldValue = {
  __typename?: 'Wp_ImageFieldValue'
  /** The image alt text. */
  altText?: Maybe<Scalars['String']['output']>
  /** The path to the parent directory of the file. */
  basePath?: Maybe<Scalars['String']['output']>
  /** The base url to the parent directory of the file. */
  baseUrl?: Maybe<Scalars['String']['output']>
  /** The image caption. */
  caption?: Maybe<Scalars['String']['output']>
  /** The image description. */
  description?: Maybe<Scalars['String']['output']>
  /** The filename. */
  filename?: Maybe<Scalars['String']['output']>
  /** The image title. */
  title?: Maybe<Scalars['String']['output']>
  /** The url to the file. */
  url?: Maybe<Scalars['String']['output']>
}

/** The IncidentReportClassification type */
export type Wp_IncidentReportClassification = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_IncidentReportClassification'
    children: Array<Node>
    /** Connection between the IncidentReportClassification type and the ContentNode type */
    contentNodes?: Maybe<Wp_IncidentReportClassificationToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentReportClassification type and the Taxonomy type */
    taxonomy?: Maybe<Wp_IncidentReportClassificationToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_IncidentReportClassificationConnection = {
  __typename?: 'Wp_IncidentReportClassificationConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_IncidentReportClassificationEdge>
  group: Array<Wp_IncidentReportClassificationGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_IncidentReportClassification>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_IncidentReportClassificationConnectionDistinctArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationConnectionGroupArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_IncidentReportClassificationConnectionMaxArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationConnectionMinArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationConnectionSumArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationEdge = {
  __typename?: 'Wp_IncidentReportClassificationEdge'
  next?: Maybe<Wp_IncidentReportClassification>
  node: Wp_IncidentReportClassification
  previous?: Maybe<Wp_IncidentReportClassification>
}

export type Wp_IncidentReportClassificationFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_IncidentReportClassificationToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_IncidentReportClassificationToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_IncidentReportClassificationFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_IncidentReportClassificationToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_IncidentReportClassificationToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_IncidentReportClassificationGroupConnection = {
  __typename?: 'Wp_IncidentReportClassificationGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_IncidentReportClassificationEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_IncidentReportClassificationGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_IncidentReportClassification>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_IncidentReportClassificationGroupConnectionDistinctArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationGroupConnectionGroupArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_IncidentReportClassificationGroupConnectionMaxArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationGroupConnectionMinArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationGroupConnectionSumArgs = {
  field: Wp_IncidentReportClassificationFieldSelector
}

export type Wp_IncidentReportClassificationSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_IncidentReportClassificationToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_IncidentReportClassificationToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the IncidentReportClassification type and the ContentNode type */
export type Wp_IncidentReportClassificationToContentNodeConnection = {
  __typename?: 'Wp_IncidentReportClassificationToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_IncidentReportClassificationToContentNodeConnectionFieldSelector =
  {
    nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
  }

export type Wp_IncidentReportClassificationToContentNodeConnectionFilterInput =
  {
    nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
  }

export type Wp_IncidentReportClassificationToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the IncidentReportClassification type and the Taxonomy type */
export type Wp_IncidentReportClassificationToTaxonomyConnectionEdge = {
  __typename?: 'Wp_IncidentReportClassificationToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_IncidentReportClassificationToTaxonomyConnectionEdgeFieldSelector =
  {
    node?: InputMaybe<Wp_TaxonomyFieldSelector>
  }

export type Wp_IncidentReportClassificationToTaxonomyConnectionEdgeFilterInput =
  {
    node?: InputMaybe<Wp_TaxonomyFilterInput>
  }

export type Wp_IncidentReportClassificationToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** Incident report data used to complete the OSHA 300A form. */
export type Wp_IncidentReportData = {
  __typename?: 'Wp_IncidentReportData'
  /** The total number of days away from work. */
  casesWithDaysAwayFromWork?: Maybe<Scalars['Int']['output']>
  /** The total number of deaths. */
  casesWithDeaths?: Maybe<Scalars['Int']['output']>
  /** The total number of job restrictions. */
  casesWithJobRestrictions?: Maybe<Scalars['Int']['output']>
  /** The total number of days away from work. */
  daysAwayFromWork?: Maybe<Scalars['Int']['output']>
  /** The total number of job transfers or restrictions. */
  daysWithRestrictions?: Maybe<Scalars['Int']['output']>
  /** The total number of hearing loss. */
  hearingLoss?: Maybe<Scalars['Int']['output']>
  /** The total number of injuries. */
  injuries?: Maybe<Scalars['Int']['output']>
  /** The total number of other injuries. */
  otherIllnesses?: Maybe<Scalars['Int']['output']>
  /** The total number of other recordable cases. */
  otherRecordableCases?: Maybe<Scalars['Int']['output']>
  /** The total number of poisonings. */
  poisonings?: Maybe<Scalars['Int']['output']>
  /** The total number of respiratory conditions. */
  respiratoryConditions?: Maybe<Scalars['Int']['output']>
  /** The total number of skin disorders. */
  skinDisorders?: Maybe<Scalars['Int']['output']>
}

export type Wp_IncidentReportDataFieldSelector = {
  casesWithDaysAwayFromWork?: InputMaybe<FieldSelectorEnum>
  casesWithDeaths?: InputMaybe<FieldSelectorEnum>
  casesWithJobRestrictions?: InputMaybe<FieldSelectorEnum>
  daysAwayFromWork?: InputMaybe<FieldSelectorEnum>
  daysWithRestrictions?: InputMaybe<FieldSelectorEnum>
  hearingLoss?: InputMaybe<FieldSelectorEnum>
  injuries?: InputMaybe<FieldSelectorEnum>
  otherIllnesses?: InputMaybe<FieldSelectorEnum>
  otherRecordableCases?: InputMaybe<FieldSelectorEnum>
  poisonings?: InputMaybe<FieldSelectorEnum>
  respiratoryConditions?: InputMaybe<FieldSelectorEnum>
  skinDisorders?: InputMaybe<FieldSelectorEnum>
}

export type Wp_IncidentReportDataFilterInput = {
  casesWithDaysAwayFromWork?: InputMaybe<IntQueryOperatorInput>
  casesWithDeaths?: InputMaybe<IntQueryOperatorInput>
  casesWithJobRestrictions?: InputMaybe<IntQueryOperatorInput>
  daysAwayFromWork?: InputMaybe<IntQueryOperatorInput>
  daysWithRestrictions?: InputMaybe<IntQueryOperatorInput>
  hearingLoss?: InputMaybe<IntQueryOperatorInput>
  injuries?: InputMaybe<IntQueryOperatorInput>
  otherIllnesses?: InputMaybe<IntQueryOperatorInput>
  otherRecordableCases?: InputMaybe<IntQueryOperatorInput>
  poisonings?: InputMaybe<IntQueryOperatorInput>
  respiratoryConditions?: InputMaybe<IntQueryOperatorInput>
  skinDisorders?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_IncidentReportDataSortInput = {
  casesWithDaysAwayFromWork?: InputMaybe<SortOrderEnum>
  casesWithDeaths?: InputMaybe<SortOrderEnum>
  casesWithJobRestrictions?: InputMaybe<SortOrderEnum>
  daysAwayFromWork?: InputMaybe<SortOrderEnum>
  daysWithRestrictions?: InputMaybe<SortOrderEnum>
  hearingLoss?: InputMaybe<SortOrderEnum>
  injuries?: InputMaybe<SortOrderEnum>
  otherIllnesses?: InputMaybe<SortOrderEnum>
  otherRecordableCases?: InputMaybe<SortOrderEnum>
  poisonings?: InputMaybe<SortOrderEnum>
  respiratoryConditions?: InputMaybe<SortOrderEnum>
  skinDisorders?: InputMaybe<SortOrderEnum>
}

/** The IncidentReportStatus type */
export type Wp_IncidentReportStatus = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_IncidentReportStatus'
    children: Array<Node>
    /** Connection between the IncidentReportStatus type and the ContentNode type */
    contentNodes?: Maybe<Wp_IncidentReportStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentReportStatus type and the Taxonomy type */
    taxonomy?: Maybe<Wp_IncidentReportStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_IncidentReportStatusConnection = {
  __typename?: 'Wp_IncidentReportStatusConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_IncidentReportStatusEdge>
  group: Array<Wp_IncidentReportStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_IncidentReportStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_IncidentReportStatusConnectionDistinctArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusConnectionGroupArgs = {
  field: Wp_IncidentReportStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_IncidentReportStatusConnectionMaxArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusConnectionMinArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusConnectionSumArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusEdge = {
  __typename?: 'Wp_IncidentReportStatusEdge'
  next?: Maybe<Wp_IncidentReportStatus>
  node: Wp_IncidentReportStatus
  previous?: Maybe<Wp_IncidentReportStatus>
}

export type Wp_IncidentReportStatusFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_IncidentReportStatusToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_IncidentReportStatusToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_IncidentReportStatusFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_IncidentReportStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_IncidentReportStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_IncidentReportStatusGroupConnection = {
  __typename?: 'Wp_IncidentReportStatusGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_IncidentReportStatusEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_IncidentReportStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_IncidentReportStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_IncidentReportStatusGroupConnectionDistinctArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusGroupConnectionGroupArgs = {
  field: Wp_IncidentReportStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_IncidentReportStatusGroupConnectionMaxArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusGroupConnectionMinArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusGroupConnectionSumArgs = {
  field: Wp_IncidentReportStatusFieldSelector
}

export type Wp_IncidentReportStatusSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_IncidentReportStatusToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_IncidentReportStatusToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the IncidentReportStatus type and the ContentNode type */
export type Wp_IncidentReportStatusToContentNodeConnection = {
  __typename?: 'Wp_IncidentReportStatusToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_IncidentReportStatusToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_IncidentReportStatusToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_IncidentReportStatusToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the IncidentReportStatus type and the Taxonomy type */
export type Wp_IncidentReportStatusToTaxonomyConnectionEdge = {
  __typename?: 'Wp_IncidentReportStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_IncidentReportStatusToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_IncidentReportStatusToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_IncidentReportStatusToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The IncidentType type */
export type Wp_IncidentType = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_IncidentType'
    children: Array<Node>
    /** Connection between the IncidentType type and the ContentNode type */
    contentNodes?: Maybe<Wp_IncidentTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentType type and the Taxonomy type */
    taxonomy?: Maybe<Wp_IncidentTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_IncidentTypeConnection = {
  __typename?: 'Wp_IncidentTypeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_IncidentTypeEdge>
  group: Array<Wp_IncidentTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_IncidentType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_IncidentTypeConnectionDistinctArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeConnectionGroupArgs = {
  field: Wp_IncidentTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_IncidentTypeConnectionMaxArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeConnectionMinArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeConnectionSumArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeEdge = {
  __typename?: 'Wp_IncidentTypeEdge'
  next?: Maybe<Wp_IncidentType>
  node: Wp_IncidentType
  previous?: Maybe<Wp_IncidentType>
}

export type Wp_IncidentTypeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_IncidentTypeToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_IncidentTypeToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_IncidentTypeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_IncidentTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_IncidentTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_IncidentTypeGroupConnection = {
  __typename?: 'Wp_IncidentTypeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_IncidentTypeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_IncidentTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_IncidentType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_IncidentTypeGroupConnectionDistinctArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeGroupConnectionGroupArgs = {
  field: Wp_IncidentTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_IncidentTypeGroupConnectionMaxArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeGroupConnectionMinArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeGroupConnectionSumArgs = {
  field: Wp_IncidentTypeFieldSelector
}

export type Wp_IncidentTypeSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_IncidentTypeToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_IncidentTypeToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the IncidentType type and the ContentNode type */
export type Wp_IncidentTypeToContentNodeConnection = {
  __typename?: 'Wp_IncidentTypeToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_IncidentTypeToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_IncidentTypeToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_IncidentTypeToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the IncidentType type and the Taxonomy type */
export type Wp_IncidentTypeToTaxonomyConnectionEdge = {
  __typename?: 'Wp_IncidentTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_IncidentTypeToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_IncidentTypeToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_IncidentTypeToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The InjuryArea type */
export type Wp_InjuryArea = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_InjuryArea'
    children: Array<Node>
    /** Connection between the InjuryArea type and the ContentNode type */
    contentNodes?: Maybe<Wp_InjuryAreaToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the InjuryArea type and the Taxonomy type */
    taxonomy?: Maybe<Wp_InjuryAreaToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_InjuryAreaConnection = {
  __typename?: 'Wp_InjuryAreaConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_InjuryAreaEdge>
  group: Array<Wp_InjuryAreaGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_InjuryArea>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_InjuryAreaConnectionDistinctArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaConnectionGroupArgs = {
  field: Wp_InjuryAreaFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_InjuryAreaConnectionMaxArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaConnectionMinArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaConnectionSumArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaEdge = {
  __typename?: 'Wp_InjuryAreaEdge'
  next?: Maybe<Wp_InjuryArea>
  node: Wp_InjuryArea
  previous?: Maybe<Wp_InjuryArea>
}

export type Wp_InjuryAreaFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_InjuryAreaToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_InjuryAreaToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_InjuryAreaFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_InjuryAreaToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_InjuryAreaToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_InjuryAreaGroupConnection = {
  __typename?: 'Wp_InjuryAreaGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_InjuryAreaEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_InjuryAreaGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_InjuryArea>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_InjuryAreaGroupConnectionDistinctArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaGroupConnectionGroupArgs = {
  field: Wp_InjuryAreaFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_InjuryAreaGroupConnectionMaxArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaGroupConnectionMinArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaGroupConnectionSumArgs = {
  field: Wp_InjuryAreaFieldSelector
}

export type Wp_InjuryAreaSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_InjuryAreaToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_InjuryAreaToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the InjuryArea type and the ContentNode type */
export type Wp_InjuryAreaToContentNodeConnection = {
  __typename?: 'Wp_InjuryAreaToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_InjuryAreaToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_InjuryAreaToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_InjuryAreaToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the InjuryArea type and the Taxonomy type */
export type Wp_InjuryAreaToTaxonomyConnectionEdge = {
  __typename?: 'Wp_InjuryAreaToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_InjuryAreaToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_InjuryAreaToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_InjuryAreaToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The InspectionInterval type */
export type Wp_InspectionInterval = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_InspectionInterval'
    children: Array<Node>
    /** Connection between the InspectionInterval type and the ContentNode type */
    contentNodes?: Maybe<Wp_InspectionIntervalToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the InspectionInterval type and the Taxonomy type */
    taxonomy?: Maybe<Wp_InspectionIntervalToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_InspectionIntervalConnection = {
  __typename?: 'Wp_InspectionIntervalConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_InspectionIntervalEdge>
  group: Array<Wp_InspectionIntervalGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_InspectionInterval>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_InspectionIntervalConnectionDistinctArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalConnectionGroupArgs = {
  field: Wp_InspectionIntervalFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_InspectionIntervalConnectionMaxArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalConnectionMinArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalConnectionSumArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalEdge = {
  __typename?: 'Wp_InspectionIntervalEdge'
  next?: Maybe<Wp_InspectionInterval>
  node: Wp_InspectionInterval
  previous?: Maybe<Wp_InspectionInterval>
}

export type Wp_InspectionIntervalFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_InspectionIntervalToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_InspectionIntervalToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_InspectionIntervalFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_InspectionIntervalToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_InspectionIntervalToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_InspectionIntervalGroupConnection = {
  __typename?: 'Wp_InspectionIntervalGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_InspectionIntervalEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_InspectionIntervalGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_InspectionInterval>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_InspectionIntervalGroupConnectionDistinctArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalGroupConnectionGroupArgs = {
  field: Wp_InspectionIntervalFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_InspectionIntervalGroupConnectionMaxArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalGroupConnectionMinArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalGroupConnectionSumArgs = {
  field: Wp_InspectionIntervalFieldSelector
}

export type Wp_InspectionIntervalSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_InspectionIntervalToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_InspectionIntervalToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the InspectionInterval type and the ContentNode type */
export type Wp_InspectionIntervalToContentNodeConnection = {
  __typename?: 'Wp_InspectionIntervalToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_InspectionIntervalToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_InspectionIntervalToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_InspectionIntervalToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the InspectionInterval type and the Taxonomy type */
export type Wp_InspectionIntervalToTaxonomyConnectionEdge = {
  __typename?: 'Wp_InspectionIntervalToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_InspectionIntervalToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_InspectionIntervalToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_InspectionIntervalToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The integration configuration */
export type Wp_IntegrationConfig = {
  __typename?: 'Wp_IntegrationConfig'
  /** The active integrations. */
  activeIntegrations?: Maybe<Array<Maybe<Wp_ActiveIntegration>>>
  /** Whether the integration sync is disabled on the frontend. */
  isSyncDisabled?: Maybe<Scalars['Boolean']['output']>
  /** Whether the integration sync is currently in progress. */
  isSyncInProgress?: Maybe<Scalars['Boolean']['output']>
  /** The last time the integration sync was completed. In the format of Y-m-d\TH:i:s */
  lastSyncDate?: Maybe<Scalars['String']['output']>
  /** The API data source for the integration */
  source?: Maybe<Wp_IntegrationSourceEnum>
}

export type Wp_IntegrationConfigFieldSelector = {
  activeIntegrations?: InputMaybe<Wp_ActiveIntegrationFieldSelector>
  isSyncDisabled?: InputMaybe<FieldSelectorEnum>
  isSyncInProgress?: InputMaybe<FieldSelectorEnum>
  lastSyncDate?: InputMaybe<FieldSelectorEnum>
  source?: InputMaybe<FieldSelectorEnum>
}

export type Wp_IntegrationConfigFilterInput = {
  activeIntegrations?: InputMaybe<Wp_ActiveIntegrationFilterListInput>
  isSyncDisabled?: InputMaybe<BooleanQueryOperatorInput>
  isSyncInProgress?: InputMaybe<BooleanQueryOperatorInput>
  lastSyncDate?: InputMaybe<StringQueryOperatorInput>
  source?: InputMaybe<Wp_IntegrationSourceEnumQueryOperatorInput>
}

export type Wp_IntegrationConfigSortInput = {
  activeIntegrations?: InputMaybe<Wp_ActiveIntegrationSortInput>
  isSyncDisabled?: InputMaybe<SortOrderEnum>
  isSyncInProgress?: InputMaybe<SortOrderEnum>
  lastSyncDate?: InputMaybe<SortOrderEnum>
  source?: InputMaybe<SortOrderEnum>
}

/** The data type to sync via the API Integration */
export enum Wp_IntegrationSourceDataTypeEnum {
  Company = 'COMPANY',
  Employee = 'EMPLOYEE',
  Equipment = 'EQUIPMENT',
  Job = 'JOB',
}

export type Wp_IntegrationSourceDataTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_IntegrationSourceDataTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_IntegrationSourceDataTypeEnum>>>
  ne?: InputMaybe<Wp_IntegrationSourceDataTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_IntegrationSourceDataTypeEnum>>>
}

/** The integration API source */
export enum Wp_IntegrationSourceEnum {
  Foundation = 'FOUNDATION',
}

export type Wp_IntegrationSourceEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_IntegrationSourceEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_IntegrationSourceEnum>>>
  ne?: InputMaybe<Wp_IntegrationSourceEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_IntegrationSourceEnum>>>
}

/** The data type to sync via the API Integration */
export enum Wp_IntegrationWpDataTypeEnum {
  Company = 'COMPANY',
  Equipment = 'EQUIPMENT',
  Project = 'PROJECT',
  User = 'USER',
}

export type Wp_IntegrationWpDataTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_IntegrationWpDataTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_IntegrationWpDataTypeEnum>>>
  ne?: InputMaybe<Wp_IntegrationWpDataTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_IntegrationWpDataTypeEnum>>>
}

/** Language (Polylang) */
export type Wp_Language = {
  __typename?: 'Wp_Language'
  /** Language code (Polylang) */
  code?: Maybe<Wp_LanguageCodeEnum>
  /** Language term front page URL */
  homeUrl?: Maybe<Scalars['String']['output']>
  /** Language ID (Polylang) */
  id: Scalars['ID']['output']
  /** Language locale (Polylang) */
  locale?: Maybe<Scalars['String']['output']>
  /** Human readable language name (Polylang) */
  name?: Maybe<Scalars['String']['output']>
  /** Language term slug. Prefer the &quot;code&quot; field if possible (Polylang) */
  slug?: Maybe<Scalars['String']['output']>
}

/** Enum of all available language codes */
export enum Wp_LanguageCodeEnum {
  EnUs = 'EN_US',
  EsMx = 'ES_MX',
}

export type Wp_LanguageCodeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_LanguageCodeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_LanguageCodeEnum>>>
  ne?: InputMaybe<Wp_LanguageCodeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_LanguageCodeEnum>>>
}

export type Wp_LanguageFieldSelector = {
  code?: InputMaybe<FieldSelectorEnum>
  homeUrl?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  locale?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  slug?: InputMaybe<FieldSelectorEnum>
}

export type Wp_LanguageFilterInput = {
  code?: InputMaybe<Wp_LanguageCodeEnumQueryOperatorInput>
  homeUrl?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<IdQueryOperatorInput>
  locale?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_LanguageFilterListInput = {
  elemMatch?: InputMaybe<Wp_LanguageFilterInput>
}

export type Wp_LanguageSortInput = {
  code?: InputMaybe<SortOrderEnum>
  homeUrl?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  locale?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  slug?: InputMaybe<SortOrderEnum>
}

/** The Lesson type */
export type Wp_Lesson = Node &
  Wp_ContentNode &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_NodeWithAuthor &
  Wp_NodeWithContentEditor &
  Wp_NodeWithExcerpt &
  Wp_NodeWithFeaturedImage &
  Wp_NodeWithTemplate &
  Wp_NodeWithTitle &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Lesson'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<Wp_NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    children: Array<Node>
    /** Connection between the Lesson type and the Company type */
    companies?: Maybe<Wp_LessonToCompanyConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<Wp_ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['Date']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['Date']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the Lesson type and the Division type */
    divisions?: Maybe<Wp_LessonToDivisionConnection>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** The excerpt of the post. */
    excerpt?: Maybe<Scalars['String']['output']>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<Wp_ContentNodeToEditLastConnectionEdge>
    /** Connection between the Lesson type and the lessonCategory type */
    lessonCategories?: Maybe<Wp_LessonToLessonCategoryConnection>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Lesson Details&quot; was set to Show in GraphQL. */
    lessonDetails?: Maybe<Wp_Lesson_Lessondetails>
    /** Connection between the Lesson type and the lessonDifficulty type */
    lessonDifficulties?: Maybe<Wp_LessonToLessonDifficultyConnection>
    /** Connection between the Lesson type and the Document type */
    lessonDocumentConnectionConnection?: Maybe<Wp_LessonToDocumentConnection>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['Date']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['Date']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** Connection between the Lesson type and the Provider type */
    providers?: Maybe<Wp_LessonToProviderConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<Wp_ContentTemplate>
    /** Connection between the Lesson type and the TermNode type */
    terms?: Maybe<Wp_LessonToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** Connection between the Lesson type and the TrainingType type */
    trainingTypes?: Maybe<Wp_LessonToTrainingTypeConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Lesson type */
export type Wp_LessonDateArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The Lesson type */
export type Wp_LessonDateGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The Lesson type */
export type Wp_LessonModifiedArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The Lesson type */
export type Wp_LessonModifiedGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The lessonCategory type */
export type Wp_LessonCategory = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_LessonCategory'
    children: Array<Node>
    /** Connection between the LessonCategory type and the ContentNode type */
    contentNodes?: Maybe<Wp_LessonCategoryToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the LessonCategory type and the Lesson type */
    lessons?: Maybe<Wp_LessonCategoryToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the LessonCategory type and the Taxonomy type */
    taxonomy?: Maybe<Wp_LessonCategoryToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_LessonCategoryConnection = {
  __typename?: 'Wp_LessonCategoryConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_LessonCategoryEdge>
  group: Array<Wp_LessonCategoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_LessonCategory>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_LessonCategoryConnectionDistinctArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryConnectionGroupArgs = {
  field: Wp_LessonCategoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_LessonCategoryConnectionMaxArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryConnectionMinArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryConnectionSumArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryEdge = {
  __typename?: 'Wp_LessonCategoryEdge'
  next?: Maybe<Wp_LessonCategory>
  node: Wp_LessonCategory
  previous?: Maybe<Wp_LessonCategory>
}

export type Wp_LessonCategoryFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_LessonCategoryToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_LessonCategoryToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_LessonCategoryToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_LessonCategoryFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_LessonCategoryToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_LessonCategoryToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_LessonCategoryToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_LessonCategoryFilterListInput = {
  elemMatch?: InputMaybe<Wp_LessonCategoryFilterInput>
}

export type Wp_LessonCategoryGroupConnection = {
  __typename?: 'Wp_LessonCategoryGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_LessonCategoryEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_LessonCategoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_LessonCategory>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_LessonCategoryGroupConnectionDistinctArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryGroupConnectionGroupArgs = {
  field: Wp_LessonCategoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_LessonCategoryGroupConnectionMaxArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryGroupConnectionMinArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategoryGroupConnectionSumArgs = {
  field: Wp_LessonCategoryFieldSelector
}

export type Wp_LessonCategorySortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_LessonCategoryToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_LessonCategoryToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_LessonCategoryToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the LessonCategory type and the ContentNode type */
export type Wp_LessonCategoryToContentNodeConnection = {
  __typename?: 'Wp_LessonCategoryToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_LessonCategoryToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_LessonCategoryToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_LessonCategoryToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the LessonCategory type and the Lesson type */
export type Wp_LessonCategoryToLessonConnection = {
  __typename?: 'Wp_LessonCategoryToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_LessonCategoryToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_LessonCategoryToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_LessonCategoryToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the LessonCategory type and the Taxonomy type */
export type Wp_LessonCategoryToTaxonomyConnectionEdge = {
  __typename?: 'Wp_LessonCategoryToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_LessonCategoryToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_LessonCategoryToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_LessonCategoryToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_LessonConnection = {
  __typename?: 'Wp_LessonConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_LessonEdge>
  group: Array<Wp_LessonGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Lesson>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_LessonConnectionDistinctArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonConnectionGroupArgs = {
  field: Wp_LessonFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_LessonConnectionMaxArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonConnectionMinArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonConnectionSumArgs = {
  field: Wp_LessonFieldSelector
}

/** The lessonDifficulty type */
export type Wp_LessonDifficulty = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_LessonDifficulty'
    children: Array<Node>
    /** Connection between the LessonDifficulty type and the ContentNode type */
    contentNodes?: Maybe<Wp_LessonDifficultyToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the LessonDifficulty type and the Lesson type */
    lessons?: Maybe<Wp_LessonDifficultyToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the LessonDifficulty type and the Taxonomy type */
    taxonomy?: Maybe<Wp_LessonDifficultyToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_LessonDifficultyConnection = {
  __typename?: 'Wp_LessonDifficultyConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_LessonDifficultyEdge>
  group: Array<Wp_LessonDifficultyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_LessonDifficulty>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_LessonDifficultyConnectionDistinctArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyConnectionGroupArgs = {
  field: Wp_LessonDifficultyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_LessonDifficultyConnectionMaxArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyConnectionMinArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyConnectionSumArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyEdge = {
  __typename?: 'Wp_LessonDifficultyEdge'
  next?: Maybe<Wp_LessonDifficulty>
  node: Wp_LessonDifficulty
  previous?: Maybe<Wp_LessonDifficulty>
}

export type Wp_LessonDifficultyFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_LessonDifficultyToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_LessonDifficultyToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_LessonDifficultyToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_LessonDifficultyFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_LessonDifficultyToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_LessonDifficultyToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_LessonDifficultyToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_LessonDifficultyFilterListInput = {
  elemMatch?: InputMaybe<Wp_LessonDifficultyFilterInput>
}

export type Wp_LessonDifficultyGroupConnection = {
  __typename?: 'Wp_LessonDifficultyGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_LessonDifficultyEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_LessonDifficultyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_LessonDifficulty>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_LessonDifficultyGroupConnectionDistinctArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyGroupConnectionGroupArgs = {
  field: Wp_LessonDifficultyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_LessonDifficultyGroupConnectionMaxArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyGroupConnectionMinArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultyGroupConnectionSumArgs = {
  field: Wp_LessonDifficultyFieldSelector
}

export type Wp_LessonDifficultySortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_LessonDifficultyToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_LessonDifficultyToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_LessonDifficultyToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the LessonDifficulty type and the ContentNode type */
export type Wp_LessonDifficultyToContentNodeConnection = {
  __typename?: 'Wp_LessonDifficultyToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_LessonDifficultyToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_LessonDifficultyToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_LessonDifficultyToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the LessonDifficulty type and the Lesson type */
export type Wp_LessonDifficultyToLessonConnection = {
  __typename?: 'Wp_LessonDifficultyToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_LessonDifficultyToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_LessonDifficultyToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_LessonDifficultyToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the LessonDifficulty type and the Taxonomy type */
export type Wp_LessonDifficultyToTaxonomyConnectionEdge = {
  __typename?: 'Wp_LessonDifficultyToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_LessonDifficultyToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_LessonDifficultyToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_LessonDifficultyToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_LessonEdge = {
  __typename?: 'Wp_LessonEdge'
  next?: Maybe<Wp_Lesson>
  node: Wp_Lesson
  previous?: Maybe<Wp_Lesson>
}

export type Wp_LessonFieldSelector = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFieldSelector>
  authorDatabaseId?: InputMaybe<FieldSelectorEnum>
  authorId?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  companies?: InputMaybe<Wp_LessonToCompanyConnectionFieldSelector>
  content?: InputMaybe<FieldSelectorEnum>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFieldSelector>
  contentTypeName?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  date?: InputMaybe<FieldSelectorEnum>
  dateGmt?: InputMaybe<FieldSelectorEnum>
  desiredSlug?: InputMaybe<FieldSelectorEnum>
  divisions?: InputMaybe<Wp_LessonToDivisionConnectionFieldSelector>
  enclosure?: InputMaybe<FieldSelectorEnum>
  excerpt?: InputMaybe<FieldSelectorEnum>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFieldSelector>
  featuredImageDatabaseId?: InputMaybe<FieldSelectorEnum>
  featuredImageId?: InputMaybe<FieldSelectorEnum>
  guid?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFieldSelector>
  lessonCategories?: InputMaybe<Wp_LessonToLessonCategoryConnectionFieldSelector>
  lessonDetails?: InputMaybe<Wp_Lesson_LessondetailsFieldSelector>
  lessonDifficulties?: InputMaybe<Wp_LessonToLessonDifficultyConnectionFieldSelector>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_LessonToDocumentConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  modified?: InputMaybe<FieldSelectorEnum>
  modifiedGmt?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  providers?: InputMaybe<Wp_LessonToProviderConnectionFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  status?: InputMaybe<FieldSelectorEnum>
  template?: InputMaybe<Wp_ContentTemplateFieldSelector>
  terms?: InputMaybe<Wp_LessonToTermNodeConnectionFieldSelector>
  title?: InputMaybe<FieldSelectorEnum>
  trainingTypes?: InputMaybe<Wp_LessonToTrainingTypeConnectionFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_LessonFilterInput = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  companies?: InputMaybe<Wp_LessonToCompanyConnectionFilterInput>
  content?: InputMaybe<StringQueryOperatorInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  divisions?: InputMaybe<Wp_LessonToDivisionConnectionFilterInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  excerpt?: InputMaybe<StringQueryOperatorInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput>
  featuredImageDatabaseId?: InputMaybe<IntQueryOperatorInput>
  featuredImageId?: InputMaybe<IdQueryOperatorInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  lessonCategories?: InputMaybe<Wp_LessonToLessonCategoryConnectionFilterInput>
  lessonDetails?: InputMaybe<Wp_Lesson_LessondetailsFilterInput>
  lessonDifficulties?: InputMaybe<Wp_LessonToLessonDifficultyConnectionFilterInput>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_LessonToDocumentConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  providers?: InputMaybe<Wp_LessonToProviderConnectionFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  terms?: InputMaybe<Wp_LessonToTermNodeConnectionFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  trainingTypes?: InputMaybe<Wp_LessonToTrainingTypeConnectionFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_LessonFilterListInput = {
  elemMatch?: InputMaybe<Wp_LessonFilterInput>
}

export type Wp_LessonGroupConnection = {
  __typename?: 'Wp_LessonGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_LessonEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_LessonGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Lesson>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_LessonGroupConnectionDistinctArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonGroupConnectionGroupArgs = {
  field: Wp_LessonFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_LessonGroupConnectionMaxArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonGroupConnectionMinArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonGroupConnectionSumArgs = {
  field: Wp_LessonFieldSelector
}

export type Wp_LessonSortInput = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeSortInput>
  authorDatabaseId?: InputMaybe<SortOrderEnum>
  authorId?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  companies?: InputMaybe<Wp_LessonToCompanyConnectionSortInput>
  content?: InputMaybe<SortOrderEnum>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeSortInput>
  contentTypeName?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  date?: InputMaybe<SortOrderEnum>
  dateGmt?: InputMaybe<SortOrderEnum>
  desiredSlug?: InputMaybe<SortOrderEnum>
  divisions?: InputMaybe<Wp_LessonToDivisionConnectionSortInput>
  enclosure?: InputMaybe<SortOrderEnum>
  excerpt?: InputMaybe<SortOrderEnum>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeSortInput>
  featuredImageDatabaseId?: InputMaybe<SortOrderEnum>
  featuredImageId?: InputMaybe<SortOrderEnum>
  guid?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeSortInput>
  lessonCategories?: InputMaybe<Wp_LessonToLessonCategoryConnectionSortInput>
  lessonDetails?: InputMaybe<Wp_Lesson_LessondetailsSortInput>
  lessonDifficulties?: InputMaybe<Wp_LessonToLessonDifficultyConnectionSortInput>
  lessonDocumentConnectionConnection?: InputMaybe<Wp_LessonToDocumentConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  modified?: InputMaybe<SortOrderEnum>
  modifiedGmt?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  providers?: InputMaybe<Wp_LessonToProviderConnectionSortInput>
  slug?: InputMaybe<SortOrderEnum>
  status?: InputMaybe<SortOrderEnum>
  template?: InputMaybe<Wp_ContentTemplateSortInput>
  terms?: InputMaybe<Wp_LessonToTermNodeConnectionSortInput>
  title?: InputMaybe<SortOrderEnum>
  trainingTypes?: InputMaybe<Wp_LessonToTrainingTypeConnectionSortInput>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Lesson type and the Company type */
export type Wp_LessonToCompanyConnection = {
  __typename?: 'Wp_LessonToCompanyConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Company>>>
}

export type Wp_LessonToCompanyConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CompanyFieldSelector>
}

export type Wp_LessonToCompanyConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CompanyFilterListInput>
}

export type Wp_LessonToCompanyConnectionSortInput = {
  nodes?: InputMaybe<Wp_CompanySortInput>
}

/** Connection between the Lesson type and the Division type */
export type Wp_LessonToDivisionConnection = {
  __typename?: 'Wp_LessonToDivisionConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Division>>>
}

export type Wp_LessonToDivisionConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DivisionFieldSelector>
}

export type Wp_LessonToDivisionConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DivisionFilterListInput>
}

export type Wp_LessonToDivisionConnectionSortInput = {
  nodes?: InputMaybe<Wp_DivisionSortInput>
}

/** Connection between the Lesson type and the Document type */
export type Wp_LessonToDocumentConnection = {
  __typename?: 'Wp_LessonToDocumentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Document>>>
}

export type Wp_LessonToDocumentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DocumentFieldSelector>
}

export type Wp_LessonToDocumentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DocumentFilterListInput>
}

export type Wp_LessonToDocumentConnectionSortInput = {
  nodes?: InputMaybe<Wp_DocumentSortInput>
}

/** Connection between the Lesson type and the lessonCategory type */
export type Wp_LessonToLessonCategoryConnection = {
  __typename?: 'Wp_LessonToLessonCategoryConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_LessonCategory>>>
}

export type Wp_LessonToLessonCategoryConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonCategoryFieldSelector>
}

export type Wp_LessonToLessonCategoryConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonCategoryFilterListInput>
}

export type Wp_LessonToLessonCategoryConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonCategorySortInput>
}

/** Connection between the Lesson type and the lessonDifficulty type */
export type Wp_LessonToLessonDifficultyConnection = {
  __typename?: 'Wp_LessonToLessonDifficultyConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_LessonDifficulty>>>
}

export type Wp_LessonToLessonDifficultyConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonDifficultyFieldSelector>
}

export type Wp_LessonToLessonDifficultyConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonDifficultyFilterListInput>
}

export type Wp_LessonToLessonDifficultyConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonDifficultySortInput>
}

/** Connection between the Lesson type and the Provider type */
export type Wp_LessonToProviderConnection = {
  __typename?: 'Wp_LessonToProviderConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Provider>>>
}

export type Wp_LessonToProviderConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ProviderFieldSelector>
}

export type Wp_LessonToProviderConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ProviderFilterListInput>
}

export type Wp_LessonToProviderConnectionSortInput = {
  nodes?: InputMaybe<Wp_ProviderSortInput>
}

/** Connection between the Lesson type and the TermNode type */
export type Wp_LessonToTermNodeConnection = {
  __typename?: 'Wp_LessonToTermNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_TermNode>>>
}

export type Wp_LessonToTermNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_TermNodeFieldSelector>
}

export type Wp_LessonToTermNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_TermNodeFilterListInput>
}

export type Wp_LessonToTermNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_TermNodeSortInput>
}

/** Connection between the Lesson type and the TrainingType type */
export type Wp_LessonToTrainingTypeConnection = {
  __typename?: 'Wp_LessonToTrainingTypeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_TrainingType>>>
}

export type Wp_LessonToTrainingTypeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_TrainingTypeFieldSelector>
}

export type Wp_LessonToTrainingTypeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_TrainingTypeFilterListInput>
}

export type Wp_LessonToTrainingTypeConnectionSortInput = {
  nodes?: InputMaybe<Wp_TrainingTypeSortInput>
}

/** Field Group */
export type Wp_Lesson_Lessondetails = Wp_AcfFieldGroup & {
  __typename?: 'Wp_Lesson_Lessondetails'
  /** Set to zero for a lesson that does not expire. */
  courseExpriyLengthInMonths?: Maybe<Scalars['Float']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  lessonAudioDescription?: Maybe<Scalars['String']['output']>
  lessonAudioEmbed?: Maybe<Scalars['String']['output']>
  lessonAudioTitle?: Maybe<Scalars['String']['output']>
  lessonDescription?: Maybe<Scalars['String']['output']>
  /** Gravity Forms Form quiz */
  lessonQuiz?: Maybe<Wp_GfForm>
  lessonVideoDescription?: Maybe<Scalars['String']['output']>
  lessonVideoEmbed?: Maybe<Scalars['String']['output']>
  lessonVideoTitle?: Maybe<Scalars['String']['output']>
}

export type Wp_Lesson_LessondetailsFieldSelector = {
  courseExpriyLengthInMonths?: InputMaybe<FieldSelectorEnum>
  fieldGroupName?: InputMaybe<FieldSelectorEnum>
  lessonAudioDescription?: InputMaybe<FieldSelectorEnum>
  lessonAudioEmbed?: InputMaybe<FieldSelectorEnum>
  lessonAudioTitle?: InputMaybe<FieldSelectorEnum>
  lessonDescription?: InputMaybe<FieldSelectorEnum>
  lessonQuiz?: InputMaybe<Wp_GfFormFieldSelector>
  lessonVideoDescription?: InputMaybe<FieldSelectorEnum>
  lessonVideoEmbed?: InputMaybe<FieldSelectorEnum>
  lessonVideoTitle?: InputMaybe<FieldSelectorEnum>
}

export type Wp_Lesson_LessondetailsFilterInput = {
  courseExpriyLengthInMonths?: InputMaybe<FloatQueryOperatorInput>
  fieldGroupName?: InputMaybe<StringQueryOperatorInput>
  lessonAudioDescription?: InputMaybe<StringQueryOperatorInput>
  lessonAudioEmbed?: InputMaybe<StringQueryOperatorInput>
  lessonAudioTitle?: InputMaybe<StringQueryOperatorInput>
  lessonDescription?: InputMaybe<StringQueryOperatorInput>
  lessonQuiz?: InputMaybe<Wp_GfFormFilterInput>
  lessonVideoDescription?: InputMaybe<StringQueryOperatorInput>
  lessonVideoEmbed?: InputMaybe<StringQueryOperatorInput>
  lessonVideoTitle?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_Lesson_LessondetailsSortInput = {
  courseExpriyLengthInMonths?: InputMaybe<SortOrderEnum>
  fieldGroupName?: InputMaybe<SortOrderEnum>
  lessonAudioDescription?: InputMaybe<SortOrderEnum>
  lessonAudioEmbed?: InputMaybe<SortOrderEnum>
  lessonAudioTitle?: InputMaybe<SortOrderEnum>
  lessonDescription?: InputMaybe<SortOrderEnum>
  lessonQuiz?: InputMaybe<Wp_GfFormSortInput>
  lessonVideoDescription?: InputMaybe<SortOrderEnum>
  lessonVideoEmbed?: InputMaybe<SortOrderEnum>
  lessonVideoTitle?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_ListField = Wp_FormField & {
  __typename?: 'Wp_ListField'
  /** The URL of the image to be used for the add row button. */
  addIconUrl?: Maybe<Scalars['String']['output']>
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the list field. */
  choices?: Maybe<Array<Maybe<Wp_ListFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** The URL of the image to be used for the delete row button. */
  deleteIconUrl?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field should use multiple columns. Default is false. */
  hasColumns?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** List field value. */
  listValues?: Maybe<Array<Maybe<Wp_ListFieldValue>>>
  /** The maximum number of rows the user can add to the field. */
  maxRows?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** List choice values. */
export type Wp_ListFieldChoice = {
  __typename?: 'Wp_ListFieldChoice'
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** The individual properties for each element of the List value field. */
export type Wp_ListFieldValue = {
  __typename?: 'Wp_ListFieldValue'
  /** Input values. */
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** The Manufacturer type */
export type Wp_Manufacturer = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Manufacturer'
    children: Array<Node>
    /** Connection between the Manufacturer type and the ContentNode type */
    contentNodes?: Maybe<Wp_ManufacturerToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Manufacturer type and the Taxonomy type */
    taxonomy?: Maybe<Wp_ManufacturerToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_ManufacturerConnection = {
  __typename?: 'Wp_ManufacturerConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ManufacturerEdge>
  group: Array<Wp_ManufacturerGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Manufacturer>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ManufacturerConnectionDistinctArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerConnectionGroupArgs = {
  field: Wp_ManufacturerFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ManufacturerConnectionMaxArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerConnectionMinArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerConnectionSumArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerEdge = {
  __typename?: 'Wp_ManufacturerEdge'
  next?: Maybe<Wp_Manufacturer>
  node: Wp_Manufacturer
  previous?: Maybe<Wp_Manufacturer>
}

export type Wp_ManufacturerFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_ManufacturerToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_ManufacturerToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ManufacturerFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ManufacturerToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ManufacturerToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ManufacturerGroupConnection = {
  __typename?: 'Wp_ManufacturerGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ManufacturerEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ManufacturerGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Manufacturer>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ManufacturerGroupConnectionDistinctArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerGroupConnectionGroupArgs = {
  field: Wp_ManufacturerFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ManufacturerGroupConnectionMaxArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerGroupConnectionMinArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerGroupConnectionSumArgs = {
  field: Wp_ManufacturerFieldSelector
}

export type Wp_ManufacturerSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_ManufacturerToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_ManufacturerToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Manufacturer type and the ContentNode type */
export type Wp_ManufacturerToContentNodeConnection = {
  __typename?: 'Wp_ManufacturerToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_ManufacturerToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_ManufacturerToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_ManufacturerToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Manufacturer type and the Taxonomy type */
export type Wp_ManufacturerToTaxonomyConnectionEdge = {
  __typename?: 'Wp_ManufacturerToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_ManufacturerToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_ManufacturerToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_ManufacturerToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** File details for a Media Item */
export type Wp_MediaDetails = {
  __typename?: 'Wp_MediaDetails'
  /** The filename of the mediaItem */
  file?: Maybe<Scalars['String']['output']>
  /** The height of the mediaItem */
  height?: Maybe<Scalars['Int']['output']>
  /** Meta information associated with the mediaItem */
  meta?: Maybe<Wp_MediaItemMeta>
  /** The available sizes of the mediaItem */
  sizes?: Maybe<Array<Maybe<Wp_MediaSize>>>
  /** The width of the mediaItem */
  width?: Maybe<Scalars['Int']['output']>
}

export type Wp_MediaDetailsFieldSelector = {
  file?: InputMaybe<FieldSelectorEnum>
  height?: InputMaybe<FieldSelectorEnum>
  meta?: InputMaybe<Wp_MediaItemMetaFieldSelector>
  sizes?: InputMaybe<Wp_MediaSizeFieldSelector>
  width?: InputMaybe<FieldSelectorEnum>
}

export type Wp_MediaDetailsFilterInput = {
  file?: InputMaybe<StringQueryOperatorInput>
  height?: InputMaybe<IntQueryOperatorInput>
  meta?: InputMaybe<Wp_MediaItemMetaFilterInput>
  sizes?: InputMaybe<Wp_MediaSizeFilterListInput>
  width?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_MediaDetailsSortInput = {
  file?: InputMaybe<SortOrderEnum>
  height?: InputMaybe<SortOrderEnum>
  meta?: InputMaybe<Wp_MediaItemMetaSortInput>
  sizes?: InputMaybe<Wp_MediaSizeSortInput>
  width?: InputMaybe<SortOrderEnum>
}

/** The mediaItem type */
export type Wp_MediaItem = Node &
  RemoteFile &
  Wp_ContentNode &
  Wp_DatabaseIdentifier &
  Wp_HierarchicalContentNode &
  Wp_Node &
  Wp_NodeWithAuthor &
  Wp_NodeWithComments &
  Wp_NodeWithTemplate &
  Wp_NodeWithTitle &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_MediaItem'
    /** Alternative text to display when resource is not displayed */
    altText?: Maybe<Scalars['String']['output']>
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<Wp_HierarchicalContentNodeToContentNodeAncestorsConnection>
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<Wp_NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** The caption for the resource */
    caption?: Maybe<Scalars['String']['output']>
    children: Array<Node>
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>
    /** Connection between the MediaItem type and the Comment type */
    comments?: Maybe<Wp_MediaItemToCommentConnection>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<Wp_ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['Date']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['Date']['output']>
    /** Description of the image (stored as post_content) */
    description?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** The filesize in bytes of the resource */
    fileSize?: Maybe<Scalars['Int']['output']>
    filename: Scalars['String']['output']
    filesize?: Maybe<Scalars['Int']['output']>
    /** Data used in the <GatsbyImage /> component. See https://gatsby.dev/img for more info. */
    gatsbyImage?: Maybe<Scalars['GatsbyImageData']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    height?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Polylang language */
    language?: Maybe<Wp_Language>
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<Wp_ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    localFile?: Maybe<File>
    /** Details about the mediaItem */
    mediaDetails?: Maybe<Wp_MediaDetails>
    /** Url of the mediaItem */
    mediaItemUrl?: Maybe<Scalars['String']['output']>
    /** Type of resource */
    mediaType?: Maybe<Scalars['String']['output']>
    mimeType: Scalars['String']['output']
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['Date']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['Date']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>
    publicUrl: Scalars['String']['output']
    resize?: Maybe<RemoteFileResize>
    /** The sizes attribute value for an image. */
    sizes?: Maybe<Scalars['String']['output']>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** Url of the mediaItem */
    sourceUrl?: Maybe<Scalars['String']['output']>
    /** The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths. */
    srcSet?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<Wp_ContentTemplate>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Wp_MediaItem>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    width?: Maybe<Scalars['Int']['output']>
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    wpChildren?: Maybe<Wp_HierarchicalContentNodeToContentNodeChildrenConnection>
    /** The parent of the node. The parent object can be of various types */
    wpParent?: Maybe<Wp_HierarchicalContentNodeToParentContentNodeConnectionEdge>
  }

/** The mediaItem type */
export type Wp_MediaItemDateArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The mediaItem type */
export type Wp_MediaItemDateGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The mediaItem type */
export type Wp_MediaItemGatsbyImageArgs = {
  aspectRatio?: InputMaybe<Scalars['Float']['input']>
  backgroundColor?: InputMaybe<Scalars['String']['input']>
  breakpoints?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  cropFocus?: InputMaybe<Array<InputMaybe<RemoteFileCropFocus>>>
  fit?: InputMaybe<RemoteFileFit>
  formats?: InputMaybe<Array<RemoteFileFormat>>
  height?: InputMaybe<Scalars['Int']['input']>
  layout?: InputMaybe<RemoteFileLayout>
  outputPixelDensities?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >
  placeholder?: InputMaybe<RemoteFilePlaceholder>
  quality?: InputMaybe<Scalars['Int']['input']>
  sizes?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** The mediaItem type */
export type Wp_MediaItemModifiedArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The mediaItem type */
export type Wp_MediaItemModifiedGmtArgs = {
  difference?: InputMaybe<Scalars['String']['input']>
  formatString?: InputMaybe<Scalars['String']['input']>
  fromNow?: InputMaybe<Scalars['Boolean']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
}

/** The mediaItem type */
export type Wp_MediaItemResizeArgs = {
  aspectRatio?: InputMaybe<Scalars['Float']['input']>
  cropFocus?: InputMaybe<Array<InputMaybe<RemoteFileCropFocus>>>
  fit?: InputMaybe<RemoteFileFit>
  format?: InputMaybe<RemoteFileFormat>
  height?: InputMaybe<Scalars['Int']['input']>
  quality?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MediaItemConnection = {
  __typename?: 'Wp_MediaItemConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_MediaItemEdge>
  group: Array<Wp_MediaItemGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_MediaItem>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_MediaItemConnectionDistinctArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemConnectionGroupArgs = {
  field: Wp_MediaItemFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MediaItemConnectionMaxArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemConnectionMinArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemConnectionSumArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemEdge = {
  __typename?: 'Wp_MediaItemEdge'
  next?: Maybe<Wp_MediaItem>
  node: Wp_MediaItem
  previous?: Maybe<Wp_MediaItem>
}

export type Wp_MediaItemFieldSelector = {
  altText?: InputMaybe<FieldSelectorEnum>
  ancestors?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionFieldSelector>
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFieldSelector>
  authorDatabaseId?: InputMaybe<FieldSelectorEnum>
  authorId?: InputMaybe<FieldSelectorEnum>
  caption?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  commentCount?: InputMaybe<FieldSelectorEnum>
  commentStatus?: InputMaybe<FieldSelectorEnum>
  comments?: InputMaybe<Wp_MediaItemToCommentConnectionFieldSelector>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFieldSelector>
  contentTypeName?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  date?: InputMaybe<FieldSelectorEnum>
  dateGmt?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  desiredSlug?: InputMaybe<FieldSelectorEnum>
  enclosure?: InputMaybe<FieldSelectorEnum>
  fileSize?: InputMaybe<FieldSelectorEnum>
  filename?: InputMaybe<FieldSelectorEnum>
  filesize?: InputMaybe<FieldSelectorEnum>
  gatsbyImage?: InputMaybe<FieldSelectorEnum>
  guid?: InputMaybe<FieldSelectorEnum>
  height?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  language?: InputMaybe<Wp_LanguageFieldSelector>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  localFile?: InputMaybe<FileFieldSelector>
  mediaDetails?: InputMaybe<Wp_MediaDetailsFieldSelector>
  mediaItemUrl?: InputMaybe<FieldSelectorEnum>
  mediaType?: InputMaybe<FieldSelectorEnum>
  mimeType?: InputMaybe<FieldSelectorEnum>
  modified?: InputMaybe<FieldSelectorEnum>
  modifiedGmt?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  parentDatabaseId?: InputMaybe<FieldSelectorEnum>
  parentId?: InputMaybe<FieldSelectorEnum>
  publicUrl?: InputMaybe<FieldSelectorEnum>
  resize?: InputMaybe<RemoteFileResizeFieldSelector>
  sizes?: InputMaybe<FieldSelectorEnum>
  slug?: InputMaybe<FieldSelectorEnum>
  sourceUrl?: InputMaybe<FieldSelectorEnum>
  srcSet?: InputMaybe<FieldSelectorEnum>
  status?: InputMaybe<FieldSelectorEnum>
  template?: InputMaybe<Wp_ContentTemplateFieldSelector>
  title?: InputMaybe<FieldSelectorEnum>
  translations?: InputMaybe<Wp_MediaItemFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
  wpChildren?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeChildrenConnectionFieldSelector>
  wpParent?: InputMaybe<Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeFieldSelector>
}

export type Wp_MediaItemFilterInput = {
  altText?: InputMaybe<StringQueryOperatorInput>
  ancestors?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionFilterInput>
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  caption?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  commentCount?: InputMaybe<IntQueryOperatorInput>
  commentStatus?: InputMaybe<StringQueryOperatorInput>
  comments?: InputMaybe<Wp_MediaItemToCommentConnectionFilterInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeFilterInput>
  contentTypeName?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  date?: InputMaybe<DateQueryOperatorInput>
  dateGmt?: InputMaybe<DateQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  desiredSlug?: InputMaybe<StringQueryOperatorInput>
  enclosure?: InputMaybe<StringQueryOperatorInput>
  fileSize?: InputMaybe<IntQueryOperatorInput>
  filename?: InputMaybe<StringQueryOperatorInput>
  filesize?: InputMaybe<IntQueryOperatorInput>
  gatsbyImage?: InputMaybe<GatsbyImageDataQueryOperatorInput>
  guid?: InputMaybe<StringQueryOperatorInput>
  height?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  localFile?: InputMaybe<FileFilterInput>
  mediaDetails?: InputMaybe<Wp_MediaDetailsFilterInput>
  mediaItemUrl?: InputMaybe<StringQueryOperatorInput>
  mediaType?: InputMaybe<StringQueryOperatorInput>
  mimeType?: InputMaybe<StringQueryOperatorInput>
  modified?: InputMaybe<DateQueryOperatorInput>
  modifiedGmt?: InputMaybe<DateQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  publicUrl?: InputMaybe<StringQueryOperatorInput>
  resize?: InputMaybe<RemoteFileResizeFilterInput>
  sizes?: InputMaybe<StringQueryOperatorInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  sourceUrl?: InputMaybe<StringQueryOperatorInput>
  srcSet?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<StringQueryOperatorInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
  translations?: InputMaybe<Wp_MediaItemFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<IntQueryOperatorInput>
  wpChildren?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeChildrenConnectionFilterInput>
  wpParent?: InputMaybe<Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeFilterInput>
}

export type Wp_MediaItemFilterListInput = {
  elemMatch?: InputMaybe<Wp_MediaItemFilterInput>
}

export type Wp_MediaItemGroupConnection = {
  __typename?: 'Wp_MediaItemGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_MediaItemEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_MediaItemGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_MediaItem>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_MediaItemGroupConnectionDistinctArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemGroupConnectionGroupArgs = {
  field: Wp_MediaItemFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MediaItemGroupConnectionMaxArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemGroupConnectionMinArgs = {
  field: Wp_MediaItemFieldSelector
}

export type Wp_MediaItemGroupConnectionSumArgs = {
  field: Wp_MediaItemFieldSelector
}

/** Meta connected to a MediaItem */
export type Wp_MediaItemMeta = {
  __typename?: 'Wp_MediaItemMeta'
  /** Aperture measurement of the media item. */
  aperture?: Maybe<Scalars['Float']['output']>
  /** Information about the camera used to create the media item. */
  camera?: Maybe<Scalars['String']['output']>
  /** The text string description associated with the media item. */
  caption?: Maybe<Scalars['String']['output']>
  /** Copyright information associated with the media item. */
  copyright?: Maybe<Scalars['String']['output']>
  /** The date/time when the media was created. */
  createdTimestamp?: Maybe<Scalars['Int']['output']>
  /** The original creator of the media item. */
  credit?: Maybe<Scalars['String']['output']>
  /** The focal length value of the media item. */
  focalLength?: Maybe<Scalars['Float']['output']>
  /** The ISO (International Organization for Standardization) value of the media item. */
  iso?: Maybe<Scalars['Int']['output']>
  /** List of keywords used to describe or identfy the media item. */
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The vertical or horizontal aspect of the media item. */
  orientation?: Maybe<Scalars['String']['output']>
  /** The shutter speed information of the media item. */
  shutterSpeed?: Maybe<Scalars['Float']['output']>
  /** A useful title for the media item. */
  title?: Maybe<Scalars['String']['output']>
}

export type Wp_MediaItemMetaFieldSelector = {
  aperture?: InputMaybe<FieldSelectorEnum>
  camera?: InputMaybe<FieldSelectorEnum>
  caption?: InputMaybe<FieldSelectorEnum>
  copyright?: InputMaybe<FieldSelectorEnum>
  createdTimestamp?: InputMaybe<FieldSelectorEnum>
  credit?: InputMaybe<FieldSelectorEnum>
  focalLength?: InputMaybe<FieldSelectorEnum>
  iso?: InputMaybe<FieldSelectorEnum>
  keywords?: InputMaybe<FieldSelectorEnum>
  orientation?: InputMaybe<FieldSelectorEnum>
  shutterSpeed?: InputMaybe<FieldSelectorEnum>
  title?: InputMaybe<FieldSelectorEnum>
}

export type Wp_MediaItemMetaFilterInput = {
  aperture?: InputMaybe<FloatQueryOperatorInput>
  camera?: InputMaybe<StringQueryOperatorInput>
  caption?: InputMaybe<StringQueryOperatorInput>
  copyright?: InputMaybe<StringQueryOperatorInput>
  createdTimestamp?: InputMaybe<IntQueryOperatorInput>
  credit?: InputMaybe<StringQueryOperatorInput>
  focalLength?: InputMaybe<FloatQueryOperatorInput>
  iso?: InputMaybe<IntQueryOperatorInput>
  keywords?: InputMaybe<StringQueryOperatorInput>
  orientation?: InputMaybe<StringQueryOperatorInput>
  shutterSpeed?: InputMaybe<FloatQueryOperatorInput>
  title?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_MediaItemMetaSortInput = {
  aperture?: InputMaybe<SortOrderEnum>
  camera?: InputMaybe<SortOrderEnum>
  caption?: InputMaybe<SortOrderEnum>
  copyright?: InputMaybe<SortOrderEnum>
  createdTimestamp?: InputMaybe<SortOrderEnum>
  credit?: InputMaybe<SortOrderEnum>
  focalLength?: InputMaybe<SortOrderEnum>
  iso?: InputMaybe<SortOrderEnum>
  keywords?: InputMaybe<SortOrderEnum>
  orientation?: InputMaybe<SortOrderEnum>
  shutterSpeed?: InputMaybe<SortOrderEnum>
  title?: InputMaybe<SortOrderEnum>
}

export type Wp_MediaItemSortInput = {
  altText?: InputMaybe<SortOrderEnum>
  ancestors?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeAncestorsConnectionSortInput>
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeSortInput>
  authorDatabaseId?: InputMaybe<SortOrderEnum>
  authorId?: InputMaybe<SortOrderEnum>
  caption?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  commentCount?: InputMaybe<SortOrderEnum>
  commentStatus?: InputMaybe<SortOrderEnum>
  comments?: InputMaybe<Wp_MediaItemToCommentConnectionSortInput>
  contentType?: InputMaybe<Wp_ContentNodeToContentTypeConnectionEdgeSortInput>
  contentTypeName?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  date?: InputMaybe<SortOrderEnum>
  dateGmt?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  desiredSlug?: InputMaybe<SortOrderEnum>
  enclosure?: InputMaybe<SortOrderEnum>
  fileSize?: InputMaybe<SortOrderEnum>
  filename?: InputMaybe<SortOrderEnum>
  filesize?: InputMaybe<SortOrderEnum>
  gatsbyImage?: InputMaybe<SortOrderEnum>
  guid?: InputMaybe<SortOrderEnum>
  height?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  language?: InputMaybe<Wp_LanguageSortInput>
  lastEditedBy?: InputMaybe<Wp_ContentNodeToEditLastConnectionEdgeSortInput>
  link?: InputMaybe<SortOrderEnum>
  localFile?: InputMaybe<FileSortInput>
  mediaDetails?: InputMaybe<Wp_MediaDetailsSortInput>
  mediaItemUrl?: InputMaybe<SortOrderEnum>
  mediaType?: InputMaybe<SortOrderEnum>
  mimeType?: InputMaybe<SortOrderEnum>
  modified?: InputMaybe<SortOrderEnum>
  modifiedGmt?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  parentDatabaseId?: InputMaybe<SortOrderEnum>
  parentId?: InputMaybe<SortOrderEnum>
  publicUrl?: InputMaybe<SortOrderEnum>
  resize?: InputMaybe<RemoteFileResizeSortInput>
  sizes?: InputMaybe<SortOrderEnum>
  slug?: InputMaybe<SortOrderEnum>
  sourceUrl?: InputMaybe<SortOrderEnum>
  srcSet?: InputMaybe<SortOrderEnum>
  status?: InputMaybe<SortOrderEnum>
  template?: InputMaybe<Wp_ContentTemplateSortInput>
  title?: InputMaybe<SortOrderEnum>
  translations?: InputMaybe<Wp_MediaItemSortInput>
  uri?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
  wpChildren?: InputMaybe<Wp_HierarchicalContentNodeToContentNodeChildrenConnectionSortInput>
  wpParent?: InputMaybe<Wp_HierarchicalContentNodeToParentContentNodeConnectionEdgeSortInput>
}

/** Connection between the MediaItem type and the Comment type */
export type Wp_MediaItemToCommentConnection = {
  __typename?: 'Wp_MediaItemToCommentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Comment>>>
}

export type Wp_MediaItemToCommentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CommentFieldSelector>
}

export type Wp_MediaItemToCommentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CommentFilterListInput>
}

export type Wp_MediaItemToCommentConnectionSortInput = {
  nodes?: InputMaybe<Wp_CommentSortInput>
}

/** Details of an available size for a media item */
export type Wp_MediaSize = {
  __typename?: 'Wp_MediaSize'
  /** The filename of the referenced size */
  file?: Maybe<Scalars['String']['output']>
  /** The filesize of the resource */
  fileSize?: Maybe<Scalars['Int']['output']>
  /** The height of the referenced size */
  height?: Maybe<Scalars['String']['output']>
  /** The mime type of the referenced size */
  mimeType?: Maybe<Scalars['String']['output']>
  /** The referenced size name */
  name?: Maybe<Scalars['String']['output']>
  /** The url of the referenced size */
  sourceUrl?: Maybe<Scalars['String']['output']>
  /** The width of the referenced size */
  width?: Maybe<Scalars['String']['output']>
}

export type Wp_MediaSizeFieldSelector = {
  file?: InputMaybe<FieldSelectorEnum>
  fileSize?: InputMaybe<FieldSelectorEnum>
  height?: InputMaybe<FieldSelectorEnum>
  mimeType?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  sourceUrl?: InputMaybe<FieldSelectorEnum>
  width?: InputMaybe<FieldSelectorEnum>
}

export type Wp_MediaSizeFilterInput = {
  file?: InputMaybe<StringQueryOperatorInput>
  fileSize?: InputMaybe<IntQueryOperatorInput>
  height?: InputMaybe<StringQueryOperatorInput>
  mimeType?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  sourceUrl?: InputMaybe<StringQueryOperatorInput>
  width?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_MediaSizeFilterListInput = {
  elemMatch?: InputMaybe<Wp_MediaSizeFilterInput>
}

export type Wp_MediaSizeSortInput = {
  file?: InputMaybe<SortOrderEnum>
  fileSize?: InputMaybe<SortOrderEnum>
  height?: InputMaybe<SortOrderEnum>
  mimeType?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  sourceUrl?: InputMaybe<SortOrderEnum>
  width?: InputMaybe<SortOrderEnum>
}

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type Wp_Menu = Node &
  Wp_DatabaseIdentifier &
  Wp_Node & {
    __typename?: 'Wp_Menu'
    children: Array<Node>
    /** The number of items in the menu */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    id: Scalars['ID']['output']
    internal: Internal
    /** The locations a menu is assigned to */
    locations?: Maybe<Array<Maybe<Wp_MenuLocationEnum>>>
    /** Connection between the Menu type and the MenuItem type */
    menuItems?: Maybe<Wp_MenuToMenuItemConnection>
    /** Display name of the menu. Equivalent to WP_Term-&gt;name. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** The url friendly name of the menu. Equivalent to WP_Term-&gt;slug */
    slug?: Maybe<Scalars['String']['output']>
  }

export type Wp_MenuConnection = {
  __typename?: 'Wp_MenuConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_MenuEdge>
  group: Array<Wp_MenuGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Menu>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_MenuConnectionDistinctArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuConnectionGroupArgs = {
  field: Wp_MenuFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MenuConnectionMaxArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuConnectionMinArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuConnectionSumArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuEdge = {
  __typename?: 'Wp_MenuEdge'
  next?: Maybe<Wp_Menu>
  node: Wp_Menu
  previous?: Maybe<Wp_Menu>
}

export type Wp_MenuFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  locations?: InputMaybe<FieldSelectorEnum>
  menuItems?: InputMaybe<Wp_MenuToMenuItemConnectionFieldSelector>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
}

export type Wp_MenuFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  locations?: InputMaybe<Wp_MenuLocationEnumQueryOperatorInput>
  menuItems?: InputMaybe<Wp_MenuToMenuItemConnectionFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_MenuGroupConnection = {
  __typename?: 'Wp_MenuGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_MenuEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_MenuGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Menu>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_MenuGroupConnectionDistinctArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuGroupConnectionGroupArgs = {
  field: Wp_MenuFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MenuGroupConnectionMaxArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuGroupConnectionMinArgs = {
  field: Wp_MenuFieldSelector
}

export type Wp_MenuGroupConnectionSumArgs = {
  field: Wp_MenuFieldSelector
}

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type Wp_MenuItem = Node &
  Wp_DatabaseIdentifier &
  Wp_Node & {
    __typename?: 'Wp_MenuItem'
    /** Connection between the MenuItem type and the MenuItem type */
    childItems?: Maybe<Wp_MenuItemToMenuItemConnection>
    children: Array<Node>
    /** Connection from MenuItem to it&#039;s connected node */
    connectedNode?: Maybe<Wp_MenuItemToMenuItemLinkableConnectionEdge>
    /** Class attribute for the menu item link */
    cssClasses?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Description of the menu item. */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Label or title of the menu item. */
    label?: Maybe<Scalars['String']['output']>
    /** Link relationship (XFN) of the menu item. */
    linkRelationship?: Maybe<Scalars['String']['output']>
    /** The locations the menu item&#039;s Menu is assigned to */
    locations?: Maybe<Array<Maybe<Wp_MenuLocationEnum>>>
    /** The Menu a MenuItem is part of */
    menu?: Maybe<Wp_MenuItemToMenuConnectionEdge>
    nodeType?: Maybe<Scalars['String']['output']>
    /** Menu item order */
    order?: Maybe<Scalars['Int']['output']>
    parent?: Maybe<Node>
    /** The database id of the parent menu item or null if it is the root */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent nav menu item object. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** Path for the resource. Relative path for internal resources. Absolute path for external resources. */
    path?: Maybe<Scalars['String']['output']>
    /** Target attribute for the menu item link. */
    target?: Maybe<Scalars['String']['output']>
    /** Title attribute for the menu item link */
    title?: Maybe<Scalars['String']['output']>
    /** The uri of the resource the menu item links to */
    uri?: Maybe<Scalars['String']['output']>
    /** URL or destination of the menu item. */
    url?: Maybe<Scalars['String']['output']>
  }

export type Wp_MenuItemConnection = {
  __typename?: 'Wp_MenuItemConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_MenuItemEdge>
  group: Array<Wp_MenuItemGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_MenuItem>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_MenuItemConnectionDistinctArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemConnectionGroupArgs = {
  field: Wp_MenuItemFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MenuItemConnectionMaxArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemConnectionMinArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemConnectionSumArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemEdge = {
  __typename?: 'Wp_MenuItemEdge'
  next?: Maybe<Wp_MenuItem>
  node: Wp_MenuItem
  previous?: Maybe<Wp_MenuItem>
}

export type Wp_MenuItemFieldSelector = {
  childItems?: InputMaybe<Wp_MenuItemToMenuItemConnectionFieldSelector>
  children?: InputMaybe<NodeFieldSelector>
  connectedNode?: InputMaybe<Wp_MenuItemToMenuItemLinkableConnectionEdgeFieldSelector>
  cssClasses?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  label?: InputMaybe<FieldSelectorEnum>
  linkRelationship?: InputMaybe<FieldSelectorEnum>
  locations?: InputMaybe<FieldSelectorEnum>
  menu?: InputMaybe<Wp_MenuItemToMenuConnectionEdgeFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  order?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  parentDatabaseId?: InputMaybe<FieldSelectorEnum>
  parentId?: InputMaybe<FieldSelectorEnum>
  path?: InputMaybe<FieldSelectorEnum>
  target?: InputMaybe<FieldSelectorEnum>
  title?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
  url?: InputMaybe<FieldSelectorEnum>
}

export type Wp_MenuItemFilterInput = {
  childItems?: InputMaybe<Wp_MenuItemToMenuItemConnectionFilterInput>
  children?: InputMaybe<NodeFilterListInput>
  connectedNode?: InputMaybe<Wp_MenuItemToMenuItemLinkableConnectionEdgeFilterInput>
  cssClasses?: InputMaybe<StringQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  label?: InputMaybe<StringQueryOperatorInput>
  linkRelationship?: InputMaybe<StringQueryOperatorInput>
  locations?: InputMaybe<Wp_MenuLocationEnumQueryOperatorInput>
  menu?: InputMaybe<Wp_MenuItemToMenuConnectionEdgeFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  order?: InputMaybe<IntQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  parentDatabaseId?: InputMaybe<IntQueryOperatorInput>
  parentId?: InputMaybe<IdQueryOperatorInput>
  path?: InputMaybe<StringQueryOperatorInput>
  target?: InputMaybe<StringQueryOperatorInput>
  title?: InputMaybe<StringQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_MenuItemFilterListInput = {
  elemMatch?: InputMaybe<Wp_MenuItemFilterInput>
}

export type Wp_MenuItemGroupConnection = {
  __typename?: 'Wp_MenuItemGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_MenuItemEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_MenuItemGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_MenuItem>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_MenuItemGroupConnectionDistinctArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemGroupConnectionGroupArgs = {
  field: Wp_MenuItemFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_MenuItemGroupConnectionMaxArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemGroupConnectionMinArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemGroupConnectionSumArgs = {
  field: Wp_MenuItemFieldSelector
}

export type Wp_MenuItemLinkable = {
  /** The unique resource identifier path */
  databaseId: Scalars['Int']['output']
  /** The unique resource identifier path */
  id: Scalars['ID']['output']
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

export type Wp_MenuItemLinkableFieldSelector = {
  databaseId?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_MenuItemLinkableFilterInput = {
  databaseId?: InputMaybe<IntQueryOperatorInput>
  id?: InputMaybe<IdQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_MenuItemLinkableSortInput = {
  databaseId?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

export type Wp_MenuItemSortInput = {
  childItems?: InputMaybe<Wp_MenuItemToMenuItemConnectionSortInput>
  children?: InputMaybe<NodeSortInput>
  connectedNode?: InputMaybe<Wp_MenuItemToMenuItemLinkableConnectionEdgeSortInput>
  cssClasses?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  label?: InputMaybe<SortOrderEnum>
  linkRelationship?: InputMaybe<SortOrderEnum>
  locations?: InputMaybe<SortOrderEnum>
  menu?: InputMaybe<Wp_MenuItemToMenuConnectionEdgeSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  order?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  parentDatabaseId?: InputMaybe<SortOrderEnum>
  parentId?: InputMaybe<SortOrderEnum>
  path?: InputMaybe<SortOrderEnum>
  target?: InputMaybe<SortOrderEnum>
  title?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
  url?: InputMaybe<SortOrderEnum>
}

/** Connection between the MenuItem type and the Menu type */
export type Wp_MenuItemToMenuConnectionEdge = {
  __typename?: 'Wp_MenuItemToMenuConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Menu>
}

export type Wp_MenuItemToMenuConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_MenuFieldSelector>
}

export type Wp_MenuItemToMenuConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_MenuFilterInput>
}

export type Wp_MenuItemToMenuConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_MenuSortInput>
}

/** Connection between the MenuItem type and the MenuItem type */
export type Wp_MenuItemToMenuItemConnection = {
  __typename?: 'Wp_MenuItemToMenuItemConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_MenuItem>>>
}

export type Wp_MenuItemToMenuItemConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_MenuItemFieldSelector>
}

export type Wp_MenuItemToMenuItemConnectionFilterInput = {
  nodes?: InputMaybe<Wp_MenuItemFilterListInput>
}

export type Wp_MenuItemToMenuItemConnectionSortInput = {
  nodes?: InputMaybe<Wp_MenuItemSortInput>
}

/** Connection between the MenuItem type and the MenuItemLinkable type */
export type Wp_MenuItemToMenuItemLinkableConnectionEdge = {
  __typename?: 'Wp_MenuItemToMenuItemLinkableConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_MenuItemLinkable>
}

export type Wp_MenuItemToMenuItemLinkableConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_MenuItemLinkableFieldSelector>
}

export type Wp_MenuItemToMenuItemLinkableConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_MenuItemLinkableFilterInput>
}

export type Wp_MenuItemToMenuItemLinkableConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_MenuItemLinkableSortInput>
}

/** Registered menu locations */
export enum Wp_MenuLocationEnum {
  Empty = 'EMPTY',
}

export type Wp_MenuLocationEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_MenuLocationEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_MenuLocationEnum>>>
  ne?: InputMaybe<Wp_MenuLocationEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_MenuLocationEnum>>>
}

export type Wp_MenuSortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  locations?: InputMaybe<SortOrderEnum>
  menuItems?: InputMaybe<Wp_MenuToMenuItemConnectionSortInput>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
}

/** Connection between the Menu type and the MenuItem type */
export type Wp_MenuToMenuItemConnection = {
  __typename?: 'Wp_MenuToMenuItemConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_MenuItem>>>
}

export type Wp_MenuToMenuItemConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_MenuItemFieldSelector>
}

export type Wp_MenuToMenuItemConnectionFilterInput = {
  nodes?: InputMaybe<Wp_MenuItemFilterListInput>
}

export type Wp_MenuToMenuItemConnectionSortInput = {
  nodes?: InputMaybe<Wp_MenuItemSortInput>
}

/** A Gravity Forms   field. */
export type Wp_MultiSelectField = Wp_FormField & {
  __typename?: 'Wp_MultiSelectField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The free solo boolean to allow custom option for selected fields */
  allowCustomOption?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the multiselect field. */
  choices?: Maybe<Array<Maybe<Wp_MultiSelectFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** An array of field values. */
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The label for the chosen value. */
  valuesText?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Multiselect choice values. */
export type Wp_MultiSelectFieldChoice = {
  __typename?: 'Wp_MultiSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type Wp_NameField = Wp_FormField & {
  __typename?: 'Wp_NameField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the name field. */
  inputs?: Maybe<Array<Maybe<Wp_NameInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Name Field Specific Translations */
  nameTranslations?: Maybe<Array<Maybe<Wp_NameTranslation>>>
  /** Name field value. */
  nameValues?: Maybe<Wp_NameFieldValue>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Name choice values. */
export type Wp_NameFieldChoice = {
  __typename?: 'Wp_NameFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** The individual properties for each element of the Name value field. */
export type Wp_NameFieldValue = {
  __typename?: 'Wp_NameFieldValue'
  /** First name. */
  first?: Maybe<Scalars['String']['output']>
  /** Last name. */
  last?: Maybe<Scalars['String']['output']>
  /** Middle name. */
  middle?: Maybe<Scalars['String']['output']>
  /** Prefix, such as Mr., Mrs. etc. */
  prefix?: Maybe<Scalars['String']['output']>
  /** Suffix, such as Sr., Jr. etc. */
  suffix?: Maybe<Scalars['String']['output']>
}

/** Name input values. */
export type Wp_NameInputProperty = {
  __typename?: 'Wp_NameInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The available choices for the name field. */
  choices?: Maybe<Array<Maybe<Wp_NameFieldChoice>>>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']['output']>
  /** Key used to identify this input. */
  key?: Maybe<Scalars['String']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms translation settings */
export type Wp_NameTranslation = {
  __typename?: 'Wp_NameTranslation'
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** First Name */
  first?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Last Name */
  last?: Maybe<Scalars['String']['output']>
  /** Middle Name */
  middle?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Prefix Name */
  prefix?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
  /** Suffix Name */
  suffix?: Maybe<Scalars['String']['output']>
}

export type Wp_Node = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

export type Wp_NodeWithAuthor = {
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<Wp_NodeWithAuthorToUserConnectionEdge>
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']['output']>
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithAuthorConnection = {
  __typename?: 'Wp_NodeWithAuthorConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithAuthorEdge>
  group: Array<Wp_NodeWithAuthorGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithAuthor>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithAuthorConnectionDistinctArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorConnectionGroupArgs = {
  field: Wp_NodeWithAuthorFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithAuthorConnectionMaxArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorConnectionMinArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorConnectionSumArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorEdge = {
  __typename?: 'Wp_NodeWithAuthorEdge'
  next?: Maybe<Wp_NodeWithAuthor>
  node: Wp_NodeWithAuthor
  previous?: Maybe<Wp_NodeWithAuthor>
}

export type Wp_NodeWithAuthorFieldSelector = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFieldSelector>
  authorDatabaseId?: InputMaybe<FieldSelectorEnum>
  authorId?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithAuthorFilterInput = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeFilterInput>
  authorDatabaseId?: InputMaybe<IntQueryOperatorInput>
  authorId?: InputMaybe<IdQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithAuthorGroupConnection = {
  __typename?: 'Wp_NodeWithAuthorGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithAuthorEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithAuthorGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithAuthor>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithAuthorGroupConnectionDistinctArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorGroupConnectionGroupArgs = {
  field: Wp_NodeWithAuthorFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithAuthorGroupConnectionMaxArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorGroupConnectionMinArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorGroupConnectionSumArgs = {
  field: Wp_NodeWithAuthorFieldSelector
}

export type Wp_NodeWithAuthorSortInput = {
  author?: InputMaybe<Wp_NodeWithAuthorToUserConnectionEdgeSortInput>
  authorDatabaseId?: InputMaybe<SortOrderEnum>
  authorId?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

/** Connection between the NodeWithAuthor type and the User type */
export type Wp_NodeWithAuthorToUserConnectionEdge = {
  __typename?: 'Wp_NodeWithAuthorToUserConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_User>
}

export type Wp_NodeWithAuthorToUserConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_UserFieldSelector>
}

export type Wp_NodeWithAuthorToUserConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_UserFilterInput>
}

export type Wp_NodeWithAuthorToUserConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_UserSortInput>
}

export type Wp_NodeWithComments = {
  children: Array<Node>
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount?: Maybe<Scalars['Int']['output']>
  /** Whether the comments are open or closed for this particular post. */
  commentStatus?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithCommentsConnection = {
  __typename?: 'Wp_NodeWithCommentsConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithCommentsEdge>
  group: Array<Wp_NodeWithCommentsGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithComments>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithCommentsConnectionDistinctArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsConnectionGroupArgs = {
  field: Wp_NodeWithCommentsFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithCommentsConnectionMaxArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsConnectionMinArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsConnectionSumArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsEdge = {
  __typename?: 'Wp_NodeWithCommentsEdge'
  next?: Maybe<Wp_NodeWithComments>
  node: Wp_NodeWithComments
  previous?: Maybe<Wp_NodeWithComments>
}

export type Wp_NodeWithCommentsFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  commentCount?: InputMaybe<FieldSelectorEnum>
  commentStatus?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithCommentsFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  commentCount?: InputMaybe<IntQueryOperatorInput>
  commentStatus?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithCommentsGroupConnection = {
  __typename?: 'Wp_NodeWithCommentsGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithCommentsEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithCommentsGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithComments>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithCommentsGroupConnectionDistinctArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsGroupConnectionGroupArgs = {
  field: Wp_NodeWithCommentsFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithCommentsGroupConnectionMaxArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsGroupConnectionMinArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsGroupConnectionSumArgs = {
  field: Wp_NodeWithCommentsFieldSelector
}

export type Wp_NodeWithCommentsSortInput = {
  children?: InputMaybe<NodeSortInput>
  commentCount?: InputMaybe<SortOrderEnum>
  commentStatus?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

export type Wp_NodeWithContentEditor = {
  children: Array<Node>
  /** The content of the post. */
  content?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithContentEditorConnection = {
  __typename?: 'Wp_NodeWithContentEditorConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithContentEditorEdge>
  group: Array<Wp_NodeWithContentEditorGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithContentEditor>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithContentEditorConnectionDistinctArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorConnectionGroupArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithContentEditorConnectionMaxArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorConnectionMinArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorConnectionSumArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorEdge = {
  __typename?: 'Wp_NodeWithContentEditorEdge'
  next?: Maybe<Wp_NodeWithContentEditor>
  node: Wp_NodeWithContentEditor
  previous?: Maybe<Wp_NodeWithContentEditor>
}

export type Wp_NodeWithContentEditorFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  content?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithContentEditorFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  content?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithContentEditorGroupConnection = {
  __typename?: 'Wp_NodeWithContentEditorGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithContentEditorEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithContentEditorGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithContentEditor>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithContentEditorGroupConnectionDistinctArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorGroupConnectionGroupArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithContentEditorGroupConnectionMaxArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorGroupConnectionMinArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorGroupConnectionSumArgs = {
  field: Wp_NodeWithContentEditorFieldSelector
}

export type Wp_NodeWithContentEditorSortInput = {
  children?: InputMaybe<NodeSortInput>
  content?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

export type Wp_NodeWithExcerpt = {
  children: Array<Node>
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithExcerptConnection = {
  __typename?: 'Wp_NodeWithExcerptConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithExcerptEdge>
  group: Array<Wp_NodeWithExcerptGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithExcerpt>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithExcerptConnectionDistinctArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptConnectionGroupArgs = {
  field: Wp_NodeWithExcerptFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithExcerptConnectionMaxArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptConnectionMinArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptConnectionSumArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptEdge = {
  __typename?: 'Wp_NodeWithExcerptEdge'
  next?: Maybe<Wp_NodeWithExcerpt>
  node: Wp_NodeWithExcerpt
  previous?: Maybe<Wp_NodeWithExcerpt>
}

export type Wp_NodeWithExcerptFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  excerpt?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithExcerptFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  excerpt?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithExcerptGroupConnection = {
  __typename?: 'Wp_NodeWithExcerptGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithExcerptEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithExcerptGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithExcerpt>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithExcerptGroupConnectionDistinctArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptGroupConnectionGroupArgs = {
  field: Wp_NodeWithExcerptFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithExcerptGroupConnectionMaxArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptGroupConnectionMinArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptGroupConnectionSumArgs = {
  field: Wp_NodeWithExcerptFieldSelector
}

export type Wp_NodeWithExcerptSortInput = {
  children?: InputMaybe<NodeSortInput>
  excerpt?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

export type Wp_NodeWithFeaturedImage = {
  children: Array<Node>
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdge>
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithFeaturedImageConnection = {
  __typename?: 'Wp_NodeWithFeaturedImageConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithFeaturedImageEdge>
  group: Array<Wp_NodeWithFeaturedImageGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithFeaturedImage>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithFeaturedImageConnectionDistinctArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageConnectionGroupArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithFeaturedImageConnectionMaxArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageConnectionMinArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageConnectionSumArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageEdge = {
  __typename?: 'Wp_NodeWithFeaturedImageEdge'
  next?: Maybe<Wp_NodeWithFeaturedImage>
  node: Wp_NodeWithFeaturedImage
  previous?: Maybe<Wp_NodeWithFeaturedImage>
}

export type Wp_NodeWithFeaturedImageFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFieldSelector>
  featuredImageDatabaseId?: InputMaybe<FieldSelectorEnum>
  featuredImageId?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithFeaturedImageFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput>
  featuredImageDatabaseId?: InputMaybe<IntQueryOperatorInput>
  featuredImageId?: InputMaybe<IdQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithFeaturedImageGroupConnection = {
  __typename?: 'Wp_NodeWithFeaturedImageGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithFeaturedImageEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithFeaturedImageGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithFeaturedImage>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithFeaturedImageGroupConnectionDistinctArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageGroupConnectionGroupArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithFeaturedImageGroupConnectionMaxArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageGroupConnectionMinArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageGroupConnectionSumArgs = {
  field: Wp_NodeWithFeaturedImageFieldSelector
}

export type Wp_NodeWithFeaturedImageSortInput = {
  children?: InputMaybe<NodeSortInput>
  featuredImage?: InputMaybe<Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeSortInput>
  featuredImageDatabaseId?: InputMaybe<SortOrderEnum>
  featuredImageId?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

/** Connection between the NodeWithFeaturedImage type and the MediaItem type */
export type Wp_NodeWithFeaturedImageToMediaItemConnectionEdge = {
  __typename?: 'Wp_NodeWithFeaturedImageToMediaItemConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_MediaItem>
}

export type Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_MediaItemFieldSelector>
}

export type Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_MediaItemFilterInput>
}

export type Wp_NodeWithFeaturedImageToMediaItemConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_MediaItemSortInput>
}

export type Wp_NodeWithForm = {
  /** The form object of the node. */
  form?: Maybe<Wp_GfForm>
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']['output']>
}

export type Wp_NodeWithPageAttributes = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']['output']>
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithPageAttributesConnection = {
  __typename?: 'Wp_NodeWithPageAttributesConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithPageAttributesEdge>
  group: Array<Wp_NodeWithPageAttributesGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithPageAttributes>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithPageAttributesConnectionDistinctArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesConnectionGroupArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithPageAttributesConnectionMaxArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesConnectionMinArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesConnectionSumArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesEdge = {
  __typename?: 'Wp_NodeWithPageAttributesEdge'
  next?: Maybe<Wp_NodeWithPageAttributes>
  node: Wp_NodeWithPageAttributes
  previous?: Maybe<Wp_NodeWithPageAttributes>
}

export type Wp_NodeWithPageAttributesFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  menuOrder?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithPageAttributesFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  menuOrder?: InputMaybe<IntQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithPageAttributesGroupConnection = {
  __typename?: 'Wp_NodeWithPageAttributesGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithPageAttributesEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithPageAttributesGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithPageAttributes>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithPageAttributesGroupConnectionDistinctArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesGroupConnectionGroupArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithPageAttributesGroupConnectionMaxArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesGroupConnectionMinArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesGroupConnectionSumArgs = {
  field: Wp_NodeWithPageAttributesFieldSelector
}

export type Wp_NodeWithPageAttributesSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  menuOrder?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

export type Wp_NodeWithRevisions = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']['output']>
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
}

export type Wp_NodeWithRevisionsConnection = {
  __typename?: 'Wp_NodeWithRevisionsConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithRevisionsEdge>
  group: Array<Wp_NodeWithRevisionsGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithRevisions>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithRevisionsConnectionDistinctArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsConnectionGroupArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithRevisionsConnectionMaxArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsConnectionMinArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsConnectionSumArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsEdge = {
  __typename?: 'Wp_NodeWithRevisionsEdge'
  next?: Maybe<Wp_NodeWithRevisions>
  node: Wp_NodeWithRevisions
  previous?: Maybe<Wp_NodeWithRevisions>
}

export type Wp_NodeWithRevisionsFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isRevision?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_NodeWithRevisionsFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isRevision?: InputMaybe<BooleanQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_NodeWithRevisionsGroupConnection = {
  __typename?: 'Wp_NodeWithRevisionsGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithRevisionsEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithRevisionsGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithRevisions>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithRevisionsGroupConnectionDistinctArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsGroupConnectionGroupArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithRevisionsGroupConnectionMaxArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsGroupConnectionMinArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsGroupConnectionSumArgs = {
  field: Wp_NodeWithRevisionsFieldSelector
}

export type Wp_NodeWithRevisionsSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isRevision?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

/** Connection between the NodeWithRevisions type and the ContentNode type */
export type Wp_NodeWithRevisionsToContentNodeConnectionEdge = {
  __typename?: 'Wp_NodeWithRevisionsToContentNodeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_ContentNode>
}

export type Wp_NodeWithTemplate = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** The template assigned to the node */
  template?: Maybe<Wp_ContentTemplate>
}

export type Wp_NodeWithTemplateConnection = {
  __typename?: 'Wp_NodeWithTemplateConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithTemplateEdge>
  group: Array<Wp_NodeWithTemplateGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithTemplate>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithTemplateConnectionDistinctArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateConnectionGroupArgs = {
  field: Wp_NodeWithTemplateFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithTemplateConnectionMaxArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateConnectionMinArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateConnectionSumArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateEdge = {
  __typename?: 'Wp_NodeWithTemplateEdge'
  next?: Maybe<Wp_NodeWithTemplate>
  node: Wp_NodeWithTemplate
  previous?: Maybe<Wp_NodeWithTemplate>
}

export type Wp_NodeWithTemplateFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  template?: InputMaybe<Wp_ContentTemplateFieldSelector>
}

export type Wp_NodeWithTemplateFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  template?: InputMaybe<Wp_ContentTemplateFilterInput>
}

export type Wp_NodeWithTemplateGroupConnection = {
  __typename?: 'Wp_NodeWithTemplateGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithTemplateEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithTemplateGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithTemplate>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithTemplateGroupConnectionDistinctArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateGroupConnectionGroupArgs = {
  field: Wp_NodeWithTemplateFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithTemplateGroupConnectionMaxArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateGroupConnectionMinArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateGroupConnectionSumArgs = {
  field: Wp_NodeWithTemplateFieldSelector
}

export type Wp_NodeWithTemplateSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  template?: InputMaybe<Wp_ContentTemplateSortInput>
}

export type Wp_NodeWithTitle = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']['output']>
}

export type Wp_NodeWithTitleConnection = {
  __typename?: 'Wp_NodeWithTitleConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithTitleEdge>
  group: Array<Wp_NodeWithTitleGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithTitle>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithTitleConnectionDistinctArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleConnectionGroupArgs = {
  field: Wp_NodeWithTitleFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithTitleConnectionMaxArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleConnectionMinArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleConnectionSumArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleEdge = {
  __typename?: 'Wp_NodeWithTitleEdge'
  next?: Maybe<Wp_NodeWithTitle>
  node: Wp_NodeWithTitle
  previous?: Maybe<Wp_NodeWithTitle>
}

export type Wp_NodeWithTitleFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  title?: InputMaybe<FieldSelectorEnum>
}

export type Wp_NodeWithTitleFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  title?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_NodeWithTitleGroupConnection = {
  __typename?: 'Wp_NodeWithTitleGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithTitleEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithTitleGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithTitle>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithTitleGroupConnectionDistinctArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleGroupConnectionGroupArgs = {
  field: Wp_NodeWithTitleFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithTitleGroupConnectionMaxArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleGroupConnectionMinArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleGroupConnectionSumArgs = {
  field: Wp_NodeWithTitleFieldSelector
}

export type Wp_NodeWithTitleSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  title?: InputMaybe<SortOrderEnum>
}

export type Wp_NodeWithTrackbacks = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** Whether the pings are open or closed for this particular post. */
  pingStatus?: Maybe<Scalars['String']['output']>
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type Wp_NodeWithTrackbacksConnection = {
  __typename?: 'Wp_NodeWithTrackbacksConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithTrackbacksEdge>
  group: Array<Wp_NodeWithTrackbacksGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithTrackbacks>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithTrackbacksConnectionDistinctArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksConnectionGroupArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithTrackbacksConnectionMaxArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksConnectionMinArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksConnectionSumArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksEdge = {
  __typename?: 'Wp_NodeWithTrackbacksEdge'
  next?: Maybe<Wp_NodeWithTrackbacks>
  node: Wp_NodeWithTrackbacks
  previous?: Maybe<Wp_NodeWithTrackbacks>
}

export type Wp_NodeWithTrackbacksFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  pingStatus?: InputMaybe<FieldSelectorEnum>
  pinged?: InputMaybe<FieldSelectorEnum>
  toPing?: InputMaybe<FieldSelectorEnum>
}

export type Wp_NodeWithTrackbacksFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  pingStatus?: InputMaybe<StringQueryOperatorInput>
  pinged?: InputMaybe<StringQueryOperatorInput>
  toPing?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_NodeWithTrackbacksGroupConnection = {
  __typename?: 'Wp_NodeWithTrackbacksGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_NodeWithTrackbacksEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_NodeWithTrackbacksGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_NodeWithTrackbacks>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_NodeWithTrackbacksGroupConnectionDistinctArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksGroupConnectionGroupArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_NodeWithTrackbacksGroupConnectionMaxArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksGroupConnectionMinArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksGroupConnectionSumArgs = {
  field: Wp_NodeWithTrackbacksFieldSelector
}

export type Wp_NodeWithTrackbacksSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  pingStatus?: InputMaybe<SortOrderEnum>
  pinged?: InputMaybe<SortOrderEnum>
  toPing?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_NumberField = Wp_FormField & {
  __typename?: 'Wp_NumberField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** The formula used for the number field. */
  calculationFormula?: Maybe<Scalars['String']['output']>
  /** Specifies to how many decimal places the number should be rounded. This is available when `isCalculation` is true, but will return null if the number format is `CURRENCY` or if the calculation is set to `Do not round`. */
  calculationRounding?: Maybe<Scalars['Int']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Indicates whether the number field is a calculation. */
  isCalculation?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the format allowed for the number field. */
  numberFormat?: Maybe<Wp_NumberFieldFormatEnum>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation. */
  rangeMax?: Maybe<Scalars['Float']['output']>
  /** Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation. */
  rangeMin?: Maybe<Scalars['Float']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The format allowed for the number field. . */
export enum Wp_NumberFieldFormatEnum {
  Currency = 'CURRENCY',
  DecimalComma = 'DECIMAL_COMMA',
  DecimalDot = 'DECIMAL_DOT',
}

/** OfflineModeData options that the user can select on the frontend to save name data collections to their devices. */
export enum Wp_OfflineModeDataCollectionNameEnum {
  Certifications = 'Certifications',
  CorrectiveActions = 'CorrectiveActions',
  Documents = 'Documents',
  Equipment = 'Equipment',
  Lessons = 'Lessons',
  Photos = 'Photos',
  Projects = 'Projects',
  ToolboxTalks = 'ToolboxTalks',
  Workers = 'Workers',
}

export type Wp_OfflineModeDataCollectionNameEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_OfflineModeDataCollectionNameEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_OfflineModeDataCollectionNameEnum>>>
  ne?: InputMaybe<Wp_OfflineModeDataCollectionNameEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_OfflineModeDataCollectionNameEnum>>>
}

/** A Gravity Forms   field. */
export type Wp_PageField = Wp_FormField & {
  __typename?: 'Wp_PageField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** An array containing the the individual properties for the &quot;Next&quot; button. */
  nextButton?: Maybe<Wp_FormButton>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** An array containing the the individual properties for the &quot;Previous&quot; button. */
  previousButton?: Maybe<Wp_FormButton>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms   field. */
export type Wp_PasswordField = Wp_FormField & {
  __typename?: 'Wp_PasswordField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Indicates whether the field displays the password strength indicator. */
  hasPasswordStrengthIndicator?: Maybe<Scalars['Boolean']['output']>
  /** Whether the Password visibility toggle should be enabled for this field. */
  hasPasswordVisibilityToggle?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the password field. */
  inputs?: Maybe<Array<Maybe<Wp_PasswordInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Indicates how strong the password should be. */
  minPasswordStrength?: Maybe<Wp_PasswordFieldMinStrengthEnum>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Indicates how strong the password should be. */
export enum Wp_PasswordFieldMinStrengthEnum {
  Bad = 'BAD',
  Good = 'GOOD',
  Short = 'SHORT',
  Strong = 'STRONG',
}

/** Password input values. */
export type Wp_PasswordInputProperty = {
  __typename?: 'Wp_PasswordInputProperty'
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type Wp_PhoneField = Wp_FormField & {
  __typename?: 'Wp_PhoneField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation. */
  phoneFormat?: Maybe<Wp_PhoneFieldFormatEnum>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Tthe allowed format for phone numbers. */
export enum Wp_PhoneFieldFormatEnum {
  International = 'INTERNATIONAL',
  Standard = 'STANDARD',
}

/** A Gravity Forms   field. */
export type Wp_PhotoField = Wp_FormField & {
  __typename?: 'Wp_PhotoField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms  post_category field. */
export type Wp_PostCategoryCheckboxField = Wp_FormField &
  Wp_PostCategoryField & {
    __typename?: 'Wp_PostCategoryCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<Wp_CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<Wp_PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_category field. */
    inputs?: Maybe<Array<Maybe<Wp_PostCategoryInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

export type Wp_PostCategoryField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<Wp_PostCategoryFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Post_category choice values. */
export type Wp_PostCategoryFieldChoice = {
  __typename?: 'Wp_PostCategoryFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Post_category input values. */
export type Wp_PostCategoryInputProperty = {
  __typename?: 'Wp_PostCategoryInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_category field. */
export type Wp_PostCategoryMultiSelectField = Wp_FormField &
  Wp_PostCategoryField & {
    __typename?: 'Wp_PostCategoryMultiSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<Wp_PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** An array of field values. */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_category field. */
export type Wp_PostCategoryRadioField = Wp_FormField &
  Wp_PostCategoryField & {
    __typename?: 'Wp_PostCategoryRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<Wp_PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_category field. */
export type Wp_PostCategorySelectField = Wp_FormField &
  Wp_PostCategoryField & {
    __typename?: 'Wp_PostCategorySelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<Wp_PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type Wp_PostContentField = Wp_FormField & {
  __typename?: 'Wp_PostContentField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomCheckboxField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<Wp_CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<Wp_PostCustomCheckboxFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<Wp_PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type Wp_PostCustomCheckboxFieldChoice = {
  __typename?: 'Wp_PostCustomCheckboxFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomDateField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomDateField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines how the date field displays it’s calendar icon. */
    calendarIconType?: Maybe<Wp_FormFieldCalendarIconTypeEnum>
    /** Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom. */
    calendarIconUrl?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Determines how the date is displayed. */
    dateFormat?: Maybe<Wp_DateFieldFormatEnum>
    /** The type of date field to display. */
    dateType?: Maybe<Wp_DateFieldTypeEnum>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<Wp_PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
    subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomEmailField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomEmailField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines whether the Confirm Email field is active. */
    hasEmailConfirmation?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<Wp_PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
    subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

export type Wp_PostCustomField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomFileuploadField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomFileuploadField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** A comma-delimited list of the file extensions which may be uploaded. */
    allowedExtensions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Indicates whether multiple files may be uploaded. */
    canAcceptMultipleFiles?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** File upload value */
    fileUploadValues?: Maybe<Array<Maybe<Wp_FileUploadFieldValue>>>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The maximum size (in MB) an uploaded file may be . */
    maxFileSize?: Maybe<Scalars['Int']['output']>
    /** When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded. */
    maxFiles?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomHiddenField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomHiddenField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_custom_field input values. */
export type Wp_PostCustomInputProperty = {
  __typename?: 'Wp_PostCustomInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomListField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomListField'
    /** The URL of the image to be used for the add row button. */
    addIconUrl?: Maybe<Scalars['String']['output']>
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<Wp_PostCustomListFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** The URL of the image to be used for the delete row button. */
    deleteIconUrl?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field should use multiple columns. Default is false. */
    hasColumns?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** List field value. */
    listValues?: Maybe<Array<Maybe<Wp_ListFieldValue>>>
    /** The maximum number of rows the user can add to the field. */
    maxRows?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type Wp_PostCustomListFieldChoice = {
  __typename?: 'Wp_PostCustomListFieldChoice'
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomMultiSelectField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomMultiSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<Wp_PostCustomMultiSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** An array of field values. */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type Wp_PostCustomMultiSelectFieldChoice = {
  __typename?: 'Wp_PostCustomMultiSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomNumberField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomNumberField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** The formula used for the number field. */
    calculationFormula?: Maybe<Scalars['String']['output']>
    /** Specifies to how many decimal places the number should be rounded. This is available when `isCalculation` is true, but will return null if the number format is `CURRENCY` or if the calculation is set to `Do not round`. */
    calculationRounding?: Maybe<Scalars['Int']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Indicates whether the number field is a calculation. */
    isCalculation?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the format allowed for the number field. */
    numberFormat?: Maybe<Wp_NumberFieldFormatEnum>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation. */
    rangeMax?: Maybe<Scalars['Float']['output']>
    /** Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation. */
    rangeMin?: Maybe<Scalars['Float']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomPhoneField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomPhoneField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation. */
    phoneFormat?: Maybe<Wp_PhoneFieldFormatEnum>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomRadioField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<Wp_PostCustomRadioFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type Wp_PostCustomRadioFieldChoice = {
  __typename?: 'Wp_PostCustomRadioFieldChoice'
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomSelectField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<Wp_PostCustomSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type Wp_PostCustomSelectFieldChoice = {
  __typename?: 'Wp_PostCustomSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomTextAreaField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomTextAreaField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Indicates whether the field uses the rich text editor interface. */
    hasRichTextEditor?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
    maxLength?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomTextField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomTextField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Whether the field has an input mask. */
    hasInputMask?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** The pattern used for the input mask. */
    inputMaskValue?: Maybe<Scalars['String']['output']>
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
    isPasswordInput?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
    maxLength?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomTimeField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomTimeField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<Wp_PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
    subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
    /** Determines how the time is displayed. */
    timeFormat?: Maybe<Wp_TimeFieldFormatEnum>
    /** Time field value. */
    timeValues?: Maybe<Wp_TimeFieldValue>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type Wp_PostCustomWebsiteField = Wp_FormField &
  Wp_PostCustomField & {
    __typename?: 'Wp_PostCustomWebsiteField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type Wp_PostExcerptField = Wp_FormField & {
  __typename?: 'Wp_PostExcerptField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The postFormat type */
export type Wp_PostFormat = Node &
  Wp_DatabaseIdentifier &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_PostFormat'
    children: Array<Node>
    /** Connection between the PostFormat type and the ContentNode type */
    contentNodes?: Maybe<Wp_PostFormatToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the PostFormat type and the Taxonomy type */
    taxonomy?: Maybe<Wp_PostFormatToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_PostFormatConnection = {
  __typename?: 'Wp_PostFormatConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_PostFormatEdge>
  group: Array<Wp_PostFormatGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_PostFormat>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_PostFormatConnectionDistinctArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatConnectionGroupArgs = {
  field: Wp_PostFormatFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_PostFormatConnectionMaxArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatConnectionMinArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatConnectionSumArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatEdge = {
  __typename?: 'Wp_PostFormatEdge'
  next?: Maybe<Wp_PostFormat>
  node: Wp_PostFormat
  previous?: Maybe<Wp_PostFormat>
}

export type Wp_PostFormatFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_PostFormatToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_PostFormatToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_PostFormatFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_PostFormatToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_PostFormatToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_PostFormatGroupConnection = {
  __typename?: 'Wp_PostFormatGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_PostFormatEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_PostFormatGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_PostFormat>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_PostFormatGroupConnectionDistinctArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatGroupConnectionGroupArgs = {
  field: Wp_PostFormatFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_PostFormatGroupConnectionMaxArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatGroupConnectionMinArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatGroupConnectionSumArgs = {
  field: Wp_PostFormatFieldSelector
}

export type Wp_PostFormatSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_PostFormatToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_PostFormatToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the PostFormat type and the ContentNode type */
export type Wp_PostFormatToContentNodeConnection = {
  __typename?: 'Wp_PostFormatToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_PostFormatToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_PostFormatToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_PostFormatToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the PostFormat type and the Taxonomy type */
export type Wp_PostFormatToTaxonomyConnectionEdge = {
  __typename?: 'Wp_PostFormatToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_PostFormatToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_PostFormatToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_PostFormatToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** List of possible post formats. */
export enum Wp_PostFormatTypeEnum {
  Standard = 'STANDARD',
}

export type Wp_PostFormatTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_PostFormatTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_PostFormatTypeEnum>>>
  ne?: InputMaybe<Wp_PostFormatTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_PostFormatTypeEnum>>>
}

/** A Gravity Forms   field. */
export type Wp_PostImageField = Wp_FormField & {
  __typename?: 'Wp_PostImageField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Controls the visibility of the alt metadata for Post Image fields. */
  hasAlt?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the caption metadata for Post Image fields. */
  hasCaption?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the description metadata for Post Image fields. */
  hasDescription?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the title metadata for Post Image fields. */
  hasTitle?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Image field value. */
  imageValues?: Maybe<Wp_ImageFieldValue>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Whether the image field should be used to set the post&#039;s Featured Image */
  isFeaturedImage?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms  post_tags field. */
export type Wp_PostTagsCheckboxField = Wp_FormField &
  Wp_PostTagsField & {
    __typename?: 'Wp_PostTagsCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<Wp_CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<Wp_PostTagsCheckboxFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_tags field. */
    inputs?: Maybe<Array<Maybe<Wp_PostTagsInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type Wp_PostTagsCheckboxFieldChoice = {
  __typename?: 'Wp_PostTagsCheckboxFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

export type Wp_PostTagsField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Post_tags input values. */
export type Wp_PostTagsInputProperty = {
  __typename?: 'Wp_PostTagsInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type Wp_PostTagsMultiSelectField = Wp_FormField &
  Wp_PostTagsField & {
    __typename?: 'Wp_PostTagsMultiSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<Wp_PostTagsMultiSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** An array of field values. */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type Wp_PostTagsMultiSelectFieldChoice = {
  __typename?: 'Wp_PostTagsMultiSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type Wp_PostTagsRadioField = Wp_FormField &
  Wp_PostTagsField & {
    __typename?: 'Wp_PostTagsRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<Wp_PostTagsRadioFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type Wp_PostTagsRadioFieldChoice = {
  __typename?: 'Wp_PostTagsRadioFieldChoice'
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type Wp_PostTagsSelectField = Wp_FormField &
  Wp_PostTagsField & {
    __typename?: 'Wp_PostTagsSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<Wp_PostTagsSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type Wp_PostTagsSelectFieldChoice = {
  __typename?: 'Wp_PostTagsSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type Wp_PostTagsTextField = Wp_FormField &
  Wp_PostTagsField & {
    __typename?: 'Wp_PostTagsTextField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Whether the field has an input mask. */
    hasInputMask?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** The pattern used for the input mask. */
    inputMaskValue?: Maybe<Scalars['String']['output']>
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
    isPasswordInput?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
    maxLength?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type Wp_PostTitleField = Wp_FormField & {
  __typename?: 'Wp_PostTitleField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Details for labels of the PostType */
export type Wp_PostTypeLabelDetails = {
  __typename?: 'Wp_PostTypeLabelDetails'
  /** Default is ‘Add New’ for both hierarchical and non-hierarchical types. */
  addNew?: Maybe<Scalars['String']['output']>
  /** Label for adding a new singular item. */
  addNewItem?: Maybe<Scalars['String']['output']>
  /** Label to signify all items in a submenu link. */
  allItems?: Maybe<Scalars['String']['output']>
  /** Label for archives in nav menus */
  archives?: Maybe<Scalars['String']['output']>
  /** Label for the attributes meta box. */
  attributes?: Maybe<Scalars['String']['output']>
  /** Label for editing a singular item. */
  editItem?: Maybe<Scalars['String']['output']>
  /** Label for the Featured Image meta box title. */
  featuredImage?: Maybe<Scalars['String']['output']>
  /** Label for the table views hidden heading. */
  filterItemsList?: Maybe<Scalars['String']['output']>
  /** Label for the media frame button. */
  insertIntoItem?: Maybe<Scalars['String']['output']>
  /** Label for the table hidden heading. */
  itemsList?: Maybe<Scalars['String']['output']>
  /** Label for the table pagination hidden heading. */
  itemsListNavigation?: Maybe<Scalars['String']['output']>
  /** Label for the menu name. */
  menuName?: Maybe<Scalars['String']['output']>
  /** General name for the post type, usually plural. */
  name?: Maybe<Scalars['String']['output']>
  /** Label for the new item page title. */
  newItem?: Maybe<Scalars['String']['output']>
  /** Label used when no items are found. */
  notFound?: Maybe<Scalars['String']['output']>
  /** Label used when no items are in the trash. */
  notFoundInTrash?: Maybe<Scalars['String']['output']>
  /** Label used to prefix parents of hierarchical items. */
  parentItemColon?: Maybe<Scalars['String']['output']>
  /** Label for removing the featured image. */
  removeFeaturedImage?: Maybe<Scalars['String']['output']>
  /** Label for searching plural items. */
  searchItems?: Maybe<Scalars['String']['output']>
  /** Label for setting the featured image. */
  setFeaturedImage?: Maybe<Scalars['String']['output']>
  /** Name for one object of this post type. */
  singularName?: Maybe<Scalars['String']['output']>
  /** Label for the media frame filter. */
  uploadedToThisItem?: Maybe<Scalars['String']['output']>
  /** Label in the media frame for using a featured image. */
  useFeaturedImage?: Maybe<Scalars['String']['output']>
  /** Label for viewing a singular item. */
  viewItem?: Maybe<Scalars['String']['output']>
  /** Label for viewing post type archives. */
  viewItems?: Maybe<Scalars['String']['output']>
}

export type Wp_PostTypeLabelDetailsFieldSelector = {
  addNew?: InputMaybe<FieldSelectorEnum>
  addNewItem?: InputMaybe<FieldSelectorEnum>
  allItems?: InputMaybe<FieldSelectorEnum>
  archives?: InputMaybe<FieldSelectorEnum>
  attributes?: InputMaybe<FieldSelectorEnum>
  editItem?: InputMaybe<FieldSelectorEnum>
  featuredImage?: InputMaybe<FieldSelectorEnum>
  filterItemsList?: InputMaybe<FieldSelectorEnum>
  insertIntoItem?: InputMaybe<FieldSelectorEnum>
  itemsList?: InputMaybe<FieldSelectorEnum>
  itemsListNavigation?: InputMaybe<FieldSelectorEnum>
  menuName?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  newItem?: InputMaybe<FieldSelectorEnum>
  notFound?: InputMaybe<FieldSelectorEnum>
  notFoundInTrash?: InputMaybe<FieldSelectorEnum>
  parentItemColon?: InputMaybe<FieldSelectorEnum>
  removeFeaturedImage?: InputMaybe<FieldSelectorEnum>
  searchItems?: InputMaybe<FieldSelectorEnum>
  setFeaturedImage?: InputMaybe<FieldSelectorEnum>
  singularName?: InputMaybe<FieldSelectorEnum>
  uploadedToThisItem?: InputMaybe<FieldSelectorEnum>
  useFeaturedImage?: InputMaybe<FieldSelectorEnum>
  viewItem?: InputMaybe<FieldSelectorEnum>
  viewItems?: InputMaybe<FieldSelectorEnum>
}

export type Wp_PostTypeLabelDetailsFilterInput = {
  addNew?: InputMaybe<StringQueryOperatorInput>
  addNewItem?: InputMaybe<StringQueryOperatorInput>
  allItems?: InputMaybe<StringQueryOperatorInput>
  archives?: InputMaybe<StringQueryOperatorInput>
  attributes?: InputMaybe<StringQueryOperatorInput>
  editItem?: InputMaybe<StringQueryOperatorInput>
  featuredImage?: InputMaybe<StringQueryOperatorInput>
  filterItemsList?: InputMaybe<StringQueryOperatorInput>
  insertIntoItem?: InputMaybe<StringQueryOperatorInput>
  itemsList?: InputMaybe<StringQueryOperatorInput>
  itemsListNavigation?: InputMaybe<StringQueryOperatorInput>
  menuName?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  newItem?: InputMaybe<StringQueryOperatorInput>
  notFound?: InputMaybe<StringQueryOperatorInput>
  notFoundInTrash?: InputMaybe<StringQueryOperatorInput>
  parentItemColon?: InputMaybe<StringQueryOperatorInput>
  removeFeaturedImage?: InputMaybe<StringQueryOperatorInput>
  searchItems?: InputMaybe<StringQueryOperatorInput>
  setFeaturedImage?: InputMaybe<StringQueryOperatorInput>
  singularName?: InputMaybe<StringQueryOperatorInput>
  uploadedToThisItem?: InputMaybe<StringQueryOperatorInput>
  useFeaturedImage?: InputMaybe<StringQueryOperatorInput>
  viewItem?: InputMaybe<StringQueryOperatorInput>
  viewItems?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_PostTypeLabelDetailsSortInput = {
  addNew?: InputMaybe<SortOrderEnum>
  addNewItem?: InputMaybe<SortOrderEnum>
  allItems?: InputMaybe<SortOrderEnum>
  archives?: InputMaybe<SortOrderEnum>
  attributes?: InputMaybe<SortOrderEnum>
  editItem?: InputMaybe<SortOrderEnum>
  featuredImage?: InputMaybe<SortOrderEnum>
  filterItemsList?: InputMaybe<SortOrderEnum>
  insertIntoItem?: InputMaybe<SortOrderEnum>
  itemsList?: InputMaybe<SortOrderEnum>
  itemsListNavigation?: InputMaybe<SortOrderEnum>
  menuName?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  newItem?: InputMaybe<SortOrderEnum>
  notFound?: InputMaybe<SortOrderEnum>
  notFoundInTrash?: InputMaybe<SortOrderEnum>
  parentItemColon?: InputMaybe<SortOrderEnum>
  removeFeaturedImage?: InputMaybe<SortOrderEnum>
  searchItems?: InputMaybe<SortOrderEnum>
  setFeaturedImage?: InputMaybe<SortOrderEnum>
  singularName?: InputMaybe<SortOrderEnum>
  uploadedToThisItem?: InputMaybe<SortOrderEnum>
  useFeaturedImage?: InputMaybe<SortOrderEnum>
  viewItem?: InputMaybe<SortOrderEnum>
  viewItems?: InputMaybe<SortOrderEnum>
}

/** The ProjectStatus type */
export type Wp_ProjectStatus = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_ProjectStatus'
    children: Array<Node>
    /** Connection between the ProjectStatus type and the ContentNode type */
    contentNodes?: Maybe<Wp_ProjectStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the ProjectStatus type and the Taxonomy type */
    taxonomy?: Maybe<Wp_ProjectStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_ProjectStatusConnection = {
  __typename?: 'Wp_ProjectStatusConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ProjectStatusEdge>
  group: Array<Wp_ProjectStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ProjectStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ProjectStatusConnectionDistinctArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusConnectionGroupArgs = {
  field: Wp_ProjectStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ProjectStatusConnectionMaxArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusConnectionMinArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusConnectionSumArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusEdge = {
  __typename?: 'Wp_ProjectStatusEdge'
  next?: Maybe<Wp_ProjectStatus>
  node: Wp_ProjectStatus
  previous?: Maybe<Wp_ProjectStatus>
}

export type Wp_ProjectStatusFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_ProjectStatusToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_ProjectStatusToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ProjectStatusFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ProjectStatusToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ProjectStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ProjectStatusGroupConnection = {
  __typename?: 'Wp_ProjectStatusGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ProjectStatusEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ProjectStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ProjectStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ProjectStatusGroupConnectionDistinctArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusGroupConnectionGroupArgs = {
  field: Wp_ProjectStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ProjectStatusGroupConnectionMaxArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusGroupConnectionMinArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusGroupConnectionSumArgs = {
  field: Wp_ProjectStatusFieldSelector
}

export type Wp_ProjectStatusSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_ProjectStatusToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_ProjectStatusToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the ProjectStatus type and the ContentNode type */
export type Wp_ProjectStatusToContentNodeConnection = {
  __typename?: 'Wp_ProjectStatusToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_ProjectStatusToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_ProjectStatusToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_ProjectStatusToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the ProjectStatus type and the Taxonomy type */
export type Wp_ProjectStatusToTaxonomyConnectionEdge = {
  __typename?: 'Wp_ProjectStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_ProjectStatusToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_ProjectStatusToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_ProjectStatusToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The ProjectType type */
export type Wp_ProjectType = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_ProjectType'
    children: Array<Node>
    /** Connection between the ProjectType type and the ContentNode type */
    contentNodes?: Maybe<Wp_ProjectTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the ProjectType type and the Taxonomy type */
    taxonomy?: Maybe<Wp_ProjectTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_ProjectTypeConnection = {
  __typename?: 'Wp_ProjectTypeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ProjectTypeEdge>
  group: Array<Wp_ProjectTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ProjectType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ProjectTypeConnectionDistinctArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeConnectionGroupArgs = {
  field: Wp_ProjectTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ProjectTypeConnectionMaxArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeConnectionMinArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeConnectionSumArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeEdge = {
  __typename?: 'Wp_ProjectTypeEdge'
  next?: Maybe<Wp_ProjectType>
  node: Wp_ProjectType
  previous?: Maybe<Wp_ProjectType>
}

export type Wp_ProjectTypeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_ProjectTypeToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_ProjectTypeToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ProjectTypeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ProjectTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ProjectTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ProjectTypeGroupConnection = {
  __typename?: 'Wp_ProjectTypeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ProjectTypeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ProjectTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ProjectType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ProjectTypeGroupConnectionDistinctArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeGroupConnectionGroupArgs = {
  field: Wp_ProjectTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ProjectTypeGroupConnectionMaxArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeGroupConnectionMinArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeGroupConnectionSumArgs = {
  field: Wp_ProjectTypeFieldSelector
}

export type Wp_ProjectTypeSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_ProjectTypeToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_ProjectTypeToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the ProjectType type and the ContentNode type */
export type Wp_ProjectTypeToContentNodeConnection = {
  __typename?: 'Wp_ProjectTypeToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_ProjectTypeToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_ProjectTypeToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_ProjectTypeToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the ProjectType type and the Taxonomy type */
export type Wp_ProjectTypeToTaxonomyConnectionEdge = {
  __typename?: 'Wp_ProjectTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_ProjectTypeToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_ProjectTypeToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_ProjectTypeToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The Provider type */
export type Wp_Provider = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Provider'
    children: Array<Node>
    /** Connection between the Provider type and the ContentNode type */
    contentNodes?: Maybe<Wp_ProviderToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the Provider type and the Lesson type */
    lessons?: Maybe<Wp_ProviderToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Provider type and the Taxonomy type */
    taxonomy?: Maybe<Wp_ProviderToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_ProviderConnection = {
  __typename?: 'Wp_ProviderConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ProviderEdge>
  group: Array<Wp_ProviderGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Provider>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ProviderConnectionDistinctArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderConnectionGroupArgs = {
  field: Wp_ProviderFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ProviderConnectionMaxArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderConnectionMinArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderConnectionSumArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderEdge = {
  __typename?: 'Wp_ProviderEdge'
  next?: Maybe<Wp_Provider>
  node: Wp_Provider
  previous?: Maybe<Wp_Provider>
}

export type Wp_ProviderFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_ProviderToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_ProviderToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_ProviderToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ProviderFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ProviderToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_ProviderToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ProviderToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ProviderFilterListInput = {
  elemMatch?: InputMaybe<Wp_ProviderFilterInput>
}

export type Wp_ProviderGroupConnection = {
  __typename?: 'Wp_ProviderGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ProviderEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ProviderGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Provider>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ProviderGroupConnectionDistinctArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderGroupConnectionGroupArgs = {
  field: Wp_ProviderFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ProviderGroupConnectionMaxArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderGroupConnectionMinArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderGroupConnectionSumArgs = {
  field: Wp_ProviderFieldSelector
}

export type Wp_ProviderSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_ProviderToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_ProviderToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_ProviderToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Provider type and the ContentNode type */
export type Wp_ProviderToContentNodeConnection = {
  __typename?: 'Wp_ProviderToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_ProviderToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_ProviderToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_ProviderToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Provider type and the Lesson type */
export type Wp_ProviderToLessonConnection = {
  __typename?: 'Wp_ProviderToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_ProviderToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_ProviderToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_ProviderToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the Provider type and the Taxonomy type */
export type Wp_ProviderToTaxonomyConnectionEdge = {
  __typename?: 'Wp_ProviderToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_ProviderToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_ProviderToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_ProviderToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** A Gravity Forms  quiz field. */
export type Wp_QuizCheckboxField = Wp_FormField &
  Wp_QuizField & {
    __typename?: 'Wp_QuizCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The explanation for the correct answer and/or incorrect answers. */
    answerExplanation?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<Wp_CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the quiz field. */
    choices?: Maybe<Array<Maybe<Wp_QuizFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
    hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** An array of the available properties for each input of the quiz field. */
    inputs?: Maybe<Array<Maybe<Wp_QuizInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Whether to randomize the order in which the answers are displayed to the user. */
    shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show an answer explanation. */
    shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

export type Wp_QuizField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The explanation for the correct answer and/or incorrect answers. */
  answerExplanation?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the quiz field. */
  choices?: Maybe<Array<Maybe<Wp_QuizFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
  hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Whether to randomize the order in which the answers are displayed to the user. */
  shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
  /** Whether to show an answer explanation. */
  shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Quiz choice values. */
export type Wp_QuizFieldChoice = {
  __typename?: 'Wp_QuizFieldChoice'
  /** Indicates the choice item is the correct answer. */
  isCorrect?: Maybe<Scalars['Boolean']['output']>
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
  /** The weighted score awarded for the choice. */
  weight?: Maybe<Scalars['Float']['output']>
}

export type Wp_QuizFieldChoiceFieldSelector = {
  isCorrect?: InputMaybe<FieldSelectorEnum>
  isOtherChoice?: InputMaybe<FieldSelectorEnum>
  isSelected?: InputMaybe<FieldSelectorEnum>
  text?: InputMaybe<FieldSelectorEnum>
  value?: InputMaybe<FieldSelectorEnum>
  weight?: InputMaybe<FieldSelectorEnum>
}

export type Wp_QuizFieldChoiceFilterInput = {
  isCorrect?: InputMaybe<BooleanQueryOperatorInput>
  isOtherChoice?: InputMaybe<BooleanQueryOperatorInput>
  isSelected?: InputMaybe<BooleanQueryOperatorInput>
  text?: InputMaybe<StringQueryOperatorInput>
  value?: InputMaybe<StringQueryOperatorInput>
  weight?: InputMaybe<FloatQueryOperatorInput>
}

export type Wp_QuizFieldChoiceFilterListInput = {
  elemMatch?: InputMaybe<Wp_QuizFieldChoiceFilterInput>
}

export type Wp_QuizFieldChoiceSortInput = {
  isCorrect?: InputMaybe<SortOrderEnum>
  isOtherChoice?: InputMaybe<SortOrderEnum>
  isSelected?: InputMaybe<SortOrderEnum>
  text?: InputMaybe<SortOrderEnum>
  value?: InputMaybe<SortOrderEnum>
  weight?: InputMaybe<SortOrderEnum>
}

export type Wp_QuizFieldFieldSelector = {
  adminLabel?: InputMaybe<FieldSelectorEnum>
  answerExplanation?: InputMaybe<FieldSelectorEnum>
  canPrepopulate?: InputMaybe<FieldSelectorEnum>
  choiceTranslations?: InputMaybe<Wp_ChoiceTranslationFieldSelector>
  choices?: InputMaybe<Wp_QuizFieldChoiceFieldSelector>
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFieldSelector>
  cssClass?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  descriptionPlacement?: InputMaybe<FieldSelectorEnum>
  displayOnly?: InputMaybe<FieldSelectorEnum>
  errorMessage?: InputMaybe<FieldSelectorEnum>
  hasChoiceValue?: InputMaybe<FieldSelectorEnum>
  hasWeightedScore?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  inputName?: InputMaybe<FieldSelectorEnum>
  inputType?: InputMaybe<FieldSelectorEnum>
  isRequired?: InputMaybe<FieldSelectorEnum>
  label?: InputMaybe<FieldSelectorEnum>
  labelPlacement?: InputMaybe<FieldSelectorEnum>
  layoutGridColumnSpan?: InputMaybe<FieldSelectorEnum>
  layoutSpacerGridColumnSpan?: InputMaybe<FieldSelectorEnum>
  pageNumber?: InputMaybe<FieldSelectorEnum>
  personalData?: InputMaybe<Wp_FormFieldDataPolicyFieldSelector>
  shouldRandomizeQuizChoices?: InputMaybe<FieldSelectorEnum>
  shouldShowAnswerExplanation?: InputMaybe<FieldSelectorEnum>
  type?: InputMaybe<FieldSelectorEnum>
  value?: InputMaybe<FieldSelectorEnum>
  visibility?: InputMaybe<FieldSelectorEnum>
}

export type Wp_QuizFieldFilterInput = {
  adminLabel?: InputMaybe<StringQueryOperatorInput>
  answerExplanation?: InputMaybe<StringQueryOperatorInput>
  canPrepopulate?: InputMaybe<BooleanQueryOperatorInput>
  choiceTranslations?: InputMaybe<Wp_ChoiceTranslationFilterListInput>
  choices?: InputMaybe<Wp_QuizFieldChoiceFilterListInput>
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicFilterInput>
  cssClass?: InputMaybe<StringQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  descriptionPlacement?: InputMaybe<Wp_FormFieldDescriptionPlacementEnumQueryOperatorInput>
  displayOnly?: InputMaybe<BooleanQueryOperatorInput>
  errorMessage?: InputMaybe<StringQueryOperatorInput>
  hasChoiceValue?: InputMaybe<BooleanQueryOperatorInput>
  hasWeightedScore?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<IntQueryOperatorInput>
  inputName?: InputMaybe<StringQueryOperatorInput>
  inputType?: InputMaybe<Wp_FormFieldTypeEnumQueryOperatorInput>
  isRequired?: InputMaybe<BooleanQueryOperatorInput>
  label?: InputMaybe<StringQueryOperatorInput>
  labelPlacement?: InputMaybe<Wp_FormFieldLabelPlacementEnumQueryOperatorInput>
  layoutGridColumnSpan?: InputMaybe<IntQueryOperatorInput>
  layoutSpacerGridColumnSpan?: InputMaybe<IntQueryOperatorInput>
  pageNumber?: InputMaybe<IntQueryOperatorInput>
  personalData?: InputMaybe<Wp_FormFieldDataPolicyFilterInput>
  shouldRandomizeQuizChoices?: InputMaybe<BooleanQueryOperatorInput>
  shouldShowAnswerExplanation?: InputMaybe<BooleanQueryOperatorInput>
  type?: InputMaybe<Wp_FormFieldTypeEnumQueryOperatorInput>
  value?: InputMaybe<StringQueryOperatorInput>
  visibility?: InputMaybe<Wp_FormFieldVisibilityEnumQueryOperatorInput>
}

/** Type of grading system used by Gravity Forms Quiz. Default is `NONE`. */
export enum Wp_QuizFieldGradingTypeEnum {
  Letter = 'LETTER',
  None = 'NONE',
  Passfail = 'PASSFAIL',
}

export type Wp_QuizFieldGradingTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_QuizFieldGradingTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_QuizFieldGradingTypeEnum>>>
  ne?: InputMaybe<Wp_QuizFieldGradingTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_QuizFieldGradingTypeEnum>>>
}

export type Wp_QuizFieldSortInput = {
  adminLabel?: InputMaybe<SortOrderEnum>
  answerExplanation?: InputMaybe<SortOrderEnum>
  canPrepopulate?: InputMaybe<SortOrderEnum>
  choiceTranslations?: InputMaybe<Wp_ChoiceTranslationSortInput>
  choices?: InputMaybe<Wp_QuizFieldChoiceSortInput>
  conditionalLogic?: InputMaybe<Wp_ConditionalLogicSortInput>
  cssClass?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  descriptionPlacement?: InputMaybe<SortOrderEnum>
  displayOnly?: InputMaybe<SortOrderEnum>
  errorMessage?: InputMaybe<SortOrderEnum>
  hasChoiceValue?: InputMaybe<SortOrderEnum>
  hasWeightedScore?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  inputName?: InputMaybe<SortOrderEnum>
  inputType?: InputMaybe<SortOrderEnum>
  isRequired?: InputMaybe<SortOrderEnum>
  label?: InputMaybe<SortOrderEnum>
  labelPlacement?: InputMaybe<SortOrderEnum>
  layoutGridColumnSpan?: InputMaybe<SortOrderEnum>
  layoutSpacerGridColumnSpan?: InputMaybe<SortOrderEnum>
  pageNumber?: InputMaybe<SortOrderEnum>
  personalData?: InputMaybe<Wp_FormFieldDataPolicySortInput>
  shouldRandomizeQuizChoices?: InputMaybe<SortOrderEnum>
  shouldShowAnswerExplanation?: InputMaybe<SortOrderEnum>
  type?: InputMaybe<SortOrderEnum>
  value?: InputMaybe<SortOrderEnum>
  visibility?: InputMaybe<SortOrderEnum>
}

/** Quiz input values. */
export type Wp_QuizInputProperty = {
  __typename?: 'Wp_QuizInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  quiz field. */
export type Wp_QuizRadioField = Wp_FormField &
  Wp_QuizField & {
    __typename?: 'Wp_QuizRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The explanation for the correct answer and/or incorrect answers. */
    answerExplanation?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the quiz field. */
    choices?: Maybe<Array<Maybe<Wp_QuizFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
    hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Whether to randomize the order in which the answers are displayed to the user. */
    shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show an answer explanation. */
    shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms  quiz field. */
export type Wp_QuizSelectField = Wp_FormField &
  Wp_QuizField & {
    __typename?: 'Wp_QuizSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The explanation for the correct answer and/or incorrect answers. */
    answerExplanation?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
    /** The available choices for the quiz field. */
    choices?: Maybe<Array<Maybe<Wp_QuizFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<Wp_ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
    hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<Wp_FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<Wp_FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Whether to randomize the order in which the answers are displayed to the user. */
    shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show an answer explanation. */
    shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<Wp_FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<Wp_FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<Wp_FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type Wp_RadioField = Wp_FormField & {
  __typename?: 'Wp_RadioField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the radio field. */
  choices?: Maybe<Array<Maybe<Wp_RadioFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
  hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** The label for the chosen value. */
  valueText?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Radio choice values. */
export type Wp_RadioFieldChoice = {
  __typename?: 'Wp_RadioFieldChoice'
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** The reading setting type */
export type Wp_ReadingSettings = {
  __typename?: 'Wp_ReadingSettings'
  /** Blog pages show at most. */
  postsPerPage?: Maybe<Scalars['Int']['output']>
}

export type Wp_ReadingSettingsFieldSelector = {
  postsPerPage?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ReadingSettingsFilterInput = {
  postsPerPage?: InputMaybe<IntQueryOperatorInput>
}

export type Wp_ReadingSettingsSortInput = {
  postsPerPage?: InputMaybe<SortOrderEnum>
}

/** Determines which version of reCAPTCHA v2 will be used.  */
export enum Wp_RecaptchaTypeEnum {
  Checkbox = 'CHECKBOX',
  Invisible = 'INVISIBLE',
}

export type Wp_RecaptchaTypeEnumQueryOperatorInput = {
  eq?: InputMaybe<Wp_RecaptchaTypeEnum>
  in?: InputMaybe<Array<InputMaybe<Wp_RecaptchaTypeEnum>>>
  ne?: InputMaybe<Wp_RecaptchaTypeEnum>
  nin?: InputMaybe<Array<InputMaybe<Wp_RecaptchaTypeEnum>>>
}

/** A Gravity Forms   field. */
export type Wp_RememberMeField = Wp_FormField & {
  __typename?: 'Wp_RememberMeField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the remember_me field. */
  choices?: Maybe<Array<Maybe<Wp_RememberMeFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the remember_me field. */
  inputs?: Maybe<Array<Maybe<Wp_RememberMeInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Remember_me choice values. */
export type Wp_RememberMeFieldChoice = {
  __typename?: 'Wp_RememberMeFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Remember_me input values. */
export type Wp_RememberMeInputProperty = {
  __typename?: 'Wp_RememberMeInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** The RootCause type */
export type Wp_RootCause = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_RootCause'
    children: Array<Node>
    /** Connection between the RootCause type and the ContentNode type */
    contentNodes?: Maybe<Wp_RootCauseToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the RootCause type and the Taxonomy type */
    taxonomy?: Maybe<Wp_RootCauseToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_RootCauseConnection = {
  __typename?: 'Wp_RootCauseConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_RootCauseEdge>
  group: Array<Wp_RootCauseGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_RootCause>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_RootCauseConnectionDistinctArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseConnectionGroupArgs = {
  field: Wp_RootCauseFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_RootCauseConnectionMaxArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseConnectionMinArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseConnectionSumArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseEdge = {
  __typename?: 'Wp_RootCauseEdge'
  next?: Maybe<Wp_RootCause>
  node: Wp_RootCause
  previous?: Maybe<Wp_RootCause>
}

export type Wp_RootCauseFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_RootCauseToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_RootCauseToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_RootCauseFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_RootCauseToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_RootCauseToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_RootCauseGroupConnection = {
  __typename?: 'Wp_RootCauseGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_RootCauseEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_RootCauseGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_RootCause>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_RootCauseGroupConnectionDistinctArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseGroupConnectionGroupArgs = {
  field: Wp_RootCauseFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_RootCauseGroupConnectionMaxArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseGroupConnectionMinArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseGroupConnectionSumArgs = {
  field: Wp_RootCauseFieldSelector
}

export type Wp_RootCauseSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_RootCauseToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_RootCauseToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the RootCause type and the ContentNode type */
export type Wp_RootCauseToContentNodeConnection = {
  __typename?: 'Wp_RootCauseToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_RootCauseToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_RootCauseToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_RootCauseToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the RootCause type and the Taxonomy type */
export type Wp_RootCauseToTaxonomyConnectionEdge = {
  __typename?: 'Wp_RootCauseToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_RootCauseToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_RootCauseToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_RootCauseToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** A Gravity Forms   field. */
export type Wp_SectionField = Wp_FormField & {
  __typename?: 'Wp_SectionField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms   field. */
export type Wp_SelectField = Wp_FormField & {
  __typename?: 'Wp_SelectField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The free solo boolean to allow custom option for selected fields */
  allowCustomOption?: Maybe<Scalars['Boolean']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<Wp_ChoiceTranslation>>>
  /** The available choices for the select field. */
  choices?: Maybe<Array<Maybe<Wp_SelectFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** The label for the chosen value. */
  valueText?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Select choice values. */
export type Wp_SelectFieldChoice = {
  __typename?: 'Wp_SelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** All of the registered settings */
export type Wp_Settings = {
  __typename?: 'Wp_Settings'
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsDateFormat?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsDescription?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsLanguage?: Maybe<Scalars['String']['output']>
  /** Settings of the the integer Settings Group */
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsTimeFormat?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsTimezone?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsTitle?: Maybe<Scalars['String']['output']>
  /** Settings of the the integer Settings Group */
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']['output']>
  /** Settings of the the integer Settings Group */
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']['output']>
  /** Settings of the the string Settings Group */
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']['output']>
  /** Settings of the the boolean Settings Group */
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_SettingsFieldSelector = {
  discussionSettingsDefaultCommentStatus?: InputMaybe<FieldSelectorEnum>
  discussionSettingsDefaultPingStatus?: InputMaybe<FieldSelectorEnum>
  generalSettingsDateFormat?: InputMaybe<FieldSelectorEnum>
  generalSettingsDescription?: InputMaybe<FieldSelectorEnum>
  generalSettingsLanguage?: InputMaybe<FieldSelectorEnum>
  generalSettingsStartOfWeek?: InputMaybe<FieldSelectorEnum>
  generalSettingsTimeFormat?: InputMaybe<FieldSelectorEnum>
  generalSettingsTimezone?: InputMaybe<FieldSelectorEnum>
  generalSettingsTitle?: InputMaybe<FieldSelectorEnum>
  readingSettingsPostsPerPage?: InputMaybe<FieldSelectorEnum>
  writingSettingsDefaultCategory?: InputMaybe<FieldSelectorEnum>
  writingSettingsDefaultPostFormat?: InputMaybe<FieldSelectorEnum>
  writingSettingsUseSmilies?: InputMaybe<FieldSelectorEnum>
}

export type Wp_SettingsFilterInput = {
  discussionSettingsDefaultCommentStatus?: InputMaybe<StringQueryOperatorInput>
  discussionSettingsDefaultPingStatus?: InputMaybe<StringQueryOperatorInput>
  generalSettingsDateFormat?: InputMaybe<StringQueryOperatorInput>
  generalSettingsDescription?: InputMaybe<StringQueryOperatorInput>
  generalSettingsLanguage?: InputMaybe<StringQueryOperatorInput>
  generalSettingsStartOfWeek?: InputMaybe<IntQueryOperatorInput>
  generalSettingsTimeFormat?: InputMaybe<StringQueryOperatorInput>
  generalSettingsTimezone?: InputMaybe<StringQueryOperatorInput>
  generalSettingsTitle?: InputMaybe<StringQueryOperatorInput>
  readingSettingsPostsPerPage?: InputMaybe<IntQueryOperatorInput>
  writingSettingsDefaultCategory?: InputMaybe<IntQueryOperatorInput>
  writingSettingsDefaultPostFormat?: InputMaybe<StringQueryOperatorInput>
  writingSettingsUseSmilies?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_SettingsSortInput = {
  discussionSettingsDefaultCommentStatus?: InputMaybe<SortOrderEnum>
  discussionSettingsDefaultPingStatus?: InputMaybe<SortOrderEnum>
  generalSettingsDateFormat?: InputMaybe<SortOrderEnum>
  generalSettingsDescription?: InputMaybe<SortOrderEnum>
  generalSettingsLanguage?: InputMaybe<SortOrderEnum>
  generalSettingsStartOfWeek?: InputMaybe<SortOrderEnum>
  generalSettingsTimeFormat?: InputMaybe<SortOrderEnum>
  generalSettingsTimezone?: InputMaybe<SortOrderEnum>
  generalSettingsTitle?: InputMaybe<SortOrderEnum>
  readingSettingsPostsPerPage?: InputMaybe<SortOrderEnum>
  writingSettingsDefaultCategory?: InputMaybe<SortOrderEnum>
  writingSettingsDefaultPostFormat?: InputMaybe<SortOrderEnum>
  writingSettingsUseSmilies?: InputMaybe<SortOrderEnum>
}

/** The Severity type */
export type Wp_Severity = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Severity'
    children: Array<Node>
    /** Connection between the Severity type and the ContentNode type */
    contentNodes?: Maybe<Wp_SeverityToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Severity type and the Taxonomy type */
    taxonomy?: Maybe<Wp_SeverityToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_SeverityConnection = {
  __typename?: 'Wp_SeverityConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_SeverityEdge>
  group: Array<Wp_SeverityGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Severity>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_SeverityConnectionDistinctArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityConnectionGroupArgs = {
  field: Wp_SeverityFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_SeverityConnectionMaxArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityConnectionMinArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityConnectionSumArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityEdge = {
  __typename?: 'Wp_SeverityEdge'
  next?: Maybe<Wp_Severity>
  node: Wp_Severity
  previous?: Maybe<Wp_Severity>
}

export type Wp_SeverityFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_SeverityToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_SeverityToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_SeverityFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_SeverityToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_SeverityToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_SeverityGroupConnection = {
  __typename?: 'Wp_SeverityGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_SeverityEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_SeverityGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Severity>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_SeverityGroupConnectionDistinctArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityGroupConnectionGroupArgs = {
  field: Wp_SeverityFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_SeverityGroupConnectionMaxArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityGroupConnectionMinArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeverityGroupConnectionSumArgs = {
  field: Wp_SeverityFieldSelector
}

export type Wp_SeveritySortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_SeverityToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_SeverityToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Severity type and the ContentNode type */
export type Wp_SeverityToContentNodeConnection = {
  __typename?: 'Wp_SeverityToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_SeverityToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_SeverityToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_SeverityToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Severity type and the Taxonomy type */
export type Wp_SeverityToTaxonomyConnectionEdge = {
  __typename?: 'Wp_SeverityToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_SeverityToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_SeverityToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_SeverityToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** A Gravity Forms   field. */
export type Wp_SignatureField = Wp_FormField & {
  __typename?: 'Wp_SignatureField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Color to be used for the background of the signature area. Can be any valid CSS color value. */
  backgroundColor?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Color to be used for the border around the signature area. Can be any valid CSS color value. */
  borderColor?: Maybe<Scalars['String']['output']>
  /** Border style to be used around the signature area. */
  borderStyle?: Maybe<Wp_SignatureFieldBorderStyleEnum>
  /** Width of the border around the signature area. */
  borderWidth?: Maybe<Wp_SignatureFieldBorderWidthEnum>
  /** Width of the signature field in pixels. */
  boxWidth?: Maybe<Scalars['Int']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Color of the pen to be used for the signature. Can be any valid CSS color value. */
  penColor?: Maybe<Scalars['String']['output']>
  /** Size of the pen cursor. */
  penSize?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Border style to be used around the signature area. */
export enum Wp_SignatureFieldBorderStyleEnum {
  Dashed = 'DASHED',
  Dotted = 'DOTTED',
  Double = 'DOUBLE',
  Groove = 'GROOVE',
  Inset = 'INSET',
  Outset = 'OUTSET',
  Ridge = 'RIDGE',
  Solid = 'SOLID',
}

/** Width of the border around the signature area. */
export enum Wp_SignatureFieldBorderWidthEnum {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  None = 'NONE',
  Small = 'SMALL',
}

export type Wp_SortInput = {
  allSettings?: InputMaybe<Wp_SettingsSortInput>
  certificationExpiryWarnings?: InputMaybe<Wp_CertificationExpiryWarningRuleSortInput>
  children?: InputMaybe<NodeSortInput>
  defaultLanguage?: InputMaybe<Wp_LanguageSortInput>
  discussionSettings?: InputMaybe<Wp_DiscussionSettingsSortInput>
  generalSettings?: InputMaybe<Wp_GeneralSettingsSortInput>
  gfSettings?: InputMaybe<Wp_GfSettingsSortInput>
  harnessSettings?: InputMaybe<Wp_HarnessSettingsSortInput>
  id?: InputMaybe<SortOrderEnum>
  incidentReportData?: InputMaybe<Wp_IncidentReportDataSortInput>
  integrationConfig?: InputMaybe<Wp_IntegrationConfigSortInput>
  internal?: InputMaybe<InternalSortInput>
  languages?: InputMaybe<Wp_LanguageSortInput>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  readingSettings?: InputMaybe<Wp_ReadingSettingsSortInput>
  schemaMd5?: InputMaybe<SortOrderEnum>
  viewer?: InputMaybe<Wp_UserSortInput>
  wpGatsby?: InputMaybe<Wp_WpGatsbySortInput>
  writingSettings?: InputMaybe<Wp_WritingSettingsSortInput>
}

/** Gravity Forms submission rules settings */
export type Wp_SubmissionRule = {
  __typename?: 'Wp_SubmissionRule'
  /** Entry count */
  entryCount?: Maybe<Scalars['Int']['output']>
  /** Frequency */
  frequency?: Maybe<Scalars['String']['output']>
  /** Role */
  role?: Maybe<Scalars['String']['output']>
}

export type Wp_SubmissionRuleFieldSelector = {
  entryCount?: InputMaybe<FieldSelectorEnum>
  frequency?: InputMaybe<FieldSelectorEnum>
  role?: InputMaybe<FieldSelectorEnum>
}

export type Wp_SubmissionRuleFilterInput = {
  entryCount?: InputMaybe<IntQueryOperatorInput>
  frequency?: InputMaybe<StringQueryOperatorInput>
  role?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_SubmissionRuleFilterListInput = {
  elemMatch?: InputMaybe<Wp_SubmissionRuleFilterInput>
}

export type Wp_SubmissionRuleSortInput = {
  entryCount?: InputMaybe<SortOrderEnum>
  frequency?: InputMaybe<SortOrderEnum>
  role?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_SubmitField = Wp_FormField & {
  __typename?: 'Wp_SubmitField'
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The tag type */
export type Wp_Tag = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_Tag'
    children: Array<Node>
    /** Connection between the Tag type and the ContentNode type */
    contentNodes?: Maybe<Wp_TagToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** List available translations for this post */
    language?: Maybe<Wp_Language>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Tag type and the Taxonomy type */
    taxonomy?: Maybe<Wp_TagToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Wp_Tag>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_TagConnection = {
  __typename?: 'Wp_TagConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TagEdge>
  group: Array<Wp_TagGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Tag>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TagConnectionDistinctArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagConnectionGroupArgs = {
  field: Wp_TagFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TagConnectionMaxArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagConnectionMinArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagConnectionSumArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagEdge = {
  __typename?: 'Wp_TagEdge'
  next?: Maybe<Wp_Tag>
  node: Wp_Tag
  previous?: Maybe<Wp_Tag>
}

export type Wp_TagFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_TagToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  language?: InputMaybe<Wp_LanguageFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_TagToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  translations?: InputMaybe<Wp_TagFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_TagFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_TagToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_TagToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_TagFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_TagFilterListInput = {
  elemMatch?: InputMaybe<Wp_TagFilterInput>
}

export type Wp_TagGroupConnection = {
  __typename?: 'Wp_TagGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TagEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_TagGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Tag>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TagGroupConnectionDistinctArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagGroupConnectionGroupArgs = {
  field: Wp_TagFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TagGroupConnectionMaxArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagGroupConnectionMinArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagGroupConnectionSumArgs = {
  field: Wp_TagFieldSelector
}

export type Wp_TagSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_TagToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  language?: InputMaybe<Wp_LanguageSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_TagToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  translations?: InputMaybe<Wp_TagSortInput>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the Tag type and the ContentNode type */
export type Wp_TagToContentNodeConnection = {
  __typename?: 'Wp_TagToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_TagToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_TagToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_TagToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the Tag type and the Taxonomy type */
export type Wp_TagToTaxonomyConnectionEdge = {
  __typename?: 'Wp_TagToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_TagToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_TagToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_TagToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** A taxonomy object */
export type Wp_Taxonomy = Node &
  Wp_Node & {
    __typename?: 'Wp_Taxonomy'
    /** The url path of the first page of the archive page for this content type. */
    archivePath?: Maybe<Scalars['String']['output']>
    children: Array<Node>
    /** List of Content Types associated with the Taxonomy */
    connectedContentTypes?: Maybe<Wp_TaxonomyToContentTypeConnection>
    /** Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description */
    description?: Maybe<Scalars['String']['output']>
    /** The plural name of the post type within the GraphQL Schema. */
    graphqlPluralName?: Maybe<Scalars['String']['output']>
    /** The singular name of the post type within the GraphQL Schema. */
    graphqlSingleName?: Maybe<Scalars['String']['output']>
    /** Whether the taxonomy is hierarchical */
    hierarchical?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Name of the taxonomy shown in the menu. Usually plural. */
    label?: Maybe<Scalars['String']['output']>
    /** The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** Whether the taxonomy is publicly queryable */
    public?: Maybe<Scalars['Boolean']['output']>
    /** Name of content type to diplay in REST API &quot;wp/v2&quot; namespace. */
    restBase?: Maybe<Scalars['String']['output']>
    /** The REST Controller class assigned to handling this content type. */
    restControllerClass?: Maybe<Scalars['String']['output']>
    /** Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud */
    showCloud?: Maybe<Scalars['Boolean']['output']>
    /** Whether to display a column for the taxonomy on its post type listing screens. */
    showInAdminColumn?: Maybe<Scalars['Boolean']['output']>
    /** Whether to add the post type to the GraphQL Schema. */
    showInGraphql?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show the taxonomy in the admin menu */
    showInMenu?: Maybe<Scalars['Boolean']['output']>
    /** Whether the taxonomy is available for selection in navigation menus. */
    showInNavMenus?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show the taxonomy in the quick/bulk edit panel. */
    showInQuickEdit?: Maybe<Scalars['Boolean']['output']>
    /** Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace. */
    showInRest?: Maybe<Scalars['Boolean']['output']>
    /** Whether to generate and allow a UI for managing terms in this taxonomy in the admin */
    showUi?: Maybe<Scalars['Boolean']['output']>
  }

export type Wp_TaxonomyConnection = {
  __typename?: 'Wp_TaxonomyConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TaxonomyEdge>
  group: Array<Wp_TaxonomyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Taxonomy>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TaxonomyConnectionDistinctArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyConnectionGroupArgs = {
  field: Wp_TaxonomyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TaxonomyConnectionMaxArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyConnectionMinArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyConnectionSumArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyEdge = {
  __typename?: 'Wp_TaxonomyEdge'
  next?: Maybe<Wp_Taxonomy>
  node: Wp_Taxonomy
  previous?: Maybe<Wp_Taxonomy>
}

export type Wp_TaxonomyFieldSelector = {
  archivePath?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  connectedContentTypes?: InputMaybe<Wp_TaxonomyToContentTypeConnectionFieldSelector>
  description?: InputMaybe<FieldSelectorEnum>
  graphqlPluralName?: InputMaybe<FieldSelectorEnum>
  graphqlSingleName?: InputMaybe<FieldSelectorEnum>
  hierarchical?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  label?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  public?: InputMaybe<FieldSelectorEnum>
  restBase?: InputMaybe<FieldSelectorEnum>
  restControllerClass?: InputMaybe<FieldSelectorEnum>
  showCloud?: InputMaybe<FieldSelectorEnum>
  showInAdminColumn?: InputMaybe<FieldSelectorEnum>
  showInGraphql?: InputMaybe<FieldSelectorEnum>
  showInMenu?: InputMaybe<FieldSelectorEnum>
  showInNavMenus?: InputMaybe<FieldSelectorEnum>
  showInQuickEdit?: InputMaybe<FieldSelectorEnum>
  showInRest?: InputMaybe<FieldSelectorEnum>
  showUi?: InputMaybe<FieldSelectorEnum>
}

export type Wp_TaxonomyFilterInput = {
  archivePath?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  connectedContentTypes?: InputMaybe<Wp_TaxonomyToContentTypeConnectionFilterInput>
  description?: InputMaybe<StringQueryOperatorInput>
  graphqlPluralName?: InputMaybe<StringQueryOperatorInput>
  graphqlSingleName?: InputMaybe<StringQueryOperatorInput>
  hierarchical?: InputMaybe<BooleanQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  label?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  public?: InputMaybe<BooleanQueryOperatorInput>
  restBase?: InputMaybe<StringQueryOperatorInput>
  restControllerClass?: InputMaybe<StringQueryOperatorInput>
  showCloud?: InputMaybe<BooleanQueryOperatorInput>
  showInAdminColumn?: InputMaybe<BooleanQueryOperatorInput>
  showInGraphql?: InputMaybe<BooleanQueryOperatorInput>
  showInMenu?: InputMaybe<BooleanQueryOperatorInput>
  showInNavMenus?: InputMaybe<BooleanQueryOperatorInput>
  showInQuickEdit?: InputMaybe<BooleanQueryOperatorInput>
  showInRest?: InputMaybe<BooleanQueryOperatorInput>
  showUi?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_TaxonomyFilterListInput = {
  elemMatch?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_TaxonomyGroupConnection = {
  __typename?: 'Wp_TaxonomyGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TaxonomyEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_TaxonomyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_Taxonomy>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TaxonomyGroupConnectionDistinctArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyGroupConnectionGroupArgs = {
  field: Wp_TaxonomyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TaxonomyGroupConnectionMaxArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyGroupConnectionMinArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomyGroupConnectionSumArgs = {
  field: Wp_TaxonomyFieldSelector
}

export type Wp_TaxonomySortInput = {
  archivePath?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  connectedContentTypes?: InputMaybe<Wp_TaxonomyToContentTypeConnectionSortInput>
  description?: InputMaybe<SortOrderEnum>
  graphqlPluralName?: InputMaybe<SortOrderEnum>
  graphqlSingleName?: InputMaybe<SortOrderEnum>
  hierarchical?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  label?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  public?: InputMaybe<SortOrderEnum>
  restBase?: InputMaybe<SortOrderEnum>
  restControllerClass?: InputMaybe<SortOrderEnum>
  showCloud?: InputMaybe<SortOrderEnum>
  showInAdminColumn?: InputMaybe<SortOrderEnum>
  showInGraphql?: InputMaybe<SortOrderEnum>
  showInMenu?: InputMaybe<SortOrderEnum>
  showInNavMenus?: InputMaybe<SortOrderEnum>
  showInQuickEdit?: InputMaybe<SortOrderEnum>
  showInRest?: InputMaybe<SortOrderEnum>
  showUi?: InputMaybe<SortOrderEnum>
}

/** Connection between the Taxonomy type and the ContentType type */
export type Wp_TaxonomyToContentTypeConnection = {
  __typename?: 'Wp_TaxonomyToContentTypeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentType>>>
}

export type Wp_TaxonomyToContentTypeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentTypeFieldSelector>
}

export type Wp_TaxonomyToContentTypeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentTypeFilterListInput>
}

export type Wp_TaxonomyToContentTypeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentTypeSortInput>
}

export type Wp_TermNode = {
  children: Array<Node>
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']['output']>
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output']
  /** The description of the object */
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internal: Internal
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output']
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output']
  /** The link to the term */
  link?: Maybe<Scalars['String']['output']>
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']['output']>
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']['output']>
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']['output']>
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']['output']>
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']['output']>
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

export type Wp_TermNodeConnection = {
  __typename?: 'Wp_TermNodeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TermNodeEdge>
  group: Array<Wp_TermNodeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_TermNode>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TermNodeConnectionDistinctArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeConnectionGroupArgs = {
  field: Wp_TermNodeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TermNodeConnectionMaxArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeConnectionMinArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeConnectionSumArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeEdge = {
  __typename?: 'Wp_TermNodeEdge'
  next?: Maybe<Wp_TermNode>
  node: Wp_TermNode
  previous?: Maybe<Wp_TermNode>
}

export type Wp_TermNodeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_TermNodeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_TermNodeFilterListInput = {
  elemMatch?: InputMaybe<Wp_TermNodeFilterInput>
}

export type Wp_TermNodeGroupConnection = {
  __typename?: 'Wp_TermNodeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TermNodeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_TermNodeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_TermNode>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TermNodeGroupConnectionDistinctArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeGroupConnectionGroupArgs = {
  field: Wp_TermNodeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TermNodeGroupConnectionMaxArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeGroupConnectionMinArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeGroupConnectionSumArgs = {
  field: Wp_TermNodeFieldSelector
}

export type Wp_TermNodeSortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_TextAreaField = Wp_FormField & {
  __typename?: 'Wp_TextAreaField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms   field. */
export type Wp_TextField = Wp_FormField & {
  __typename?: 'Wp_TextField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** A Gravity Forms   field. */
export type Wp_TimeField = Wp_FormField & {
  __typename?: 'Wp_TimeField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** An array of the available properties for each input of the time field. */
  inputs?: Maybe<Array<Maybe<Wp_TimeInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<Wp_FormFieldSubLabelPlacementEnum>
  /** Determines how the time is displayed. */
  timeFormat?: Maybe<Wp_TimeFieldFormatEnum>
  /** Time field value. */
  timeValues?: Maybe<Wp_TimeFieldValue>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** How the time is displayed. */
export enum Wp_TimeFieldFormatEnum {
  H12 = 'H12',
  H24 = 'H24',
}

/** The individual properties for each element of the Time value field. */
export type Wp_TimeFieldValue = {
  __typename?: 'Wp_TimeFieldValue'
  /** AM or PM. */
  amPm?: Maybe<Wp_AmPmEnum>
  /** The full display value in 12-hour format. Example: &quot;08:25 am&quot;. */
  displayValue?: Maybe<Scalars['String']['output']>
  /** The hours, in this format: hh. */
  hours?: Maybe<Scalars['String']['output']>
  /** The minutes, in this format: mm. */
  minutes?: Maybe<Scalars['String']['output']>
}

/** Time input values. */
export type Wp_TimeInputProperty = {
  __typename?: 'Wp_TimeInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** The ToolboxTalkCategory type */
export type Wp_ToolboxTalkCategory = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_ToolboxTalkCategory'
    children: Array<Node>
    /** Connection between the ToolboxTalkCategory type and the ContentNode type */
    contentNodes?: Maybe<Wp_ToolboxTalkCategoryToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** List available translations for this post */
    language?: Maybe<Wp_Language>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the ToolboxTalkCategory type and the Taxonomy type */
    taxonomy?: Maybe<Wp_ToolboxTalkCategoryToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Wp_ToolboxTalkCategory>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_ToolboxTalkCategoryConnection = {
  __typename?: 'Wp_ToolboxTalkCategoryConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ToolboxTalkCategoryEdge>
  group: Array<Wp_ToolboxTalkCategoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ToolboxTalkCategory>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ToolboxTalkCategoryConnectionDistinctArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryConnectionGroupArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ToolboxTalkCategoryConnectionMaxArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryConnectionMinArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryConnectionSumArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryEdge = {
  __typename?: 'Wp_ToolboxTalkCategoryEdge'
  next?: Maybe<Wp_ToolboxTalkCategory>
  node: Wp_ToolboxTalkCategory
  previous?: Maybe<Wp_ToolboxTalkCategory>
}

export type Wp_ToolboxTalkCategoryFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_ToolboxTalkCategoryToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  language?: InputMaybe<Wp_LanguageFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  translations?: InputMaybe<Wp_ToolboxTalkCategoryFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_ToolboxTalkCategoryFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_ToolboxTalkCategoryToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  language?: InputMaybe<Wp_LanguageFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  translations?: InputMaybe<Wp_ToolboxTalkCategoryFilterListInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_ToolboxTalkCategoryFilterListInput = {
  elemMatch?: InputMaybe<Wp_ToolboxTalkCategoryFilterInput>
}

export type Wp_ToolboxTalkCategoryGroupConnection = {
  __typename?: 'Wp_ToolboxTalkCategoryGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_ToolboxTalkCategoryEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_ToolboxTalkCategoryGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_ToolboxTalkCategory>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_ToolboxTalkCategoryGroupConnectionDistinctArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryGroupConnectionGroupArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_ToolboxTalkCategoryGroupConnectionMaxArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryGroupConnectionMinArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategoryGroupConnectionSumArgs = {
  field: Wp_ToolboxTalkCategoryFieldSelector
}

export type Wp_ToolboxTalkCategorySortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_ToolboxTalkCategoryToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  language?: InputMaybe<Wp_LanguageSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  translations?: InputMaybe<Wp_ToolboxTalkCategorySortInput>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the ToolboxTalkCategory type and the ContentNode type */
export type Wp_ToolboxTalkCategoryToContentNodeConnection = {
  __typename?: 'Wp_ToolboxTalkCategoryToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_ToolboxTalkCategoryToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_ToolboxTalkCategoryToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_ToolboxTalkCategoryToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the ToolboxTalkCategory type and the Taxonomy type */
export type Wp_ToolboxTalkCategoryToTaxonomyConnectionEdge = {
  __typename?: 'Wp_ToolboxTalkCategoryToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_ToolboxTalkCategoryToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** The TrainingType type */
export type Wp_TrainingType = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_TrainingType'
    children: Array<Node>
    /** Connection between the TrainingType type and the ContentNode type */
    contentNodes?: Maybe<Wp_TrainingTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the TrainingType type and the Lesson type */
    lessons?: Maybe<Wp_TrainingTypeToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the TrainingType type and the Taxonomy type */
    taxonomy?: Maybe<Wp_TrainingTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_TrainingTypeConnection = {
  __typename?: 'Wp_TrainingTypeConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TrainingTypeEdge>
  group: Array<Wp_TrainingTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_TrainingType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TrainingTypeConnectionDistinctArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeConnectionGroupArgs = {
  field: Wp_TrainingTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TrainingTypeConnectionMaxArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeConnectionMinArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeConnectionSumArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeEdge = {
  __typename?: 'Wp_TrainingTypeEdge'
  next?: Maybe<Wp_TrainingType>
  node: Wp_TrainingType
  previous?: Maybe<Wp_TrainingType>
}

export type Wp_TrainingTypeFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  contentNodes?: InputMaybe<Wp_TrainingTypeToContentNodeConnectionFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_TrainingTypeToLessonConnectionFieldSelector>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_TrainingTypeToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_TrainingTypeFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  contentNodes?: InputMaybe<Wp_TrainingTypeToContentNodeConnectionFilterInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  lessons?: InputMaybe<Wp_TrainingTypeToLessonConnectionFilterInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_TrainingTypeToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_TrainingTypeFilterListInput = {
  elemMatch?: InputMaybe<Wp_TrainingTypeFilterInput>
}

export type Wp_TrainingTypeGroupConnection = {
  __typename?: 'Wp_TrainingTypeGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_TrainingTypeEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_TrainingTypeGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_TrainingType>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_TrainingTypeGroupConnectionDistinctArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeGroupConnectionGroupArgs = {
  field: Wp_TrainingTypeFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_TrainingTypeGroupConnectionMaxArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeGroupConnectionMinArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeGroupConnectionSumArgs = {
  field: Wp_TrainingTypeFieldSelector
}

export type Wp_TrainingTypeSortInput = {
  children?: InputMaybe<NodeSortInput>
  contentNodes?: InputMaybe<Wp_TrainingTypeToContentNodeConnectionSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_TrainingTypeToLessonConnectionSortInput>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_TrainingTypeToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the TrainingType type and the ContentNode type */
export type Wp_TrainingTypeToContentNodeConnection = {
  __typename?: 'Wp_TrainingTypeToContentNodeConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_ContentNode>>>
}

export type Wp_TrainingTypeToContentNodeConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_ContentNodeFieldSelector>
}

export type Wp_TrainingTypeToContentNodeConnectionFilterInput = {
  nodes?: InputMaybe<Wp_ContentNodeFilterListInput>
}

export type Wp_TrainingTypeToContentNodeConnectionSortInput = {
  nodes?: InputMaybe<Wp_ContentNodeSortInput>
}

/** Connection between the TrainingType type and the Lesson type */
export type Wp_TrainingTypeToLessonConnection = {
  __typename?: 'Wp_TrainingTypeToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_TrainingTypeToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_TrainingTypeToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_TrainingTypeToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the TrainingType type and the Taxonomy type */
export type Wp_TrainingTypeToTaxonomyConnectionEdge = {
  __typename?: 'Wp_TrainingTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_TrainingTypeToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_TrainingTypeToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_TrainingTypeToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_UniformResourceIdentifiable = {
  children: Array<Node>
  id: Scalars['ID']['output']
  internal: Internal
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output']
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output']
  nodeType?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Node>
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

export type Wp_UniformResourceIdentifiableConnection = {
  __typename?: 'Wp_UniformResourceIdentifiableConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UniformResourceIdentifiableEdge>
  group: Array<Wp_UniformResourceIdentifiableGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UniformResourceIdentifiable>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UniformResourceIdentifiableConnectionDistinctArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableConnectionGroupArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UniformResourceIdentifiableConnectionMaxArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableConnectionMinArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableConnectionSumArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableEdge = {
  __typename?: 'Wp_UniformResourceIdentifiableEdge'
  next?: Maybe<Wp_UniformResourceIdentifiable>
  node: Wp_UniformResourceIdentifiable
  previous?: Maybe<Wp_UniformResourceIdentifiable>
}

export type Wp_UniformResourceIdentifiableFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_UniformResourceIdentifiableFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_UniformResourceIdentifiableGroupConnection = {
  __typename?: 'Wp_UniformResourceIdentifiableGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UniformResourceIdentifiableEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UniformResourceIdentifiableGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UniformResourceIdentifiable>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UniformResourceIdentifiableGroupConnectionDistinctArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableGroupConnectionGroupArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UniformResourceIdentifiableGroupConnectionMaxArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableGroupConnectionMinArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableGroupConnectionSumArgs = {
  field: Wp_UniformResourceIdentifiableFieldSelector
}

export type Wp_UniformResourceIdentifiableSortInput = {
  children?: InputMaybe<NodeSortInput>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  uri?: InputMaybe<SortOrderEnum>
}

/** A User object */
export type Wp_User = Node &
  Wp_Commenter &
  Wp_DatabaseIdentifier &
  Wp_Node &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_User'
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Wp_Avatar>
    /** User metadata option name. Usually it will be &quot;wp_capabilities&quot;. */
    capKey?: Maybe<Scalars['String']['output']>
    /** A list of capabilities (permissions) granted to the user */
    capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    children: Array<Node>
    /** Connection between the User type and the Comment type */
    comments?: Maybe<Wp_UserToCommentConnection>
    /** Connection between the User type and the UserCompany type */
    companies?: Maybe<Wp_UserToUserCompanyConnection>
    /** Identifies the primary key from the database. */
    databaseId: Scalars['Int']['output']
    /** Description of the user. */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserDivision type */
    divisions?: Maybe<Wp_UserToUserDivisionConnection>
    /** Connection between the User type and the Document type */
    documents?: Maybe<Wp_UserToDocumentConnection>
    /** Email address of the user. This is equivalent to the WP_User-&gt;user_email property. */
    email?: Maybe<Scalars['String']['output']>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Emergency Contact Info&quot; was set to Show in GraphQL. */
    emergencyContactInfo?: Maybe<Wp_User_Emergencycontactinfo>
    /** EmployeeId that is set while editin a user. Created by Carbon fields. */
    employeeId?: Maybe<Scalars['String']['output']>
    /** A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps. */
    extraCapabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** First name of the user. This is equivalent to the WP_User-&gt;user_first_name property. */
    firstName?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /** Integration source for the user. Must request databaseId at the same time. Otherwise returns null */
    integrationSource?: Maybe<Scalars['String']['output']>
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether user is a super admin. */
    isSuperAdmin?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the User type and the UserLanguage type */
    languages?: Maybe<Wp_UserToUserLanguageConnection>
    /** Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property. */
    lastName?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the Lesson type */
    lessons?: Maybe<Wp_UserToLessonConnection>
    /** The preferred language locale set for the user. Value derived from get_user_locale(). */
    locale?: Maybe<Scalars['String']['output']>
    /** Magicbell HMAC authentication powered email. */
    magicBellHmacEmail?: Maybe<Scalars['String']['output']>
    /** Display name of the user. This is equivalent to the WP_User-&gt;dispaly_name property. */
    name?: Maybe<Scalars['String']['output']>
    /** The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename */
    nicename?: Maybe<Scalars['String']['output']>
    /** Nickname of the user. */
    nickname?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    /** Offline mode setting for the user. Must request databaseId at the same time. Otherwise returns null */
    offlineMode?: Maybe<Scalars['Boolean']['output']>
    /** Offline mode data to save on the user&#039;s device. Must request databaseId at the same time. Otherwise returns null */
    offlineModeData?: Maybe<Array<Maybe<Wp_OfflineModeDataCollectionNameEnum>>>
    parent?: Maybe<Node>
    /** Phone number that is set while editing a user. Created by Carbon fields. */
    phoneNumber?: Maybe<Scalars['String']['output']>
    /** The date the user registered or was created. The field follows a full ISO8601 date string format. */
    registeredDate?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserRole type */
    roles?: Maybe<Wp_UserToUserRoleConnection>
    /** The slug for the user. This field is equivalent to WP_User-&gt;user_nicename */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserStatus type */
    status?: Maybe<Wp_UserToUserStatusConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /** A website url that is associated with the user. */
    url?: Maybe<Scalars['String']['output']>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Usage Counts&quot; was set to Show in GraphQL. */
    usageCounts?: Maybe<Wp_User_Usagecounts>
    /** Username for the user. This field is equivalent to WP_User-&gt;user_login. */
    username?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserVaccineStatus type */
    vaccineStatus?: Maybe<Wp_UserToUserVaccineStatusConnectionEdge>
  }

/** The UserCompany type */
export type Wp_UserCompany = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_UserCompany'
    children: Array<Node>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserCompany type and the Taxonomy type */
    taxonomy?: Maybe<Wp_UserCompanyToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_UserCompanyConnection = {
  __typename?: 'Wp_UserCompanyConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserCompanyEdge>
  group: Array<Wp_UserCompanyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserCompany>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserCompanyConnectionDistinctArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyConnectionGroupArgs = {
  field: Wp_UserCompanyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserCompanyConnectionMaxArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyConnectionMinArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyConnectionSumArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyEdge = {
  __typename?: 'Wp_UserCompanyEdge'
  next?: Maybe<Wp_UserCompany>
  node: Wp_UserCompany
  previous?: Maybe<Wp_UserCompany>
}

export type Wp_UserCompanyFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_UserCompanyToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_UserCompanyFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserCompanyToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_UserCompanyFilterListInput = {
  elemMatch?: InputMaybe<Wp_UserCompanyFilterInput>
}

export type Wp_UserCompanyGroupConnection = {
  __typename?: 'Wp_UserCompanyGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserCompanyEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserCompanyGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserCompany>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserCompanyGroupConnectionDistinctArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyGroupConnectionGroupArgs = {
  field: Wp_UserCompanyFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserCompanyGroupConnectionMaxArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyGroupConnectionMinArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanyGroupConnectionSumArgs = {
  field: Wp_UserCompanyFieldSelector
}

export type Wp_UserCompanySortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_UserCompanyToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the UserCompany type and the Taxonomy type */
export type Wp_UserCompanyToTaxonomyConnectionEdge = {
  __typename?: 'Wp_UserCompanyToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_UserCompanyToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_UserCompanyToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_UserCompanyToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_UserConnection = {
  __typename?: 'Wp_UserConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserEdge>
  group: Array<Wp_UserGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_User>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserConnectionDistinctArgs = {
  field: Wp_UserFieldSelector
}

export type Wp_UserConnectionGroupArgs = {
  field: Wp_UserFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserConnectionMaxArgs = {
  field: Wp_UserFieldSelector
}

export type Wp_UserConnectionMinArgs = {
  field: Wp_UserFieldSelector
}

export type Wp_UserConnectionSumArgs = {
  field: Wp_UserFieldSelector
}

/** The UserDivision type */
export type Wp_UserDivision = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_UserDivision'
    children: Array<Node>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserDivision type and the Taxonomy type */
    taxonomy?: Maybe<Wp_UserDivisionToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_UserDivisionConnection = {
  __typename?: 'Wp_UserDivisionConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserDivisionEdge>
  group: Array<Wp_UserDivisionGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserDivision>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserDivisionConnectionDistinctArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionConnectionGroupArgs = {
  field: Wp_UserDivisionFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserDivisionConnectionMaxArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionConnectionMinArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionConnectionSumArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionEdge = {
  __typename?: 'Wp_UserDivisionEdge'
  next?: Maybe<Wp_UserDivision>
  node: Wp_UserDivision
  previous?: Maybe<Wp_UserDivision>
}

export type Wp_UserDivisionFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_UserDivisionToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_UserDivisionFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserDivisionToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_UserDivisionFilterListInput = {
  elemMatch?: InputMaybe<Wp_UserDivisionFilterInput>
}

export type Wp_UserDivisionGroupConnection = {
  __typename?: 'Wp_UserDivisionGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserDivisionEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserDivisionGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserDivision>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserDivisionGroupConnectionDistinctArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionGroupConnectionGroupArgs = {
  field: Wp_UserDivisionFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserDivisionGroupConnectionMaxArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionGroupConnectionMinArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionGroupConnectionSumArgs = {
  field: Wp_UserDivisionFieldSelector
}

export type Wp_UserDivisionSortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_UserDivisionToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the UserDivision type and the Taxonomy type */
export type Wp_UserDivisionToTaxonomyConnectionEdge = {
  __typename?: 'Wp_UserDivisionToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_UserDivisionToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_UserDivisionToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_UserDivisionToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

export type Wp_UserEdge = {
  __typename?: 'Wp_UserEdge'
  next?: Maybe<Wp_User>
  node: Wp_User
  previous?: Maybe<Wp_User>
}

export type Wp_UserFieldSelector = {
  avatar?: InputMaybe<Wp_AvatarFieldSelector>
  capKey?: InputMaybe<FieldSelectorEnum>
  capabilities?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  comments?: InputMaybe<Wp_UserToCommentConnectionFieldSelector>
  companies?: InputMaybe<Wp_UserToUserCompanyConnectionFieldSelector>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  divisions?: InputMaybe<Wp_UserToUserDivisionConnectionFieldSelector>
  documents?: InputMaybe<Wp_UserToDocumentConnectionFieldSelector>
  email?: InputMaybe<FieldSelectorEnum>
  emergencyContactInfo?: InputMaybe<Wp_User_EmergencycontactinfoFieldSelector>
  employeeId?: InputMaybe<FieldSelectorEnum>
  extraCapabilities?: InputMaybe<FieldSelectorEnum>
  firstName?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  integrationSource?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isSuperAdmin?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  languages?: InputMaybe<Wp_UserToUserLanguageConnectionFieldSelector>
  lastName?: InputMaybe<FieldSelectorEnum>
  lessons?: InputMaybe<Wp_UserToLessonConnectionFieldSelector>
  locale?: InputMaybe<FieldSelectorEnum>
  magicBellHmacEmail?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nicename?: InputMaybe<FieldSelectorEnum>
  nickname?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  offlineMode?: InputMaybe<FieldSelectorEnum>
  offlineModeData?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  phoneNumber?: InputMaybe<FieldSelectorEnum>
  registeredDate?: InputMaybe<FieldSelectorEnum>
  roles?: InputMaybe<Wp_UserToUserRoleConnectionFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  status?: InputMaybe<Wp_UserToUserStatusConnectionFieldSelector>
  uri?: InputMaybe<FieldSelectorEnum>
  url?: InputMaybe<FieldSelectorEnum>
  usageCounts?: InputMaybe<Wp_User_UsagecountsFieldSelector>
  username?: InputMaybe<FieldSelectorEnum>
  vaccineStatus?: InputMaybe<Wp_UserToUserVaccineStatusConnectionEdgeFieldSelector>
}

export type Wp_UserFilterInput = {
  avatar?: InputMaybe<Wp_AvatarFilterInput>
  capKey?: InputMaybe<StringQueryOperatorInput>
  capabilities?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  comments?: InputMaybe<Wp_UserToCommentConnectionFilterInput>
  companies?: InputMaybe<Wp_UserToUserCompanyConnectionFilterInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  divisions?: InputMaybe<Wp_UserToUserDivisionConnectionFilterInput>
  documents?: InputMaybe<Wp_UserToDocumentConnectionFilterInput>
  email?: InputMaybe<StringQueryOperatorInput>
  emergencyContactInfo?: InputMaybe<Wp_User_EmergencycontactinfoFilterInput>
  employeeId?: InputMaybe<StringQueryOperatorInput>
  extraCapabilities?: InputMaybe<StringQueryOperatorInput>
  firstName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  integrationSource?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isSuperAdmin?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  languages?: InputMaybe<Wp_UserToUserLanguageConnectionFilterInput>
  lastName?: InputMaybe<StringQueryOperatorInput>
  lessons?: InputMaybe<Wp_UserToLessonConnectionFilterInput>
  locale?: InputMaybe<StringQueryOperatorInput>
  magicBellHmacEmail?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nicename?: InputMaybe<StringQueryOperatorInput>
  nickname?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  offlineMode?: InputMaybe<BooleanQueryOperatorInput>
  offlineModeData?: InputMaybe<Wp_OfflineModeDataCollectionNameEnumQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  phoneNumber?: InputMaybe<StringQueryOperatorInput>
  registeredDate?: InputMaybe<StringQueryOperatorInput>
  roles?: InputMaybe<Wp_UserToUserRoleConnectionFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  status?: InputMaybe<Wp_UserToUserStatusConnectionFilterInput>
  uri?: InputMaybe<StringQueryOperatorInput>
  url?: InputMaybe<StringQueryOperatorInput>
  usageCounts?: InputMaybe<Wp_User_UsagecountsFilterInput>
  username?: InputMaybe<StringQueryOperatorInput>
  vaccineStatus?: InputMaybe<Wp_UserToUserVaccineStatusConnectionEdgeFilterInput>
}

export type Wp_UserFilterListInput = {
  elemMatch?: InputMaybe<Wp_UserFilterInput>
}

export type Wp_UserGroupConnection = {
  __typename?: 'Wp_UserGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_User>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserGroupConnectionDistinctArgs = {
  field: Wp_UserFieldSelector
}

export type Wp_UserGroupConnectionGroupArgs = {
  field: Wp_UserFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserGroupConnectionMaxArgs = {
  field: Wp_UserFieldSelector
}

export type Wp_UserGroupConnectionMinArgs = {
  field: Wp_UserFieldSelector
}

export type Wp_UserGroupConnectionSumArgs = {
  field: Wp_UserFieldSelector
}

/** The UserLanguage type */
export type Wp_UserLanguage = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_UserLanguage'
    children: Array<Node>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserLanguage type and the Taxonomy type */
    taxonomy?: Maybe<Wp_UserLanguageToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_UserLanguageConnection = {
  __typename?: 'Wp_UserLanguageConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserLanguageEdge>
  group: Array<Wp_UserLanguageGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserLanguage>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserLanguageConnectionDistinctArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageConnectionGroupArgs = {
  field: Wp_UserLanguageFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserLanguageConnectionMaxArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageConnectionMinArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageConnectionSumArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageEdge = {
  __typename?: 'Wp_UserLanguageEdge'
  next?: Maybe<Wp_UserLanguage>
  node: Wp_UserLanguage
  previous?: Maybe<Wp_UserLanguage>
}

export type Wp_UserLanguageFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_UserLanguageToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_UserLanguageFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserLanguageToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_UserLanguageFilterListInput = {
  elemMatch?: InputMaybe<Wp_UserLanguageFilterInput>
}

export type Wp_UserLanguageGroupConnection = {
  __typename?: 'Wp_UserLanguageGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserLanguageEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserLanguageGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserLanguage>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserLanguageGroupConnectionDistinctArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageGroupConnectionGroupArgs = {
  field: Wp_UserLanguageFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserLanguageGroupConnectionMaxArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageGroupConnectionMinArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageGroupConnectionSumArgs = {
  field: Wp_UserLanguageFieldSelector
}

export type Wp_UserLanguageSortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_UserLanguageToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the UserLanguage type and the Taxonomy type */
export type Wp_UserLanguageToTaxonomyConnectionEdge = {
  __typename?: 'Wp_UserLanguageToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_UserLanguageToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_UserLanguageToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_UserLanguageToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** A user role object */
export type Wp_UserRole = Node &
  Wp_Node & {
    __typename?: 'Wp_UserRole'
    /** The capabilities that belong to this role */
    capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    children: Array<Node>
    /** The display name of the role */
    displayName?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** The registered name of the role */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
  }

export type Wp_UserRoleConnection = {
  __typename?: 'Wp_UserRoleConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserRoleEdge>
  group: Array<Wp_UserRoleGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserRole>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserRoleConnectionDistinctArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleConnectionGroupArgs = {
  field: Wp_UserRoleFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserRoleConnectionMaxArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleConnectionMinArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleConnectionSumArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleEdge = {
  __typename?: 'Wp_UserRoleEdge'
  next?: Maybe<Wp_UserRole>
  node: Wp_UserRole
  previous?: Maybe<Wp_UserRole>
}

export type Wp_UserRoleFieldSelector = {
  capabilities?: InputMaybe<FieldSelectorEnum>
  children?: InputMaybe<NodeFieldSelector>
  displayName?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
}

export type Wp_UserRoleFilterInput = {
  capabilities?: InputMaybe<StringQueryOperatorInput>
  children?: InputMaybe<NodeFilterListInput>
  displayName?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
}

export type Wp_UserRoleFilterListInput = {
  elemMatch?: InputMaybe<Wp_UserRoleFilterInput>
}

export type Wp_UserRoleGroupConnection = {
  __typename?: 'Wp_UserRoleGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserRoleEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserRoleGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserRole>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserRoleGroupConnectionDistinctArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleGroupConnectionGroupArgs = {
  field: Wp_UserRoleFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserRoleGroupConnectionMaxArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleGroupConnectionMinArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleGroupConnectionSumArgs = {
  field: Wp_UserRoleFieldSelector
}

export type Wp_UserRoleSortInput = {
  capabilities?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  displayName?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
}

export type Wp_UserSortInput = {
  avatar?: InputMaybe<Wp_AvatarSortInput>
  capKey?: InputMaybe<SortOrderEnum>
  capabilities?: InputMaybe<SortOrderEnum>
  children?: InputMaybe<NodeSortInput>
  comments?: InputMaybe<Wp_UserToCommentConnectionSortInput>
  companies?: InputMaybe<Wp_UserToUserCompanyConnectionSortInput>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  divisions?: InputMaybe<Wp_UserToUserDivisionConnectionSortInput>
  documents?: InputMaybe<Wp_UserToDocumentConnectionSortInput>
  email?: InputMaybe<SortOrderEnum>
  emergencyContactInfo?: InputMaybe<Wp_User_EmergencycontactinfoSortInput>
  employeeId?: InputMaybe<SortOrderEnum>
  extraCapabilities?: InputMaybe<SortOrderEnum>
  firstName?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  integrationSource?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isSuperAdmin?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  languages?: InputMaybe<Wp_UserToUserLanguageConnectionSortInput>
  lastName?: InputMaybe<SortOrderEnum>
  lessons?: InputMaybe<Wp_UserToLessonConnectionSortInput>
  locale?: InputMaybe<SortOrderEnum>
  magicBellHmacEmail?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nicename?: InputMaybe<SortOrderEnum>
  nickname?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  offlineMode?: InputMaybe<SortOrderEnum>
  offlineModeData?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  phoneNumber?: InputMaybe<SortOrderEnum>
  registeredDate?: InputMaybe<SortOrderEnum>
  roles?: InputMaybe<Wp_UserToUserRoleConnectionSortInput>
  slug?: InputMaybe<SortOrderEnum>
  status?: InputMaybe<Wp_UserToUserStatusConnectionSortInput>
  uri?: InputMaybe<SortOrderEnum>
  url?: InputMaybe<SortOrderEnum>
  usageCounts?: InputMaybe<Wp_User_UsagecountsSortInput>
  username?: InputMaybe<SortOrderEnum>
  vaccineStatus?: InputMaybe<Wp_UserToUserVaccineStatusConnectionEdgeSortInput>
}

/** The UserStatus type */
export type Wp_UserStatus = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_UserStatus'
    children: Array<Node>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserStatus type and the Taxonomy type */
    taxonomy?: Maybe<Wp_UserStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_UserStatusConnection = {
  __typename?: 'Wp_UserStatusConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserStatusEdge>
  group: Array<Wp_UserStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserStatusConnectionDistinctArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusConnectionGroupArgs = {
  field: Wp_UserStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserStatusConnectionMaxArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusConnectionMinArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusConnectionSumArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusEdge = {
  __typename?: 'Wp_UserStatusEdge'
  next?: Maybe<Wp_UserStatus>
  node: Wp_UserStatus
  previous?: Maybe<Wp_UserStatus>
}

export type Wp_UserStatusFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_UserStatusToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_UserStatusFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_UserStatusFilterListInput = {
  elemMatch?: InputMaybe<Wp_UserStatusFilterInput>
}

export type Wp_UserStatusGroupConnection = {
  __typename?: 'Wp_UserStatusGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserStatusEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserStatusGroupConnectionDistinctArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusGroupConnectionGroupArgs = {
  field: Wp_UserStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserStatusGroupConnectionMaxArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusGroupConnectionMinArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusGroupConnectionSumArgs = {
  field: Wp_UserStatusFieldSelector
}

export type Wp_UserStatusSortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_UserStatusToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the UserStatus type and the Taxonomy type */
export type Wp_UserStatusToTaxonomyConnectionEdge = {
  __typename?: 'Wp_UserStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_UserStatusToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_UserStatusToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_UserStatusToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** Connection between the User type and the Comment type */
export type Wp_UserToCommentConnection = {
  __typename?: 'Wp_UserToCommentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Comment>>>
}

export type Wp_UserToCommentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_CommentFieldSelector>
}

export type Wp_UserToCommentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_CommentFilterListInput>
}

export type Wp_UserToCommentConnectionSortInput = {
  nodes?: InputMaybe<Wp_CommentSortInput>
}

/** Connection between the User type and the Document type */
export type Wp_UserToDocumentConnection = {
  __typename?: 'Wp_UserToDocumentConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Document>>>
}

export type Wp_UserToDocumentConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_DocumentFieldSelector>
}

export type Wp_UserToDocumentConnectionFilterInput = {
  nodes?: InputMaybe<Wp_DocumentFilterListInput>
}

export type Wp_UserToDocumentConnectionSortInput = {
  nodes?: InputMaybe<Wp_DocumentSortInput>
}

/** Connection between the User type and the Lesson type */
export type Wp_UserToLessonConnection = {
  __typename?: 'Wp_UserToLessonConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_Lesson>>>
}

export type Wp_UserToLessonConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_LessonFieldSelector>
}

export type Wp_UserToLessonConnectionFilterInput = {
  nodes?: InputMaybe<Wp_LessonFilterListInput>
}

export type Wp_UserToLessonConnectionSortInput = {
  nodes?: InputMaybe<Wp_LessonSortInput>
}

/** Connection between the User type and the UserCompany type */
export type Wp_UserToUserCompanyConnection = {
  __typename?: 'Wp_UserToUserCompanyConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_UserCompany>>>
}

export type Wp_UserToUserCompanyConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_UserCompanyFieldSelector>
}

export type Wp_UserToUserCompanyConnectionFilterInput = {
  nodes?: InputMaybe<Wp_UserCompanyFilterListInput>
}

export type Wp_UserToUserCompanyConnectionSortInput = {
  nodes?: InputMaybe<Wp_UserCompanySortInput>
}

/** Connection between the User type and the UserDivision type */
export type Wp_UserToUserDivisionConnection = {
  __typename?: 'Wp_UserToUserDivisionConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_UserDivision>>>
}

export type Wp_UserToUserDivisionConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_UserDivisionFieldSelector>
}

export type Wp_UserToUserDivisionConnectionFilterInput = {
  nodes?: InputMaybe<Wp_UserDivisionFilterListInput>
}

export type Wp_UserToUserDivisionConnectionSortInput = {
  nodes?: InputMaybe<Wp_UserDivisionSortInput>
}

/** Connection between the User type and the UserLanguage type */
export type Wp_UserToUserLanguageConnection = {
  __typename?: 'Wp_UserToUserLanguageConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_UserLanguage>>>
}

export type Wp_UserToUserLanguageConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_UserLanguageFieldSelector>
}

export type Wp_UserToUserLanguageConnectionFilterInput = {
  nodes?: InputMaybe<Wp_UserLanguageFilterListInput>
}

export type Wp_UserToUserLanguageConnectionSortInput = {
  nodes?: InputMaybe<Wp_UserLanguageSortInput>
}

/** Connection between the User type and the UserRole type */
export type Wp_UserToUserRoleConnection = {
  __typename?: 'Wp_UserToUserRoleConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_UserRole>>>
}

export type Wp_UserToUserRoleConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_UserRoleFieldSelector>
}

export type Wp_UserToUserRoleConnectionFilterInput = {
  nodes?: InputMaybe<Wp_UserRoleFilterListInput>
}

export type Wp_UserToUserRoleConnectionSortInput = {
  nodes?: InputMaybe<Wp_UserRoleSortInput>
}

/** Connection between the User type and the UserStatus type */
export type Wp_UserToUserStatusConnection = {
  __typename?: 'Wp_UserToUserStatusConnection'
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Wp_UserStatus>>>
}

export type Wp_UserToUserStatusConnectionFieldSelector = {
  nodes?: InputMaybe<Wp_UserStatusFieldSelector>
}

export type Wp_UserToUserStatusConnectionFilterInput = {
  nodes?: InputMaybe<Wp_UserStatusFilterListInput>
}

export type Wp_UserToUserStatusConnectionSortInput = {
  nodes?: InputMaybe<Wp_UserStatusSortInput>
}

/** Connection between the User type and the UserVaccineStatus type */
export type Wp_UserToUserVaccineStatusConnectionEdge = {
  __typename?: 'Wp_UserToUserVaccineStatusConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_UserVaccineStatus>
}

export type Wp_UserToUserVaccineStatusConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_UserVaccineStatusFieldSelector>
}

export type Wp_UserToUserVaccineStatusConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_UserVaccineStatusFilterInput>
}

export type Wp_UserToUserVaccineStatusConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_UserVaccineStatusSortInput>
}

/** The UserVaccineStatus type */
export type Wp_UserVaccineStatus = Node &
  Wp_DatabaseIdentifier &
  Wp_MenuItemLinkable &
  Wp_Node &
  Wp_TermNode &
  Wp_UniformResourceIdentifiable & {
    __typename?: 'Wp_UserVaccineStatus'
    children: Array<Node>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    internal: Internal
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    nodeType?: Maybe<Scalars['String']['output']>
    parent?: Maybe<Node>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserVaccineStatus type and the Taxonomy type */
    taxonomy?: Maybe<Wp_UserVaccineStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

export type Wp_UserVaccineStatusConnection = {
  __typename?: 'Wp_UserVaccineStatusConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserVaccineStatusEdge>
  group: Array<Wp_UserVaccineStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserVaccineStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserVaccineStatusConnectionDistinctArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusConnectionGroupArgs = {
  field: Wp_UserVaccineStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserVaccineStatusConnectionMaxArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusConnectionMinArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusConnectionSumArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusEdge = {
  __typename?: 'Wp_UserVaccineStatusEdge'
  next?: Maybe<Wp_UserVaccineStatus>
  node: Wp_UserVaccineStatus
  previous?: Maybe<Wp_UserVaccineStatus>
}

export type Wp_UserVaccineStatusFieldSelector = {
  children?: InputMaybe<NodeFieldSelector>
  count?: InputMaybe<FieldSelectorEnum>
  databaseId?: InputMaybe<FieldSelectorEnum>
  description?: InputMaybe<FieldSelectorEnum>
  id?: InputMaybe<FieldSelectorEnum>
  internal?: InputMaybe<InternalFieldSelector>
  isContentNode?: InputMaybe<FieldSelectorEnum>
  isTermNode?: InputMaybe<FieldSelectorEnum>
  link?: InputMaybe<FieldSelectorEnum>
  name?: InputMaybe<FieldSelectorEnum>
  nodeType?: InputMaybe<FieldSelectorEnum>
  parent?: InputMaybe<NodeFieldSelector>
  slug?: InputMaybe<FieldSelectorEnum>
  taxonomy?: InputMaybe<Wp_UserVaccineStatusToTaxonomyConnectionEdgeFieldSelector>
  taxonomyName?: InputMaybe<FieldSelectorEnum>
  termGroupId?: InputMaybe<FieldSelectorEnum>
  termTaxonomyId?: InputMaybe<FieldSelectorEnum>
  uri?: InputMaybe<FieldSelectorEnum>
}

export type Wp_UserVaccineStatusFilterInput = {
  children?: InputMaybe<NodeFilterListInput>
  count?: InputMaybe<IntQueryOperatorInput>
  databaseId?: InputMaybe<IntQueryOperatorInput>
  description?: InputMaybe<StringQueryOperatorInput>
  id?: InputMaybe<StringQueryOperatorInput>
  internal?: InputMaybe<InternalFilterInput>
  isContentNode?: InputMaybe<BooleanQueryOperatorInput>
  isTermNode?: InputMaybe<BooleanQueryOperatorInput>
  link?: InputMaybe<StringQueryOperatorInput>
  name?: InputMaybe<StringQueryOperatorInput>
  nodeType?: InputMaybe<StringQueryOperatorInput>
  parent?: InputMaybe<NodeFilterInput>
  slug?: InputMaybe<StringQueryOperatorInput>
  taxonomy?: InputMaybe<Wp_UserVaccineStatusToTaxonomyConnectionEdgeFilterInput>
  taxonomyName?: InputMaybe<StringQueryOperatorInput>
  termGroupId?: InputMaybe<IntQueryOperatorInput>
  termTaxonomyId?: InputMaybe<IntQueryOperatorInput>
  uri?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_UserVaccineStatusGroupConnection = {
  __typename?: 'Wp_UserVaccineStatusGroupConnection'
  distinct: Array<Scalars['String']['output']>
  edges: Array<Wp_UserVaccineStatusEdge>
  field: Scalars['String']['output']
  fieldValue?: Maybe<Scalars['String']['output']>
  group: Array<Wp_UserVaccineStatusGroupConnection>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  nodes: Array<Wp_UserVaccineStatus>
  pageInfo: PageInfo
  sum?: Maybe<Scalars['Float']['output']>
  totalCount: Scalars['Int']['output']
}

export type Wp_UserVaccineStatusGroupConnectionDistinctArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusGroupConnectionGroupArgs = {
  field: Wp_UserVaccineStatusFieldSelector
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type Wp_UserVaccineStatusGroupConnectionMaxArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusGroupConnectionMinArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusGroupConnectionSumArgs = {
  field: Wp_UserVaccineStatusFieldSelector
}

export type Wp_UserVaccineStatusSortInput = {
  children?: InputMaybe<NodeSortInput>
  count?: InputMaybe<SortOrderEnum>
  databaseId?: InputMaybe<SortOrderEnum>
  description?: InputMaybe<SortOrderEnum>
  id?: InputMaybe<SortOrderEnum>
  internal?: InputMaybe<InternalSortInput>
  isContentNode?: InputMaybe<SortOrderEnum>
  isTermNode?: InputMaybe<SortOrderEnum>
  link?: InputMaybe<SortOrderEnum>
  name?: InputMaybe<SortOrderEnum>
  nodeType?: InputMaybe<SortOrderEnum>
  parent?: InputMaybe<NodeSortInput>
  slug?: InputMaybe<SortOrderEnum>
  taxonomy?: InputMaybe<Wp_UserVaccineStatusToTaxonomyConnectionEdgeSortInput>
  taxonomyName?: InputMaybe<SortOrderEnum>
  termGroupId?: InputMaybe<SortOrderEnum>
  termTaxonomyId?: InputMaybe<SortOrderEnum>
  uri?: InputMaybe<SortOrderEnum>
}

/** Connection between the UserVaccineStatus type and the Taxonomy type */
export type Wp_UserVaccineStatusToTaxonomyConnectionEdge = {
  __typename?: 'Wp_UserVaccineStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Wp_Taxonomy>
}

export type Wp_UserVaccineStatusToTaxonomyConnectionEdgeFieldSelector = {
  node?: InputMaybe<Wp_TaxonomyFieldSelector>
}

export type Wp_UserVaccineStatusToTaxonomyConnectionEdgeFilterInput = {
  node?: InputMaybe<Wp_TaxonomyFilterInput>
}

export type Wp_UserVaccineStatusToTaxonomyConnectionEdgeSortInput = {
  node?: InputMaybe<Wp_TaxonomySortInput>
}

/** Field Group */
export type Wp_User_Emergencycontactinfo = Wp_AcfFieldGroup & {
  __typename?: 'Wp_User_Emergencycontactinfo'
  emergencyContactName?: Maybe<Scalars['String']['output']>
  emergencyContactNumber?: Maybe<Scalars['String']['output']>
  emergencyContactRelationship?: Maybe<Scalars['String']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
}

export type Wp_User_EmergencycontactinfoFieldSelector = {
  emergencyContactName?: InputMaybe<FieldSelectorEnum>
  emergencyContactNumber?: InputMaybe<FieldSelectorEnum>
  emergencyContactRelationship?: InputMaybe<FieldSelectorEnum>
  fieldGroupName?: InputMaybe<FieldSelectorEnum>
}

export type Wp_User_EmergencycontactinfoFilterInput = {
  emergencyContactName?: InputMaybe<StringQueryOperatorInput>
  emergencyContactNumber?: InputMaybe<StringQueryOperatorInput>
  emergencyContactRelationship?: InputMaybe<StringQueryOperatorInput>
  fieldGroupName?: InputMaybe<StringQueryOperatorInput>
}

export type Wp_User_EmergencycontactinfoSortInput = {
  emergencyContactName?: InputMaybe<SortOrderEnum>
  emergencyContactNumber?: InputMaybe<SortOrderEnum>
  emergencyContactRelationship?: InputMaybe<SortOrderEnum>
  fieldGroupName?: InputMaybe<SortOrderEnum>
}

/** Field Group */
export type Wp_User_Usagecounts = Wp_AcfFieldGroup & {
  __typename?: 'Wp_User_Usagecounts'
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  lastSeen?: Maybe<Scalars['String']['output']>
  loginCount?: Maybe<Scalars['Float']['output']>
  sdsDownloadCount?: Maybe<Scalars['Float']['output']>
}

export type Wp_User_UsagecountsFieldSelector = {
  fieldGroupName?: InputMaybe<FieldSelectorEnum>
  lastSeen?: InputMaybe<FieldSelectorEnum>
  loginCount?: InputMaybe<FieldSelectorEnum>
  sdsDownloadCount?: InputMaybe<FieldSelectorEnum>
}

export type Wp_User_UsagecountsFilterInput = {
  fieldGroupName?: InputMaybe<StringQueryOperatorInput>
  lastSeen?: InputMaybe<StringQueryOperatorInput>
  loginCount?: InputMaybe<FloatQueryOperatorInput>
  sdsDownloadCount?: InputMaybe<FloatQueryOperatorInput>
}

export type Wp_User_UsagecountsSortInput = {
  fieldGroupName?: InputMaybe<SortOrderEnum>
  lastSeen?: InputMaybe<SortOrderEnum>
  loginCount?: InputMaybe<SortOrderEnum>
  sdsDownloadCount?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_UsernameField = Wp_FormField & {
  __typename?: 'Wp_UsernameField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** Information needed by gatsby-source-wordpress. */
export type Wp_WpGatsby = {
  __typename?: 'Wp_WPGatsby'
  /** Returns wether or not pretty permalinks are enabled. */
  arePrettyPermalinksEnabled?: Maybe<Scalars['Boolean']['output']>
  /** Wether or not the Preview frontend URL is online. */
  isPreviewFrontendOnline?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_WpGatsbyFieldSelector = {
  arePrettyPermalinksEnabled?: InputMaybe<FieldSelectorEnum>
  isPreviewFrontendOnline?: InputMaybe<FieldSelectorEnum>
}

export type Wp_WpGatsbyFilterInput = {
  arePrettyPermalinksEnabled?: InputMaybe<BooleanQueryOperatorInput>
  isPreviewFrontendOnline?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_WpGatsbySortInput = {
  arePrettyPermalinksEnabled?: InputMaybe<SortOrderEnum>
  isPreviewFrontendOnline?: InputMaybe<SortOrderEnum>
}

/** A Gravity Forms   field. */
export type Wp_WebsiteField = Wp_FormField & {
  __typename?: 'Wp_WebsiteField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<Wp_BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<Wp_ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<Wp_FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<Wp_FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<Wp_FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<Wp_FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<Wp_FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<Wp_FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<Wp_FormFieldVisibilityEnum>
}

/** The writing setting type */
export type Wp_WritingSettings = {
  __typename?: 'Wp_WritingSettings'
  /** Default post category. */
  defaultCategory?: Maybe<Scalars['Int']['output']>
  /** Default post format. */
  defaultPostFormat?: Maybe<Scalars['String']['output']>
  /** Convert emoticons like :-) and :-P to graphics on display. */
  useSmilies?: Maybe<Scalars['Boolean']['output']>
}

export type Wp_WritingSettingsFieldSelector = {
  defaultCategory?: InputMaybe<FieldSelectorEnum>
  defaultPostFormat?: InputMaybe<FieldSelectorEnum>
  useSmilies?: InputMaybe<FieldSelectorEnum>
}

export type Wp_WritingSettingsFilterInput = {
  defaultCategory?: InputMaybe<IntQueryOperatorInput>
  defaultPostFormat?: InputMaybe<StringQueryOperatorInput>
  useSmilies?: InputMaybe<BooleanQueryOperatorInput>
}

export type Wp_WritingSettingsSortInput = {
  defaultCategory?: InputMaybe<SortOrderEnum>
  defaultPostFormat?: InputMaybe<SortOrderEnum>
  useSmilies?: InputMaybe<SortOrderEnum>
}

export type GatsbyImageSharpFixedFragment = {
  __typename?: 'ImageSharpFixed'
  base64?: string | null
  width: number
  height: number
  src: string
  srcSet: string
}

export type GatsbyImageSharpFixed_TracedSvgFragment = {
  __typename?: 'ImageSharpFixed'
  tracedSVG?: string | null
  width: number
  height: number
  src: string
  srcSet: string
}

export type GatsbyImageSharpFixed_WithWebpFragment = {
  __typename?: 'ImageSharpFixed'
  base64?: string | null
  width: number
  height: number
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
}

export type GatsbyImageSharpFixed_WithWebp_TracedSvgFragment = {
  __typename?: 'ImageSharpFixed'
  tracedSVG?: string | null
  width: number
  height: number
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
}

export type GatsbyImageSharpFixed_NoBase64Fragment = {
  __typename?: 'ImageSharpFixed'
  width: number
  height: number
  src: string
  srcSet: string
}

export type GatsbyImageSharpFixed_WithWebp_NoBase64Fragment = {
  __typename?: 'ImageSharpFixed'
  width: number
  height: number
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
}

export type GatsbyImageSharpFluidFragment = {
  __typename?: 'ImageSharpFluid'
  base64?: string | null
  aspectRatio: number
  src: string
  srcSet: string
  sizes: string
}

export type GatsbyImageSharpFluidLimitPresentationSizeFragment = {
  __typename?: 'ImageSharpFluid'
  maxHeight: number
  maxWidth: number
}

export type GatsbyImageSharpFluid_TracedSvgFragment = {
  __typename?: 'ImageSharpFluid'
  tracedSVG?: string | null
  aspectRatio: number
  src: string
  srcSet: string
  sizes: string
}

export type GatsbyImageSharpFluid_WithWebpFragment = {
  __typename?: 'ImageSharpFluid'
  base64?: string | null
  aspectRatio: number
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
  sizes: string
}

export type GatsbyImageSharpFluid_WithWebp_TracedSvgFragment = {
  __typename?: 'ImageSharpFluid'
  tracedSVG?: string | null
  aspectRatio: number
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
  sizes: string
}

export type GatsbyImageSharpFluid_NoBase64Fragment = {
  __typename?: 'ImageSharpFluid'
  aspectRatio: number
  src: string
  srcSet: string
  sizes: string
}

export type GatsbyImageSharpFluid_WithWebp_NoBase64Fragment = {
  __typename?: 'ImageSharpFluid'
  aspectRatio: number
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
  sizes: string
}

export type AllTrainingTypesQueryVariables = Exact<{ [key: string]: never }>

export type AllTrainingTypesQuery = {
  __typename?: 'Query'
  allWpTrainingType: {
    __typename?: 'Wp_TrainingTypeConnection'
    edges: Array<{
      __typename?: 'Wp_TrainingTypeEdge'
      node: {
        __typename?: 'Wp_TrainingType'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllCorrectiveActionStatusesQueryVariables = Exact<{
  [key: string]: never
}>

export type AllCorrectiveActionStatusesQuery = {
  __typename?: 'Query'
  allWpCorrectiveActionStatus: {
    __typename?: 'Wp_CorrectiveActionStatusConnection'
    edges: Array<{
      __typename?: 'Wp_CorrectiveActionStatusEdge'
      node: {
        __typename?: 'Wp_CorrectiveActionStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllDeficiencyTypesQueryVariables = Exact<{ [key: string]: never }>

export type AllDeficiencyTypesQuery = {
  __typename?: 'Query'
  allWpDeficiencyType: {
    __typename?: 'Wp_DeficiencyTypeConnection'
    edges: Array<{
      __typename?: 'Wp_DeficiencyTypeEdge'
      node: {
        __typename?: 'Wp_DeficiencyType'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllSeveritiesQueryVariables = Exact<{ [key: string]: never }>

export type AllSeveritiesQuery = {
  __typename?: 'Query'
  allWpSeverity: {
    __typename?: 'Wp_SeverityConnection'
    edges: Array<{
      __typename?: 'Wp_SeverityEdge'
      node: {
        __typename?: 'Wp_Severity'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllDocumentTagsQueryVariables = Exact<{ [key: string]: never }>

export type AllDocumentTagsQuery = {
  __typename?: 'Query'
  allWpDocumentTag: {
    __typename?: 'Wp_DocumentTagConnection'
    edges: Array<{
      __typename?: 'Wp_DocumentTagEdge'
      node: {
        __typename?: 'Wp_DocumentTag'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllEquipmentStatusesQueryVariables = Exact<{ [key: string]: never }>

export type AllEquipmentStatusesQuery = {
  __typename?: 'Query'
  allWpEquipmentStatus: {
    __typename?: 'Wp_EquipmentStatusConnection'
    edges: Array<{
      __typename?: 'Wp_EquipmentStatusEdge'
      node: {
        __typename?: 'Wp_EquipmentStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllEquipmentTagsQueryVariables = Exact<{ [key: string]: never }>

export type AllEquipmentTagsQuery = {
  __typename?: 'Query'
  allWpEquipmentTag: {
    __typename?: 'Wp_EquipmentTagConnection'
    edges: Array<{
      __typename?: 'Wp_EquipmentTagEdge'
      node: {
        __typename?: 'Wp_EquipmentTag'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllInspectionIntervalsQueryVariables = Exact<{
  [key: string]: never
}>

export type AllInspectionIntervalsQuery = {
  __typename?: 'Query'
  allWpInspectionInterval: {
    __typename?: 'Wp_InspectionIntervalConnection'
    edges: Array<{
      __typename?: 'Wp_InspectionIntervalEdge'
      node: {
        __typename?: 'Wp_InspectionInterval'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllManufacturersQueryVariables = Exact<{ [key: string]: never }>

export type AllManufacturersQuery = {
  __typename?: 'Query'
  allWpManufacturer: {
    __typename?: 'Wp_ManufacturerConnection'
    edges: Array<{
      __typename?: 'Wp_ManufacturerEdge'
      node: {
        __typename?: 'Wp_Manufacturer'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type MediaItemFragFragment = {
  __typename?: 'Wp_MediaItem'
  altText?: string | null
  id: string
  title?: string | null
  description?: string | null
  mediaItemUrl?: string | null
  sizes?: string | null
  databaseId: number
  sourceUrl?: string | null
  srcSet?: string | null
}

export type LessonDetailsFragFragment = {
  __typename?: 'Wp_Lesson_Lessondetails'
  courseExpriyLengthInMonths?: number | null
  fieldGroupName?: string | null
  lessonAudioDescription?: string | null
  lessonAudioEmbed?: string | null
  lessonAudioTitle?: string | null
  lessonDescription?: string | null
  lessonVideoDescription?: string | null
  lessonVideoEmbed?: string | null
  lessonVideoTitle?: string | null
  lessonQuiz?: {
    __typename?: 'Wp_GfForm'
    isQuizForm?: boolean | null
    cssClass?: string | null
    description?: string | null
    disableEditing?: boolean | null
    hideJumpToSection?: boolean | null
    id: string
    reloadOnSubmit?: boolean | null
    restrictEntriesToUserCreated?: Array<string | null> | null
    restrictFormSubmissionByRole?: Array<string | null> | null
    slug?: string | null
    title?: string | null
    postTypeToCreate?: string | null
    isCAForm?: boolean | null
    isPrimary?: boolean | null
    group?: string | null
    databaseId: number
    isActive?: boolean | null
    version?: string | null
    quiz?: {
      __typename?: 'Wp_FormQuiz'
      gradingType?: Wp_QuizFieldGradingTypeEnum | null
      hasInstantFeedback?: boolean | null
      hasLetterConfirmationMessage?: boolean | null
      hasPassFailConfirmationMessage?: boolean | null
      isShuffleFieldsEnabled?: boolean | null
      maxScore?: number | null
      passPercent?: number | null
      failConfirmation?: {
        __typename?: 'Wp_FormQuizConfirmation'
        isAutoformatted?: boolean | null
        message?: string | null
      } | null
      letterConfirmation?: {
        __typename?: 'Wp_FormQuizConfirmation'
        isAutoformatted?: boolean | null
        message?: string | null
      } | null
      passConfirmation?: {
        __typename?: 'Wp_FormQuizConfirmation'
        isAutoformatted?: boolean | null
        message?: string | null
      } | null
      grades?: Array<{
        __typename?: 'Wp_FormQuizGrades'
        text?: string | null
        value?: number | null
      } | null> | null
    } | null
    formFields?: {
      __typename?: 'Wp_GfFormToFormFieldConnection'
      nodes?: Array<
        | {
            __typename: 'Wp_AddressField'
            label?: string | null
            description?: string | null
            isRequired?: boolean | null
            addressType?: Wp_AddressFieldTypeEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            copyValuesOptionLabel?: string | null
            copyValuesOptionFieldId?: number | null
            cssClass?: string | null
            defaultCountry?: Wp_AddressFieldCountryEnum | null
            defaultState?: string | null
            defaultProvince?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldCopyValuesOption?: boolean | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            inputs?: Array<{
              __typename?: 'Wp_AddressInputProperty'
              key?: string | null
              label?: string | null
              placeholder?: string | null
              defaultValue?: string | null
              customLabel?: string | null
              autocompleteAttribute?: string | null
              id?: number | null
              isHidden?: boolean | null
              name?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            addressTranslations?: Array<{
              __typename?: 'Wp_AddressTranlation'
              name?: string | null
              slug?: string | null
              label?: string | null
              description?: string | null
              address?: string | null
              address2?: string | null
              city?: string | null
              state?: string | null
              country?: string | null
              zip?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_CaptchaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_CheckboxField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasSelectAll?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_CheckboxFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            inputs?: Array<{
              __typename?: 'Wp_CheckboxInputProperty'
              id?: number | null
              label?: string | null
              name?: string | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_ComboSignatureField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            backgroundColor?: string | null
            borderColor?: string | null
            borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
            borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
            boxWidth?: number | null
            penSize?: number | null
            penColor?: string | null
            minSignatures?: number | null
            maxSignatures?: number | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            defaultValue?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            placeholder?: string | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_ComboSignatureFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_ConsentField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_DateField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
            inputName?: string | null
            dateFormat?: Wp_DateFieldFormatEnum | null
            adminLabel?: string | null
            calendarIconUrl?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'Wp_DateInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_EmailField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasEmailConfirmation?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'Wp_EmailInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              name?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_FileUploadField'
            label?: string | null
            isRequired?: boolean | null
            maxFileSize?: number | null
            maxFiles?: number | null
            adminLabel?: string | null
            allowedExtensions?: Array<string | null> | null
            canAcceptMultipleFiles?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_FollowUpField'
            label?: string | null
            content?: string | null
            cssClass?: string | null
            followUpFormId?: number | null
            hasMargins?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            followUpForm?: {
              __typename?: 'Wp_GfForm'
              slug?: string | null
              title?: string | null
            } | null
            correctiveActionSettings?: Array<{
              __typename?: 'Wp_CorrectiveActionSettings'
              actionTaken?: string | null
              deficiency?: string | null
              deficiencyType?: Array<string | null> | null
              severity?: string | null
            } | null> | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_HiddenField'
            defaultValue?: string | null
            label?: string | null
            canPrepopulate?: boolean | null
            inputName?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_HtmlField'
            content?: string | null
            label?: string | null
            cssClass?: string | null
            hasMargins?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_ListField'
            description?: string | null
            isRequired?: boolean | null
            inputName?: string | null
            label?: string | null
            maxRows?: number | null
            addIconUrl?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            deleteIconUrl?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasColumns?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_ListFieldChoice'
              text?: string | null
              value?: string | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_MultiSelectField'
            label?: string | null
            description?: string | null
            isRequired?: boolean | null
            allowCustomOption?: boolean | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_MultiSelectFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_NameField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            inputs?: Array<{
              __typename?: 'Wp_NameInputProperty'
              key?: string | null
              label?: string | null
              placeholder?: string | null
              isHidden?: boolean | null
              defaultValue?: string | null
              customLabel?: string | null
              autocompleteAttribute?: string | null
              hasChoiceValue?: boolean | null
              id?: number | null
              name?: string | null
              choices?: Array<{
                __typename?: 'Wp_NameFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
            } | null> | null
            nameTranslations?: Array<{
              __typename?: 'Wp_NameTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              first?: string | null
              last?: string | null
              middle?: string | null
              prefix?: string | null
              suffix?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_NumberField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            rangeMin?: number | null
            rangeMax?: number | null
            numberFormat?: Wp_NumberFieldFormatEnum | null
            placeholder?: string | null
            defaultValue?: string | null
            calculationFormula?: string | null
            calculationRounding?: number | null
            errorMessage?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            isCalculation?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_PageField'
            cssClass?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            previousButton?: {
              __typename?: 'Wp_FormButton'
              text?: string | null
              type?: Wp_FormButtonTypeEnum | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            } | null
            nextButton?: {
              __typename?: 'Wp_FormButton'
              text?: string | null
              type?: Wp_FormButtonTypeEnum | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            } | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_PasswordField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PhoneField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            defaultValue?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            phoneFormat?: Wp_PhoneFieldFormatEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_PhotoField'
            label?: string | null
            isRequired?: boolean | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_PostCategoryCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCategoryMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCategoryRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCategorySelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostContentField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomDateField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomEmailField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomFileuploadField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomHiddenField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomListField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomNumberField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomPhoneField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomTextAreaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomTextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomTimeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomWebsiteField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostExcerptField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostImageField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsTextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTitleField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_QuizCheckboxField'
            hasSelectAll?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            inputs?: Array<{
              __typename?: 'Wp_QuizInputProperty'
              label?: string | null
              id?: number | null
              name?: string | null
            } | null> | null
            choices?: Array<{
              __typename?: 'Wp_QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'Wp_QuizRadioField'
            shouldAllowDuplicates?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'Wp_QuizSelectField'
            defaultValue?: string | null
            placeholder?: string | null
            autocompleteAttribute?: string | null
            hasAutocomplete?: boolean | null
            hasEnhancedUI?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'Wp_RadioField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasOtherChoice?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_RadioFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
              isOtherChoice?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_RememberMeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_SectionField'
            label?: string | null
            description?: string | null
            cssClass?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_SelectField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            defaultValue?: string | null
            allowCustomOption?: boolean | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            placeholder?: string | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_SelectFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_SignatureField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            penColor?: string | null
            penSize?: number | null
            backgroundColor?: string | null
            borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
            borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
            borderColor?: string | null
            adminLabel?: string | null
            boxWidth?: number | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_SubmitField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_TextAreaField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            maxLength?: number | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasRichTextEditor?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_TextField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            maxLength?: number | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasInputMask?: boolean | null
            inputMaskValue?: string | null
            isPasswordInput?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_TimeField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            timeFormat?: Wp_TimeFieldFormatEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'Wp_TimeInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_UsernameField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_WebsiteField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | null
      > | null
    } | null
    confirmations?: Array<{
      __typename?: 'Wp_FormConfirmation'
      id?: string | null
      isActive?: boolean | null
      isDefault?: boolean | null
      message?: string | null
      name?: string | null
      pageId?: number | null
      queryString?: string | null
      type?: Wp_FormConfirmationTypeEnum | null
      url?: string | null
      conditionalLogic?: {
        __typename?: 'Wp_ConditionalLogic'
        actionType?: Wp_ConditionalLogicActionTypeEnum | null
        logicType?: Wp_ConditionalLogicLogicTypeEnum | null
        rules?: Array<{
          __typename?: 'Wp_ConditionalLogicRule'
          fieldId?: number | null
          operator?: Wp_FormRuleOperatorEnum | null
          value?: string | null
        } | null> | null
      } | null
    } | null> | null
    pagination?: {
      __typename?: 'Wp_FormPagination'
      pageNames?: Array<string | null> | null
      lastPageButton?: {
        __typename?: 'Wp_FormLastPageButton'
        text?: string | null
        type?: Wp_FormButtonTypeEnum | null
      } | null
    } | null
    submitButton?: {
      __typename?: 'Wp_FormSubmitButton'
      text?: string | null
      type?: Wp_FormButtonTypeEnum | null
      conditionalLogic?: {
        __typename?: 'Wp_ConditionalLogic'
        actionType?: Wp_ConditionalLogicActionTypeEnum | null
        logicType?: Wp_ConditionalLogicLogicTypeEnum | null
        rules?: Array<{
          __typename?: 'Wp_ConditionalLogicRule'
          fieldId?: number | null
          operator?: Wp_FormRuleOperatorEnum | null
          value?: string | null
        } | null> | null
      } | null
    } | null
    saveAndContinue?: {
      __typename?: 'Wp_FormSaveAndContinue'
      hasSaveAndContinue?: boolean | null
      buttonText?: string | null
    } | null
    translations?: Array<{
      __typename?: 'Wp_FormTranslation'
      slug?: string | null
      name?: string | null
      active?: boolean | null
      title?: string | null
      description?: string | null
      submitButtonText?: string | null
      nextButtonText?: string | null
      clearButtonText?: string | null
      previousButtonText?: string | null
      cardDisplay?: {
        __typename?: 'Wp_CardDisplayTranslation'
        detail1?: string | null
        detail2?: string | null
        detail3?: string | null
      } | null
    } | null> | null
  } | null
}

export type LessonFragFragment = {
  __typename?: 'Wp_Lesson'
  id: string
  databaseId: number
  title?: string | null
  excerpt?: string | null
  content?: string | null
  slug?: string | null
  featuredImage?: {
    __typename?: 'Wp_NodeWithFeaturedImageToMediaItemConnectionEdge'
    node?: {
      __typename?: 'Wp_MediaItem'
      altText?: string | null
      id: string
      title?: string | null
      description?: string | null
      mediaItemUrl?: string | null
      sizes?: string | null
      databaseId: number
      sourceUrl?: string | null
      srcSet?: string | null
    } | null
  } | null
  lessonDetails?: {
    __typename?: 'Wp_Lesson_Lessondetails'
    courseExpriyLengthInMonths?: number | null
    fieldGroupName?: string | null
    lessonAudioDescription?: string | null
    lessonAudioEmbed?: string | null
    lessonAudioTitle?: string | null
    lessonDescription?: string | null
    lessonVideoDescription?: string | null
    lessonVideoEmbed?: string | null
    lessonVideoTitle?: string | null
    lessonQuiz?: {
      __typename?: 'Wp_GfForm'
      isQuizForm?: boolean | null
      cssClass?: string | null
      description?: string | null
      disableEditing?: boolean | null
      hideJumpToSection?: boolean | null
      id: string
      reloadOnSubmit?: boolean | null
      restrictEntriesToUserCreated?: Array<string | null> | null
      restrictFormSubmissionByRole?: Array<string | null> | null
      slug?: string | null
      title?: string | null
      postTypeToCreate?: string | null
      isCAForm?: boolean | null
      isPrimary?: boolean | null
      group?: string | null
      databaseId: number
      isActive?: boolean | null
      version?: string | null
      quiz?: {
        __typename?: 'Wp_FormQuiz'
        gradingType?: Wp_QuizFieldGradingTypeEnum | null
        hasInstantFeedback?: boolean | null
        hasLetterConfirmationMessage?: boolean | null
        hasPassFailConfirmationMessage?: boolean | null
        isShuffleFieldsEnabled?: boolean | null
        maxScore?: number | null
        passPercent?: number | null
        failConfirmation?: {
          __typename?: 'Wp_FormQuizConfirmation'
          isAutoformatted?: boolean | null
          message?: string | null
        } | null
        letterConfirmation?: {
          __typename?: 'Wp_FormQuizConfirmation'
          isAutoformatted?: boolean | null
          message?: string | null
        } | null
        passConfirmation?: {
          __typename?: 'Wp_FormQuizConfirmation'
          isAutoformatted?: boolean | null
          message?: string | null
        } | null
        grades?: Array<{
          __typename?: 'Wp_FormQuizGrades'
          text?: string | null
          value?: number | null
        } | null> | null
      } | null
      formFields?: {
        __typename?: 'Wp_GfFormToFormFieldConnection'
        nodes?: Array<
          | {
              __typename: 'Wp_AddressField'
              label?: string | null
              description?: string | null
              isRequired?: boolean | null
              addressType?: Wp_AddressFieldTypeEnum | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              copyValuesOptionLabel?: string | null
              copyValuesOptionFieldId?: number | null
              cssClass?: string | null
              defaultCountry?: Wp_AddressFieldCountryEnum | null
              defaultState?: string | null
              defaultProvince?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldCopyValuesOption?: boolean | null
              subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              inputs?: Array<{
                __typename?: 'Wp_AddressInputProperty'
                key?: string | null
                label?: string | null
                placeholder?: string | null
                defaultValue?: string | null
                customLabel?: string | null
                autocompleteAttribute?: string | null
                id?: number | null
                isHidden?: boolean | null
                name?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              addressTranslations?: Array<{
                __typename?: 'Wp_AddressTranlation'
                name?: string | null
                slug?: string | null
                label?: string | null
                description?: string | null
                address?: string | null
                address2?: string | null
                city?: string | null
                state?: string | null
                country?: string | null
                zip?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_CaptchaField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_CheckboxField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasChoiceValue?: boolean | null
              hasSelectAll?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_CheckboxFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
              inputs?: Array<{
                __typename?: 'Wp_CheckboxInputProperty'
                id?: number | null
                label?: string | null
                name?: string | null
              } | null> | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_ComboSignatureField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              backgroundColor?: string | null
              borderColor?: string | null
              borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
              borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
              boxWidth?: number | null
              penSize?: number | null
              penColor?: string | null
              minSignatures?: number | null
              maxSignatures?: number | null
              adminLabel?: string | null
              autocompleteAttribute?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              defaultValue?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              hasChoiceValue?: boolean | null
              hasEnhancedUI?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              placeholder?: string | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_ComboSignatureFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_ConsentField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_DateField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              placeholder?: string | null
              defaultValue?: string | null
              calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
              inputName?: string | null
              dateFormat?: Wp_DateFieldFormatEnum | null
              adminLabel?: string | null
              calendarIconUrl?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              inputs?: Array<{
                __typename?: 'Wp_DateInputProperty'
                autocompleteAttribute?: string | null
                customLabel?: string | null
                defaultValue?: string | null
                id?: number | null
                label?: string | null
                placeholder?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_EmailField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              placeholder?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              hasEmailConfirmation?: boolean | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              inputs?: Array<{
                __typename?: 'Wp_EmailInputProperty'
                autocompleteAttribute?: string | null
                customLabel?: string | null
                defaultValue?: string | null
                id?: number | null
                label?: string | null
                name?: string | null
                placeholder?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_FileUploadField'
              label?: string | null
              isRequired?: boolean | null
              maxFileSize?: number | null
              maxFiles?: number | null
              adminLabel?: string | null
              allowedExtensions?: Array<string | null> | null
              canAcceptMultipleFiles?: boolean | null
              cssClass?: string | null
              description?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_FollowUpField'
              label?: string | null
              content?: string | null
              cssClass?: string | null
              followUpFormId?: number | null
              hasMargins?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              followUpForm?: {
                __typename?: 'Wp_GfForm'
                slug?: string | null
                title?: string | null
              } | null
              correctiveActionSettings?: Array<{
                __typename?: 'Wp_CorrectiveActionSettings'
                actionTaken?: string | null
                deficiency?: string | null
                deficiencyType?: Array<string | null> | null
                severity?: string | null
              } | null> | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_HiddenField'
              defaultValue?: string | null
              label?: string | null
              canPrepopulate?: boolean | null
              inputName?: string | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_HtmlField'
              content?: string | null
              label?: string | null
              cssClass?: string | null
              hasMargins?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_ListField'
              description?: string | null
              isRequired?: boolean | null
              inputName?: string | null
              label?: string | null
              maxRows?: number | null
              addIconUrl?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              deleteIconUrl?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasColumns?: boolean | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_ListFieldChoice'
                text?: string | null
                value?: string | null
              } | null> | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_MultiSelectField'
              label?: string | null
              description?: string | null
              isRequired?: boolean | null
              allowCustomOption?: boolean | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasChoiceValue?: boolean | null
              hasEnhancedUI?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_MultiSelectFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_NameField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              inputs?: Array<{
                __typename?: 'Wp_NameInputProperty'
                key?: string | null
                label?: string | null
                placeholder?: string | null
                isHidden?: boolean | null
                defaultValue?: string | null
                customLabel?: string | null
                autocompleteAttribute?: string | null
                hasChoiceValue?: boolean | null
                id?: number | null
                name?: string | null
                choices?: Array<{
                  __typename?: 'Wp_NameFieldChoice'
                  text?: string | null
                  value?: string | null
                  isSelected?: boolean | null
                } | null> | null
              } | null> | null
              nameTranslations?: Array<{
                __typename?: 'Wp_NameTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                first?: string | null
                last?: string | null
                middle?: string | null
                prefix?: string | null
                suffix?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_NumberField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              rangeMin?: number | null
              rangeMax?: number | null
              numberFormat?: Wp_NumberFieldFormatEnum | null
              placeholder?: string | null
              defaultValue?: string | null
              calculationFormula?: string | null
              calculationRounding?: number | null
              errorMessage?: string | null
              adminLabel?: string | null
              autocompleteAttribute?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              hasAutocomplete?: boolean | null
              inputName?: string | null
              isCalculation?: boolean | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_PageField'
              cssClass?: string | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              previousButton?: {
                __typename?: 'Wp_FormButton'
                text?: string | null
                type?: Wp_FormButtonTypeEnum | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              } | null
              nextButton?: {
                __typename?: 'Wp_FormButton'
                text?: string | null
                type?: Wp_FormButtonTypeEnum | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              } | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_PasswordField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PhoneField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              placeholder?: string | null
              adminLabel?: string | null
              autocompleteAttribute?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              defaultValue?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              phoneFormat?: Wp_PhoneFieldFormatEnum | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_PhotoField'
              label?: string | null
              isRequired?: boolean | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              description?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_PostCategoryCheckboxField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCategoryMultiSelectField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCategoryRadioField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCategorySelectField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostContentField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomCheckboxField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomDateField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomEmailField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomFileuploadField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomHiddenField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomListField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomMultiSelectField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomNumberField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomPhoneField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomRadioField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomSelectField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomTextAreaField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomTextField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomTimeField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostCustomWebsiteField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostExcerptField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostImageField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostTagsCheckboxField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostTagsMultiSelectField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostTagsRadioField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostTagsSelectField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostTagsTextField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_PostTitleField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_QuizCheckboxField'
              hasSelectAll?: boolean | null
              adminLabel?: string | null
              answerExplanation?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              description?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasChoiceValue?: boolean | null
              hasWeightedScore?: boolean | null
              inputName?: string | null
              isRequired?: boolean | null
              label?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldRandomizeQuizChoices?: boolean | null
              shouldShowAnswerExplanation?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              inputs?: Array<{
                __typename?: 'Wp_QuizInputProperty'
                label?: string | null
                id?: number | null
                name?: string | null
              } | null> | null
              choices?: Array<{
                __typename?: 'Wp_QuizFieldChoice'
                isCorrect?: boolean | null
                isOtherChoice?: boolean | null
                isSelected?: boolean | null
                text?: string | null
                value?: string | null
                weight?: number | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
            }
          | {
              __typename: 'Wp_QuizRadioField'
              shouldAllowDuplicates?: boolean | null
              adminLabel?: string | null
              answerExplanation?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              description?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasChoiceValue?: boolean | null
              hasWeightedScore?: boolean | null
              inputName?: string | null
              isRequired?: boolean | null
              label?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldRandomizeQuizChoices?: boolean | null
              shouldShowAnswerExplanation?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_QuizFieldChoice'
                isCorrect?: boolean | null
                isOtherChoice?: boolean | null
                isSelected?: boolean | null
                text?: string | null
                value?: string | null
                weight?: number | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
            }
          | {
              __typename: 'Wp_QuizSelectField'
              defaultValue?: string | null
              placeholder?: string | null
              autocompleteAttribute?: string | null
              hasAutocomplete?: boolean | null
              hasEnhancedUI?: boolean | null
              adminLabel?: string | null
              answerExplanation?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              description?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasChoiceValue?: boolean | null
              hasWeightedScore?: boolean | null
              inputName?: string | null
              isRequired?: boolean | null
              label?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldRandomizeQuizChoices?: boolean | null
              shouldShowAnswerExplanation?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_QuizFieldChoice'
                isCorrect?: boolean | null
                isOtherChoice?: boolean | null
                isSelected?: boolean | null
                text?: string | null
                value?: string | null
                weight?: number | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
            }
          | {
              __typename: 'Wp_RadioField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasChoiceValue?: boolean | null
              hasOtherChoice?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_RadioFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
                isOtherChoice?: boolean | null
              } | null> | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_RememberMeField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_SectionField'
              label?: string | null
              description?: string | null
              cssClass?: string | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_SelectField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              defaultValue?: string | null
              allowCustomOption?: boolean | null
              adminLabel?: string | null
              autocompleteAttribute?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              hasChoiceValue?: boolean | null
              hasEnhancedUI?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              placeholder?: string | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              choices?: Array<{
                __typename?: 'Wp_SelectFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
              choiceTranslations?: Array<{
                __typename?: 'Wp_ChoiceTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
                choices?: Array<string | null> | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_SignatureField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              penColor?: string | null
              penSize?: number | null
              backgroundColor?: string | null
              borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
              borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
              borderColor?: string | null
              adminLabel?: string | null
              boxWidth?: number | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_SubmitField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_TextAreaField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              maxLength?: number | null
              placeholder?: string | null
              defaultValue?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasRichTextEditor?: boolean | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_TextField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              maxLength?: number | null
              placeholder?: string | null
              defaultValue?: string | null
              adminLabel?: string | null
              autocompleteAttribute?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              hasAutocomplete?: boolean | null
              hasInputMask?: boolean | null
              inputMaskValue?: string | null
              isPasswordInput?: boolean | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              size?: Wp_FormFieldSizeEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'Wp_TimeField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              timeFormat?: Wp_TimeFieldFormatEnum | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
              inputs?: Array<{
                __typename?: 'Wp_TimeInputProperty'
                autocompleteAttribute?: string | null
                customLabel?: string | null
                defaultValue?: string | null
                id?: number | null
                label?: string | null
                placeholder?: string | null
              } | null> | null
            }
          | {
              __typename: 'Wp_UsernameField'
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
            }
          | {
              __typename: 'Wp_WebsiteField'
              label?: string | null
              isRequired?: boolean | null
              description?: string | null
              placeholder?: string | null
              defaultValue?: string | null
              adminLabel?: string | null
              canPrepopulate?: boolean | null
              cssClass?: string | null
              descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
              errorMessage?: string | null
              inputName?: string | null
              labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
              shouldAllowDuplicates?: boolean | null
              id: number
              type?: Wp_FormFieldTypeEnum | null
              inputType?: Wp_FormFieldTypeEnum | null
              pageNumber?: number | null
              visibility?: Wp_FormFieldVisibilityEnum | null
              displayOnly?: boolean | null
              basicTranslations?: Array<{
                __typename?: 'Wp_BasicTranslation'
                slug?: string | null
                name?: string | null
                label?: string | null
                description?: string | null
              } | null> | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | null
        > | null
      } | null
      confirmations?: Array<{
        __typename?: 'Wp_FormConfirmation'
        id?: string | null
        isActive?: boolean | null
        isDefault?: boolean | null
        message?: string | null
        name?: string | null
        pageId?: number | null
        queryString?: string | null
        type?: Wp_FormConfirmationTypeEnum | null
        url?: string | null
        conditionalLogic?: {
          __typename?: 'Wp_ConditionalLogic'
          actionType?: Wp_ConditionalLogicActionTypeEnum | null
          logicType?: Wp_ConditionalLogicLogicTypeEnum | null
          rules?: Array<{
            __typename?: 'Wp_ConditionalLogicRule'
            fieldId?: number | null
            operator?: Wp_FormRuleOperatorEnum | null
            value?: string | null
          } | null> | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: 'Wp_FormPagination'
        pageNames?: Array<string | null> | null
        lastPageButton?: {
          __typename?: 'Wp_FormLastPageButton'
          text?: string | null
          type?: Wp_FormButtonTypeEnum | null
        } | null
      } | null
      submitButton?: {
        __typename?: 'Wp_FormSubmitButton'
        text?: string | null
        type?: Wp_FormButtonTypeEnum | null
        conditionalLogic?: {
          __typename?: 'Wp_ConditionalLogic'
          actionType?: Wp_ConditionalLogicActionTypeEnum | null
          logicType?: Wp_ConditionalLogicLogicTypeEnum | null
          rules?: Array<{
            __typename?: 'Wp_ConditionalLogicRule'
            fieldId?: number | null
            operator?: Wp_FormRuleOperatorEnum | null
            value?: string | null
          } | null> | null
        } | null
      } | null
      saveAndContinue?: {
        __typename?: 'Wp_FormSaveAndContinue'
        hasSaveAndContinue?: boolean | null
        buttonText?: string | null
      } | null
      translations?: Array<{
        __typename?: 'Wp_FormTranslation'
        slug?: string | null
        name?: string | null
        active?: boolean | null
        title?: string | null
        description?: string | null
        submitButtonText?: string | null
        nextButtonText?: string | null
        clearButtonText?: string | null
        previousButtonText?: string | null
        cardDisplay?: {
          __typename?: 'Wp_CardDisplayTranslation'
          detail1?: string | null
          detail2?: string | null
          detail3?: string | null
        } | null
      } | null> | null
    } | null
  } | null
  lessonDocumentConnectionConnection?: {
    __typename?: 'Wp_LessonToDocumentConnection'
    nodes?: Array<{
      __typename?: 'Wp_Document'
      databaseId: number
      title?: string | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'Wp_LessonToCompanyConnection'
    nodes?: Array<{
      __typename?: 'Wp_Company'
      name?: string | null
      slug?: string | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'Wp_LessonToDivisionConnection'
    nodes?: Array<{
      __typename?: 'Wp_Division'
      name?: string | null
      slug?: string | null
    } | null> | null
  } | null
  lessonCategories?: {
    __typename?: 'Wp_LessonToLessonCategoryConnection'
    nodes?: Array<{
      __typename?: 'Wp_LessonCategory'
      name?: string | null
      slug?: string | null
    } | null> | null
  } | null
  lessonDifficulties?: {
    __typename?: 'Wp_LessonToLessonDifficultyConnection'
    nodes?: Array<{
      __typename?: 'Wp_LessonDifficulty'
      name?: string | null
      slug?: string | null
    } | null> | null
  } | null
  trainingTypes?: {
    __typename?: 'Wp_LessonToTrainingTypeConnection'
    nodes?: Array<{
      __typename?: 'Wp_TrainingType'
      name?: string | null
      slug?: string | null
    } | null> | null
  } | null
  providers?: {
    __typename?: 'Wp_LessonToProviderConnection'
    nodes?: Array<{
      __typename?: 'Wp_Provider'
      name?: string | null
      slug?: string | null
    } | null> | null
  } | null
}

export type ConditionalLogicFragFragment = {
  __typename?: 'Wp_ConditionalLogic'
  actionType?: Wp_ConditionalLogicActionTypeEnum | null
  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
  rules?: Array<{
    __typename?: 'Wp_ConditionalLogicRule'
    fieldId?: number | null
    operator?: Wp_FormRuleOperatorEnum | null
    value?: string | null
  } | null> | null
}

export type CorrectiveActionSettingsFragFragment = {
  __typename?: 'Wp_CorrectiveActionSettings'
  actionTaken?: string | null
  deficiency?: string | null
  deficiencyType?: Array<string | null> | null
  severity?: string | null
}

export type FormButtonFragFragment = {
  __typename?: 'Wp_FormButton'
  text?: string | null
  type?: Wp_FormButtonTypeEnum | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type AddressFieldFragFragment = {
  __typename?: 'Wp_AddressField'
  label?: string | null
  description?: string | null
  isRequired?: boolean | null
  addressType?: Wp_AddressFieldTypeEnum | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  copyValuesOptionLabel?: string | null
  copyValuesOptionFieldId?: number | null
  cssClass?: string | null
  defaultCountry?: Wp_AddressFieldCountryEnum | null
  defaultState?: string | null
  defaultProvince?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldCopyValuesOption?: boolean | null
  subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
  inputs?: Array<{
    __typename?: 'Wp_AddressInputProperty'
    key?: string | null
    label?: string | null
    placeholder?: string | null
    defaultValue?: string | null
    customLabel?: string | null
    autocompleteAttribute?: string | null
    id?: number | null
    isHidden?: boolean | null
    name?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  addressTranslations?: Array<{
    __typename?: 'Wp_AddressTranlation'
    name?: string | null
    slug?: string | null
    label?: string | null
    description?: string | null
    address?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    zip?: string | null
  } | null> | null
}

export type CaptchaFieldFragFragment = {
  __typename?: 'Wp_CaptchaField'
  captchaBadgePosition?: Wp_CaptchaFieldBadgePositionEnum | null
  captchaLanguage?: string | null
  captchaTheme?: Wp_CaptchaFieldThemeEnum | null
  captchaType?: Wp_CaptchaFieldTypeEnum | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  label?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  simpleCaptchaBackgroundColor?: string | null
  simpleCaptchaFontColor?: string | null
  simpleCaptchaSize?: Wp_FormFieldSizeEnum | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type CheckboxFieldFragFragment = {
  __typename?: 'Wp_CheckboxField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasSelectAll?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  choices?: Array<{
    __typename?: 'Wp_CheckboxFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  inputs?: Array<{
    __typename?: 'Wp_CheckboxInputProperty'
    id?: number | null
    label?: string | null
    name?: string | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type ComboSignatureFieldFragFragment = {
  __typename?: 'Wp_ComboSignatureField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  backgroundColor?: string | null
  borderColor?: string | null
  borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
  borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
  boxWidth?: number | null
  penSize?: number | null
  penColor?: string | null
  minSignatures?: number | null
  maxSignatures?: number | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  defaultValue?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasChoiceValue?: boolean | null
  hasEnhancedUI?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  placeholder?: string | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  choices?: Array<{
    __typename?: 'Wp_ComboSignatureFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type ConsentFieldFragFragment = {
  __typename?: 'Wp_ConsentField'
  description?: string | null
  checkboxLabel?: string | null
  label?: string | null
  isRequired?: boolean | null
  adminLabel?: string | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  consentTranslations?: Array<{
    __typename?: 'Wp_ConsentTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    checkboxLabel?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type DateFieldFragFragment = {
  __typename?: 'Wp_DateField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  defaultValue?: string | null
  calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
  inputName?: string | null
  dateFormat?: Wp_DateFieldFormatEnum | null
  adminLabel?: string | null
  calendarIconUrl?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'Wp_DateInputProperty'
    autocompleteAttribute?: string | null
    customLabel?: string | null
    defaultValue?: string | null
    id?: number | null
    label?: string | null
    placeholder?: string | null
  } | null> | null
}

export type EmailFieldFragFragment = {
  __typename?: 'Wp_EmailField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasEmailConfirmation?: boolean | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'Wp_EmailInputProperty'
    autocompleteAttribute?: string | null
    customLabel?: string | null
    defaultValue?: string | null
    id?: number | null
    label?: string | null
    name?: string | null
    placeholder?: string | null
  } | null> | null
}

export type FileUploadFieldFragFragment = {
  __typename?: 'Wp_FileUploadField'
  label?: string | null
  isRequired?: boolean | null
  maxFileSize?: number | null
  maxFiles?: number | null
  adminLabel?: string | null
  allowedExtensions?: Array<string | null> | null
  canAcceptMultipleFiles?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FollowUpFieldFragFragment = {
  __typename?: 'Wp_FollowUpField'
  label?: string | null
  content?: string | null
  cssClass?: string | null
  followUpFormId?: number | null
  hasMargins?: boolean | null
  followUpForm?: {
    __typename?: 'Wp_GfForm'
    slug?: string | null
    title?: string | null
  } | null
  correctiveActionSettings?: Array<{
    __typename?: 'Wp_CorrectiveActionSettings'
    actionTaken?: string | null
    deficiency?: string | null
    deficiencyType?: Array<string | null> | null
    severity?: string | null
  } | null> | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

type FormFieldFrag_Wp_AddressField_Fragment = {
  __typename: 'Wp_AddressField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_CaptchaField_Fragment = {
  __typename: 'Wp_CaptchaField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_CheckboxField_Fragment = {
  __typename: 'Wp_CheckboxField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_ComboSignatureField_Fragment = {
  __typename: 'Wp_ComboSignatureField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_ConsentField_Fragment = {
  __typename: 'Wp_ConsentField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_DateField_Fragment = {
  __typename: 'Wp_DateField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_EmailField_Fragment = {
  __typename: 'Wp_EmailField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_FileUploadField_Fragment = {
  __typename: 'Wp_FileUploadField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_FollowUpField_Fragment = {
  __typename: 'Wp_FollowUpField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_HiddenField_Fragment = {
  __typename: 'Wp_HiddenField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_HtmlField_Fragment = {
  __typename: 'Wp_HtmlField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_ListField_Fragment = {
  __typename: 'Wp_ListField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_MultiSelectField_Fragment = {
  __typename: 'Wp_MultiSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_NameField_Fragment = {
  __typename: 'Wp_NameField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_NumberField_Fragment = {
  __typename: 'Wp_NumberField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PageField_Fragment = {
  __typename: 'Wp_PageField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PasswordField_Fragment = {
  __typename: 'Wp_PasswordField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PhoneField_Fragment = {
  __typename: 'Wp_PhoneField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PhotoField_Fragment = {
  __typename: 'Wp_PhotoField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCategoryCheckboxField_Fragment = {
  __typename: 'Wp_PostCategoryCheckboxField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCategoryMultiSelectField_Fragment = {
  __typename: 'Wp_PostCategoryMultiSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCategoryRadioField_Fragment = {
  __typename: 'Wp_PostCategoryRadioField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCategorySelectField_Fragment = {
  __typename: 'Wp_PostCategorySelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostContentField_Fragment = {
  __typename: 'Wp_PostContentField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomCheckboxField_Fragment = {
  __typename: 'Wp_PostCustomCheckboxField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomDateField_Fragment = {
  __typename: 'Wp_PostCustomDateField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomEmailField_Fragment = {
  __typename: 'Wp_PostCustomEmailField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomFileuploadField_Fragment = {
  __typename: 'Wp_PostCustomFileuploadField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomHiddenField_Fragment = {
  __typename: 'Wp_PostCustomHiddenField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomListField_Fragment = {
  __typename: 'Wp_PostCustomListField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomMultiSelectField_Fragment = {
  __typename: 'Wp_PostCustomMultiSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomNumberField_Fragment = {
  __typename: 'Wp_PostCustomNumberField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomPhoneField_Fragment = {
  __typename: 'Wp_PostCustomPhoneField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomRadioField_Fragment = {
  __typename: 'Wp_PostCustomRadioField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomSelectField_Fragment = {
  __typename: 'Wp_PostCustomSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomTextAreaField_Fragment = {
  __typename: 'Wp_PostCustomTextAreaField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomTextField_Fragment = {
  __typename: 'Wp_PostCustomTextField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomTimeField_Fragment = {
  __typename: 'Wp_PostCustomTimeField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostCustomWebsiteField_Fragment = {
  __typename: 'Wp_PostCustomWebsiteField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostExcerptField_Fragment = {
  __typename: 'Wp_PostExcerptField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostImageField_Fragment = {
  __typename: 'Wp_PostImageField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostTagsCheckboxField_Fragment = {
  __typename: 'Wp_PostTagsCheckboxField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostTagsMultiSelectField_Fragment = {
  __typename: 'Wp_PostTagsMultiSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostTagsRadioField_Fragment = {
  __typename: 'Wp_PostTagsRadioField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostTagsSelectField_Fragment = {
  __typename: 'Wp_PostTagsSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostTagsTextField_Fragment = {
  __typename: 'Wp_PostTagsTextField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_PostTitleField_Fragment = {
  __typename: 'Wp_PostTitleField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_QuizCheckboxField_Fragment = {
  __typename: 'Wp_QuizCheckboxField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_QuizRadioField_Fragment = {
  __typename: 'Wp_QuizRadioField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_QuizSelectField_Fragment = {
  __typename: 'Wp_QuizSelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_RadioField_Fragment = {
  __typename: 'Wp_RadioField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_RememberMeField_Fragment = {
  __typename: 'Wp_RememberMeField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_SectionField_Fragment = {
  __typename: 'Wp_SectionField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_SelectField_Fragment = {
  __typename: 'Wp_SelectField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_SignatureField_Fragment = {
  __typename: 'Wp_SignatureField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_SubmitField_Fragment = {
  __typename: 'Wp_SubmitField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_TextAreaField_Fragment = {
  __typename: 'Wp_TextAreaField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_TextField_Fragment = {
  __typename: 'Wp_TextField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_TimeField_Fragment = {
  __typename: 'Wp_TimeField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_UsernameField_Fragment = {
  __typename: 'Wp_UsernameField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_Wp_WebsiteField_Fragment = {
  __typename: 'Wp_WebsiteField'
  id: number
  type?: Wp_FormFieldTypeEnum | null
  inputType?: Wp_FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

export type FormFieldFragFragment =
  | FormFieldFrag_Wp_AddressField_Fragment
  | FormFieldFrag_Wp_CaptchaField_Fragment
  | FormFieldFrag_Wp_CheckboxField_Fragment
  | FormFieldFrag_Wp_ComboSignatureField_Fragment
  | FormFieldFrag_Wp_ConsentField_Fragment
  | FormFieldFrag_Wp_DateField_Fragment
  | FormFieldFrag_Wp_EmailField_Fragment
  | FormFieldFrag_Wp_FileUploadField_Fragment
  | FormFieldFrag_Wp_FollowUpField_Fragment
  | FormFieldFrag_Wp_HiddenField_Fragment
  | FormFieldFrag_Wp_HtmlField_Fragment
  | FormFieldFrag_Wp_ListField_Fragment
  | FormFieldFrag_Wp_MultiSelectField_Fragment
  | FormFieldFrag_Wp_NameField_Fragment
  | FormFieldFrag_Wp_NumberField_Fragment
  | FormFieldFrag_Wp_PageField_Fragment
  | FormFieldFrag_Wp_PasswordField_Fragment
  | FormFieldFrag_Wp_PhoneField_Fragment
  | FormFieldFrag_Wp_PhotoField_Fragment
  | FormFieldFrag_Wp_PostCategoryCheckboxField_Fragment
  | FormFieldFrag_Wp_PostCategoryMultiSelectField_Fragment
  | FormFieldFrag_Wp_PostCategoryRadioField_Fragment
  | FormFieldFrag_Wp_PostCategorySelectField_Fragment
  | FormFieldFrag_Wp_PostContentField_Fragment
  | FormFieldFrag_Wp_PostCustomCheckboxField_Fragment
  | FormFieldFrag_Wp_PostCustomDateField_Fragment
  | FormFieldFrag_Wp_PostCustomEmailField_Fragment
  | FormFieldFrag_Wp_PostCustomFileuploadField_Fragment
  | FormFieldFrag_Wp_PostCustomHiddenField_Fragment
  | FormFieldFrag_Wp_PostCustomListField_Fragment
  | FormFieldFrag_Wp_PostCustomMultiSelectField_Fragment
  | FormFieldFrag_Wp_PostCustomNumberField_Fragment
  | FormFieldFrag_Wp_PostCustomPhoneField_Fragment
  | FormFieldFrag_Wp_PostCustomRadioField_Fragment
  | FormFieldFrag_Wp_PostCustomSelectField_Fragment
  | FormFieldFrag_Wp_PostCustomTextAreaField_Fragment
  | FormFieldFrag_Wp_PostCustomTextField_Fragment
  | FormFieldFrag_Wp_PostCustomTimeField_Fragment
  | FormFieldFrag_Wp_PostCustomWebsiteField_Fragment
  | FormFieldFrag_Wp_PostExcerptField_Fragment
  | FormFieldFrag_Wp_PostImageField_Fragment
  | FormFieldFrag_Wp_PostTagsCheckboxField_Fragment
  | FormFieldFrag_Wp_PostTagsMultiSelectField_Fragment
  | FormFieldFrag_Wp_PostTagsRadioField_Fragment
  | FormFieldFrag_Wp_PostTagsSelectField_Fragment
  | FormFieldFrag_Wp_PostTagsTextField_Fragment
  | FormFieldFrag_Wp_PostTitleField_Fragment
  | FormFieldFrag_Wp_QuizCheckboxField_Fragment
  | FormFieldFrag_Wp_QuizRadioField_Fragment
  | FormFieldFrag_Wp_QuizSelectField_Fragment
  | FormFieldFrag_Wp_RadioField_Fragment
  | FormFieldFrag_Wp_RememberMeField_Fragment
  | FormFieldFrag_Wp_SectionField_Fragment
  | FormFieldFrag_Wp_SelectField_Fragment
  | FormFieldFrag_Wp_SignatureField_Fragment
  | FormFieldFrag_Wp_SubmitField_Fragment
  | FormFieldFrag_Wp_TextAreaField_Fragment
  | FormFieldFrag_Wp_TextField_Fragment
  | FormFieldFrag_Wp_TimeField_Fragment
  | FormFieldFrag_Wp_UsernameField_Fragment
  | FormFieldFrag_Wp_WebsiteField_Fragment

export type HiddenFieldFragFragment = {
  __typename?: 'Wp_HiddenField'
  defaultValue?: string | null
  label?: string | null
  canPrepopulate?: boolean | null
  inputName?: string | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
}

export type HtmlFieldFragFragment = {
  __typename?: 'Wp_HtmlField'
  content?: string | null
  label?: string | null
  cssClass?: string | null
  hasMargins?: boolean | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type ListFieldFragFragment = {
  __typename?: 'Wp_ListField'
  description?: string | null
  isRequired?: boolean | null
  inputName?: string | null
  label?: string | null
  maxRows?: number | null
  addIconUrl?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  deleteIconUrl?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasColumns?: boolean | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  choices?: Array<{
    __typename?: 'Wp_ListFieldChoice'
    text?: string | null
    value?: string | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type MultiSelectFieldFragFragment = {
  __typename?: 'Wp_MultiSelectField'
  label?: string | null
  description?: string | null
  isRequired?: boolean | null
  allowCustomOption?: boolean | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasEnhancedUI?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  size?: Wp_FormFieldSizeEnum | null
  choices?: Array<{
    __typename?: 'Wp_MultiSelectFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type NameFieldFragFragment = {
  __typename?: 'Wp_NameField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
  inputs?: Array<{
    __typename?: 'Wp_NameInputProperty'
    key?: string | null
    label?: string | null
    placeholder?: string | null
    isHidden?: boolean | null
    defaultValue?: string | null
    customLabel?: string | null
    autocompleteAttribute?: string | null
    hasChoiceValue?: boolean | null
    id?: number | null
    name?: string | null
    choices?: Array<{
      __typename?: 'Wp_NameFieldChoice'
      text?: string | null
      value?: string | null
      isSelected?: boolean | null
    } | null> | null
  } | null> | null
  nameTranslations?: Array<{
    __typename?: 'Wp_NameTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    first?: string | null
    last?: string | null
    middle?: string | null
    prefix?: string | null
    suffix?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type NumberFieldFragFragment = {
  __typename?: 'Wp_NumberField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  rangeMin?: number | null
  rangeMax?: number | null
  numberFormat?: Wp_NumberFieldFormatEnum | null
  placeholder?: string | null
  defaultValue?: string | null
  calculationFormula?: string | null
  calculationRounding?: number | null
  errorMessage?: string | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  isCalculation?: boolean | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type PageFieldFragFragment = {
  __typename?: 'Wp_PageField'
  cssClass?: string | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  previousButton?: {
    __typename?: 'Wp_FormButton'
    text?: string | null
    type?: Wp_FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  nextButton?: {
    __typename?: 'Wp_FormButton'
    text?: string | null
    type?: Wp_FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
}

export type PasswordFieldFragFragment = {
  __typename?: 'Wp_PasswordField'
  adminLabel?: string | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasPasswordStrengthIndicator?: boolean | null
  hasPasswordVisibilityToggle?: boolean | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  minPasswordStrength?: Wp_PasswordFieldMinStrengthEnum | null
  size?: Wp_FormFieldSizeEnum | null
  subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'Wp_PasswordInputProperty'
    customLabel?: string | null
    id?: number | null
    isHidden?: boolean | null
    label?: string | null
    placeholder?: string | null
  } | null> | null
}

export type PhoneFieldFragFragment = {
  __typename?: 'Wp_PhoneField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  defaultValue?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  phoneFormat?: Wp_PhoneFieldFormatEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type PhotoFieldFragFragment = {
  __typename?: 'Wp_PhotoField'
  label?: string | null
  isRequired?: boolean | null
  visibility?: Wp_FormFieldVisibilityEnum | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

type QuizFieldFrag_Wp_QuizCheckboxField_Fragment = {
  __typename?: 'Wp_QuizCheckboxField'
  hasSelectAll?: boolean | null
  adminLabel?: string | null
  answerExplanation?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasWeightedScore?: boolean | null
  inputName?: string | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldRandomizeQuizChoices?: boolean | null
  shouldShowAnswerExplanation?: boolean | null
  inputs?: Array<{
    __typename?: 'Wp_QuizInputProperty'
    label?: string | null
    id?: number | null
    name?: string | null
  } | null> | null
  choices?: Array<{
    __typename?: 'Wp_QuizFieldChoice'
    isCorrect?: boolean | null
    isOtherChoice?: boolean | null
    isSelected?: boolean | null
    text?: string | null
    value?: string | null
    weight?: number | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
}

type QuizFieldFrag_Wp_QuizRadioField_Fragment = {
  __typename?: 'Wp_QuizRadioField'
  shouldAllowDuplicates?: boolean | null
  adminLabel?: string | null
  answerExplanation?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasWeightedScore?: boolean | null
  inputName?: string | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldRandomizeQuizChoices?: boolean | null
  shouldShowAnswerExplanation?: boolean | null
  choices?: Array<{
    __typename?: 'Wp_QuizFieldChoice'
    isCorrect?: boolean | null
    isOtherChoice?: boolean | null
    isSelected?: boolean | null
    text?: string | null
    value?: string | null
    weight?: number | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
}

type QuizFieldFrag_Wp_QuizSelectField_Fragment = {
  __typename?: 'Wp_QuizSelectField'
  defaultValue?: string | null
  placeholder?: string | null
  autocompleteAttribute?: string | null
  hasAutocomplete?: boolean | null
  hasEnhancedUI?: boolean | null
  adminLabel?: string | null
  answerExplanation?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasWeightedScore?: boolean | null
  inputName?: string | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldRandomizeQuizChoices?: boolean | null
  shouldShowAnswerExplanation?: boolean | null
  choices?: Array<{
    __typename?: 'Wp_QuizFieldChoice'
    isCorrect?: boolean | null
    isOtherChoice?: boolean | null
    isSelected?: boolean | null
    text?: string | null
    value?: string | null
    weight?: number | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
}

export type QuizFieldFragFragment =
  | QuizFieldFrag_Wp_QuizCheckboxField_Fragment
  | QuizFieldFrag_Wp_QuizRadioField_Fragment
  | QuizFieldFrag_Wp_QuizSelectField_Fragment

export type RadioFieldFragFragment = {
  __typename?: 'Wp_RadioField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasOtherChoice?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  choices?: Array<{
    __typename?: 'Wp_RadioFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
    isOtherChoice?: boolean | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type SectionFieldFragFragment = {
  __typename?: 'Wp_SectionField'
  label?: string | null
  description?: string | null
  cssClass?: string | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
}

export type SelectFieldFragFragment = {
  __typename?: 'Wp_SelectField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  defaultValue?: string | null
  allowCustomOption?: boolean | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasChoiceValue?: boolean | null
  hasEnhancedUI?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  placeholder?: string | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  choices?: Array<{
    __typename?: 'Wp_SelectFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'Wp_ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type SignatureFieldFragFragment = {
  __typename?: 'Wp_SignatureField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  penColor?: string | null
  penSize?: number | null
  backgroundColor?: string | null
  borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
  borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
  borderColor?: string | null
  adminLabel?: string | null
  boxWidth?: number | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type TextAreaFieldFragFragment = {
  __typename?: 'Wp_TextAreaField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  maxLength?: number | null
  placeholder?: string | null
  defaultValue?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasRichTextEditor?: boolean | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type TextFieldFragFragment = {
  __typename?: 'Wp_TextField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  maxLength?: number | null
  placeholder?: string | null
  defaultValue?: string | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasInputMask?: boolean | null
  inputMaskValue?: string | null
  isPasswordInput?: boolean | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: Wp_FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type TimeFieldFragFragment = {
  __typename?: 'Wp_TimeField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  timeFormat?: Wp_TimeFieldFormatEnum | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'Wp_TimeInputProperty'
    autocompleteAttribute?: string | null
    customLabel?: string | null
    defaultValue?: string | null
    id?: number | null
    label?: string | null
    placeholder?: string | null
  } | null> | null
}

export type WebsiteFieldFragFragment = {
  __typename?: 'Wp_WebsiteField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  defaultValue?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  inputName?: string | null
  labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  basicTranslations?: Array<{
    __typename?: 'Wp_BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FormFragFragment = {
  __typename?: 'Wp_GfForm'
  cssClass?: string | null
  description?: string | null
  disableEditing?: boolean | null
  hideJumpToSection?: boolean | null
  id: string
  reloadOnSubmit?: boolean | null
  restrictEntriesToUserCreated?: Array<string | null> | null
  restrictFormSubmissionByRole?: Array<string | null> | null
  slug?: string | null
  title?: string | null
  postTypeToCreate?: string | null
  isCAForm?: boolean | null
  isPrimary?: boolean | null
  isQuizForm?: boolean | null
  group?: string | null
  databaseId: number
  isActive?: boolean | null
  version?: string | null
  formFields?: {
    __typename?: 'Wp_GfFormToFormFieldConnection'
    nodes?: Array<
      | {
          __typename: 'Wp_AddressField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          addressType?: Wp_AddressFieldTypeEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          copyValuesOptionLabel?: string | null
          copyValuesOptionFieldId?: number | null
          cssClass?: string | null
          defaultCountry?: Wp_AddressFieldCountryEnum | null
          defaultState?: string | null
          defaultProvince?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldCopyValuesOption?: boolean | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'Wp_AddressInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            id?: number | null
            isHidden?: boolean | null
            name?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          addressTranslations?: Array<{
            __typename?: 'Wp_AddressTranlation'
            name?: string | null
            slug?: string | null
            label?: string | null
            description?: string | null
            address?: string | null
            address2?: string | null
            city?: string | null
            state?: string | null
            country?: string | null
            zip?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_CaptchaField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_CheckboxField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasSelectAll?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_CheckboxFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          inputs?: Array<{
            __typename?: 'Wp_CheckboxInputProperty'
            id?: number | null
            label?: string | null
            name?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_ComboSignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          backgroundColor?: string | null
          borderColor?: string | null
          borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
          borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
          boxWidth?: number | null
          penSize?: number | null
          penColor?: string | null
          minSignatures?: number | null
          maxSignatures?: number | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_ComboSignatureFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_ConsentField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_DateField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
          inputName?: string | null
          dateFormat?: Wp_DateFieldFormatEnum | null
          adminLabel?: string | null
          calendarIconUrl?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'Wp_DateInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_EmailField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasEmailConfirmation?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'Wp_EmailInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            name?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_FileUploadField'
          label?: string | null
          isRequired?: boolean | null
          maxFileSize?: number | null
          maxFiles?: number | null
          adminLabel?: string | null
          allowedExtensions?: Array<string | null> | null
          canAcceptMultipleFiles?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_FollowUpField'
          label?: string | null
          content?: string | null
          cssClass?: string | null
          followUpFormId?: number | null
          hasMargins?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          followUpForm?: {
            __typename?: 'Wp_GfForm'
            slug?: string | null
            title?: string | null
          } | null
          correctiveActionSettings?: Array<{
            __typename?: 'Wp_CorrectiveActionSettings'
            actionTaken?: string | null
            deficiency?: string | null
            deficiencyType?: Array<string | null> | null
            severity?: string | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_HiddenField'
          defaultValue?: string | null
          label?: string | null
          canPrepopulate?: boolean | null
          inputName?: string | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_HtmlField'
          content?: string | null
          label?: string | null
          cssClass?: string | null
          hasMargins?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_ListField'
          description?: string | null
          isRequired?: boolean | null
          inputName?: string | null
          label?: string | null
          maxRows?: number | null
          addIconUrl?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          deleteIconUrl?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasColumns?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_ListFieldChoice'
            text?: string | null
            value?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_MultiSelectField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_MultiSelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_NameField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'Wp_NameInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            isHidden?: boolean | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            hasChoiceValue?: boolean | null
            id?: number | null
            name?: string | null
            choices?: Array<{
              __typename?: 'Wp_NameFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
          } | null> | null
          nameTranslations?: Array<{
            __typename?: 'Wp_NameTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            first?: string | null
            last?: string | null
            middle?: string | null
            prefix?: string | null
            suffix?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_NumberField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          rangeMin?: number | null
          rangeMax?: number | null
          numberFormat?: Wp_NumberFieldFormatEnum | null
          placeholder?: string | null
          defaultValue?: string | null
          calculationFormula?: string | null
          calculationRounding?: number | null
          errorMessage?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          isCalculation?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_PageField'
          cssClass?: string | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          previousButton?: {
            __typename?: 'Wp_FormButton'
            text?: string | null
            type?: Wp_FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          nextButton?: {
            __typename?: 'Wp_FormButton'
            text?: string | null
            type?: Wp_FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_PasswordField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PhoneField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          phoneFormat?: Wp_PhoneFieldFormatEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_PhotoField'
          label?: string | null
          isRequired?: boolean | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_PostCategoryCheckboxField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCategoryMultiSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCategoryRadioField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCategorySelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostContentField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomCheckboxField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomDateField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomEmailField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomFileuploadField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomHiddenField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomListField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomMultiSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomNumberField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomPhoneField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomRadioField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomTextAreaField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomTextField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomTimeField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomWebsiteField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostExcerptField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostImageField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsCheckboxField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsMultiSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsRadioField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsTextField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTitleField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_QuizCheckboxField'
          hasSelectAll?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'Wp_QuizInputProperty'
            label?: string | null
            id?: number | null
            name?: string | null
          } | null> | null
          choices?: Array<{
            __typename?: 'Wp_QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'Wp_QuizRadioField'
          shouldAllowDuplicates?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'Wp_QuizSelectField'
          defaultValue?: string | null
          placeholder?: string | null
          autocompleteAttribute?: string | null
          hasAutocomplete?: boolean | null
          hasEnhancedUI?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'Wp_RadioField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasOtherChoice?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_RadioFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
            isOtherChoice?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_RememberMeField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_SectionField'
          label?: string | null
          description?: string | null
          cssClass?: string | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_SelectField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          defaultValue?: string | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_SelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_SignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          penColor?: string | null
          penSize?: number | null
          backgroundColor?: string | null
          borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
          borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
          borderColor?: string | null
          adminLabel?: string | null
          boxWidth?: number | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_SubmitField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_TextAreaField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasRichTextEditor?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_TextField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasInputMask?: boolean | null
          inputMaskValue?: string | null
          isPasswordInput?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_TimeField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          timeFormat?: Wp_TimeFieldFormatEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'Wp_TimeInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_UsernameField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_WebsiteField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | null
    > | null
  } | null
  confirmations?: Array<{
    __typename?: 'Wp_FormConfirmation'
    id?: string | null
    isActive?: boolean | null
    isDefault?: boolean | null
    message?: string | null
    name?: string | null
    pageId?: number | null
    queryString?: string | null
    type?: Wp_FormConfirmationTypeEnum | null
    url?: string | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null> | null
  pagination?: {
    __typename?: 'Wp_FormPagination'
    pageNames?: Array<string | null> | null
    lastPageButton?: {
      __typename?: 'Wp_FormLastPageButton'
      text?: string | null
      type?: Wp_FormButtonTypeEnum | null
    } | null
  } | null
  submitButton?: {
    __typename?: 'Wp_FormSubmitButton'
    text?: string | null
    type?: Wp_FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  saveAndContinue?: {
    __typename?: 'Wp_FormSaveAndContinue'
    hasSaveAndContinue?: boolean | null
    buttonText?: string | null
  } | null
  translations?: Array<{
    __typename?: 'Wp_FormTranslation'
    slug?: string | null
    name?: string | null
    active?: boolean | null
    title?: string | null
    description?: string | null
    submitButtonText?: string | null
    nextButtonText?: string | null
    clearButtonText?: string | null
    previousButtonText?: string | null
    cardDisplay?: {
      __typename?: 'Wp_CardDisplayTranslation'
      detail1?: string | null
      detail2?: string | null
      detail3?: string | null
    } | null
  } | null> | null
}

export type FormBasicFragFragment = {
  __typename?: 'Wp_GfForm'
  cssClass?: string | null
  description?: string | null
  disableEditing?: boolean | null
  hideJumpToSection?: boolean | null
  id: string
  reloadOnSubmit?: boolean | null
  restrictEntriesToUserCreated?: Array<string | null> | null
  restrictFormSubmissionByRole?: Array<string | null> | null
  slug?: string | null
  title?: string | null
  postTypeToCreate?: string | null
  isCAForm?: boolean | null
  isPrimary?: boolean | null
  isQuizForm?: boolean | null
  group?: string | null
  databaseId: number
  isActive?: boolean | null
  version?: string | null
  confirmations?: Array<{
    __typename?: 'Wp_FormConfirmation'
    id?: string | null
    isActive?: boolean | null
    isDefault?: boolean | null
    message?: string | null
    name?: string | null
    pageId?: number | null
    queryString?: string | null
    type?: Wp_FormConfirmationTypeEnum | null
    url?: string | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null> | null
  pagination?: {
    __typename?: 'Wp_FormPagination'
    pageNames?: Array<string | null> | null
    lastPageButton?: {
      __typename?: 'Wp_FormLastPageButton'
      text?: string | null
      type?: Wp_FormButtonTypeEnum | null
    } | null
  } | null
  submitButton?: {
    __typename?: 'Wp_FormSubmitButton'
    text?: string | null
    type?: Wp_FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  saveAndContinue?: {
    __typename?: 'Wp_FormSaveAndContinue'
    hasSaveAndContinue?: boolean | null
    buttonText?: string | null
  } | null
  translations?: Array<{
    __typename?: 'Wp_FormTranslation'
    slug?: string | null
    name?: string | null
    active?: boolean | null
    title?: string | null
    description?: string | null
    submitButtonText?: string | null
    nextButtonText?: string | null
    clearButtonText?: string | null
    previousButtonText?: string | null
    cardDisplay?: {
      __typename?: 'Wp_CardDisplayTranslation'
      detail1?: string | null
      detail2?: string | null
      detail3?: string | null
    } | null
  } | null> | null
}

export type FormConfirmationFragFragment = {
  __typename?: 'Wp_FormConfirmation'
  id?: string | null
  isActive?: boolean | null
  isDefault?: boolean | null
  message?: string | null
  name?: string | null
  pageId?: number | null
  queryString?: string | null
  type?: Wp_FormConfirmationTypeEnum | null
  url?: string | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FormEntryLimitFragFragment = {
  __typename?: 'Wp_FormEntryLimits'
  hasLimit?: boolean | null
  limitReachedMessage?: string | null
  limitationPeriod?: Wp_FormLimitEntriesPeriodEnum | null
  maxEntries?: number | null
}

export type FormForCoreNavListFragFragment = {
  __typename?: 'Wp_GfForm'
  title?: string | null
  slug?: string | null
  databaseId: number
  postTypeToCreate?: string | null
  isPrimary?: boolean | null
  hideInNav?: boolean | null
  isCAForm?: boolean | null
  isQuizForm?: boolean | null
  group?: string | null
  translations?: Array<{
    __typename?: 'Wp_FormTranslation'
    name?: string | null
    slug?: string | null
    active?: boolean | null
    title?: string | null
  } | null> | null
}

export type FormLastPageButtonFragFragment = {
  __typename?: 'Wp_FormLastPageButton'
  text?: string | null
  type?: Wp_FormButtonTypeEnum | null
}

export type FormPaginationFragFragment = {
  __typename?: 'Wp_FormPagination'
  pageNames?: Array<string | null> | null
  lastPageButton?: {
    __typename?: 'Wp_FormLastPageButton'
    text?: string | null
    type?: Wp_FormButtonTypeEnum | null
  } | null
}

export type FormQuizFragFragment = {
  __typename?: 'Wp_FormQuiz'
  gradingType?: Wp_QuizFieldGradingTypeEnum | null
  hasInstantFeedback?: boolean | null
  hasLetterConfirmationMessage?: boolean | null
  hasPassFailConfirmationMessage?: boolean | null
  isShuffleFieldsEnabled?: boolean | null
  maxScore?: number | null
  passPercent?: number | null
  failConfirmation?: {
    __typename?: 'Wp_FormQuizConfirmation'
    isAutoformatted?: boolean | null
    message?: string | null
  } | null
  letterConfirmation?: {
    __typename?: 'Wp_FormQuizConfirmation'
    isAutoformatted?: boolean | null
    message?: string | null
  } | null
  passConfirmation?: {
    __typename?: 'Wp_FormQuizConfirmation'
    isAutoformatted?: boolean | null
    message?: string | null
  } | null
  grades?: Array<{
    __typename?: 'Wp_FormQuizGrades'
    text?: string | null
    value?: number | null
  } | null> | null
}

export type FormQuizConfirmationFragFragment = {
  __typename?: 'Wp_FormQuizConfirmation'
  isAutoformatted?: boolean | null
  message?: string | null
}

export type FormSaveAndContinueFragFragment = {
  __typename?: 'Wp_FormSaveAndContinue'
  hasSaveAndContinue?: boolean | null
  buttonText?: string | null
}

export type FormSubmitButtonFragFragment = {
  __typename?: 'Wp_FormSubmitButton'
  text?: string | null
  type?: Wp_FormButtonTypeEnum | null
  conditionalLogic?: {
    __typename?: 'Wp_ConditionalLogic'
    actionType?: Wp_ConditionalLogicActionTypeEnum | null
    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'Wp_ConditionalLogicRule'
      fieldId?: number | null
      operator?: Wp_FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FormTranslationFragFragment = {
  __typename?: 'Wp_FormTranslation'
  slug?: string | null
  name?: string | null
  active?: boolean | null
  title?: string | null
  description?: string | null
  submitButtonText?: string | null
  nextButtonText?: string | null
  clearButtonText?: string | null
  previousButtonText?: string | null
  cardDisplay?: {
    __typename?: 'Wp_CardDisplayTranslation'
    detail1?: string | null
    detail2?: string | null
    detail3?: string | null
  } | null
}

export type FormWithQuizFragFragment = {
  __typename?: 'Wp_GfForm'
  isQuizForm?: boolean | null
  cssClass?: string | null
  description?: string | null
  disableEditing?: boolean | null
  hideJumpToSection?: boolean | null
  id: string
  reloadOnSubmit?: boolean | null
  restrictEntriesToUserCreated?: Array<string | null> | null
  restrictFormSubmissionByRole?: Array<string | null> | null
  slug?: string | null
  title?: string | null
  postTypeToCreate?: string | null
  isCAForm?: boolean | null
  isPrimary?: boolean | null
  group?: string | null
  databaseId: number
  isActive?: boolean | null
  version?: string | null
  quiz?: {
    __typename?: 'Wp_FormQuiz'
    gradingType?: Wp_QuizFieldGradingTypeEnum | null
    hasInstantFeedback?: boolean | null
    hasLetterConfirmationMessage?: boolean | null
    hasPassFailConfirmationMessage?: boolean | null
    isShuffleFieldsEnabled?: boolean | null
    maxScore?: number | null
    passPercent?: number | null
    failConfirmation?: {
      __typename?: 'Wp_FormQuizConfirmation'
      isAutoformatted?: boolean | null
      message?: string | null
    } | null
    letterConfirmation?: {
      __typename?: 'Wp_FormQuizConfirmation'
      isAutoformatted?: boolean | null
      message?: string | null
    } | null
    passConfirmation?: {
      __typename?: 'Wp_FormQuizConfirmation'
      isAutoformatted?: boolean | null
      message?: string | null
    } | null
    grades?: Array<{
      __typename?: 'Wp_FormQuizGrades'
      text?: string | null
      value?: number | null
    } | null> | null
  } | null
  formFields?: {
    __typename?: 'Wp_GfFormToFormFieldConnection'
    nodes?: Array<
      | {
          __typename: 'Wp_AddressField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          addressType?: Wp_AddressFieldTypeEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          copyValuesOptionLabel?: string | null
          copyValuesOptionFieldId?: number | null
          cssClass?: string | null
          defaultCountry?: Wp_AddressFieldCountryEnum | null
          defaultState?: string | null
          defaultProvince?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldCopyValuesOption?: boolean | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'Wp_AddressInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            id?: number | null
            isHidden?: boolean | null
            name?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          addressTranslations?: Array<{
            __typename?: 'Wp_AddressTranlation'
            name?: string | null
            slug?: string | null
            label?: string | null
            description?: string | null
            address?: string | null
            address2?: string | null
            city?: string | null
            state?: string | null
            country?: string | null
            zip?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_CaptchaField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_CheckboxField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasSelectAll?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_CheckboxFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          inputs?: Array<{
            __typename?: 'Wp_CheckboxInputProperty'
            id?: number | null
            label?: string | null
            name?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_ComboSignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          backgroundColor?: string | null
          borderColor?: string | null
          borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
          borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
          boxWidth?: number | null
          penSize?: number | null
          penColor?: string | null
          minSignatures?: number | null
          maxSignatures?: number | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_ComboSignatureFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_ConsentField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_DateField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
          inputName?: string | null
          dateFormat?: Wp_DateFieldFormatEnum | null
          adminLabel?: string | null
          calendarIconUrl?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'Wp_DateInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_EmailField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasEmailConfirmation?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'Wp_EmailInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            name?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_FileUploadField'
          label?: string | null
          isRequired?: boolean | null
          maxFileSize?: number | null
          maxFiles?: number | null
          adminLabel?: string | null
          allowedExtensions?: Array<string | null> | null
          canAcceptMultipleFiles?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_FollowUpField'
          label?: string | null
          content?: string | null
          cssClass?: string | null
          followUpFormId?: number | null
          hasMargins?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          followUpForm?: {
            __typename?: 'Wp_GfForm'
            slug?: string | null
            title?: string | null
          } | null
          correctiveActionSettings?: Array<{
            __typename?: 'Wp_CorrectiveActionSettings'
            actionTaken?: string | null
            deficiency?: string | null
            deficiencyType?: Array<string | null> | null
            severity?: string | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_HiddenField'
          defaultValue?: string | null
          label?: string | null
          canPrepopulate?: boolean | null
          inputName?: string | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_HtmlField'
          content?: string | null
          label?: string | null
          cssClass?: string | null
          hasMargins?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_ListField'
          description?: string | null
          isRequired?: boolean | null
          inputName?: string | null
          label?: string | null
          maxRows?: number | null
          addIconUrl?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          deleteIconUrl?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasColumns?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_ListFieldChoice'
            text?: string | null
            value?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_MultiSelectField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_MultiSelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_NameField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'Wp_NameInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            isHidden?: boolean | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            hasChoiceValue?: boolean | null
            id?: number | null
            name?: string | null
            choices?: Array<{
              __typename?: 'Wp_NameFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
          } | null> | null
          nameTranslations?: Array<{
            __typename?: 'Wp_NameTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            first?: string | null
            last?: string | null
            middle?: string | null
            prefix?: string | null
            suffix?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_NumberField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          rangeMin?: number | null
          rangeMax?: number | null
          numberFormat?: Wp_NumberFieldFormatEnum | null
          placeholder?: string | null
          defaultValue?: string | null
          calculationFormula?: string | null
          calculationRounding?: number | null
          errorMessage?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          isCalculation?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_PageField'
          cssClass?: string | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          previousButton?: {
            __typename?: 'Wp_FormButton'
            text?: string | null
            type?: Wp_FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          nextButton?: {
            __typename?: 'Wp_FormButton'
            text?: string | null
            type?: Wp_FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_PasswordField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PhoneField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          phoneFormat?: Wp_PhoneFieldFormatEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_PhotoField'
          label?: string | null
          isRequired?: boolean | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_PostCategoryCheckboxField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCategoryMultiSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCategoryRadioField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCategorySelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostContentField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomCheckboxField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomDateField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomEmailField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomFileuploadField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomHiddenField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomListField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomMultiSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomNumberField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomPhoneField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomRadioField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomTextAreaField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomTextField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomTimeField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostCustomWebsiteField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostExcerptField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostImageField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsCheckboxField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsMultiSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsRadioField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsSelectField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTagsTextField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_PostTitleField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_QuizCheckboxField'
          hasSelectAll?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'Wp_QuizInputProperty'
            label?: string | null
            id?: number | null
            name?: string | null
          } | null> | null
          choices?: Array<{
            __typename?: 'Wp_QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'Wp_QuizRadioField'
          shouldAllowDuplicates?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'Wp_QuizSelectField'
          defaultValue?: string | null
          placeholder?: string | null
          autocompleteAttribute?: string | null
          hasAutocomplete?: boolean | null
          hasEnhancedUI?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'Wp_RadioField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasOtherChoice?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_RadioFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
            isOtherChoice?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_RememberMeField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_SectionField'
          label?: string | null
          description?: string | null
          cssClass?: string | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_SelectField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          defaultValue?: string | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'Wp_SelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'Wp_ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_SignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          penColor?: string | null
          penSize?: number | null
          backgroundColor?: string | null
          borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
          borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
          borderColor?: string | null
          adminLabel?: string | null
          boxWidth?: number | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_SubmitField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_TextAreaField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasRichTextEditor?: boolean | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_TextField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasInputMask?: boolean | null
          inputMaskValue?: string | null
          isPasswordInput?: boolean | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: Wp_FormFieldSizeEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'Wp_TimeField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          timeFormat?: Wp_TimeFieldFormatEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'Wp_TimeInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'Wp_UsernameField'
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'Wp_WebsiteField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: Wp_FormFieldTypeEnum | null
          inputType?: Wp_FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: Wp_FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'Wp_BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | null
    > | null
  } | null
  confirmations?: Array<{
    __typename?: 'Wp_FormConfirmation'
    id?: string | null
    isActive?: boolean | null
    isDefault?: boolean | null
    message?: string | null
    name?: string | null
    pageId?: number | null
    queryString?: string | null
    type?: Wp_FormConfirmationTypeEnum | null
    url?: string | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null> | null
  pagination?: {
    __typename?: 'Wp_FormPagination'
    pageNames?: Array<string | null> | null
    lastPageButton?: {
      __typename?: 'Wp_FormLastPageButton'
      text?: string | null
      type?: Wp_FormButtonTypeEnum | null
    } | null
  } | null
  submitButton?: {
    __typename?: 'Wp_FormSubmitButton'
    text?: string | null
    type?: Wp_FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'Wp_ConditionalLogic'
      actionType?: Wp_ConditionalLogicActionTypeEnum | null
      logicType?: Wp_ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'Wp_ConditionalLogicRule'
        fieldId?: number | null
        operator?: Wp_FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  saveAndContinue?: {
    __typename?: 'Wp_FormSaveAndContinue'
    hasSaveAndContinue?: boolean | null
    buttonText?: string | null
  } | null
  translations?: Array<{
    __typename?: 'Wp_FormTranslation'
    slug?: string | null
    name?: string | null
    active?: boolean | null
    title?: string | null
    description?: string | null
    submitButtonText?: string | null
    nextButtonText?: string | null
    clearButtonText?: string | null
    previousButtonText?: string | null
    cardDisplay?: {
      __typename?: 'Wp_CardDisplayTranslation'
      detail1?: string | null
      detail2?: string | null
      detail3?: string | null
    } | null
  } | null> | null
}

export type AllLanguagesQueryVariables = Exact<{ [key: string]: never }>

export type AllLanguagesQuery = {
  __typename?: 'Query'
  wp?: {
    __typename?: 'Wp_'
    languages?: Array<{
      __typename?: 'Wp_Language'
      slug?: string | null
      name?: string | null
      id: string
      locale?: string | null
    } | null> | null
  } | null
}

export type AllUserLanguagesQueryVariables = Exact<{ [key: string]: never }>

export type AllUserLanguagesQuery = {
  __typename?: 'Query'
  allWpUserLanguage: {
    __typename?: 'Wp_UserLanguageConnection'
    edges: Array<{
      __typename?: 'Wp_UserLanguageEdge'
      node: {
        __typename?: 'Wp_UserLanguage'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type AllCompaniesQuery = {
  __typename?: 'Query'
  allWpCompany: {
    __typename?: 'Wp_CompanyConnection'
    edges: Array<{
      __typename?: 'Wp_CompanyEdge'
      node: {
        __typename?: 'Wp_Company'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
        termTaxonomyId?: number | null
      }
    }>
  }
}

export type AllDivisionsQueryVariables = Exact<{ [key: string]: never }>

export type AllDivisionsQuery = {
  __typename?: 'Query'
  allWpDivision: {
    __typename?: 'Wp_DivisionConnection'
    edges: Array<{
      __typename?: 'Wp_DivisionEdge'
      node: {
        __typename?: 'Wp_Division'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type HarnessSettingsQueryVariables = Exact<{ [key: string]: never }>

export type HarnessSettingsQuery = {
  __typename?: 'Query'
  wp?: {
    __typename?: 'Wp_'
    harnessSettings?: {
      __typename?: 'Wp_HarnessSettings'
      sdsManufacturerHideList?: Array<string | null> | null
      moduleHideList?: Array<string | null> | null
      hideCompany?: boolean | null
      hideDivision?: boolean | null
      employeeIdRequired?: boolean | null
      projectNumberRequired?: boolean | null
      etsSupport?: boolean | null
      formGroups?: Array<{
        __typename?: 'Wp_FormGroup'
        header?: string | null
        order?: number | null
        translations?: Array<{
          __typename?: 'Wp_FormGroupTranslation'
          slug?: string | null
          name?: string | null
          code?: Wp_LanguageCodeEnum | null
          id?: string | null
          header?: string | null
        } | null> | null
      } | null> | null
    } | null
  } | null
}

export type IntegrationConfigQueryVariables = Exact<{ [key: string]: never }>

export type IntegrationConfigQuery = {
  __typename?: 'Query'
  wp?: {
    __typename?: 'Wp_'
    integrationConfig?: {
      __typename?: 'Wp_IntegrationConfig'
      source?: Wp_IntegrationSourceEnum | null
      activeIntegrations?: Array<{
        __typename?: 'Wp_ActiveIntegration'
        sourcedFields?: Array<string | null> | null
        destinationType?: Wp_IntegrationWpDataTypeEnum | null
        sourceType?: Wp_IntegrationSourceDataTypeEnum | null
      } | null> | null
    } | null
  } | null
}

export type AllCoreGfFormsQueryVariables = Exact<{ [key: string]: never }>

export type AllCoreGfFormsQuery = {
  __typename?: 'Query'
  allWpGfForm: {
    __typename?: 'Wp_GfFormConnection'
    nodes: Array<{
      __typename?: 'Wp_GfForm'
      title?: string | null
      slug?: string | null
      databaseId: number
      postTypeToCreate?: string | null
      isPrimary?: boolean | null
      hideInNav?: boolean | null
      isCAForm?: boolean | null
      isQuizForm?: boolean | null
      group?: string | null
      translations?: Array<{
        __typename?: 'Wp_FormTranslation'
        name?: string | null
        slug?: string | null
        active?: boolean | null
        title?: string | null
      } | null> | null
    }>
  }
}

export type AllFormNavFormsQueryVariables = Exact<{ [key: string]: never }>

export type AllFormNavFormsQuery = {
  __typename?: 'Query'
  allWpGfForm: {
    __typename?: 'Wp_GfFormConnection'
    nodes: Array<{
      __typename?: 'Wp_GfForm'
      title?: string | null
      slug?: string | null
      databaseId: number
      postTypeToCreate?: string | null
      isPrimary?: boolean | null
      hideInNav?: boolean | null
      isCAForm?: boolean | null
      isQuizForm?: boolean | null
      group?: string | null
      translations?: Array<{
        __typename?: 'Wp_FormTranslation'
        name?: string | null
        slug?: string | null
        active?: boolean | null
        title?: string | null
      } | null> | null
    }>
  }
}

export type RecordToolboxTalkFormQueryVariables = Exact<{
  [key: string]: never
}>

export type RecordToolboxTalkFormQuery = {
  __typename?: 'Query'
  wpGfForm?: {
    __typename?: 'Wp_GfForm'
    title?: string | null
    databaseId: number
    id: string
    formFields?: {
      __typename?: 'Wp_GfFormToFormFieldConnection'
      nodes?: Array<
        | {
            __typename: 'Wp_AddressField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_CaptchaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_CheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_ComboSignatureField'
            cssClass?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_ConsentField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_DateField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_EmailField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_FileUploadField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_FollowUpField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_HiddenField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_HtmlField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_ListField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_MultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_NameField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_NumberField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PageField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PasswordField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PhoneField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PhotoField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCategoryCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCategoryMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCategoryRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCategorySelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostContentField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomDateField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomEmailField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomFileuploadField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomHiddenField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomListField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomNumberField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomPhoneField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomTextAreaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomTextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomTimeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostCustomWebsiteField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostExcerptField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostImageField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostTagsCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostTagsMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostTagsRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostTagsSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostTagsTextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_PostTitleField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_QuizCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_QuizRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_QuizSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_RadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_RememberMeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_SectionField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_SelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_SignatureField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_SubmitField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_TextAreaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_TextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_TimeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_UsernameField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | {
            __typename: 'Wp_WebsiteField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
          }
        | null
      > | null
    } | null
  } | null
}

export type AllIncidentReportClassificationsQueryVariables = Exact<{
  [key: string]: never
}>

export type AllIncidentReportClassificationsQuery = {
  __typename?: 'Query'
  allWpIncidentReportClassification: {
    __typename?: 'Wp_IncidentReportClassificationConnection'
    edges: Array<{
      __typename?: 'Wp_IncidentReportClassificationEdge'
      node: {
        __typename?: 'Wp_IncidentReportClassification'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllIncidentReportStatusesQueryVariables = Exact<{
  [key: string]: never
}>

export type AllIncidentReportStatusesQuery = {
  __typename?: 'Query'
  allWpIncidentReportStatus: {
    __typename?: 'Wp_IncidentReportStatusConnection'
    edges: Array<{
      __typename?: 'Wp_IncidentReportStatusEdge'
      node: {
        __typename?: 'Wp_IncidentReportStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllIncidentTypesQueryVariables = Exact<{ [key: string]: never }>

export type AllIncidentTypesQuery = {
  __typename?: 'Query'
  allWpIncidentType: {
    __typename?: 'Wp_IncidentTypeConnection'
    edges: Array<{
      __typename?: 'Wp_IncidentTypeEdge'
      node: {
        __typename?: 'Wp_IncidentType'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllInjuryAreasQueryVariables = Exact<{ [key: string]: never }>

export type AllInjuryAreasQuery = {
  __typename?: 'Query'
  allWpInjuryArea: {
    __typename?: 'Wp_InjuryAreaConnection'
    edges: Array<{
      __typename?: 'Wp_InjuryAreaEdge'
      node: {
        __typename?: 'Wp_InjuryArea'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllRootCausesQueryVariables = Exact<{ [key: string]: never }>

export type AllRootCausesQuery = {
  __typename?: 'Query'
  allWpRootCause: {
    __typename?: 'Wp_RootCauseConnection'
    edges: Array<{
      __typename?: 'Wp_RootCauseEdge'
      node: {
        __typename?: 'Wp_RootCause'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllLessonCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type AllLessonCategoriesQuery = {
  __typename?: 'Query'
  allWpLessonCategory: {
    __typename?: 'Wp_LessonCategoryConnection'
    edges: Array<{
      __typename?: 'Wp_LessonCategoryEdge'
      node: {
        __typename?: 'Wp_LessonCategory'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllLessonDifficultiesQueryVariables = Exact<{
  [key: string]: never
}>

export type AllLessonDifficultiesQuery = {
  __typename?: 'Query'
  allWpLessonDifficulty: {
    __typename?: 'Wp_LessonDifficultyConnection'
    edges: Array<{
      __typename?: 'Wp_LessonDifficultyEdge'
      node: {
        __typename?: 'Wp_LessonDifficulty'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllProvidersQueryVariables = Exact<{ [key: string]: never }>

export type AllProvidersQuery = {
  __typename?: 'Query'
  allWpProvider: {
    __typename?: 'Wp_ProviderConnection'
    edges: Array<{
      __typename?: 'Wp_ProviderEdge'
      node: {
        __typename?: 'Wp_Provider'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllProjectStatusesQueryVariables = Exact<{ [key: string]: never }>

export type AllProjectStatusesQuery = {
  __typename?: 'Query'
  allWpProjectStatus: {
    __typename?: 'Wp_ProjectStatusConnection'
    edges: Array<{
      __typename?: 'Wp_ProjectStatusEdge'
      node: {
        __typename?: 'Wp_ProjectStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllProjectTypesQueryVariables = Exact<{ [key: string]: never }>

export type AllProjectTypesQuery = {
  __typename?: 'Query'
  allWpProjectType: {
    __typename?: 'Wp_ProjectTypeConnection'
    edges: Array<{
      __typename?: 'Wp_ProjectTypeEdge'
      node: {
        __typename?: 'Wp_ProjectType'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type GetIrReportQueryVariables = Exact<{ [key: string]: never }>

export type GetIrReportQuery = {
  __typename?: 'Query'
  file?: {
    __typename?: 'File'
    id: string
    name: string
    extension: string
    base: string
    publicURL?: string | null
  } | null
}

export type GetOshaReportTemplateQueryVariables = Exact<{
  [key: string]: never
}>

export type GetOshaReportTemplateQuery = {
  __typename?: 'Query'
  file?: {
    __typename?: 'File'
    id: string
    name: string
    extension: string
    base: string
    publicURL?: string | null
  } | null
}

export type AllToolboxTalkCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type AllToolboxTalkCategoriesQuery = {
  __typename?: 'Query'
  allWpToolboxTalkCategory: {
    __typename?: 'Wp_ToolboxTalkCategoryConnection'
    edges: Array<{
      __typename?: 'Wp_ToolboxTalkCategoryEdge'
      node: {
        __typename?: 'Wp_ToolboxTalkCategory'
        id: string
        name?: string | null
        databaseId: number
        slug?: string | null
        language?: {
          __typename?: 'Wp_Language'
          id: string
          name?: string | null
          slug?: string | null
        } | null
        translations?: Array<{
          __typename?: 'Wp_ToolboxTalkCategory'
          id: string
          name?: string | null
          databaseId: number
          slug?: string | null
        } | null> | null
      }
    }>
  }
}

export type GetClientLogoQueryVariables = Exact<{ [key: string]: never }>

export type GetClientLogoQuery = {
  __typename?: 'Query'
  wp?: {
    __typename?: 'Wp_'
    harnessSettings?: {
      __typename?: 'Wp_HarnessSettings'
      clientLogo?: {
        __typename?: 'Wp_HarnessSettingsToMediaItemConnectionEdge'
        node?: { __typename?: 'Wp_MediaItem'; sourceUrl?: string | null } | null
      } | null
    } | null
  } | null
}

export type MonogramGreenLogoQueryVariables = Exact<{ [key: string]: never }>

export type MonogramGreenLogoQuery = {
  __typename?: 'Query'
  monogramLogo?: {
    __typename?: 'File'
    name: string
    id: string
    childImageSharp?: { __typename?: 'ImageSharp'; gatsbyImageData: any } | null
  } | null
}

export type MonogramWhiteLogoQueryVariables = Exact<{ [key: string]: never }>

export type MonogramWhiteLogoQuery = {
  __typename?: 'Query'
  monogramLogo?: {
    __typename?: 'File'
    name: string
    id: string
    childImageSharp?: { __typename?: 'ImageSharp'; gatsbyImageData: any } | null
  } | null
}

export type AllUserStatusesQueryVariables = Exact<{ [key: string]: never }>

export type AllUserStatusesQuery = {
  __typename?: 'Query'
  allWpUserStatus: {
    __typename?: 'Wp_UserStatusConnection'
    edges: Array<{
      __typename?: 'Wp_UserStatusEdge'
      node: {
        __typename?: 'Wp_UserStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type AllWorkerRolesQueryVariables = Exact<{ [key: string]: never }>

export type AllWorkerRolesQuery = {
  __typename?: 'Query'
  allWpUserRole: {
    __typename?: 'Wp_UserRoleConnection'
    edges: Array<{
      __typename?: 'Wp_UserRoleEdge'
      node: {
        __typename?: 'Wp_UserRole'
        id: string
        displayName?: string | null
        name?: string | null
      }
    }>
  }
}

export type AllWorkerVaccineStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type AllWorkerVaccineStatusQuery = {
  __typename?: 'Query'
  allWpUserVaccineStatus: {
    __typename?: 'Wp_UserVaccineStatusConnection'
    edges: Array<{
      __typename?: 'Wp_UserVaccineStatusEdge'
      node: {
        __typename?: 'Wp_UserVaccineStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      }
    }>
  }
}

export type SiteMetadataQueryVariables = Exact<{ [key: string]: never }>

export type SiteMetadataQuery = {
  __typename?: 'Query'
  site?: {
    __typename?: 'Site'
    buildTime?: any | null
    relativeBuildTime?: any | null
    siteMetadata?: {
      __typename?: 'SiteSiteMetadata'
      title?: string | null
      author?: string | null
      description?: string | null
      siteUrl?: string | null
      version?: string | null
    } | null
  } | null
}

export type EntryFormGetFormQueryVariables = Exact<{
  formId: Scalars['Int']['input']
}>

export type EntryFormGetFormQuery = {
  __typename?: 'Query'
  wpGfForm?: {
    __typename?: 'Wp_GfForm'
    cssClass?: string | null
    description?: string | null
    disableEditing?: boolean | null
    hideJumpToSection?: boolean | null
    id: string
    reloadOnSubmit?: boolean | null
    restrictEntriesToUserCreated?: Array<string | null> | null
    restrictFormSubmissionByRole?: Array<string | null> | null
    slug?: string | null
    title?: string | null
    postTypeToCreate?: string | null
    isCAForm?: boolean | null
    isPrimary?: boolean | null
    isQuizForm?: boolean | null
    group?: string | null
    databaseId: number
    isActive?: boolean | null
    version?: string | null
    formFields?: {
      __typename?: 'Wp_GfFormToFormFieldConnection'
      nodes?: Array<
        | {
            __typename: 'Wp_AddressField'
            label?: string | null
            description?: string | null
            isRequired?: boolean | null
            addressType?: Wp_AddressFieldTypeEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            copyValuesOptionLabel?: string | null
            copyValuesOptionFieldId?: number | null
            cssClass?: string | null
            defaultCountry?: Wp_AddressFieldCountryEnum | null
            defaultState?: string | null
            defaultProvince?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldCopyValuesOption?: boolean | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            inputs?: Array<{
              __typename?: 'Wp_AddressInputProperty'
              key?: string | null
              label?: string | null
              placeholder?: string | null
              defaultValue?: string | null
              customLabel?: string | null
              autocompleteAttribute?: string | null
              id?: number | null
              isHidden?: boolean | null
              name?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            addressTranslations?: Array<{
              __typename?: 'Wp_AddressTranlation'
              name?: string | null
              slug?: string | null
              label?: string | null
              description?: string | null
              address?: string | null
              address2?: string | null
              city?: string | null
              state?: string | null
              country?: string | null
              zip?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_CaptchaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_CheckboxField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasSelectAll?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_CheckboxFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            inputs?: Array<{
              __typename?: 'Wp_CheckboxInputProperty'
              id?: number | null
              label?: string | null
              name?: string | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_ComboSignatureField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            backgroundColor?: string | null
            borderColor?: string | null
            borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
            borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
            boxWidth?: number | null
            penSize?: number | null
            penColor?: string | null
            minSignatures?: number | null
            maxSignatures?: number | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            defaultValue?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            placeholder?: string | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_ComboSignatureFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_ConsentField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_DateField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
            inputName?: string | null
            dateFormat?: Wp_DateFieldFormatEnum | null
            adminLabel?: string | null
            calendarIconUrl?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'Wp_DateInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_EmailField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasEmailConfirmation?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'Wp_EmailInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              name?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_FileUploadField'
            label?: string | null
            isRequired?: boolean | null
            maxFileSize?: number | null
            maxFiles?: number | null
            adminLabel?: string | null
            allowedExtensions?: Array<string | null> | null
            canAcceptMultipleFiles?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_FollowUpField'
            label?: string | null
            content?: string | null
            cssClass?: string | null
            followUpFormId?: number | null
            hasMargins?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            followUpForm?: {
              __typename?: 'Wp_GfForm'
              slug?: string | null
              title?: string | null
            } | null
            correctiveActionSettings?: Array<{
              __typename?: 'Wp_CorrectiveActionSettings'
              actionTaken?: string | null
              deficiency?: string | null
              deficiencyType?: Array<string | null> | null
              severity?: string | null
            } | null> | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_HiddenField'
            defaultValue?: string | null
            label?: string | null
            canPrepopulate?: boolean | null
            inputName?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_HtmlField'
            content?: string | null
            label?: string | null
            cssClass?: string | null
            hasMargins?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_ListField'
            description?: string | null
            isRequired?: boolean | null
            inputName?: string | null
            label?: string | null
            maxRows?: number | null
            addIconUrl?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            deleteIconUrl?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasColumns?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_ListFieldChoice'
              text?: string | null
              value?: string | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_MultiSelectField'
            label?: string | null
            description?: string | null
            isRequired?: boolean | null
            allowCustomOption?: boolean | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_MultiSelectFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_NameField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            inputs?: Array<{
              __typename?: 'Wp_NameInputProperty'
              key?: string | null
              label?: string | null
              placeholder?: string | null
              isHidden?: boolean | null
              defaultValue?: string | null
              customLabel?: string | null
              autocompleteAttribute?: string | null
              hasChoiceValue?: boolean | null
              id?: number | null
              name?: string | null
              choices?: Array<{
                __typename?: 'Wp_NameFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
            } | null> | null
            nameTranslations?: Array<{
              __typename?: 'Wp_NameTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              first?: string | null
              last?: string | null
              middle?: string | null
              prefix?: string | null
              suffix?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_NumberField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            rangeMin?: number | null
            rangeMax?: number | null
            numberFormat?: Wp_NumberFieldFormatEnum | null
            placeholder?: string | null
            defaultValue?: string | null
            calculationFormula?: string | null
            calculationRounding?: number | null
            errorMessage?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            isCalculation?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_PageField'
            cssClass?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            previousButton?: {
              __typename?: 'Wp_FormButton'
              text?: string | null
              type?: Wp_FormButtonTypeEnum | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            } | null
            nextButton?: {
              __typename?: 'Wp_FormButton'
              text?: string | null
              type?: Wp_FormButtonTypeEnum | null
              conditionalLogic?: {
                __typename?: 'Wp_ConditionalLogic'
                actionType?: Wp_ConditionalLogicActionTypeEnum | null
                logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'Wp_ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: Wp_FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            } | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_PasswordField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PhoneField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            defaultValue?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            phoneFormat?: Wp_PhoneFieldFormatEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_PhotoField'
            label?: string | null
            isRequired?: boolean | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_PostCategoryCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCategoryMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCategoryRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCategorySelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostContentField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomDateField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomEmailField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomFileuploadField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomHiddenField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomListField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomNumberField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomPhoneField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomTextAreaField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomTextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomTimeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostCustomWebsiteField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostExcerptField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostImageField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsCheckboxField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsMultiSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsRadioField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsSelectField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTagsTextField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_PostTitleField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_QuizCheckboxField'
            hasSelectAll?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            inputs?: Array<{
              __typename?: 'Wp_QuizInputProperty'
              label?: string | null
              id?: number | null
              name?: string | null
            } | null> | null
            choices?: Array<{
              __typename?: 'Wp_QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'Wp_QuizRadioField'
            shouldAllowDuplicates?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'Wp_QuizSelectField'
            defaultValue?: string | null
            placeholder?: string | null
            autocompleteAttribute?: string | null
            hasAutocomplete?: boolean | null
            hasEnhancedUI?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'Wp_RadioField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasOtherChoice?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_RadioFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
              isOtherChoice?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_RememberMeField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_SectionField'
            label?: string | null
            description?: string | null
            cssClass?: string | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_SelectField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            defaultValue?: string | null
            allowCustomOption?: boolean | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            placeholder?: string | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            choices?: Array<{
              __typename?: 'Wp_SelectFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'Wp_ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_SignatureField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            penColor?: string | null
            penSize?: number | null
            backgroundColor?: string | null
            borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
            borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
            borderColor?: string | null
            adminLabel?: string | null
            boxWidth?: number | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_SubmitField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_TextAreaField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            maxLength?: number | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasRichTextEditor?: boolean | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_TextField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            maxLength?: number | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasInputMask?: boolean | null
            inputMaskValue?: string | null
            isPasswordInput?: boolean | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: Wp_FormFieldSizeEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'Wp_TimeField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            timeFormat?: Wp_TimeFieldFormatEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'Wp_TimeInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename: 'Wp_UsernameField'
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename: 'Wp_WebsiteField'
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            inputName?: string | null
            labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            id: number
            type?: Wp_FormFieldTypeEnum | null
            inputType?: Wp_FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: Wp_FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'Wp_BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'Wp_ConditionalLogic'
              actionType?: Wp_ConditionalLogicActionTypeEnum | null
              logicType?: Wp_ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'Wp_ConditionalLogicRule'
                fieldId?: number | null
                operator?: Wp_FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | null
      > | null
    } | null
    confirmations?: Array<{
      __typename?: 'Wp_FormConfirmation'
      id?: string | null
      isActive?: boolean | null
      isDefault?: boolean | null
      message?: string | null
      name?: string | null
      pageId?: number | null
      queryString?: string | null
      type?: Wp_FormConfirmationTypeEnum | null
      url?: string | null
      conditionalLogic?: {
        __typename?: 'Wp_ConditionalLogic'
        actionType?: Wp_ConditionalLogicActionTypeEnum | null
        logicType?: Wp_ConditionalLogicLogicTypeEnum | null
        rules?: Array<{
          __typename?: 'Wp_ConditionalLogicRule'
          fieldId?: number | null
          operator?: Wp_FormRuleOperatorEnum | null
          value?: string | null
        } | null> | null
      } | null
    } | null> | null
    pagination?: {
      __typename?: 'Wp_FormPagination'
      pageNames?: Array<string | null> | null
      lastPageButton?: {
        __typename?: 'Wp_FormLastPageButton'
        text?: string | null
        type?: Wp_FormButtonTypeEnum | null
      } | null
    } | null
    submitButton?: {
      __typename?: 'Wp_FormSubmitButton'
      text?: string | null
      type?: Wp_FormButtonTypeEnum | null
      conditionalLogic?: {
        __typename?: 'Wp_ConditionalLogic'
        actionType?: Wp_ConditionalLogicActionTypeEnum | null
        logicType?: Wp_ConditionalLogicLogicTypeEnum | null
        rules?: Array<{
          __typename?: 'Wp_ConditionalLogicRule'
          fieldId?: number | null
          operator?: Wp_FormRuleOperatorEnum | null
          value?: string | null
        } | null> | null
      } | null
    } | null
    saveAndContinue?: {
      __typename?: 'Wp_FormSaveAndContinue'
      hasSaveAndContinue?: boolean | null
      buttonText?: string | null
    } | null
    translations?: Array<{
      __typename?: 'Wp_FormTranslation'
      slug?: string | null
      name?: string | null
      active?: boolean | null
      title?: string | null
      description?: string | null
      submitButtonText?: string | null
      nextButtonText?: string | null
      clearButtonText?: string | null
      previousButtonText?: string | null
      cardDisplay?: {
        __typename?: 'Wp_CardDisplayTranslation'
        detail1?: string | null
        detail2?: string | null
        detail3?: string | null
      } | null
    } | null> | null
  } | null
}

export type GetLessonQueryVariables = Exact<{
  databaseId: Scalars['Int']['input']
}>

export type GetLessonQuery = {
  __typename?: 'Query'
  wpLesson?: {
    __typename?: 'Wp_Lesson'
    id: string
    databaseId: number
    title?: string | null
    excerpt?: string | null
    content?: string | null
    slug?: string | null
    featuredImage?: {
      __typename?: 'Wp_NodeWithFeaturedImageToMediaItemConnectionEdge'
      node?: {
        __typename?: 'Wp_MediaItem'
        altText?: string | null
        id: string
        title?: string | null
        description?: string | null
        mediaItemUrl?: string | null
        sizes?: string | null
        databaseId: number
        sourceUrl?: string | null
        srcSet?: string | null
      } | null
    } | null
    lessonDetails?: {
      __typename?: 'Wp_Lesson_Lessondetails'
      courseExpriyLengthInMonths?: number | null
      fieldGroupName?: string | null
      lessonAudioDescription?: string | null
      lessonAudioEmbed?: string | null
      lessonAudioTitle?: string | null
      lessonDescription?: string | null
      lessonVideoDescription?: string | null
      lessonVideoEmbed?: string | null
      lessonVideoTitle?: string | null
      lessonQuiz?: {
        __typename?: 'Wp_GfForm'
        isQuizForm?: boolean | null
        cssClass?: string | null
        description?: string | null
        disableEditing?: boolean | null
        hideJumpToSection?: boolean | null
        id: string
        reloadOnSubmit?: boolean | null
        restrictEntriesToUserCreated?: Array<string | null> | null
        restrictFormSubmissionByRole?: Array<string | null> | null
        slug?: string | null
        title?: string | null
        postTypeToCreate?: string | null
        isCAForm?: boolean | null
        isPrimary?: boolean | null
        group?: string | null
        databaseId: number
        isActive?: boolean | null
        version?: string | null
        quiz?: {
          __typename?: 'Wp_FormQuiz'
          gradingType?: Wp_QuizFieldGradingTypeEnum | null
          hasInstantFeedback?: boolean | null
          hasLetterConfirmationMessage?: boolean | null
          hasPassFailConfirmationMessage?: boolean | null
          isShuffleFieldsEnabled?: boolean | null
          maxScore?: number | null
          passPercent?: number | null
          failConfirmation?: {
            __typename?: 'Wp_FormQuizConfirmation'
            isAutoformatted?: boolean | null
            message?: string | null
          } | null
          letterConfirmation?: {
            __typename?: 'Wp_FormQuizConfirmation'
            isAutoformatted?: boolean | null
            message?: string | null
          } | null
          passConfirmation?: {
            __typename?: 'Wp_FormQuizConfirmation'
            isAutoformatted?: boolean | null
            message?: string | null
          } | null
          grades?: Array<{
            __typename?: 'Wp_FormQuizGrades'
            text?: string | null
            value?: number | null
          } | null> | null
        } | null
        formFields?: {
          __typename?: 'Wp_GfFormToFormFieldConnection'
          nodes?: Array<
            | {
                __typename: 'Wp_AddressField'
                label?: string | null
                description?: string | null
                isRequired?: boolean | null
                addressType?: Wp_AddressFieldTypeEnum | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                copyValuesOptionLabel?: string | null
                copyValuesOptionFieldId?: number | null
                cssClass?: string | null
                defaultCountry?: Wp_AddressFieldCountryEnum | null
                defaultState?: string | null
                defaultProvince?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldCopyValuesOption?: boolean | null
                subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                inputs?: Array<{
                  __typename?: 'Wp_AddressInputProperty'
                  key?: string | null
                  label?: string | null
                  placeholder?: string | null
                  defaultValue?: string | null
                  customLabel?: string | null
                  autocompleteAttribute?: string | null
                  id?: number | null
                  isHidden?: boolean | null
                  name?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                addressTranslations?: Array<{
                  __typename?: 'Wp_AddressTranlation'
                  name?: string | null
                  slug?: string | null
                  label?: string | null
                  description?: string | null
                  address?: string | null
                  address2?: string | null
                  city?: string | null
                  state?: string | null
                  country?: string | null
                  zip?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_CaptchaField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_CheckboxField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasChoiceValue?: boolean | null
                hasSelectAll?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_CheckboxFieldChoice'
                  text?: string | null
                  value?: string | null
                  isSelected?: boolean | null
                } | null> | null
                inputs?: Array<{
                  __typename?: 'Wp_CheckboxInputProperty'
                  id?: number | null
                  label?: string | null
                  name?: string | null
                } | null> | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_ComboSignatureField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                backgroundColor?: string | null
                borderColor?: string | null
                borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
                borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
                boxWidth?: number | null
                penSize?: number | null
                penColor?: string | null
                minSignatures?: number | null
                maxSignatures?: number | null
                adminLabel?: string | null
                autocompleteAttribute?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                defaultValue?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                hasChoiceValue?: boolean | null
                hasEnhancedUI?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                placeholder?: string | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_ComboSignatureFieldChoice'
                  text?: string | null
                  value?: string | null
                  isSelected?: boolean | null
                } | null> | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_ConsentField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_DateField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                placeholder?: string | null
                defaultValue?: string | null
                calendarIconType?: Wp_FormFieldCalendarIconTypeEnum | null
                inputName?: string | null
                dateFormat?: Wp_DateFieldFormatEnum | null
                adminLabel?: string | null
                calendarIconUrl?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                inputs?: Array<{
                  __typename?: 'Wp_DateInputProperty'
                  autocompleteAttribute?: string | null
                  customLabel?: string | null
                  defaultValue?: string | null
                  id?: number | null
                  label?: string | null
                  placeholder?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_EmailField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                placeholder?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                hasEmailConfirmation?: boolean | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                inputs?: Array<{
                  __typename?: 'Wp_EmailInputProperty'
                  autocompleteAttribute?: string | null
                  customLabel?: string | null
                  defaultValue?: string | null
                  id?: number | null
                  label?: string | null
                  name?: string | null
                  placeholder?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_FileUploadField'
                label?: string | null
                isRequired?: boolean | null
                maxFileSize?: number | null
                maxFiles?: number | null
                adminLabel?: string | null
                allowedExtensions?: Array<string | null> | null
                canAcceptMultipleFiles?: boolean | null
                cssClass?: string | null
                description?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_FollowUpField'
                label?: string | null
                content?: string | null
                cssClass?: string | null
                followUpFormId?: number | null
                hasMargins?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                followUpForm?: {
                  __typename?: 'Wp_GfForm'
                  slug?: string | null
                  title?: string | null
                } | null
                correctiveActionSettings?: Array<{
                  __typename?: 'Wp_CorrectiveActionSettings'
                  actionTaken?: string | null
                  deficiency?: string | null
                  deficiencyType?: Array<string | null> | null
                  severity?: string | null
                } | null> | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_HiddenField'
                defaultValue?: string | null
                label?: string | null
                canPrepopulate?: boolean | null
                inputName?: string | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_HtmlField'
                content?: string | null
                label?: string | null
                cssClass?: string | null
                hasMargins?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_ListField'
                description?: string | null
                isRequired?: boolean | null
                inputName?: string | null
                label?: string | null
                maxRows?: number | null
                addIconUrl?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                deleteIconUrl?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasColumns?: boolean | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_ListFieldChoice'
                  text?: string | null
                  value?: string | null
                } | null> | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_MultiSelectField'
                label?: string | null
                description?: string | null
                isRequired?: boolean | null
                allowCustomOption?: boolean | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasChoiceValue?: boolean | null
                hasEnhancedUI?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_MultiSelectFieldChoice'
                  text?: string | null
                  value?: string | null
                  isSelected?: boolean | null
                } | null> | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_NameField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                inputs?: Array<{
                  __typename?: 'Wp_NameInputProperty'
                  key?: string | null
                  label?: string | null
                  placeholder?: string | null
                  isHidden?: boolean | null
                  defaultValue?: string | null
                  customLabel?: string | null
                  autocompleteAttribute?: string | null
                  hasChoiceValue?: boolean | null
                  id?: number | null
                  name?: string | null
                  choices?: Array<{
                    __typename?: 'Wp_NameFieldChoice'
                    text?: string | null
                    value?: string | null
                    isSelected?: boolean | null
                  } | null> | null
                } | null> | null
                nameTranslations?: Array<{
                  __typename?: 'Wp_NameTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  first?: string | null
                  last?: string | null
                  middle?: string | null
                  prefix?: string | null
                  suffix?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_NumberField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                rangeMin?: number | null
                rangeMax?: number | null
                numberFormat?: Wp_NumberFieldFormatEnum | null
                placeholder?: string | null
                defaultValue?: string | null
                calculationFormula?: string | null
                calculationRounding?: number | null
                errorMessage?: string | null
                adminLabel?: string | null
                autocompleteAttribute?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                hasAutocomplete?: boolean | null
                inputName?: string | null
                isCalculation?: boolean | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_PageField'
                cssClass?: string | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                previousButton?: {
                  __typename?: 'Wp_FormButton'
                  text?: string | null
                  type?: Wp_FormButtonTypeEnum | null
                  conditionalLogic?: {
                    __typename?: 'Wp_ConditionalLogic'
                    actionType?: Wp_ConditionalLogicActionTypeEnum | null
                    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                    rules?: Array<{
                      __typename?: 'Wp_ConditionalLogicRule'
                      fieldId?: number | null
                      operator?: Wp_FormRuleOperatorEnum | null
                      value?: string | null
                    } | null> | null
                  } | null
                } | null
                nextButton?: {
                  __typename?: 'Wp_FormButton'
                  text?: string | null
                  type?: Wp_FormButtonTypeEnum | null
                  conditionalLogic?: {
                    __typename?: 'Wp_ConditionalLogic'
                    actionType?: Wp_ConditionalLogicActionTypeEnum | null
                    logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                    rules?: Array<{
                      __typename?: 'Wp_ConditionalLogicRule'
                      fieldId?: number | null
                      operator?: Wp_FormRuleOperatorEnum | null
                      value?: string | null
                    } | null> | null
                  } | null
                } | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_PasswordField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PhoneField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                placeholder?: string | null
                adminLabel?: string | null
                autocompleteAttribute?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                defaultValue?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                phoneFormat?: Wp_PhoneFieldFormatEnum | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_PhotoField'
                label?: string | null
                isRequired?: boolean | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                description?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_PostCategoryCheckboxField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCategoryMultiSelectField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCategoryRadioField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCategorySelectField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostContentField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomCheckboxField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomDateField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomEmailField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomFileuploadField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomHiddenField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomListField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomMultiSelectField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomNumberField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomPhoneField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomRadioField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomSelectField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomTextAreaField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomTextField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomTimeField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostCustomWebsiteField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostExcerptField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostImageField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostTagsCheckboxField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostTagsMultiSelectField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostTagsRadioField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostTagsSelectField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostTagsTextField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_PostTitleField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_QuizCheckboxField'
                hasSelectAll?: boolean | null
                adminLabel?: string | null
                answerExplanation?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                description?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasChoiceValue?: boolean | null
                hasWeightedScore?: boolean | null
                inputName?: string | null
                isRequired?: boolean | null
                label?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldRandomizeQuizChoices?: boolean | null
                shouldShowAnswerExplanation?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                inputs?: Array<{
                  __typename?: 'Wp_QuizInputProperty'
                  label?: string | null
                  id?: number | null
                  name?: string | null
                } | null> | null
                choices?: Array<{
                  __typename?: 'Wp_QuizFieldChoice'
                  isCorrect?: boolean | null
                  isOtherChoice?: boolean | null
                  isSelected?: boolean | null
                  text?: string | null
                  value?: string | null
                  weight?: number | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
              }
            | {
                __typename: 'Wp_QuizRadioField'
                shouldAllowDuplicates?: boolean | null
                adminLabel?: string | null
                answerExplanation?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                description?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasChoiceValue?: boolean | null
                hasWeightedScore?: boolean | null
                inputName?: string | null
                isRequired?: boolean | null
                label?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldRandomizeQuizChoices?: boolean | null
                shouldShowAnswerExplanation?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_QuizFieldChoice'
                  isCorrect?: boolean | null
                  isOtherChoice?: boolean | null
                  isSelected?: boolean | null
                  text?: string | null
                  value?: string | null
                  weight?: number | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
              }
            | {
                __typename: 'Wp_QuizSelectField'
                defaultValue?: string | null
                placeholder?: string | null
                autocompleteAttribute?: string | null
                hasAutocomplete?: boolean | null
                hasEnhancedUI?: boolean | null
                adminLabel?: string | null
                answerExplanation?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                description?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasChoiceValue?: boolean | null
                hasWeightedScore?: boolean | null
                inputName?: string | null
                isRequired?: boolean | null
                label?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldRandomizeQuizChoices?: boolean | null
                shouldShowAnswerExplanation?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_QuizFieldChoice'
                  isCorrect?: boolean | null
                  isOtherChoice?: boolean | null
                  isSelected?: boolean | null
                  text?: string | null
                  value?: string | null
                  weight?: number | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
              }
            | {
                __typename: 'Wp_RadioField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasChoiceValue?: boolean | null
                hasOtherChoice?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_RadioFieldChoice'
                  text?: string | null
                  value?: string | null
                  isSelected?: boolean | null
                  isOtherChoice?: boolean | null
                } | null> | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_RememberMeField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_SectionField'
                label?: string | null
                description?: string | null
                cssClass?: string | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_SelectField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                defaultValue?: string | null
                allowCustomOption?: boolean | null
                adminLabel?: string | null
                autocompleteAttribute?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                hasChoiceValue?: boolean | null
                hasEnhancedUI?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                placeholder?: string | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                choices?: Array<{
                  __typename?: 'Wp_SelectFieldChoice'
                  text?: string | null
                  value?: string | null
                  isSelected?: boolean | null
                } | null> | null
                choiceTranslations?: Array<{
                  __typename?: 'Wp_ChoiceTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                  choices?: Array<string | null> | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_SignatureField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                penColor?: string | null
                penSize?: number | null
                backgroundColor?: string | null
                borderWidth?: Wp_SignatureFieldBorderWidthEnum | null
                borderStyle?: Wp_SignatureFieldBorderStyleEnum | null
                borderColor?: string | null
                adminLabel?: string | null
                boxWidth?: number | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_SubmitField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_TextAreaField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                maxLength?: number | null
                placeholder?: string | null
                defaultValue?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasRichTextEditor?: boolean | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_TextField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                maxLength?: number | null
                placeholder?: string | null
                defaultValue?: string | null
                adminLabel?: string | null
                autocompleteAttribute?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                hasAutocomplete?: boolean | null
                hasInputMask?: boolean | null
                inputMaskValue?: string | null
                isPasswordInput?: boolean | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                size?: Wp_FormFieldSizeEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'Wp_TimeField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                timeFormat?: Wp_TimeFieldFormatEnum | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                subLabelPlacement?: Wp_FormFieldSubLabelPlacementEnum | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
                inputs?: Array<{
                  __typename?: 'Wp_TimeInputProperty'
                  autocompleteAttribute?: string | null
                  customLabel?: string | null
                  defaultValue?: string | null
                  id?: number | null
                  label?: string | null
                  placeholder?: string | null
                } | null> | null
              }
            | {
                __typename: 'Wp_UsernameField'
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
              }
            | {
                __typename: 'Wp_WebsiteField'
                label?: string | null
                isRequired?: boolean | null
                description?: string | null
                placeholder?: string | null
                defaultValue?: string | null
                adminLabel?: string | null
                canPrepopulate?: boolean | null
                cssClass?: string | null
                descriptionPlacement?: Wp_FormFieldDescriptionPlacementEnum | null
                errorMessage?: string | null
                inputName?: string | null
                labelPlacement?: Wp_FormFieldLabelPlacementEnum | null
                shouldAllowDuplicates?: boolean | null
                id: number
                type?: Wp_FormFieldTypeEnum | null
                inputType?: Wp_FormFieldTypeEnum | null
                pageNumber?: number | null
                visibility?: Wp_FormFieldVisibilityEnum | null
                displayOnly?: boolean | null
                basicTranslations?: Array<{
                  __typename?: 'Wp_BasicTranslation'
                  slug?: string | null
                  name?: string | null
                  label?: string | null
                  description?: string | null
                } | null> | null
                conditionalLogic?: {
                  __typename?: 'Wp_ConditionalLogic'
                  actionType?: Wp_ConditionalLogicActionTypeEnum | null
                  logicType?: Wp_ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'Wp_ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: Wp_FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | null
          > | null
        } | null
        confirmations?: Array<{
          __typename?: 'Wp_FormConfirmation'
          id?: string | null
          isActive?: boolean | null
          isDefault?: boolean | null
          message?: string | null
          name?: string | null
          pageId?: number | null
          queryString?: string | null
          type?: Wp_FormConfirmationTypeEnum | null
          url?: string | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        } | null> | null
        pagination?: {
          __typename?: 'Wp_FormPagination'
          pageNames?: Array<string | null> | null
          lastPageButton?: {
            __typename?: 'Wp_FormLastPageButton'
            text?: string | null
            type?: Wp_FormButtonTypeEnum | null
          } | null
        } | null
        submitButton?: {
          __typename?: 'Wp_FormSubmitButton'
          text?: string | null
          type?: Wp_FormButtonTypeEnum | null
          conditionalLogic?: {
            __typename?: 'Wp_ConditionalLogic'
            actionType?: Wp_ConditionalLogicActionTypeEnum | null
            logicType?: Wp_ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'Wp_ConditionalLogicRule'
              fieldId?: number | null
              operator?: Wp_FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        } | null
        saveAndContinue?: {
          __typename?: 'Wp_FormSaveAndContinue'
          hasSaveAndContinue?: boolean | null
          buttonText?: string | null
        } | null
        translations?: Array<{
          __typename?: 'Wp_FormTranslation'
          slug?: string | null
          name?: string | null
          active?: boolean | null
          title?: string | null
          description?: string | null
          submitButtonText?: string | null
          nextButtonText?: string | null
          clearButtonText?: string | null
          previousButtonText?: string | null
          cardDisplay?: {
            __typename?: 'Wp_CardDisplayTranslation'
            detail1?: string | null
            detail2?: string | null
            detail3?: string | null
          } | null
        } | null> | null
      } | null
    } | null
    lessonDocumentConnectionConnection?: {
      __typename?: 'Wp_LessonToDocumentConnection'
      nodes?: Array<{
        __typename?: 'Wp_Document'
        databaseId: number
        title?: string | null
      } | null> | null
    } | null
    companies?: {
      __typename?: 'Wp_LessonToCompanyConnection'
      nodes?: Array<{
        __typename?: 'Wp_Company'
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'Wp_LessonToDivisionConnection'
      nodes?: Array<{
        __typename?: 'Wp_Division'
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
    lessonCategories?: {
      __typename?: 'Wp_LessonToLessonCategoryConnection'
      nodes?: Array<{
        __typename?: 'Wp_LessonCategory'
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
    lessonDifficulties?: {
      __typename?: 'Wp_LessonToLessonDifficultyConnection'
      nodes?: Array<{
        __typename?: 'Wp_LessonDifficulty'
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
    trainingTypes?: {
      __typename?: 'Wp_LessonToTrainingTypeConnection'
      nodes?: Array<{
        __typename?: 'Wp_TrainingType'
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
    providers?: {
      __typename?: 'Wp_LessonToProviderConnection'
      nodes?: Array<{
        __typename?: 'Wp_Provider'
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
  } | null
}

export type ComplianceReportGetFormQueryVariables = Exact<{
  formId: Scalars['Int']['input']
}>

export type ComplianceReportGetFormQuery = {
  __typename?: 'Query'
  wpGfForm?: {
    __typename?: 'Wp_GfForm'
    submissionRules?: Array<{
      __typename?: 'Wp_SubmissionRule'
      frequency?: string | null
      role?: string | null
      entryCount?: number | null
    } | null> | null
  } | null
}
