import format from 'date-fns/fp/format'
import snakeCase from 'lodash/snakeCase'
import { FieldValues } from 'react-hook-form'
import { createAddressFieldFieldNames } from '../../components/GravityForms/Fields/AddressField'
import { getCountryLabelFromCode } from './fields/getCountryLabelFromCode'
import { ListFieldValue } from '../../types/generated'
import { Maybe } from '../../types/generated-gatsby'
import { GFormFieldApollo } from '../../types/gravityForms'
import dateFormatter from '../dates/dateFormater'
import getBase64FromUrl from '../getBase64FromUrl'
import getFileFromUrl from '../getFileFromUrl'
import { excludedFields } from './makeFieldValueInputsFromEntryFields'
import { CheckboxValue } from '../../components/GravityForms/Fields/CheckboxField'

/**
 * Create the React Hook Form FieldValues from the given GF Form Fields from an GF entry
 *
 * @param formFields Form Fields With field values from runtime graphql layer via apollo
 * @returns FieldValues
 */
const makeFieldValues = async (
  formFields: GFormFieldApollo[]
): Promise<FieldValues> => {
  // the following fields have values that are returned from the server as urls. However those urls need to be changed into the expected value type for the special field.
  // For FileUpload the url needs to be converted to a File Object
  // ComboSignature and Signature Fields need to have the url converted to a base64String which the signature canvas component uses as a value.
  // this requires that the server have a wide open CORS policy
  const specialValueFields = formFields?.filter(
    (field) =>
      field?.__typename === `FileUploadField` ||
      field?.__typename === `ComboSignatureField` ||
      field?.__typename === `SignatureField` ||
      field?.__typename === `PhotoField`
  )

  const promisedFields =
    (await Promise.all(
      specialValueFields?.map(async (field) => {
        if (!field?.id) {
          return null
        }
        if (field?.__typename === `FileUploadField`) {
          const values = await Promise.all(
            field?.fileUploadValues?.map((v) =>
              v?.url ? getFileFromUrl(v.url ?? ``) : Promise.resolve()
            ) ?? []
          )
          const final = values?.map((value) => ({
            file: value,
            metadata: {},
          }))
          return {
            [field?.id]: final,
          }
        } else if (field?.__typename === `PhotoField`) {
          const values = await Promise.all(
            field?.photoValues?.map((image) =>
              getFileFromUrl(image?.featuredImage?.node?.sourceUrl ?? ``)
            ) ?? []
          )
          const final = values?.map((value) => ({
            file: value,
            metadata: {},
          }))
          return {
            [field?.id]: final,
          }
        } else if (
          field?.__typename === `ComboSignatureField` &&
          field?.signatureValues?.length
        ) {
          const base64StringValues = await Promise.all(
            field?.signatureValues?.map((value) =>
              getBase64FromUrl(value?.signature ?? ``)
            )
          )
          const final = base64StringValues?.map((value, idx) => ({
            signature: value,
            user: {
              text: field?.signatureValues?.[idx]?.user?.name,
              value: field?.signatureValues?.[idx]?.user?.databaseId,
            },
          }))
          return {
            [field?.id]: final,
          }
        } else if (field?.__typename === `SignatureField` && field?.value) {
          const value = await getBase64FromUrl(field?.value)
          return {
            [field?.id]: value,
          }
        }
      })
    )) ?? []

  const accumulator = promisedFields?.reduce((acc, field) => {
    return {
      ...acc,
      ...field,
    }
  }, {} as FieldValues)

  return (
    formFields?.reduce((acc, field) => {
      const fieldId = field?.id
      const fieldTypeName = field?.__typename ?? ``

      if (excludedFields?.includes(fieldTypeName) || !fieldId) {
        return acc
      } else if (fieldTypeName === `AddressField` && field?.addressValues) {
        const {
          addressFieldName,
          address2FieldName,
          cityFieldName,
          countryFieldName,
          stateFieldName,
          zipFieldName,
        } = createAddressFieldFieldNames(String(fieldId ?? ``))
        const addressValues = field?.addressValues
        return {
          ...acc,
          ...(addressValues?.street && {
            [addressFieldName]: addressValues?.street,
          }),
          ...(addressValues?.lineTwo && {
            [address2FieldName]: addressValues?.lineTwo,
          }),
          ...(addressValues?.city && { [cityFieldName]: addressValues?.city }),
          ...(addressValues?.state && {
            [stateFieldName]: addressValues?.state,
          }),
          ...(addressValues?.country && {
            [countryFieldName]: {
              text: getCountryLabelFromCode(addressValues?.country),
              value: addressValues?.country,
            },
          }),
          ...(addressValues?.zip && { [zipFieldName]: addressValues?.zip }),
        }
      } else if (fieldTypeName === `NameField`) {
        const firstFieldName = `${fieldId}_first`
        const lastFieldName = `${fieldId}_last`
        const middleFieldName = `${fieldId}_middle`
        const prefixFieldName = `${fieldId}_prefix`
        const suffixFieldName = `${fieldId}_suffix`
        return {
          ...acc,
          ...(field?.nameValues?.first && {
            [firstFieldName]: field?.nameValues?.first,
          }),
          ...(field?.nameValues?.last && {
            [lastFieldName]: field?.nameValues?.last,
          }),
          ...(field?.nameValues?.middle && {
            [middleFieldName]: field?.nameValues?.middle,
          }),
          ...(field?.nameValues?.prefix && {
            [prefixFieldName]: field?.nameValues?.prefix,
          }),
          ...(field?.nameValues?.suffix && {
            [suffixFieldName]: field?.nameValues?.suffix,
          }),
        }
      } else if (
        fieldTypeName === `CheckboxField` &&
        field?.checkboxValues?.length
      ) {
        const values: CheckboxValue[] | undefined =
          field?.checkboxValues?.reduce((acc, value) => {
            if (value?.value && value?.inputId) {
              return [
                ...acc,
                {
                  value: value?.value,
                  inputId: value?.inputId,
                },
              ]
            } else {
              return acc
            }
          }, [] as CheckboxValue[]) ?? []
        return {
          ...acc,
          [fieldId]: values,
        }
      } else if (fieldTypeName === `ConsentField` && field?.value) {
        return {
          ...acc,
          [fieldId]: field?.value === `Yes`,
        }
      } else if (fieldTypeName === `DateField` && field?.value) {
        if (!field.value) {
          return acc
        }
        return {
          ...acc,
          [fieldId]: new Date(
            dateFormatter(field?.value, {
              dateFormat: `P`,
            })
          ),
        }
      } else if (fieldTypeName === `TimeField` && field?.timeValues) {
        const time = field?.timeValues
        if (!time?.displayValue) {
          return acc
        }
        const dateValue = format(`P`, new Date()) + ` ` + time?.displayValue
        return {
          ...acc,
          [fieldId]: new Date(dateValue),
        }
      } else if (fieldTypeName === `SelectField` && field?.value) {
        const text = field?.valueText || field?.value
        return {
          ...acc,
          [fieldId]: { value: field?.value, text },
        }
      } else if (
        fieldTypeName === `MultiSelectField` &&
        field?.values?.length
      ) {
        const valuesText = field?.valuesText
        const values = field?.values?.map((value, idx) => {
          return {
            value,
            text: valuesText?.[idx] || value,
          }
        })

        return {
          ...acc,
          [fieldId]: values ?? [],
        }
      } else if (fieldTypeName === `FileUploadField`) {
        return {
          ...acc,
        }
      } else if (fieldTypeName === `PhotoField`) {
        // const photoDataArray: PhotoDataArray = field?.photoValues?.map(
        //   (photo) => {
        //     return {
        //       base64DataUrl:
        //         photo?.featuredImage?.node?.mediaDetails?.sizes?.[0]?.sourceUrl,
        //       file: {
        //         lastModified: new Date(),
        //         name: photo?.featuredImage?.node?.title,
        //         type: `image/png`,
        //       },
        //     }
        //   }
        // )

        return acc
      } else if (fieldTypeName === `SignatureField`) {
        return acc
      } else if (fieldTypeName === `ComboSignatureField`) {
        return acc
      } else if (fieldTypeName === `ListField`) {
        const listValues: Maybe<ListFieldValue>[] = field?.listValues ?? []
        if (!field?.hasColumns) {
          return {
            ...acc,
            [fieldId]: listValues?.map((prop) => ({
              value: prop?.values?.[0],
            })),
          }
        } else if (listValues?.length && field?.hasColumns) {
          const choices = field?.choices

          const listFieldValues =
            field?.listValues?.reduce((acc, value, idx) => {
              if (value?.values?.length) {
                const columnValues = value?.values?.reduce((acc2, v, idx2) => {
                  return {
                    ...acc2,
                    [`${fieldId}.${idx}.${snakeCase(
                      choices?.[idx2]?.text?.toLowerCase()
                    )}`]: v,
                  }
                }, {})

                return {
                  ...acc,
                  ...columnValues,
                }
              } else {
                return acc
              }
            }, {}) ?? {}

          return { ...acc, ...listFieldValues }
        }
        return {
          ...acc,
        }
      } else if (field?.value) {
        const value = field?.value
        return { ...acc, [fieldId]: value }
      } else {
        return acc
      }
    }, accumulator) ?? []
  )
}

export default makeFieldValues
