import { GatsbyBrowser } from 'gatsby'
// import '@fontsource/work-sans/latin-ext.css'
import '@fontsource/work-sans/latin.css'

/**
 * Prompt user to reload when service worker finds an update.
 */
export const onServiceWorkerUpdateReady: GatsbyBrowser['onServiceWorkerUpdateReady'] =
  () => {
    const answer = window.confirm(
      `SafetyHQ has been updated! ` + `Reload to display the latest version?`
    )

    if (answer === true) {
      window.location.reload()
    }
  }
