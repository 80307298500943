import * as React from 'react'
import client from '../../services/apollo/client'
import {
  setSavedAuthData,
  deleteSavedAuthData,
} from '../../services/apollo/auth'
import { authDataVar } from '../../services/apollo/cache'
import {
  LoginPayload,
  Maybe,
  useRefreshAuthTokenMutation,
} from '../../types/generated'
import { useNetwork } from './Network'
import { ApolloError, useReactiveVar } from '@apollo/client'
import { useDeepCompareEffect } from 'use-deep-compare'

export interface AuthValue {
  isLoggedIn: boolean
  user?: LoginPayload['user'] | null
  userId?: string | number
  setAuthData: (authData: LoginPayload) => void
  deleteAuthData: () => void
  error: ApolloError | null
  loading: boolean
}

const ApolloAuthContext = React.createContext<AuthValue | null>(null)

export const setAuthData = (
  authData: LoginPayload & { userId?: Maybe<string | number> }
): void => {
  const { refreshToken, authToken } = authData
  const user = authData?.user ?? authDataVar()?.user
  const userId = authData?.userId ?? authDataVar()?.userId

  // memory
  authDataVar({ userId, user, refreshToken, authToken })
  // only save the needed data to localStorage
  // const saveUser: LoginPayload['user'] = {
  //   firstName: user?.firstName,
  //   lastName: user?.lastName,
  //   email: user?.email,
  //   id: user?.id,
  //   databaseId: user?.databaseId,
  //   capabilities: user?.capabilities,
  //   companies: user?.companies,
  //   divisions: user?.divisions
  // }
  // setSavedAuthData({ refreshToken, user, userId })
  setSavedAuthData({ refreshToken })
}

export const deleteAuthData = (): void => {
  // memory
  authDataVar({
    authToken: null,
    refreshToken: null,
    user: null,
    userId: null,
  })
  client.resetStore()
  // localStorage
  deleteSavedAuthData()
}

interface ApolloAuthProviderProps {
  children?: React.ReactNode
}

export const ApolloAuthProvider: React.FC<ApolloAuthProviderProps> = ({
  children,
}) => {
  const authData = useReactiveVar(authDataVar)

  // check if the user is logged in
  const isOnline = useNetwork()
  const refreshToken = authData?.refreshToken
  const authToken = authData?.authToken
  // if the user is online, check if they have an authToken
  // if the user is offline, check if they have a refreshToken
  const isLoggedIn = isOnline ? !!authToken : !!refreshToken

  const user = authData?.user
  const userId = authData?.userId ?? undefined
  const [loading, setLoading] = React.useState(!isLoggedIn)

  const [refreshAuthToken, { error }] = useRefreshAuthTokenMutation()

  const value: AuthValue = {
    isLoggedIn,
    user,
    userId,
    setAuthData,
    deleteAuthData,
    error: error ?? null,
    loading,
  }

  useDeepCompareEffect(() => {
    if (!isLoggedIn && refreshToken && isOnline) {
      refreshAuthToken({
        variables: {
          refreshToken,
        },
      })
        .then((results) => {
          const authToken = results?.data?.refreshJwtAuthToken?.authToken
          setAuthData({ ...authData, authToken })
          setLoading(false)
        })
        .catch(deleteAuthData)
    } else {
      setLoading(false)
    }
  }, [isLoggedIn, refreshToken, isOnline, authData, refreshAuthToken])

  return (
    <ApolloAuthContext.Provider value={value}>
      {children}
    </ApolloAuthContext.Provider>
  )
}

export const useApolloAuth = (): AuthValue | null =>
  React.useContext(ApolloAuthContext)
