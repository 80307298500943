import React from 'react'
import { SnackbarProvider, SnackbarKey, SnackbarProviderProps } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { AUTO_HIDE_DURATION } from '../../constants'

interface CloseSnackProps {
  closeSnackbar: () => void
}

export const CloseSnack: React.FC<CloseSnackProps> = ({
  closeSnackbar,
}: CloseSnackProps) => {
  return (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={() => closeSnackbar()}
      size="large"
    >
      <CloseIcon />
    </IconButton>
  )
}

interface SnackbarProviderComponentProps extends SnackbarProviderProps {}

const SnackbarProviderComponent: React.FC<SnackbarProviderComponentProps> = ({
  children,
  ...rest
}) => {
  const notistackRef = React.useRef<SnackbarProvider>(null)
  const closeSnack = (key: SnackbarKey) =>
    notistackRef?.current?.closeSnackbar(key)

  return (
    <SnackbarProvider
      autoHideDuration={AUTO_HIDE_DURATION}
      maxSnack={6}
      preventDuplicate
      ref={notistackRef}
      ariaAttributes={{
        'aria-labelledby': `notistack-snackbar`, // id of the inner div of the alert where the message text goes
      }}
      action={(key) => <CloseSnack closeSnackbar={() => closeSnack(key)} />}
      anchorOrigin={{ horizontal: `right`, vertical: `top` }}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  )
}

export default SnackbarProviderComponent
