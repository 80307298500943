import classNames from 'classnames'
import { Maybe, Wp_FormFieldTypeEnum } from '../../../types/generated-gatsby'

export const createFieldCssClass = (
  type?: Maybe<Wp_FormFieldTypeEnum>,
  isHidden?: boolean,
  cssClass?: Maybe<string>
): string => {
  const gfCssClasses = cssClass?.split(/\s+/)?.reduce((acc, cls) => {
    if (!cls) return acc
    return {
      ...acc,
      [cls]: true,
    }
  }, {})
  return classNames({
    // gfField: true,
    [`gfField-${type}`]: !!type,
    hidden: isHidden,
    ...gfCssClasses,
  })
}
