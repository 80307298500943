/**
 * Custom Post Type Singular Name
 */
export const EQUIPMENT_SINGLE_NAME = `equipment`
/**
 * Custom Post Type Plural Name
 */
export const EQUIPMENT_PLURAL_NAME = `equipment`
/**
 * Session Storage Key for filters
 */
export const EQUIPMENT_LIST_PAGE_FILTERS = `EQUIPMENT_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const EQUIPMENT_SLUG = `equipment`

/**
 * Meta Key
 */
export const EQUIPMENT_LAST_INSPECTION_DATE_KEY = `_last_inpsection_date` // this typo is expect!! DO NOT CHANGE UNLESS THE BACKEND CHANGES
export const EQUIPMENT_NEXT_INSPECTION_DATE_KEY = `_next_inspection_date`
