import CircularProgress from '@mui/material/CircularProgress'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import MuiAutocomplete, {
  AutocompleteInputChangeReason,
  createFilterOptions,
} from '@mui/material/Autocomplete'
import { debounce } from 'lodash'
import * as React from 'react'
import {
  Controller,
  ControllerProps,
  FieldError,
  useFormContext,
} from 'react-hook-form'
import { DEFAULT_VARIANT } from '../../../../constants'
import {
  Maybe,
  Wp_MultiSelectFieldChoice,
  Wp_SelectField,
  Wp_SelectFieldChoice,
} from '../../../../types/generated-gatsby'
import WarningAmber from '@mui/icons-material/WarningAmber'
import Box from '@mui/material/Box'
import useIsMobile from '../../../../hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'usehooks-ts'
import path from 'lodash/fp/path'
import split from 'lodash/fp/split'
import { isEmptyValue } from '../../../../utils/misc'

const filter = createFilterOptions<
  Wp_SelectFieldChoice & {
    /**
     * Will only be set when the autocomplete is free solo and the user inputs a new option
     */
    textValue?: string
  }
>()

export interface AutoCompleteProps
  extends Pick<
    Wp_SelectField,
    | 'label'
    | 'autocompleteAttribute'
    | 'hasAutocomplete'
    | 'cssClass'
    | 'description'
    | 'isRequired'
    | 'placeholder'
  > {
  defaultValue?:
    | Maybe<Maybe<Wp_MultiSelectFieldChoice>[]>
    | Maybe<Wp_SelectFieldChoice>
  fieldName: string
  htmlId: string
  isMultiSelect?: boolean
  /**
   * @deprecated favor cssClass.includes('readonly')
   */
  isDisabled?: boolean
  options: Maybe<Wp_SelectFieldChoice | Wp_MultiSelectFieldChoice>[]
  /**
   * @deprecated favour color prop
   */
  textFieldProps?: TextFieldProps
  color?: 'primary' | 'secondary'
  onInputChange?: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void
  loading?: boolean
  freeSolo?: Maybe<boolean>
  noOptionText?: string
  helperText?: string
  /**
   * Can pass additional field rules.
   * Example to pass validation for quiz field correct answer
   */
  additionalFieldRules?: ControllerProps['rules']
  maxSelections?: number
}

const AutoComplete: React.FC<AutoCompleteProps> = (props) => {
  const {
    cssClass,
    defaultValue,
    description,
    fieldName,
    htmlId,
    isMultiSelect = false,
    isRequired,
    isDisabled = false,
    label,
    options,
    textFieldProps,
    color = `primary`,
    loading = false,
    onInputChange,
    freeSolo = false,
    placeholder,
    noOptionText = `No Options Available`,
    helperText,
    autocompleteAttribute,
    hasAutocomplete,
    additionalFieldRules = {},
    maxSelections,
  } = props

  const { t } = useTranslation()
  const {
    control,
    clearErrors,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext()
  const fieldError = path(split(`.`, fieldName), errors) as FieldError
  const disabled = cssClass?.includes(`readonly`) || isDisabled
  const isMobile = useIsMobile()
  const isDefaultArray = Array.isArray(defaultValue)
  const safeDefaultValue = isDefaultArray
    ? isMultiSelect
      ? defaultValue
      : defaultValue[0] ?? undefined
    : defaultValue ?? undefined

  useEffectOnce(() => {
    // check if the value is empty by default then set to undefined
    // autocomplete needs a non-null value for auto population and dynamic population to work
    // however this was then breaking the required feature of the field when "empty" as RHF saw {} or []
    // Therefore remove the RHF value if empty
    const fieldValue = path(split(`.`, fieldName), getValues())
    if (
      (!safeDefaultValue || isEmptyValue(safeDefaultValue)) &&
      (!fieldValue || isEmptyValue(fieldValue))
    ) {
      setValue(fieldName, undefined)
    }
  })

  return (
    <Controller
      control={control}
      name={fieldName}
      // I have to set {} or [] as the default value of control for auto population to work correctly
      // as well as dynamic population based on selection of other fields
      defaultValue={safeDefaultValue}
      rules={{
        required: {
          value: !!isRequired,
          message: `${label} ${t(`is required`)}.`,
        },
        ...additionalFieldRules,
      }}
      render={({ field: fieldProp }) => {
        const debouncedOnChange = debounce(fieldProp?.onChange, 100)
        return (
          <MuiAutocomplete
            autoHighlight
            autoComplete
            // autoSelect
            aria-labelledby={htmlId}
            filterSelectedOptions
            id={htmlId}
            options={[...options]}
            // Must be set or will cause error to be show after selection made
            blurOnSelect={false}
            // helps the user to enter a new value
            selectOnFocus
            // helps the user clear the value
            clearOnBlur={false}
            // move focus inside the popup with the Home and End keys
            handleHomeEndKeys
            filterOptions={(filteredOptions, params) => {
              const filtered = filter(filteredOptions, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option?.text
              )
              if (
                inputValue !== `` &&
                !isExisting &&
                freeSolo &&
                filtered?.length === 0
              ) {
                filtered.push({
                  value: inputValue,
                  textValue: inputValue,
                  text: `Add "${inputValue}"`,
                })
              }
              return filtered
            }}
            multiple={isMultiSelect}
            freeSolo={freeSolo ?? false}
            isOptionEqualToValue={(option, value) => {
              if (typeof value === `string`) {
                return option?.value === value
              }
              return option?.value === value?.value
            }}
            noOptionsText={
              <Box display="flex" alignItems="center">
                <WarningAmber color="warning" sx={{ mr: 1 }} />
                {noOptionText}
              </Box>
            }
            {...fieldProp}
            // have to use custom blur to set the value to undefined for select fields to show the required error
            onBlur={(e) => {
              // @ts-expect-error yes it does, it will be the text input of the combo field. NOT the selection
              const value = e?.target?.value
              if (isRequired && isEmptyValue(value) && !isMultiSelect) {
                fieldProp?.onChange(undefined)
              }
              fieldProp?.onBlur()
            }}
            getOptionLabel={(option) => {
              return option?.text ?? option?.label ?? option?.value ?? ``
            }}
            onChange={(e, v) => {
              if (!!v) {
                clearErrors(fieldName)
              }
              if (Array.isArray(v)) {
                if (maxSelections && v.length > maxSelections) {
                  return
                }
                const safeValues = v?.map((value) => {
                  if (typeof value === `string`) {
                    return {
                      text: value,
                      value,
                    }
                  } else {
                    return {
                      // this handles when the user types in a custom option and we need to remove the Add ""
                      ...value,
                      text: value?.textValue || value?.text || value?.value,
                      value: value?.value,
                      textValue: value?.textValue,
                    }
                  }
                })
                return debouncedOnChange(safeValues)
              } else if (typeof v === `string`) {
                return debouncedOnChange({
                  text: v,
                  value: v,
                })
              } else if (v && (v?.text || v?.textValue)) {
                return debouncedOnChange({
                  ...v,
                  text: v?.textValue ?? v?.text,
                  value: v?.value,
                })
              } else {
                return debouncedOnChange(v)
              }
            }}
            loading={loading}
            disabled={disabled}
            // @ts-expect-error this is fine
            onInputChange={
              !isMultiSelect && freeSolo && !onInputChange
                ? (e, value) => {
                    e?.preventDefault()
                    if (typeof value === `string`) {
                      return debouncedOnChange({ text: value, value })
                    } else {
                      return debouncedOnChange(value)
                    }
                  }
                : onInputChange
            }
            limitTags={isMobile ? 3 : 6}
            // size={isMobile ? `small` : `medium`}
            renderInput={(params) => (
              <TextField
                {...params}
                required={Boolean(isRequired)}
                variant={DEFAULT_VARIANT}
                autoComplete={
                  hasAutocomplete ? autocompleteAttribute || `off` : `off`
                }
                label={label}
                placeholder={placeholder ?? ``}
                disabled={disabled}
                fullWidth
                error={!!fieldError}
                helperText={fieldError?.message ?? helperText ?? description}
                InputProps={{
                  ...params.InputProps,
                  required: false, // html input required does not read the multiselect values
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                {...textFieldProps}
                color={color}
              />
            )}
          />
        )
      }}
    />
  )
}

export default AutoComplete
