import { SOFT_TEXT_OPACITY } from '../constants'
export interface HarnessColors {
  green900: '#0E5814'
  green800: '#247726'
  green700: '#2f8730'
  green600: '#3a993b'
  green500: '#43a843'
  green400: '#5fb55f'
  green300: '#7bc17b'
  green200: '#a1d2a1'
  green100: '#c6e4c5'
  green50: '#e7f4e7'

  // New greys
  surfaceGrey: '#f7f7f7'
  lightGrey: '#fbfbfb'
  darkGrey: '#e5e5e5'
  heavyMetal: '#323532'
  ghostGray: '#c6cbd4'
  mediumGray: '#737373'
  softWhite: 'rgba(255, 255, 255, 0.72)'

  textPrimary: `#4d4d4d`
  textSecondary: `#5d5d5d`
  textTertiary: `#707070`
  // Reds + oranges
  alertRed: '#ff0016'
  orange900: '#eb4f00'
  orange800: '#ec7300'
  orange700: '#ed8400'
  orange600: '#ed9700'
  orange500: '#eca500'
  orange400: '#f0b100'
  orange300: '#f3bf34'
  orange200: '#f7d070'
  orange100: '#fbe2a8'
  orange50: '#fef4dc'
}

const harnessColors: HarnessColors = {
  green900: `#0E5814`,
  green800: `#247726`,
  green700: `#2f8730`,
  green600: `#3a993b`,
  green500: `#43a843`,
  green400: `#5fb55f`,
  green300: `#7bc17b`,
  green200: `#a1d2a1`,
  green100: `#c6e4c5`,
  green50: `#e7f4e7`,

  // New greys
  surfaceGrey: `#f7f7f7`,
  lightGrey: `#fbfbfb`,
  darkGrey: `#e5e5e5`,
  heavyMetal: `#323532`,
  ghostGray: `#c6cbd4`,
  mediumGray: `#737373`,
  softWhite: `rgba(255, 255, 255, 0.${SOFT_TEXT_OPACITY})` as const,

  textPrimary: `#4d4d4d`,
  textSecondary: `#5d5d5d`,
  textTertiary: `#707070`,

  // Reds + oranges
  alertRed: `#ff0016`,
  orange900: `#eb4f00`,
  orange800: `#ec7300`,
  orange700: `#ed8400`,
  orange600: `#ed9700`,
  orange500: `#eca500`,
  orange400: `#f0b100`,
  orange300: `#f3bf34`,
  orange200: `#f7d070`,
  orange100: `#fbe2a8`,
  orange50: `#fef4dc`,
}

export default harnessColors
