import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export interface LoginHeaderProps {
  mainText: string
  secondaryText?: string
}

const LoginHeader: React.FC<LoginHeaderProps> = (props) => {
  const { mainText, secondaryText } = props

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      my={4}
    >
      <StaticImage
        src={`../../images/safety-hq-logo.png`}
        alt="SafetyHQ Logo"
        layout="constrained"
        placeholder="dominantColor"
        height={120}
      />
      <Box mt={4} />
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        {mainText}
      </Typography>
      {secondaryText && (
        <Typography align="center" variant="h6">
          {secondaryText}
        </Typography>
      )}
    </Box>
  )
}

export default LoginHeader
