/**
 * Custom Post Type Singular Name
 */
export const DOCUMENT_SINGLE_NAME = `document`
/**
 * Custom Post Type Plural Name
 */
export const DOCUMENT_PLURAL_NAME = `documents`
/**
 * Session Storage Key for filters
 */
export const DOCUMENT_LIST_PAGE_FILTERS = `DOCUMENT_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const DOCUMENT_SLUG = `documents`
