import Box from '@mui/material/Box'
import { TextFieldProps } from '@mui/material/TextField'
import debounce from 'awesome-debounce-promise'
import { useSnackbar } from 'notistack'
import React from 'react'
import { DEBOUNCE_TIMEOUT, SEARCH_PAGE_SIZE } from '../../../../constants'
import useIsVisible from '../../../../hooks/forms/useIsVisible'
import {
  Equipment,
  EquipmentStatusEnum,
  Maybe,
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
  RelationEnum,
  RootQueryToEquipmentConnectionEdge,
  RootQueryToEquipmentConnectionWhereArgs,
  RootQueryToEquipmentConnectionWhereArgsTaxQueryField,
  RootQueryToEquipmentConnectionWhereArgsTaxQueryOperator,
  TaxonomyEnum,
  useAllEquipmentQuery,
} from '../../../../types/generated'
import {
  Wp_FormFieldTypeEnum,
  Wp_MultiSelectField,
  Wp_MultiSelectFieldChoice,
  Wp_SelectField,
  Wp_SelectFieldChoice,
} from '../../../../types/generated-gatsby'
import { CommonFieldProps } from '../../../../types/gf/fields/CommonFieldProps'
import { SelectOptions } from '../../../../types/gravityForms'
import { useFormTranlation } from '../../../providers/Form/formTranslation'
import { useNetwork } from '../../../providers/Network'
import { useOfflineData } from '../../../providers/OfflineMode'
import AutoComplete, { AutoCompleteProps } from '../Common/AutoComplete'

interface EquipmentSearchFieldProps extends CommonFieldProps {
  field: Omit<Wp_SelectField | Wp_MultiSelectField, 'id'> & {
    id: number | string
  }
  textFieldProps?: TextFieldProps
  defaultValue?: AutoCompleteProps['defaultValue']
  isMultiSelect?: AutoCompleteProps['isMultiSelect']
  noOptionText?: AutoCompleteProps['noOptionText']
  isDisabled?: boolean
}

const OFFLINE_NO_OPTION_TEXT = `Options enabled with Equipment Offline Mode Collection`

export const createEquipmentSelectOption = (
  edge: Maybe<{
    node?: Maybe<Pick<Equipment, 'title' | 'databaseId' | 'serialNumber'>>
  }>
): Pick<Wp_SelectFieldChoice, 'text' | 'value'> => {
  return {
    text: `${edge?.node?.title}${
      edge?.node?.serialNumber ? ` - ${edge?.node?.serialNumber}` : ``
    }`,
    value: edge?.node?.databaseId?.toString(),
  }
}

export const EQUIPMENT_DEFAULT_WHERE_ARGS: RootQueryToEquipmentConnectionWhereArgs =
  {
    orderby: [
      {
        field: PostObjectsConnectionOrderbyEnum[`Modified`],
        order: OrderEnum[`Desc`],
      },
    ],
    taxQuery: {
      relation: RelationEnum[`And`],
      taxArray: [
        {
          operator:
            RootQueryToEquipmentConnectionWhereArgsTaxQueryOperator[`NotIn`],
          taxonomy: TaxonomyEnum[`Equipmentstatus`],
          field: RootQueryToEquipmentConnectionWhereArgsTaxQueryField[`Slug`],
          terms: [EquipmentStatusEnum[`NotInService`]],
        },
      ],
    },
  }

const EquipmentSearchField: React.FC<EquipmentSearchFieldProps> = (props) => {
  const {
    field,
    textFieldProps,
    isMultiSelect,
    defaultValue: defaultValueProp,
    noOptionText,
    isDisabled = false,
    formId,
  } = props
  const {
    id,
    type,
    label,
    description,
    cssClass,
    isRequired,
    choices,
    allowCustomOption,
    choiceTranslations,
  } = field
  const [isLoading, setIsLoading] = React.useState(false)
  const htmlId = `field_${formId}_${id}`
  const { enqueueSnackbar } = useSnackbar()
  const isOnline = useNetwork()
  const { fieldTranslation } = useFormTranlation(choiceTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description

  const defaultChoices =
    (
      useOfflineData()?.offlineData
        ?.Equipment as RootQueryToEquipmentConnectionEdge[]
    )?.map(createEquipmentSelectOption) ?? choices

  const defaultValue =
    defaultValueProp ??
    (choices as Wp_MultiSelectFieldChoice[])?.filter(
      (choice) => choice?.isSelected
    ) ??
    []
  const { isVisible, isHidden } = useIsVisible(field)

  const { loading, data, refetch } = useAllEquipmentQuery({
    variables: {
      first: SEARCH_PAGE_SIZE,
      where: EQUIPMENT_DEFAULT_WHERE_ARGS,
    },
    notifyOnNetworkStatusChange: true,
    skip: !isOnline || isDisabled,
    fetchPolicy: `network-only`,
  })

  const fieldName = String(id)

  const getAsyncOptions = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e?.target?.value

    if (isOnline) {
      try {
        const result = await refetch?.({
          first: SEARCH_PAGE_SIZE,
          where: {
            ...EQUIPMENT_DEFAULT_WHERE_ARGS,
            orderby: undefined,
            search: inputValue || undefined,
          },
        })

        if (result?.error) {
          enqueueSnackbar(`Error fetching equipment from server`, {
            variant: `warning`,
          })
        }
      } catch (err) {
        enqueueSnackbar(`Error fetching equipment from server`, {
          variant: `warning`,
        })
      }
    }
    setIsLoading(false)
  }

  const debouncedLoadOptions = debounce(getAsyncOptions, DEBOUNCE_TIMEOUT)

  const options: SelectOptions =
    data?.allEquipment?.edges?.map(createEquipmentSelectOption) ??
    defaultChoices

  return isVisible ? (
    <Box
      mb={3}
      className={`gfield gfield-${type} ${
        isHidden && `hidden`
      } ${cssClass}`.trim()}
    >
      <AutoComplete
        defaultValue={defaultValue}
        fieldName={fieldName}
        htmlId={htmlId}
        label={safeLabel}
        options={options}
        cssClass={cssClass}
        description={safeDescription}
        isMultiSelect={
          isMultiSelect || type === Wp_FormFieldTypeEnum.Multiselect
        }
        isRequired={!isHidden && !!isRequired}
        isDisabled={isDisabled}
        textFieldProps={textFieldProps}
        onInputChange={(e) => {
          setIsLoading(true)
          // @ts-expect-error this is fine
          debouncedLoadOptions(e)
        }}
        loading={loading || isLoading}
        freeSolo={allowCustomOption}
        noOptionText={!isOnline ? OFFLINE_NO_OPTION_TEXT : noOptionText}
      />
    </Box>
  ) : null
}

export default EquipmentSearchField
