import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { LanguageCodeEnum, Maybe } from '../types/generated'

/**
 * Given the language slug (en_us) will convert to the LanguageCodeEnum key
 *
 * @param lang language slug
 * @returns keyof typeof LanguageCodeEnum
 */
const userLanguageToEnumKey = (
  lang?: Maybe<string>
): keyof typeof LanguageCodeEnum => {
  if (!lang) {
    return `EnUs`
  }
  return upperFirst(camelCase(lang)) as 'EnUs' | 'EsMx'
}

export default userLanguageToEnumKey
