import Box from '@mui/material/Box'
import { useEventListener } from 'usehooks-ts'
import { Button } from 'gatsby-material-ui-components'
import { capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { LocalDraftEntry, LocalDraftEntryStatusEnum } from '../../types/drafts'
import { useGfSubmitFormMutation } from '../../types/generated'
import formSubmitHanlder from '../../utils/gravityForms/formSubmitHandler'
import { LogoComponent } from '../Common/Logos/LogoComponent'
import LoadingDialog from '../Common/Modals/LoadingDialog'
import { useDraftEntries } from './DraftEntriesProvider'
import { CloseSnack } from './Snackbar'

interface FormSubmitSyncProviderProps {
  children?: React.ReactNode
}

const FormSubmitSyncContext = React.createContext(null)

const FormSubmitSyncProvider: React.FC<FormSubmitSyncProviderProps> = ({
  children,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const {
    localDraftEntries,
    updateDraftEntry,
    getFollowUpFormDraftEntries,
    removeDraftEntries,
  } = useDraftEntries()
  const [open, setOpen] = React.useState(false)

  const [submitForm, { loading }] = useGfSubmitFormMutation()
  const draftEntries = Object.values(localDraftEntries)

  const checkAndSubmitForms = () => {
    const online = window?.navigator?.onLine ?? false
    const filteredDrafts = draftEntries?.filter(
      (d) => d?.status === LocalDraftEntryStatusEnum.AwaitingOnlineStatus
    )
    const draftCount = filteredDrafts?.length

    if (online && draftCount > 0) {
      enqueueSnackbar(`${draftCount} drafts ready for submission.`, {
        persist: true,
        action: (key) => (
          <Box>
            <Button
              color="secondary"
              onClick={() => {
                if (draftCount > 0) {
                  setOpen(true)
                  const promises = filteredDrafts.map((draft) => {
                    return formSubmitHanlder({
                      submitForm,
                      updatedDraftEntry: draft,
                      enqueueSnackbar,
                      closeSnackbar,
                      getFollowUpFormDraftEntries,
                      isOnline: online,
                      updateDraftEntry,
                      removeDraftEntries,
                      type: `new`,
                    })
                  })

                  Promise.allSettled(promises).finally(() => {
                    closeSnackbar(key)
                    setOpen(false)
                  })
                }
              }}
            >
              Submit?
            </Button>
            <CloseSnack closeSnackbar={() => closeSnackbar(key)} />
          </Box>
        ),
      })
    }
  }

  const checkCPTDraftsReadyForSubmit = () => {
    const online = window?.navigator?.onLine ?? false
    const filteredDrafts = draftEntries?.filter(
      (d) => d?.status === LocalDraftEntryStatusEnum.ReadyForSubmission
    )
    const draftCount = filteredDrafts?.length
    if (draftCount <= 0) {
      return
    }

    const draftByCPT = filteredDrafts?.reduce<
      Record<string, LocalDraftEntry[]>
    >((acc, draft) => {
      const formSlug = draft?.form?.slug ?? ``
      return {
        ...acc,
        [formSlug]: [...(acc?.[formSlug] ?? []), draft],
      }
    }, {})

    Object.keys(draftByCPT)?.forEach((formSlugKey) => {
      const drafts = draftByCPT[formSlugKey]
      const count = drafts?.length
      if (online && count > 0) {
        enqueueSnackbar(
          `${count} ${capitalize(
            formSlugKey.replaceAll(`-`, ` `)
          )} draft(s) ready for submission`,
          {
            persist: true,

            action: (key) => (
              <Box>
                <Button color="secondary" to={`/forms/${formSlugKey}/drafts`}>
                  Drafts
                </Button>
                <CloseSnack closeSnackbar={() => closeSnackbar(key)} />
              </Box>
            ),
          }
        )
      }
    })
  }

  useEventListener(`online`, checkAndSubmitForms)
  useEventListener(`online`, checkCPTDraftsReadyForSubmit)

  return (
    <FormSubmitSyncContext.Provider value={null}>
      <LoadingDialog
        open={open || loading}
        logoComponent={LogoComponent}
        loadingList={
          draftEntries?.map((entry) => ({
            id: entry?.draftId?.toString() ?? ``,
            text: `Draft #${entry?.draftId}`,
            state: `loading`,
          })) ?? []
        }
        mainText="Submitting forms to the server now that the device is online."
      />
      {children}
    </FormSubmitSyncContext.Provider>
  )
}

// export const useFormSubmitSync = (): boolean | null =>
//   React.useContext(FormSubmitSyncContext)

export default FormSubmitSyncProvider
