import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'
import * as React from 'react'
import {
  HARNESS_OFFLINE_CORRECTIVE_ACTIONS,
  HARNESS_OFFLINE_DOCUMENTS,
  HARNESS_OFFLINE_EQUIPMENT,
  HARNESS_OFFLINE_LESSONS,
  HARNESS_OFFLINE_PROJECTS,
  HARNESS_OFFLINE_TOOLBOX_TALKS,
  HARNESS_OFFLINE_WORKERS,
} from '../../constants'
import useOfflineCorrectiveActions from '../../hooks/offlineData/useOfflineCorrectiveActions'
import useOfflineDocuments from '../../hooks/offlineData/useOfflineDocuments'
import useOfflineEquipment from '../../hooks/offlineData/useOfflineEquipment'
import useOfflineLessons from '../../hooks/offlineData/useOfflineLessons'
import useOfflineProjects from '../../hooks/offlineData/useOfflineProjects'
import useOfflineToolboxTalks from '../../hooks/offlineData/useOfflineToolboxTalks'
import useOfflineWorkers from '../../hooks/offlineData/useOfflineWorkers'
import useLocalForage from '../../hooks/useLocalForage'
import {
  Maybe,
  OfflineModeDataCollectionNameEnum,
  RootQueryToCorrectiveActionConnectionEdge,
  RootQueryToDocumentConnectionEdge,
  RootQueryToEquipmentConnectionEdge,
  RootQueryToLessonConnectionEdge,
  RootQueryToProjectConnectionEdge,
  RootQueryToToolboxTalkConnectionEdge,
  RootQueryToUserConnectionEdge,
} from '../../types/generated'
import {
  OfflineDataStoredValue,
  UseOfflineDataReturnValue,
} from '../../types/offlineData'
import { LogoComponent } from '../Common/Logos/LogoComponent'
import LoadingDialog, {
  LoadingDialogProps,
} from '../Common/Modals/LoadingDialog'

export interface OfflineDataContextValue {
  handleDownload: (
    collectionNames: Maybe<OfflineModeDataCollectionNameEnum>[]
  ) => Promise<void>
  offlineData?: OfflineDataStoredValue
  clearData: () => Promise<void>
  error?: Error | null
}

export const OfflineDataContext =
  React.createContext<OfflineDataContextValue | null>(null)

/**
 * List of Data collection names for offline use.
 * Capitalized names.
 */
const offlineDataCollectionNames = Object.keys(
  OfflineModeDataCollectionNameEnum
).map((name) => name) as OfflineModeDataCollectionNameEnum[]

interface OfflineProviderProps {
  children?: React.ReactNode
}

const OfflineDataProvider: React.FC<OfflineProviderProps> = ({ children }) => {
  const [
    projectOfflineData,
    setProjectOfflineData,
    removeProjectOfflineData,
    projectOfflineDataError,
  ] = useLocalForage<RootQueryToProjectConnectionEdge[]>(
    HARNESS_OFFLINE_PROJECTS,
    []
  )
  const [
    workerOfflineData,
    setWorkerOfflineData,
    removeWorkerOfflineData,
    workerOfflineDataError,
  ] = useLocalForage<RootQueryToUserConnectionEdge[]>(
    HARNESS_OFFLINE_WORKERS,
    []
  )
  const [
    toolboxTalkOfflineData,
    setToolboxTalkOfflineData,
    removeToolboxTalkOfflineData,
    toolboxTalkOfflineDataError,
  ] = useLocalForage<RootQueryToToolboxTalkConnectionEdge[]>(
    HARNESS_OFFLINE_TOOLBOX_TALKS,
    []
  )
  const [
    documentOfflineData,
    setDocumentOfflineData,
    removeDocumentOfflineData,
    documentOfflineDataError,
  ] = useLocalForage<RootQueryToDocumentConnectionEdge[]>(
    HARNESS_OFFLINE_DOCUMENTS,
    []
  )
  const [
    equipmentOfflineData,
    setEquipmentOfflineData,
    removeEquipmentOfflineData,
    equipmentOfflineDataError,
  ] = useLocalForage<RootQueryToEquipmentConnectionEdge[]>(
    HARNESS_OFFLINE_EQUIPMENT,
    []
  )

  const [
    lessonOfflineData,
    setLessonOfflineData,
    removeLessonOfflineData,
    lessonOfflineDataError,
  ] = useLocalForage<RootQueryToLessonConnectionEdge[]>(
    HARNESS_OFFLINE_LESSONS,
    []
  )

  const [
    correvtiveActionOfflineData,
    setCorrectiveActionOfflineData,
    removeCorrectiveActionOfflineData,
    correctiveActionOfflineDataError,
  ] = useLocalForage<RootQueryToCorrectiveActionConnectionEdge[]>(
    HARNESS_OFFLINE_CORRECTIVE_ACTIONS,
    []
  )

  const offlineWorkers = useOfflineWorkers(setWorkerOfflineData)
  const offlineProjects = useOfflineProjects(setProjectOfflineData)
  const offlineToolboxTalks = useOfflineToolboxTalks(setToolboxTalkOfflineData)
  const offlineDocuments = useOfflineDocuments(setDocumentOfflineData)
  const offlineEquipment = useOfflineEquipment(setEquipmentOfflineData)
  const offlineLessons = useOfflineLessons(setLessonOfflineData)
  const offlineCorrectiveActions = useOfflineCorrectiveActions(
    setCorrectiveActionOfflineData
  )

  const offlineData: Record<
    OfflineModeDataCollectionNameEnum,
    UseOfflineDataReturnValue | undefined
  > = {
    Workers: offlineWorkers,
    Projects: offlineProjects,
    ToolboxTalks: offlineToolboxTalks,
    Documents: offlineDocuments,
    Equipment: offlineEquipment,
    Lessons: offlineLessons,
    CorrectiveActions: offlineCorrectiveActions,
    Photos: undefined,
    Certifications: undefined,
  }

  const workersLoading = offlineWorkers?.loading
  const projectsLoading = offlineProjects?.loading
  const documentsLoading = offlineDocuments?.loading
  const equipmentLoading = offlineEquipment?.loading
  const toolboxTalksLoading = offlineToolboxTalks?.loading
  const lessonsLoading = offlineLessons?.loading
  const caLoading = offlineCorrectiveActions?.loading
  const open =
    workersLoading ||
    projectsLoading ||
    toolboxTalksLoading ||
    documentsLoading ||
    equipmentLoading ||
    lessonsLoading ||
    caLoading

  const loadingList = offlineDataCollectionNames?.reduce<
    LoadingDialogProps['loadingList']
  >((acc, name) => {
    const offline = offlineData[name]
    if (offline?.called && acc) {
      const state = offline?.loading
        ? `loading`
        : offline?.done
        ? `completed`
        : `pending`
      return [
        ...acc,
        {
          id: snakeCase(name),
          text: startCase(name),
          state: state,
        },
      ]
    } else {
      return acc
    }
  }, [])

  const clearData = async () => {
    removeProjectOfflineData()
    removeWorkerOfflineData()
    removeToolboxTalkOfflineData()
    removeDocumentOfflineData()
    removeEquipmentOfflineData()
    removeLessonOfflineData()
    removeCorrectiveActionOfflineData()
  }

  const handleDownload = async (
    collectionNames?: Maybe<OfflineModeDataCollectionNameEnum>[]
  ) => {
    // need a way to set which collections are being downloading in the loadingList besides the called property as this stay true after the first call.
    // await clearData()
    collectionNames?.forEach((name) => {
      if (name) {
        offlineData?.[name]?.getOfflineData?.()
      }
    })
  }

  return (
    <OfflineDataContext.Provider
      value={{
        handleDownload,
        offlineData: {
          Projects: projectOfflineData,
          Workers: workerOfflineData,
          ToolboxTalks: toolboxTalkOfflineData,
          Documents: documentOfflineData,
          Equipment: equipmentOfflineData,
          Lessons: lessonOfflineData,
          CorrectiveActions: correvtiveActionOfflineData,
        },
        clearData,
        error:
          workerOfflineDataError ||
          projectOfflineDataError ||
          toolboxTalkOfflineDataError ||
          documentOfflineDataError ||
          equipmentOfflineDataError ||
          lessonOfflineDataError ||
          correctiveActionOfflineDataError,
      }}
    >
      <LoadingDialog
        open={open}
        logoComponent={LogoComponent}
        loadingList={loadingList}
      />

      {children}
    </OfflineDataContext.Provider>
  )
}

export const useOfflineData = (): OfflineDataContextValue | null =>
  React.useContext(OfflineDataContext)

export default OfflineDataProvider
