import {
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
  PostObjectsConnectionOrderbyInput,
} from '../../types/generated'

export const DEFAULT_ORDERING: PostObjectsConnectionOrderbyInput[] = [
  {
    order: OrderEnum[`Desc`],
    field: PostObjectsConnectionOrderbyEnum[`Modified`],
  },
]

export * from './certifications'
export * from './correctiveActions'
export * from './documents'
export * from './equipment'
export * from './incidentReports'
export * from './lessons'
export * from './projects'
export * from './toolboxTalks'
export * from './workers'
export * from './photos'
