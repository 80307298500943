export function isEmptyValue(value: unknown) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return true
  }

  if (typeof value === `object`) {
    if (value instanceof Date) {
      return value.toString() === `Invalid Date`
    }
    return Object.keys(value).length === 0
  }

  if (typeof value === `string`) {
    return value.trim().length === 0
  }

  return false
}

/**
 * Returns the url for the form entries
 *
 * With a leading slash and without a trailing slash
 *
 * @param slug string
 * @returns string
 */
export function formEntriesUrl(slug: string) {
  return `/forms/${slug}/entries`
}
