/**
 * Custom Post Type Singular Name
 */
export const TOOLBOX_TALK_SINGLE_NAME = `toolbox_talk`
/**
 * Custom Post Type Plural Name
 */
export const TOOLBOX_TALK_PLURAL_NAME = `toolbox_talks`
/**
 * Session Storage Key for filters
 */
export const TOOLBOX_TALKS_LIST_PAGE_FILTERS = `TOOLBOX_TALKS_LIST_PAGE_FILTERS`
/**
 * CPT Pages Url Slug
 */
export const TOOLBOX_TALK_SLUG = `toolbox-talk`

/**
 * Record a Toolbox Talk GF Form Slug
 */
export const TOOLBOX_TALK_RECORD_SLUG = `toolbox-talks`
/**
 * Send a Toolbox Talk notification GF Form Slug
 */
export const TOOLBOX_TALK_NOTIFICATION_SLUG = `toolbox-talk-notifications`
