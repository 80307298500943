import { Wp_AddressFieldCountryEnum } from '../../../types/generated-gatsby'
import i18n from '../../../services/translations/i18n'
import { kebabCase } from 'lodash'
import { AddressFieldCountryEnum } from '../../../types/generated'

export const getCountryLabelFromCode = (
  code: Wp_AddressFieldCountryEnum | AddressFieldCountryEnum
): string | undefined => {
  const regionNames = new Intl.DisplayNames(kebabCase(i18n?.language ?? `en`), {
    type: `region`,
  })
  return regionNames.of(code)
}
