import { useReactiveVar } from '@apollo/client'
import Box from '@mui/material/Box'
import { TextFieldProps } from '@mui/material/TextField'
import debounce from 'awesome-debounce-promise'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { DEBOUNCE_TIMEOUT, SEARCH_PAGE_SIZE } from '../../../../constants'
import useAllLanguages from '../../../../graphql/gatsby/general/useAllLanguages'
import useIsVisible from '../../../../hooks/forms/useIsVisible'
import { authDataVar } from '../../../../services/apollo/cache'
import {
  LanguageCodeEnum,
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
  PostStatusEnum,
  RootQueryToToolboxTalkConnectionEdge,
  RootQueryToToolboxTalkConnectionWhereArgs,
  useAllToolboxTalksQuery,
} from '../../../../types/generated'
import {
  Maybe,
  Wp_FormFieldTypeEnum,
  Wp_MultiSelectField,
  Wp_MultiSelectFieldChoice,
  Wp_SelectField,
  Wp_SelectFieldChoice,
} from '../../../../types/generated-gatsby'
import { CommonFieldProps } from '../../../../types/gf/fields/CommonFieldProps'
import { getBasedOnFieldName } from '../../../../utils/gravityForms/getBasedOnFieldName'
import { languageSlugToEnum } from '../../../../utils/languageSlugToEnum'
import userLanguageToEnumKey from '../../../../utils/userLanguageToEnumKey'
import { useFormTranlation } from '../../../providers/Form/formTranslation'
import { useNetwork } from '../../../providers/Network'
import { useOfflineData } from '../../../providers/OfflineMode'
import AutoComplete, { AutoCompleteProps } from '../Common/AutoComplete'

export interface ToolboxTalkSearchFieldProps extends CommonFieldProps {
  field: Omit<Wp_SelectField | Wp_MultiSelectField, 'id'> & {
    id: number | string
  }
  textFieldProps?: TextFieldProps
  isMultiSelect?: AutoCompleteProps['isMultiSelect']
  defaultValue?: AutoCompleteProps['defaultValue']
  noOptionText?: AutoCompleteProps['noOptionText']
  isDisabled?: boolean
}

const OFFLINE_NO_OPTION_TEXT = `Options enabled with Toolbox Talks Offline Mode Collection`

export const TOOLBOX_TALK_DEFAULT_WHERE_ARGS: RootQueryToToolboxTalkConnectionWhereArgs =
  {
    orderby: [
      {
        field: PostObjectsConnectionOrderbyEnum.Date,
        order: OrderEnum.Desc,
      },
      {
        field: PostObjectsConnectionOrderbyEnum.Title,
        order: OrderEnum.Asc,
      },
    ],
    status: PostStatusEnum[`Publish`],
  }

export const createToolboxTalkSelectOption = (
  edge?: Maybe<RootQueryToToolboxTalkConnectionEdge>
): Pick<Wp_SelectFieldChoice, 'text' | 'value'> => {
  return {
    text: edge?.node?.title,
    value: edge?.node?.databaseId?.toString(),
  }
}

const ToolboxTalkSearchField: React.FC<ToolboxTalkSearchFieldProps> = (
  props
) => {
  const {
    field,
    textFieldProps,
    isMultiSelect,
    defaultValue: defaultValueProp,
    noOptionText,
    isDisabled = false,
    formId,
  } = props
  const {
    id,
    type,
    label,
    description,
    cssClass,
    isRequired,
    choices,
    allowCustomOption,
    choiceTranslations,
  } = field
  const [isLoading, setIsLoading] = React.useState(false)
  const htmlId = `field_${formId}_${id}`
  const { enqueueSnackbar } = useSnackbar()
  const isOnline = useNetwork()
  const { fieldTranslation } = useFormTranlation(choiceTranslations)
  const safeLabel = fieldTranslation?.label || label
  const safeDescription = fieldTranslation?.description || description
  const allLanguages = useAllLanguages()?.wp?.languages

  const { control } = useFormContext()

  const reverserFilter = cssClass?.includes(`reverse-filter`) ?? false
  const basedOnFieldName = getBasedOnFieldName(cssClass)
  const selectedLanguageValue = useWatch({
    name: basedOnFieldName ?? ``,
    control,
    disabled: !basedOnFieldName,
    defaultValue: basedOnFieldName ? LanguageCodeEnum[`EnUs`] : null,
  }) as LanguageCodeEnum | null

  const defaultChoices =
    (
      useOfflineData()?.offlineData
        ?.ToolboxTalks as RootQueryToToolboxTalkConnectionEdge[]
    )?.map(createToolboxTalkSelectOption) ?? choices

  const user = useReactiveVar(authDataVar)?.user
  const defaultValue =
    defaultValueProp ??
    (choices as Wp_MultiSelectFieldChoice[])?.filter(
      (choice) => choice?.isSelected
    ) ??
    []
  const { isVisible, isHidden } = useIsVisible(field)

  const userLangSlug = user?.languages?.edges?.[0]?.node?.slug
  const removeTBTsWithTranslation = cssClass?.includes(`only-no-translation`)

  // if there is a basedOnFieldName then use it instead of the user's language to filter the search results
  const langArrayFilter =
    basedOnFieldName && selectedLanguageValue
      ? [selectedLanguageValue]
      : userLangSlug
      ? [LanguageCodeEnum[userLanguageToEnumKey(userLangSlug)]]
      : null

  // check to see if the search result should inculde the based-on languages or the opposite of that
  const finalFilter = reverserFilter
    ? allLanguages
        ?.map((l) => languageSlugToEnum(l?.slug))
        ?.filter((langCode) => {
          return !langArrayFilter?.includes(langCode)
        })
    : langArrayFilter

  const whereArgs: RootQueryToToolboxTalkConnectionWhereArgs = {
    languages: finalFilter,
    ...TOOLBOX_TALK_DEFAULT_WHERE_ARGS,
  }

  const { loading, data, refetch } = useAllToolboxTalksQuery({
    variables: {
      first: SEARCH_PAGE_SIZE,
      where: whereArgs,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `network-only`,
    skip: !isOnline || isDisabled,
  })

  const fieldName = String(id)

  const getAsyncOptions = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e?.target?.value

    if (isOnline) {
      try {
        const result = await refetch?.({
          first: SEARCH_PAGE_SIZE,
          where: {
            ...whereArgs,
            search: inputValue || undefined,
            orderby: undefined,
          },
        })

        if (result?.error) {
          enqueueSnackbar(`Error fetching toolbox talks from server`, {
            variant: `warning`,
          })
        }
      } catch (err) {
        enqueueSnackbar(`Error fetching toolbox talks from server`, {
          variant: `warning`,
        })
      }
    }
    setIsLoading(false)
  }
  const debouncedLoadOptions = debounce(getAsyncOptions, DEBOUNCE_TIMEOUT)

  const edges = data?.toolboxTalks?.edges
  // check to see if only Toolbox Talks without translations should be included in the searched results
  const cleanData = removeTBTsWithTranslation
    ? edges?.filter((edge) => {
        return !edge?.node?.translations?.length
      })
    : edges
  const options =
    // @ts-expect-error this is fine
    cleanData?.map(createToolboxTalkSelectOption) ?? defaultChoices

  return isVisible ? (
    <Box
      mb={3}
      className={`gfield gfield-${type} ${
        isHidden && `hidden`
      } ${cssClass}`.trim()}
    >
      <AutoComplete
        defaultValue={defaultValue}
        fieldName={fieldName}
        htmlId={htmlId}
        label={safeLabel}
        options={options}
        cssClass={cssClass}
        description={safeDescription}
        isMultiSelect={
          isMultiSelect || type === Wp_FormFieldTypeEnum.Multiselect
        }
        isRequired={!isHidden && !!isRequired}
        isDisabled={isDisabled}
        textFieldProps={textFieldProps}
        onInputChange={(e) => {
          setIsLoading(true)
          // @ts-expect-error this is fine
          debouncedLoadOptions(e)
        }}
        loading={loading || isLoading}
        freeSolo={allowCustomOption}
        noOptionText={!isOnline ? OFFLINE_NO_OPTION_TEXT : noOptionText}
      />
    </Box>
  ) : null
}

export default ToolboxTalkSearchField
