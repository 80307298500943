import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import LoginHeader from '../Login/LoginHeader'

const reload = () => {
  window?.location?.reload()
}

const ErrorFallBack: React.FC<FallbackProps> = () => {
  const logout = () => {
    if (window) {
      localStorage?.clear()
      window.location.href = `/login`
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <LoginHeader mainText="Oops!" secondaryText="Something went wrong..." />
        <Button variant="contained" color="primary" onClick={reload}>
          Reload
        </Button>
        <Box mt={2} />
        <Button variant="outlined" color="primary" onClick={logout}>
          Log in again
        </Button>
      </Box>
    </Container>
  )
}

interface ErrorWrapperProps {
  children?: React.ReactNode
}

const ErrorWrapper: React.FC<ErrorWrapperProps> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallBack}>{children}</ErrorBoundary>
  )
}

export default ErrorWrapper
