// export const FIELD_KEY_PERSONAL_CSS_CLASS = `field-key-personnel`
// Field Identifiers
export const FIELD_PROJECT_ID_CSS_CLASS = `field-project-id`
export const FIELD_COMPANY_CSS_CLASS = `field-company`
export const FIELD_DIVISION_CSS_CLASS = `field-division`

// CPT search
export const FIELD_PROJECTS_SEARCH_CSS_CLASS = `search-projects`
export const FIELD_DOCUMENTS_SEARCH_CSS_CLASS = `search-documents`
export const FIELD_EQUIPMENT_SEARCH_CSS_CLASS = `search-equipment`
// export const FIELD_PROJECTS_POPULATE_CSS_CLASS = `populate-projects`
// export const FIELD_WORKERS_SEARCH_CSS_CLASS = `search-workers`
// Taxonomy Search
export const FIELD_COMPANY_SEARCH_CSS_CLASS = `search-companies`
export const FIELD_DIVISION_SEARCH_CSS_CLASS = `search-divisions`

// taxonomies
export const FIELD_MANUFACTURERS_SEARCH_CSS_CLASS = `search-manufacturers`
// this had to be different due to conflict with the `search-equipment` css class
export const FIELD_EQUIPMENT_TAGS_SEARCH_CSS_CLASS = `search-tags-equipment-tags`
