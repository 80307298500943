import { useSnackbar } from 'notistack'
import * as React from 'react'

const NetworkContext = React.createContext<boolean | null>(null)

interface NetworkProviderProps {
  children?: React.ReactNode
}

const NetworkProvider: React.FC<NetworkProviderProps> = ({ children }) => {
  const initialValue =
    typeof window !== `undefined` ? window.navigator.onLine : true
  const [isOnline, setNetwork] = React.useState(initialValue)
  const { enqueueSnackbar } = useSnackbar()

  const updateNetwork = React.useCallback(() => {
    const online = window?.navigator?.onLine ?? false
    if (!online) {
      enqueueSnackbar(`Device Offline`, { variant: `error` })
    } else {
      enqueueSnackbar(`Device back Online`)
    }
    setNetwork(window?.navigator?.onLine)
  }, [enqueueSnackbar])

  React.useEffect(() => {
    if (typeof window === `undefined`) {
      return
    }

    window.addEventListener(`offline`, updateNetwork)
    window.addEventListener(`online`, updateNetwork)

    return () => {
      window.removeEventListener(`offline`, updateNetwork)
      window.removeEventListener(`online`, updateNetwork)
    }
  }, [updateNetwork])

  return (
    <NetworkContext.Provider value={isOnline}>
      {children}
    </NetworkContext.Provider>
  )
}

/**
 * Returns a value to show if the application is online or not
 * @returns boolean
 */
export const useNetwork = (): boolean | null => React.useContext(NetworkContext)

export default NetworkProvider
