import { useReactiveVar } from '@apollo/client'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { TOOLBOX_TALK_DEFAULT_WHERE_ARGS } from '../../components/GravityForms/Fields/searchers/ToolboxTalkSearchField'
import { OFFLINE_DATA_BATCH_SIZE } from '../../constants'
import { authDataVar } from '../../services/apollo/cache'
import {
  LanguageCodeFilterEnum,
  RootQueryToToolboxTalkConnectionEdge,
  useAllToolboxTalksLazyQuery,
} from '../../types/generated'
import { UseOfflineDataReturnValue } from '../../types/offlineData'
import usePrevious from '../usePrevious'

const useOfflineToolboxTalks = (
  setOfflineData: React.AsyncDispatch<
    React.SetStateAction<RootQueryToToolboxTalkConnectionEdge[]>
  >
): UseOfflineDataReturnValue => {
  const { enqueueSnackbar } = useSnackbar()
  const batchSize = OFFLINE_DATA_BATCH_SIZE
  const [fetching, setFetching] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)
  const user = useReactiveVar(authDataVar)?.user
  const userLanguage = user?.languages?.edges?.[0]?.node?.slug

  const [getToolboxTalks, { refetch, called, fetchMore, loading, data }] =
    useAllToolboxTalksLazyQuery({
      variables: {
        first: batchSize,
        after: null,
        where: {
          ...TOOLBOX_TALK_DEFAULT_WHERE_ARGS,
          language:
            userLanguage === `es_mx`
              ? LanguageCodeFilterEnum[`EsMx`]
              : LanguageCodeFilterEnum[`EnUs`],
        },
      },
      notifyOnNetworkStatusChange: true,
    })

  const shouldFetchMore =
    (called && !loading && data?.toolboxTalks?.pageInfo?.hasNextPage) ?? false

  if (shouldFetchMore && fetchMore) {
    fetchMore({
      variables: {
        first: batchSize,
        after: data?.toolboxTalks?.pageInfo?.endCursor,
      },
    })
  }

  const doneFetching =
    ((!loading &&
      called &&
      data &&
      !data?.toolboxTalks?.pageInfo?.hasNextPage) ||
      data?.toolboxTalks?.edges?.length === 0) ??
    false
  const prevDoneFetching = usePrevious(
    doneFetching && data?.toolboxTalks?.pageInfo?.hasPreviousPage
  )

  React.useEffect(() => {
    if (doneFetching && !prevDoneFetching && fetching) {
      const edges = data?.toolboxTalks?.edges ?? []
      enqueueSnackbar(`${edges?.length} Toolbox Talks downloaded`)
      setTimeout(() => {
        // @ts-expect-error this is fine
        setOfflineData(edges).then(stopLoading).catch(stopLoading)
        setFetching(false)
      }, 300)
    }
    // eslint-disable-next-line
  }, [doneFetching, prevDoneFetching, enqueueSnackbar, fetching])

  return {
    getOfflineData: () => {
      setFetching(true)
      startLoading()
      !called ? getToolboxTalks() : refetch?.()
    },
    loading: isLoading,
    done: doneFetching,
    called: called,
  }
}

export default useOfflineToolboxTalks
